module.exports = {
    affiliate_info_title: "Earn higher commission as an affiliate!",
    affiliate_info_description: "When you buy traffic you become an affiliate, and will earn a higher commission by turning guests into paying members. Increase your commission by 15-40%",
    affiliate_info_link: "Click here to read more about being an affiliate",

    affiliate_earn_tokens: "Earn tokens in DivaTraffic",
    affiliate_earn_tokens_description: "Your affiliate earnings will be paid in tokens to your DivaTraffic account",
    affiliate_your_own_links: "Use your own affiliate link",
    affiliate_your_own_links_description: "Your affiliate earnings will be paid to your affiliate account at the camsite",

    affiliate_no_program_option: "Select Program",

    affiliate_program_error: "Error while saving",
    affiliate_param_error: "Not a valid ",
    affiliate_validation_error_title: "Save invalid settings?",
    affiliate_validation_error: "Not all affiliate program parameters are valid, do you want to them save anyway? The other settings will be saved.",
    save_everything: "Save all",
    only_valid: "Only valid",


}