module.exports = {
    accepted            : 'The :attribute must be accepted.',
    active_url          : 'The :attribute is not a valid URL.',
    after               : 'The :attribute must be a date after :date.',
    alpha               : 'The :attribute may only contain letters.',
    alpha_dash          : 'The :attribute may only contain letters, numbers, and dashes.',
    alpha_num           : 'The :attribute may only contain letters and numbers.',
    array               : 'The :attribute must be an array.',
    before              : 'The :attribute must be a date before :date.',
    between             : {
        numeric: 'The :attribute must be between :min and :max.',
        file: 'The :attribute must be between :min and :max kilobytes.',
        string: 'The :attribute must be between :min and :max characters.',
        array: 'The :attribute must have between :min and :max items.',
    },
    boolean             : 'The :attribute field must be true or false.',
    confirmed           : 'The :attribute confirmation does not match.',
    date                : 'The :attribute is not a valid date.',
    date_format         : 'The :attribute does not match the format :format.',
    different           : 'The :attribute and :other must be different.',
    digits              : 'The :attribute must be :digits digits.',
    digits_between      : 'The :attribute must be between :min and :max digits.',
    email               : 'The :attribute must be a valid email address.',
    exists              : 'The selected :attribute is invalid.',
    filled              : 'The :attribute field is required.',
    image               : 'The :attribute must be an image.',
    in                  : 'The selected :attribute is invalid.',
    integer             : 'The :attribute must be an integer.',
    ip                  : 'The :attribute must be a valid IP address.',
    json                : 'The :attribute must be a valid JSON string.',
    max                 : {
        numeric: 'The :attribute may not be greater than :max.',
        file: 'The :attribute may not be greater than :max kilobytes.',
        string: 'The :attribute may not be greater than :max characters.',
        array: 'The :attribute may not have more than :max items.',
    },
    mimes               : 'The :attribute must be a file of type: :values.',
    min                 : {
        numeric: 'The :attribute must be at least :min.',
        file: 'The :attribute must be at least :min kilobytes.',
        string: 'The :attribute must be at least :min characters.',
        array: 'The :attribute must have at least :min items.',
    },
    not_in              : 'The selected :attribute is invalid.',
    numeric             : 'The :attribute must be a number.',
    regex               : 'The :attribute format is invalid.',
    required            : 'The :attribute field is required.',
    required_if         : 'The :attribute field is required when :other is :value.',
    required_unless     : 'The :attribute field is required unless :other is in :values.',
    required_with       : 'The :attribute field is required when :values is present.',
    required_with_all   : 'The :attribute field is required when :values is present.',
    required_without    : 'The :attribute field is required when :values is not present.',
    required_without_all: 'The :attribute field is required when none of :values are present.',
    same                : 'The :attribute and :other must match.',
    size                : {
        numeric: 'The :attribute must be :size.',
        file: 'The :attribute must be :size kilobytes.',
        string: 'The :attribute must be :size characters.',
        array: 'The :attribute must contain :size items.',
    },
    string              : 'The :attribute must be a string.',
    timezone            : 'The :attribute must be a valid zone.',
    unique              : 'The :attribute has already been taken.',
    url                 : 'The :attribute format is invalid.',
    website             : 'The field is required. Write N/A if you don\'t have a website',

    order_boost_one_profile: 'Free boost available only for single profile.',
    order_free_boost    : 'You have no free boosts.',
    order_not_completed : 'Status of completed order cannot be changed.',
    order_not_cancelled : 'Status of canceled order cannot be changed.',
    order_active_update : 'Active order cannot be updated.',
    orders_use_white_label: 'White label is in use in orders: :orders',
    platform_white_label_url: 'Platform white label url is required.',
    platform_white_label_mobile_url: 'Platform white label mobile url is required.',
    password_match      : 'The password is incorrect.',
    payment_connection   : 'Payment system connection error. Please try later.',

    order: {
        plan_required: 'Please select a plan.',
        budget: 'You have insufficient tokens.',
    },

    payment: {
        user_subscribed: 'You already subscribed on a plan.',
        payment_plan_deleted: 'Plan was deleted on payment system.',
        payment_plan_does_not_exists: 'Payment plan does not exists.',
    },

    subscription_plan: {
        canceled: 'This plan has been already canceled.',
        permission: 'You do not have permissions to use this plan',
    },

    source: {
        banned: 'Source has been already banned',
        not_banned: 'Source is not banned',
    },

    /*
    |--------------------------------------------------------------------------
    | Custom Validation Language Lines
    |--------------------------------------------------------------------------
    |
    | Here you may specify custom validation messages for attributes using the
    | convention "attribute.rule" to name the lines. This makes it quick to
    | specify a specific custom language line for a given attribute rule.
    |
    */

    custom: {
        'attribute-name': {
            'rule-name': 'custom-message',
        },
        studiowebsite: {
            required: 'The field is required. Write N/A if you don\'t have a website'
        }
    },

    /*
    |--------------------------------------------------------------------------
    | Custom Validation Attributes
    |--------------------------------------------------------------------------
    |
    | The following language lines are used to swap attribute place-holders
    | with something more reader friendly such as E-Mail Address instead
    | of "email". This simply helps us make messages a little cleaner.
    |
    */

    attributes: {
        plan_id: 'plan',
        platform_id: 'platform',
    },
}