<template>
  <bread-crumb list-classes="breadcrumb-links breadcrumb-dark">
    <BreadCrumbItem>
      <li class="breadcrumb-item">
        <router-link to="/">
          <i class="fas fa-home"></i>
        </router-link>
      </li>
    </BreadCrumbItem>
    <BreadCrumbItem
      v-for="route in routes"
      :key="route.name"
      :active="true"
      style="display: inline-block"
    >
      <router-link
        :to="{ name: route.name }"
        v-if="route.isLink"
      >
        {{ getBreadName(route) }}
      </router-link>
      <span v-else>{{ getBreadName(route) }}</span>
    </BreadCrumbItem>
  </bread-crumb>
</template>

<script>
import BreadCrumb from "./Breadcrumb";
import BreadCrumbItem from "./BreadcrumbItem";

export default {
  name: "route-breadcrumb",
  components: {
    BreadCrumb,
    BreadCrumbItem,
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return null;
      }
    }
  },
  computed: {
    routes() {
      const res = [];
      if (this.items) {
        this.items.forEach(item => {
          res.push({name: item, isLink: true});
        });
        res.push({name: this.$route.name, isLink: false});
      } else {
        this.$route.matched.slice(1).forEach((item, index) => {
          res.push({name: item.name, isLink: index < this.$route.matched.length - 2});
        });
      }

      return res;
    }
  },
  methods: {
    getBreadName(route) {
      return this.getTrans('routes.' + route.name);
    },
  },
};
</script>

<style scoped></style>
