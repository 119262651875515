import StatsService from "../../service/stats/stats.service";
import dimensions from "../../service/stats/dimensions";
import AbstractSingleStore from "@/store/AbstractSingleStore";
import AbstractMultipleStore from "@/store/AbstractMultipleStore";

const statsService = new StatsService();

const state = {
  baseStateListName: 'stats',
  stats: {
    data: [],
    pagination: {},
  },
  datedStats: {
    data: []
  },
  totals: {
    data: {}
  },
};

const mutations = {
  SET_STATS: (state, payload) => {
    state.stats.data = payload.data;
    state.stats.pagination = payload.meta.pagination;
  },
  SET_STATS_REQUEST: (state, payload) => {
    state.currentParameters = payload;
  },
  RESET_STATS: state => {
    state.stats.data = [];
    state.currentParameters = null;
  },

  SET_TOTALS: (state, payload) => {
    state.totals.data = payload;
  },
  SET_TOTALS_REQUEST: (state, payload) => {
    state.totals.params = payload;
  }
};

const actions = {
  get: ({commit}, payload) => {
    let requests = [];
    commit('setListLoading', true);
    requests.push(statsService.getStats(payload).then(response => {
      commit("SET_STATS_REQUEST", payload);
      commit('setData', {name: state.baseStateListName, data: response.data.data, pagination: response.data.meta.pagination, loading: false});
      if (payload.groupBy=='date') {
        commit('setData', {name: 'datedStats', data: response.data.data, pagination: response.data.meta.pagination, loading: false});
      }
      return response;
    }).catch((error) => {
      commit('setListValidationErrors', error);
    }).finally(() => {
      commit('setListLoading', false)
    }));
  },

  getGraphData: ({commit}, payload) => {
    commit('setLoadingByName', {name: 'datedStats', val: true});
    let datedPayload = Object.assign({}, payload);
    statsService.getStats(datedPayload).then(response => {
      commit('setData', {name: 'datedStats', data: response.data.data, pagination: response.data.meta.pagination, loading: false});
      return response;
    }).catch((error) => {
      commit('setListValidationErrors', error);
    }).finally(() => {
      commit('setLoadingByName', {name: 'datedStats', val: false});
    });
  },

  reset: ({ commit }) => {
    commit("RESET_STATS");
  },

  getTotals: ({commit}, payload) => {
    if (!payload) payload = {};
    return statsService.getTotals(payload).then(response => {
      commit("SET_STATS_REQUEST", payload);
      commit('setData', {name: 'totals', data: response.data.data, loading: false});
      return response;
    }).catch((error) => {
      commit('setValidationErrorsByName', {name: 'totals', error: error});
    }).finally(() => {
      commit('setLoadingByName', {name: 'totals', val: false});
    });
  }
};

const getters = {
  getUserStats(state) {
    return state.stats.data;
  },
  getDatedStats(state) {
    if (state.datedStats.data && state.datedStats.data.length) {
      return state.datedStats.data;
    }
    return state.stats.data;
  },
  getCurrentStatsPageNumber(state) {
    if (state.stats?.pagination?.current_page) {
      return state.stats.pagination.current_page;
    }
    return 1;
  },
  getStatsPageCount(state) {
    if (state.stats?.pagination?.total_pages) {
      return state.stats.pagination.total_pages;
    }
    return 0;
  },

  getTotals(state) {
    return state.totals.data;
  },
  getDimensions() {
    return dimensions;
  },
  getDimensionIndexByParamName: () => (paramName) => {
    return dimensions.findIndex(d => d.param_name == paramName);
  }
};

export default AbstractMultipleStore.mergeInto(AbstractSingleStore.mergeInto({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}));
