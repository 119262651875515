import BaseService from '../BaseService'

export default class PlanService extends BaseService {
  constructor() {
    super();
    this.baseUrl = 'plan';
  }

  async getPackages(conditions) {
    return super.makeRequest('GET',
      {url: `${this.baseUrl}/?include[]=targetingData`, params: conditions}
    ).then(response => {
      return response.data;
    });
  }

  async getPackage(id) {
    return super.makeRequest('GET',
      {url: `${this.baseUrl}/${id}?include[]=user&include[]=targetingData`}
    )
  } 

  savePackage(context, payload) {
    return super
      .makeRequest(
        "PATCH",
        "plan/" + payload.id,
        payload,
        context
      )
  }
  async createPackage(context, payload) {
    return super
      .makeRequest(
        "POST",
        "plan",
        payload,
        context
      )
  }
  async deletePackage(context, payload) {
    return super
      .makeRequest(
        "DELETE",
        "plan/" + payload, null,
        context
      )
      .catch(error => {
        throw error;
      });
  }
  async getPage(filter) {
    let url = "plan?include[]=targetingData&page=";
    url += filter.page ? filter.page : "1"
    if (filter.filters) {
      filter.filters.forEach(f => {
        url += "&filter[" + f.key + "]=" + f.value
      })
    }
    return super
      .makeRequest(
        "GET",
        url, { page: filter.page }
      ).then(response => {
        return response.data;
      });
  }
  async daily_volume(context, payload) {
    let url = "plan/daily_volume";

    return super.makeRequest("POST", url, payload, context
    ).then((response) => {
      return response;
    }).catch((err)=>{
      throw err;
    });
  }
}
