import ProfileService from "../../service/profile/profile.service";
import AbstractSingleStore from "@/store/AbstractSingleStore";

const profileService = new ProfileService();

const profileStore = {
  namespaced: true,
  state: {
    baseStateName: 'profile',
    baseStateListName: 'profiles',
    profile: {},
    profiles: {
      data: []
    },
    waitGet: [],
    page: 1,
    totalPages: 1,
  },
  getters: {
    getProfiles(state) {
      return state.profiles.data;
    },
    getFilteredProfiles: (state) => (filters) => {
      let result = state.profiles.data;
      if (Object.keys(filters).length>0) {
        //filter on online/offline
        if (filters.status) {
          result = result.filter(p => {
            return p.performer.data.online == filters.status;
          })
        }
        //filter on name
        if (filters.search) {
          result = result.filter(p => {
            return p.performer.data.name.toLowerCase().indexOf(filters.search.toLowerCase())>=0;
          })
        }
        //filter on platform
        if (filters.platform) {
          result = result.filter(p => {
            return p.performer.data.platform_id == filters.platform;
          })
        }
      }
      return result;
    },
    //contains only profiles, type=1
    getProfileById: (state) => (id) => {
      try {
        let profile = state.profiles.data.find((p) => p.id == id);
        return profile;
      } catch (e) {
        return null;
      }
    },
    getProfilePageNum(state) {
      return state.page;
    },
    getProfilesTotalPages(state) {
      return state.totalPages;
    },
    getProfileTypes() {
      return profile_types;
    },
    getProfilesFromOrder: (state) => (order) =>{
      if (!order || !order.profiles || !order.profiles.data) {
        return [];
      }
      return order.profiles.data.map(op => {
        let n = Object.assign({}, state.profiles.data.find(p=>p.id==op.profile_id));
        n.stats = Object.assign(Object.assign({},order.profilesStatistic.find(os=>os.profile_id==op.profile_id)), op);
        n.stats.viewers = order.viewers[op.profile_id];

        return n;
      });
    },
    getOrderProfiles: (state) => (order) => {
      return state.profiles.data.filter(p => {
        return order.profilesId.indexOf(p.id)>=0;
      })
    },

    getType: () => (profile) => {
      if (profile && profile.type) {
        return profile_types[profile.type];
      } else {
        return '';
      }
    },
    getCamsiteProfilesFromList: () => (list) => {
      if (list)
        return list.filter(i => i.type == profile_types['profile']);

      return [];
    },
    getProfileLandingUrl: () => (profile) => {
      return process.env.VUE_APP_API_URL + 'profile/go_to_landing/' + profile.id;
    }
  },
  mutations: {
    setProfiles: (state, payload) => {
      state.profiles.data = Array.isArray(payload)?payload.filter(p=>p.type==1):[];
    },
  },
  actions: {
    getAll: ({ commit }) => {
      return profileService.getAllProfiles()
      .then((response) => {
        commit("setProfiles", response.data.data);
      }).finally(() => {
        commit('setListLoading', false);
      });
    },
    getProfiles: ({commit, state}, conditions) => {
      commit('setListLoading');
      setTypeFilter(conditions, 1);
      return profileService.getProfiles(conditions).then(response => {
        commit('setData', {name: state.baseStateListName, data: response.data, pagination: response.meta.pagination, loading: false});
      }).finally(() => {
        commit('setListLoading', false);
      });
    },

    getById({commit}, id) {
      commit('setLoading');
      return profileService.getById(id).then(profile => {
        commit('setData', {data: profile});
      }).finally(() => commit('setLoading', false));
    },

    reset: ({state}) => {
      state.profiles.data = [];
      state.profiles.pagination = null;
    },

    create: ({commit}, profile) => {
      commit('setLoading');
      commit('setErrors', null);
      return profileService.create(profile)
        .then(p => commit('setData', {data: p}))
        .catch(error => {
          commit('setValidationErrors', error);
          return Promise.reject(error)
        }).finally(() => commit('setLoading', false));
    },

    update({commit}, payload) {
      commit('setLoading');
      const data = payload.data;
      return profileService.update(data.id, data, {include: payload.include}).then(profile => {
        commit('setData', {data: profile});
        commit('updateList', profile);
      }).catch(error => {
        commit('setValidationErrors', error);
        return Promise.reject(error)
      }).finally(() => commit('setLoading', false));
    },

    save: ({commit}, profile) => {
      return profileService.save(profile)
        .then(p => commit('setData', {data: p}))
        .catch(error => {
          commit('setValidationErrors', error);
          return Promise.reject(error)
        });
    },

    batch: () => {
      // eslint-disable-next-line no-console
      console.log("need Profile/BATCH ");
      //'profile/batch?include[]=performer',
    },

    delete: ({commit}, id) => {
      commit('setLoading');
      return profileService.delete(id).then(() => {
        commit('removeFromList', id);
      }).finally(() => commit('setLoading', false));
    },
  }
};

function setTypeFilter(conditions, type) {
  const filter = (conditions?.filter || {});
  filter['type'] = filter.type || type;
  conditions['filter'] = filter;
}

export default AbstractSingleStore.mergeInto(profileStore);

const profile_types = {
  1:'profile',
  2:'link',
  'profile':1,
  'link':2,
}
