import BaseService from "../BaseService";

export default class TransactionService extends BaseService {
  constructor() {
    super();
  }

  async getBalance(conditions) {
    return super
      .makeRequest(
        "GET",
          {url: "balance", params: conditions}
      )
      .then(response => {
        return response.data;
      });
  }

  async getPayments(conditions) {
    return super
        .makeRequest(
            'GET',
            {url: 'payment', params: conditions}
        ).then(response => {
          return response.data;
        });
  }
}
