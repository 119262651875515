import PlatformService from "../../service/platform/platform.service";
import AbstractSingleStore from "@/store/AbstractSingleStore";

const platformService = new PlatformService();

const platformStore = {
  namespaced: true,
  state: {
    baseStateListName: 'platforms',
    platforms: {
      data: [],
    },
  },
  getters: {
    getPlatforms(state) {
      return state.platforms.data;
    },
    getPlatformById: (state) => (id) => {
      try {
        return state.platforms.data.find((p) => p.id == id);
      } catch (e) {
        return {title:""};
      }
    },
    getSearchResults(state) {
      return state.search;
    },
    getCamsites(state) {
      return state.platforms.data.filter(p => {
        return p.types.includes(1);
      });
    },
    getClipsites(state) {
      return state.platforms.data.filter(p => {
        return p.types.includes(2);
      });
    },
    getCamsite: (state) => (id) => {
      if (state.camsites) {
          let c = state.camsites.data.find(c => {
              return c.id == id;
          })
          return c;
      }
      return { title: "" }
    },
    isCaptchaBlocked: () => (url) => {
      let domain = (new URL(url)).hostname.replace('www.','');
      if(domain.length>3 && domain.includes('.')){
          return captchaBlocked.findIndex(element => element.includes(domain)) >= 0;
      }
      return false;
    }
  },
  actions: {
    get: ({commit, state}) => {
      if (!state.platforms.data?.length) {
        commit('setListLoading');
        return platformService.getPlatforms().then(response => {
          commit('setData', {name: state.baseStateListName, data: response.data});
        }).finally(() => {
          commit('setListLoading', false);
        });
      }
      return Promise.resolve();
    },
  }
};

export default AbstractSingleStore.mergeInto(platformStore);

const captchaBlocked = [
  'twitter.com', 'onlyfans.com'
]