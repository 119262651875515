module.exports = {
    boost: {
        title: 'Nombre del boost',
        profiles: 'Selecciona los perfiles a los que deseas enviar tráfico. Puedes seleccionar varios perfiles a la vez.',
        packages: 'Selecciona el paquete de tráfico que deseas. Un paquete tiene configuración para tipo de tráfico y precio.',
        options: 'Selecciona la cantidad de tokens que quieres gastar en esta orden. <br> También puedes establecer el límite máximo de espectadores. Esto significa que al llegar al límite, no enviaremos más tráfico hasta que el conteo de espectadores esté otra vez por debajo del número que has seleccionado. Dependiendo del camsite y la orientación del paquete, no hay garantía de alcanzar este número.',
        autostart: 'Cuando una modelo se desconecta o se pasa al privado, la orden se pausará. Con esta opción, el pedido se reanudará automáticamente cuando la modelo vuelva a estar en línea.',
        autorenew: 'Esta opción creará automáticamente una nueva orden idéntica con el mismo precio',
        create: 'Pinche para completar su orden'
    },
    model_intro: {
        header: 'No se han añadido modelos',
        text: 'Antes de boostear a tus modelos, necesitamos saber quiénes son y en cuáles sitios tienen sus perfiles.',
        button: 'Pincha aquí para añadir una modelo a tu cuenta'
    },
    profile_intro: {
        header: 'No hay perfiles añadidos',
        text: 'Antes de boostear, debemos saber en qué sitios estás registrada y el nombre de tu perfil.',
        button: 'Pincha aquí para anadir un perfil a tu cuenta'
    },
    tokens_intro: {
        header: 'No tienes tokens',
        text: 'Los boosts de DivaTraffic se pagan con tokens.',
        button: 'Pincha aquí para comprar tokens'
    },
    orders_intro: {
        header: '¡Ya estás lista para tu primer boost!',
        text: 'Ahora puedes comenzar a aumentar el tráfico de la modelo que has añadido.',
        button: 'Pincha aquí para boostear a la modelo'
    },
    add_model: {
        name: 'Ingrese el nombre de la modelo',
        email: 'Rellenar el campo de correo electrónico no es necesario, pero si quieres que la modelo acceda a DivaTraffic para administrar su propia cuenta, debes facilitar la dirección de correo electrónico.',
        performer: 'Primero selecciona la plataforma camsite en la que está la modelo, luego ingresa el nombre de usuario con el que se identifica en el sitio.',
        image: 'Selecciona una imagen para el perfil',
        save: 'Pincha para guardar'
    },
    add_package: {
        name: 'Nombre del paquete y descripción',
        camsites: 'Puedes optar por que este paquete solo funcione para modelos de los camsites seleccionados. No rellenes el campo para que funcione en todos los camsites',
        targeting_tooltip: 'Para crear tu propio paquete, debes seleccionar entre las opciones de targeting de las que deseas recibir tráfico',
        selecting: 'Puedes seleccionar tantas opciones como desees. Deja el campo vacío para seleccionar todo',
        bids: 'Una vez que hayas establecido las selecciones de targeting, ve a ofertas para establecer los precios',
        get_started: 'Pincha este botón para empezar'
    },
    bids: {
        default_bid: 'La oferta es el costo en $ por cada 1000 visitantes que coincida con tu targeting',
        dynamic_bids: 'Si quieres personalizar el precio por targeting  específico dentro de tu plan, puedes activar las ofertas dinámicas.',
        add_bid: 'Agregar ofertas puede ayudarte a aumentar las ganancias. Agrega una oferta más alta para el targeting que te ofrezca mejores resultados, así podrás recibir más de este tráfico',
        click: 'Pincha para agregar una oferta',
        custom_bid: 'Selecciona el targeting y la oferta. Puedes agregar tantas ofertas personalizadas como desees'
    }
}