import AbstractSingleStore from "@/store/AbstractSingleStore";
import PostbackService from "../../service/postback/postback.service";

const module = {
    namespaced: true,
    state: {
        baseStateName: 'postbacks',
        postbacks: {
            data: [],
            loading: false,
        },
    },
    actions: {
        get: ({commit}) => {
            commit('setListLoading', true);
            return PostbackService.getPostbacks().then(response=>{
                commit("setList", {data: response.data, loading:false});
            }).catch(error => {
                commit('setValidationErrors', error);
                return Promise.reject(error);
            });
        }
    }
}

export default AbstractSingleStore.mergeInto(module);
  