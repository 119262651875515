import PaymentService from "../../service/payment/payment.service";
const paymentService = new PaymentService();

const state = {};
const getters = {};
const actions = {
  startPayment(context, payload) {
    return paymentService.startPayment(payload.type, payload.price);
  },
  sendToPaypal({dispatch}, price) {
    return dispatch('startPayment', {type: 2, price:price})
      .then(result => {
        window.location.href = result.data.response.redirect;
      });
  },
  sendWiretransfer(context, price) {
    return paymentService.sendWiretransfer({ 
      type: 6,
      "create-wiretransfer": 1,
      "load-total-amount": price,
    })
  },
  sendSecurionPay({dispatch}, price) {
    return dispatch('startPayment', {type: 36, price: price})
  },
  securionConfirm(context, chargeId) {
    return paymentService.securionConfirm(chargeId)
  }
};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
