<template>
  <div class="card">
    <div class="card-body">
      <div class="filters__container">
        <div class="filters__item checkbox">
          <base-checkbox>Filter by checkbox</base-checkbox>
        </div>
        <div class="filters__item">
          <ElSelect :name="'testSelect'" v-model="select" :class="'filters__select'" clearable placeholder="Sort by">
            <ElOption
              v-for="(option, index) in options"
              :class="'filterOptionClass'"
              :value="option.value"
              :key="index"
              :label="option.name"
            >{{option.name}}</ElOption>
          </ElSelect>
        </div>
        <div class="filters__item">
          <base-input :name="'search'" :type="text" :placeholder="'Search By'" :formClasses="'filters__input-wrapper'" :inputClasses="'filters__input'">
          </base-input>
        </div>
        <div class="filters__item">
          <base-radio
            name="radio0"
            class="filters__radio-wrapper"
          >
            Sort By Radio
          </base-radio>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {ElSelect, ElOption} from 'element-plus';
  export default {
    name: 'static',
    components: {
      ElSelect,
      ElOption,
    },
    props: {
      options: {
        type: Array,
        default: ()=> {
          return [
            {value: 1, name: 'Test Option 1'},
            {value: 2, name: 'Test Option 2'}
          ]
        },
      },
    }
  };
</script>

<style>

</style>
