module.exports = {
  dashboard: 'Dashboard',
  tokens: 'Tokens',
  wiretransfer: 'Wiretransfer',
  transactions: 'Transactions',
  orders: 'Orders',
  order_stats: 'Order statistic',
  studio: 'My Studio',
  profiles: 'Profiles',
  add_profile: 'Add profile',
  edit_profile: 'Edit profile',
  links: 'Links',
  add_link: 'Add link',
  edit_link: 'Edit link',
  resources: 'Resources',
  statistics: 'Statistics',
  packages: 'Packages',
  create_package: 'Create package',
  settings: 'Settings',
  account: 'Account',
  password: 'Password',
  billing: 'Billing',
  privacy: 'Privacy',
  notifications: 'Notifications',
  subscription: 'Subscription',
  camsite: 'Camsite',
  payouts: 'Payouts',
  tools: 'Tools',
  invite_friend: 'Invite a friend',
  boost: 'Boost my cam',
  boost_link: 'Boost my link',
  models: 'Models',
  add_model: 'Add model',
  model_settings: 'Model Settings',
  model_account: 'Account',
  model_profiles: 'Profiles',
  model_tokens: 'Tokens',
  model_password: 'Password',
  model_privacy: 'Privacy',
  model_social: 'Social',
  not_found: 'Page not found',
}
