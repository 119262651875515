const state = {
  os: null
};

const mutations = {
  SET_OS: (state, payload) => {
    state.os = payload;
  }
};

const actions = {
  get: ({ dispatch }) => {
    return dispatch("Dictionary/get", null, {root: true})
  }
};

const getters = {
  getOs(state) {
    return state.os;
  },
  getOsById: state => id => {
    return state.os.find(c => {
      return id == c.id;
    })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
