/*!

=========================================================
* Vue Argon Dashboard PRO - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { createApp } from 'vue';
import App from './App.vue';
import router from './routes/router';
import ArgonDashboard from './plugins/argon-dashboard';
import 'element-plus/lib/theme-chalk/index.css';
import swal from 'sweetalert2';
import 'vue-toastification/dist/index.css';
import { provideAppToast } from './components/toast.js';
import VueGtag from 'vue-gtag';

import store from './store';
import ContentLoader from '@/components/Loaders/ContentLoader';
import FullScreenLoader from '@/components/Loaders/FullScreenLoader';
import DivaCard from '@/components/Content/DivaCard';

/**global mixins */
import lang from './mixins/lang';

// Set base API URL.
import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const options = {
  containerClassName: 'ct-notification',
  maxToasts: 10,
  newestOnTop: true,
};

const appInstance = createApp(App);
appInstance.config.globalProperties.$swal = swal;
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(store);
appInstance.use(provideAppToast, options);
appInstance.use(VueGtag, { config: { id: 'UA-73165016-1' } });
appInstance.component('content-loader', ContentLoader);
appInstance.component('full-screen-loader', FullScreenLoader);
appInstance.component('diva-card', DivaCard);
appInstance.mixin(lang);
appInstance.mount('#app');
store.dispatch('Lang/set', 'en');
store.dispatch('Lang/load');
