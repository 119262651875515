module.exports = {
    /*
    |--------------------------------------------------------------------------
    | Validation Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines contain the default error messages used by
    | the validator class. Some of these rules have multiple versions such
    | as the size rules. Feel free to tweak each of these messages here.
    |
    */

    accepted            : 'El/la :attribute debe ser aceptado.',
    active_url          : 'El/la :attribute no es una URL válida.',
    after               : 'El/la :attribute debe ser una fecha posterior a :date.',
    alpha               : 'El/la :attribute debe contener sólo letras.',
    alpha_dash          : 'El/la :attribute debe contener sólo letras, números y guiones.',
    alpha_num           : 'El/la :attribute debe contener sólo letras y números.',
    array               : 'El/la :attribute debe ser una matriz.',
    before              : 'El/la :attribute must be a date before :date.',
    between             : {
        numeric: 'El/la :attribute debe ser entre :min y :max.',
        file: 'El/la :attribute debe ser entre :min y :max kilobytes.',
        string: 'El/la :attribute debe ser entre :min y :max caracteres.',
        array: 'El/la :attribute debe ser entre :min y :max items.',
    },
    boolean             : 'El campo :attribute debe ser verdadero o falso.',
    confirmed           : 'La confirmación del/la :attribute confirmation no coincida.',
    date                : 'El/la :attribute no es una fecha válida.',
    date_format         : 'El/la :attribute no coincide con el formato :format.',
    different           : 'El/la :attribute y :other deben ser diferentes.',
    digits              : 'El/la :attribute deben ser :digits dígitos.',
    digits_between      : 'El/la :attribute debe ser entre :min y :max dígitos.',
    email               : 'El/la :attribute debe ser una dirección de correo electrónico válida.',
    exists              : 'El :attribute seleccionado es inválido.',
    filled              : 'El campo :attribute es obligatorio.',
    image               : 'El/la :attribute debe ser una imagen.',
    in                  : 'El/la :attribute seleccionado es inválido.',
    integer             : 'El/la :attribute debe ser un íntegro.',
    ip                  : 'El/la :attribute debe ser una dirección de IP válida.',
    json                : 'El/la :attribute debe ser una cadena JSON válida.',
    max                 : {
        numeric : 'El/la :attribute no puede ser mayor que :max.',
        file : 'El/la :attribute no puede ser mayor de :max kilobytes.',
        string : 'El/la :attribute no puede ser mayor de :max caracteres.',
        array : 'El/la :attribute no puede tener más de :max items.',
    },
    mimes               : 'El/la :attribute debe ser un archivo type: :values.',
    min                 : {
        numeric: 'El/la :attribute debe ser de al menos :min.',
        file: 'El/la :attribute debe ser de al menos :min kilobytes.',
        string: 'El/la :attribute debe ser de al menos :min caracteres.',
        array: 'El/la :attribute debe ser de al menos :min items.',
    },
    not_in              : 'El/la :attribute seleccionado es inválido.',
    numeric             : 'El/la :attribute debe ser un número.',
    regex               : 'El formato del/la :attribute es inválido.',
    required            : 'El campo :attribute es obligatorio.',
    required_if         : 'El campo :attribute es obligatorio cuando :other es :value.',
    required_unless     : 'El campo :attribute es obligatorio a menos que :other esté en :values.',
    required_with       : 'El campo :attribute es obligatorio cuando :values está presente.',
    required_with_all   : 'El campo :attribute es obligatorio cuando :values está presente.',
    required_without    : 'El campo :attribute es obligatorio cuando :values no está presente.',
    required_without_all: 'El campo :attribute es obligatorio cuando estos  :values están presente.',
    same                : 'El/la :attribute y :other deben coincidir.',
    size                : {
        numeric: 'El/la :attribute debe ser de :size.',
        file: 'El/la :attribute debe ser de :size kilobytes.',
        string: 'El/la :attribute debe ser de :size caracteres.',
        array: 'El/la :attribute debe contener :size items.',
    },
    string              : 'El/la :attribute debe ser una cadena.',
    timezone            : 'El/la :attribute debe ser una zona válida.',
    unique              : 'El/la :attribute ya ha sido tomado.',
    url                 : 'El formato del/la :attribute format es inválido.',
    website             : 'El campo es obligatorio. Escriba N/A si no tiene un sitio web',
    order_boost_one_profile: 'Ampliación gratuita disponible solo para perfiles individuales.',
    order_free_boost    : 'Usted no tiene boosts libres.',
    order_not_completed : 'No se puede cambiar el estado de la orden completada.',
    order_not_cancelled : 'El estado del pedido cancelado no se puede cambiar.',
    order_active_update : 'La orden activa no se puede actualizar.',
    orders_use_white_label: 'La etiqueta blanca está en uso en pedidos: :orders',
    platform_white_label_url: 'Se requiere url de etiqueta blanca de plataforma.',
    platform_white_label_mobile_url: 'Se requiere url móvil de etiqueta blanca de plataforma.',
    password_match      : 'La contraseña no es válida.',

    order: {
        plan_required: 'Por favor seleccione un plan.',
    },

    /*
    |--------------------------------------------------------------------------
    | Custom Validation Language Lines
    |--------------------------------------------------------------------------
    |
    | Here you may specify custom validation messages for attributes using the
    | convention "attribute.rule" to name the lines. This makes it quick to
    | specify a specific custom language line for a given attribute rule.
    |
    */

    custom: {
        'attribute-name': {
            'rule-name': 'mensaje personalizado',
        },
        studiowebsite: {
            required: 'El campo es obligatorio. Escriba N/A si no tiene un sitio web'
        }
    },

    /*
    |--------------------------------------------------------------------------
    | Custom Validation Attributes
    |--------------------------------------------------------------------------
    |
    | The following language lines are used to swap attribute place-holders
    | with something more reader friendly such as E-Mail Address instead
    | of "email". This simply helps us make messages a little cleaner.
    |
    */

    attributes: {
        plan_id: 'plan',
        platform_id: 'plataforma',
    },
}