import BaseService from '../BaseService';

export default class UserService extends BaseService {
  async login(payload) {
    return super.makeRequest('POST', 'login', payload).then((response) => {
      return response.status === 200;
    });
  }

  async logout() {
    return super
      .makeRequest('POST', 'logout')
      .then((response) => {
        return response.status === 200;
      })
      .catch((response) => {
        throw response;
      });
  }

  async getUserInfo() {
    return super
      .makeRequest(
        'GET',
        'user?include[]=managedStudio&include[]=blockedCountries'
      )
      .then((response) => {
        return response.data.data;
      });
  }

  //register and login
  async registerUser(payload) {
    return super.makeRequest('POST', 'register', payload).then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    });
  }

  async saveUser(payload) {
    return super
      .makeRequest(
        'PATCH',
        'user?include[]=managedStudio&include[]=blockedCountries',
        payload,
        true
      )
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  async deleteCamsiteSettings(payload) {
    return super.makeRequest('DELETE', 'user/platform/' + payload.id, payload);
  }
}
