import BaseService from "../BaseService";

export default class PaymentService extends BaseService {
  constructor() {
    super();
    this.baseUrl = 'payment';
  }
  async startPayment(type, price) {
    return super.makeRequest('POST', `${this.baseUrl}/load`, {
      'paymentAccountId': type,
      'other-amount': price,
      dataType: 'json'
    })
  }
  async sendWiretransfer(payload) {
    return super.makeRequest('POST', `${this.baseUrl}/load`, {
      'paymentAccountId': payload.type,
      "create-wiretransfer": 1,
      "load-total-amount": payload.price,
      dataType: 'json'
    })
  }
  async securionConfirm(chargeId) {
    return super.makeRequest("POST", `${this.baseUrl}/securion_pay_confirm_payment`, {
      'paymentAccountId': 36,
      'tokenId': chargeId,
      dataType: 'json'
    })
  }
}
