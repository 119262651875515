module.exports = {
  order_failed_to_start: "Error while starting order",
  order_failed_while_create: "Error while creating order",
  info_chaturbate_title: "Can't find your Chaturbate Profile?",
  info_chaturbate_text:
    "If you can't find your Chaturbate profile, or it is always offline, make sure to check your Chaturbate privacy settings",
  info_chaturbate_link: "Click here to read more",
  remove_deleted_models: "Hide deactivated",
  model_deleted: "Model Deleted",
  deleted_model: "Model Deleted",
  model_restore: "Restore",
  model_restore_user: "Restore model",
  model_restore_user_text:
    "The account has been disabled, do you want to restore it?",
  model_restore_user_notice: "",
  model_restore_response_success: "Success",
  model_restore_response_success_text:
    "Please refresh page to view list with this model",
  model_restore_response_error: "Error",
  model_restore_response_error_text:
    "Error occured while trying to restore model account",

  //register page
  reg_step_one: "Step 1",
  reg_type: "Select Account Type",
  reg_step_two: "Step 2",
  reg_details: "Account Details",
  reg_step_three: "Step 3",
  reg_email_verify: "Verify Email",
  reg_step_four: "Step 4",
  reg_sub_type: "Select plan",
  reg_step_five: "Step 5",
  reg_payment: "Payment",

  //settings page
  account_type_studio: "Studio",
  account_type_model: "Model",
  account_type_affiliate: "Affiliate",

  tokens_to_spend: "Number of Tokens to use",
};
