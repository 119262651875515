import BaseService from '../BaseService'

export default class PerformerService extends BaseService {
    constructor() {
        super();
        this.baseUrl = 'performer';
    }

    async search(payload) {
        return super.makeRequest('GET',
          `${this.baseUrl}/search?name=${payload.search}&platform_id=${payload.platform_id}`
        ).then(response => response.data);
    }
}