import moment from "moment";
import store from "../../store";

export default [
  {
    param_name: "date",
    table_name: "event_date",
    display_name: "date",
    type: "date",
    displayValue(value) {
      return (
        moment(value[0]).format("YYYY-MM-DD") +
        " -> " +
        moment(value[1]).format("YYYY-MM-DD")
      );
    },
    paramValue(value) {
      return [
        "filter[date_from]=" + moment(value[0]).format("YYYY-MM-DD"),
        "filter[date_to]=" + moment(value[1]).format("YYYY-MM-DD")
      ];
    },
    createValue(value) {
      if (Array.isArray(value)) {
        return [moment(value[0], "YYYY-MM-DD"), moment(value[1], "YYYY-MM-DD")];
      } else {
        return [
          moment(value, "YYYY-MM-DD"),
          moment(value, "YYYY-MM-DD").add(1, "days")
        ];
      }
    },
    filterDisabled: true
  },
  {
    param_name: "country",
    targeting_param_name: "countries",
    table_name: "country_id",
    table_display_name: "country_name",
    table_null_trans: "messages.no_country",
    display_name: "country",
    type: "country",
    displayValue(value) {
      try {
        return store.getters["Country/getCountries"].find(c => c.id == value)
          .name;
      } catch (e) {
        return value;
      }
    },
    paramKey: 'filter[country]',
  },
  {
    param_name: "order",
    table_name: "order_id",
    table_display_name: "order_name",
    table_null_trans: "messages.no_order",
    display_name: "order",
    type: "number",
    displayValue(value) {
      return value+"";
    },
    filterDisabled: true
  },
  {
    param_name: "model_profile",
    table_name: "profile_id",
    display_name: "profile",
    type: "text",
    displayValue(value) {
      return value;
    },
    filterDisabled: true,
  },
  {
    param_name: "model_user",
    table_name: "user_account_id",
    display_name: "user",
    type: "number",
    displayValue(value) {
      return value;
    },
    filterDisabled: true,
  },
  {
    param_name: "plan",
    table_name: "plan_id",
    table_display_name: "plan_name",
    table_null_trans: "messages.no_package",
    display_name: "package",
    type: "text",
    displayValue(value) {
      return value;
    },
    filterDisabled: true,
  },
  {
    param_name: "tool",
    table_name: "tool_id",
    table_display_name: "tool_name",
    table_null_trans: "messages.no_tool",
    display_name: "tool",
    type: "tool",
    displayValue(value) {
      return value;
    },
    filterDisabled: true,
  },
  {
    param_name: "network",
    targeting_param_name: "networks",
    table_name: "network_id",
    table_display_name: "network_name",
    table_null_trans: "messages.no_network",
    display_name: "network",
    type: "network",
    displayValue(value) {
      try {
        return store.getters["Network/getNetworkById"](value)
          .title;
      } catch (e) {
        return "error";
      }
    },
  },
  {
    param_name: "date_year",
    table_name: "date_year",
    display_name: "date_year",
    type: "date",
    displayValue(value) {
      return (
        moment(value[0]).format("YYYY-MM-DD") +
        " -> " +
        moment(value[1]).format("YYYY-MM-DD")
      );
    },
    filterDisabled: true,
    groupByDisabled: true,
  },
  {
    param_name: "source",
    targeting_param_name: "sources",
    table_name: "source_id",
    table_display_name: "source",
    table_null_trans: "messages.no_source",
    display_name: "source",
    type: "text",
    displayValue(value) {
      return value;
    },
    filterDisabled: true,
  },
  {
    param_name: "platform",
    table_name: "platform_id",
    table_display_name: "platform_name",
    table_null_trans: "messages.no_camsite",
    display_name: "camsite",
    type: "camsite",
    displayValue(value) {
      try {
        return store.getters["Platform/getPlatformById"](value)
          .title;
      } catch (e) {
        return "error";
      }
    }
  },
  {
    param_name: "device_type",
    targeting_param_name: "device_types",
    table_name: "device_type_id",
    table_display_name: "device_type",
    table_null_trans: "messages.no_device_type",
    display_name: "device_type",
    type: "devices",
    displayValue(value) {
      try {
        return store.getters["DeviceType/getDeviceTypeById"](value)
          .name;
      } catch (e) {
        return "error";
      }
    },
  },
  {
    param_name: "device_brand",
    targeting_param_name: "device_brands",
    table_name: "device_vendor_id",
    table_display_name: "device_brand",
    table_null_trans: "messages.no_device_brand",
    display_name: "device_brand",
    type: "brands",
    displayValue(value) {
      try {
        return store.getters["DeviceBrand/getDeviceBrandById"](value)
          .name;
      } catch (e) {
        return "error";
      }
    },
  },
  {
    param_name: "browser",
    targeting_param_name: "browsers",
    table_name: "browser_id",
    table_display_name: "browser",
    table_null_trans: "messages.no_browser",
    display_name: "browser",
    type: "browser",
    displayValue(value) {
      try {
        return store.getters["Browser/getBrowserById"](value)
          .name;
      } catch (e) {
        return "error";
      }
    },
  },
  {
    param_name: "os",
    targeting_param_name: "os",
    table_name: "os_id",
    table_display_name: "os",
    table_null_trans: "messages.no_os",
    display_name: "os",
    type: "os",
    displayValue(value) {
      try {
        return store.getters["Os/getOsById"](value)
          .name;
      } catch (e) {
        return "error";
      }
    },
  },
  {
    param_name: "sub_id1",
    table_name: "subid1",
    display_name: "sub_id1",
    type: "text",
    displayValue(value) {
      return value;
    },
    filterDisabled: true,
  },
  {
    param_name: "sub_id2",
    table_name: "subid2",
    display_name: "sub_id2",
    type: "text",
    displayValue(value) {
      return value;
    },
    filterDisabled: true,
  },
  {
    param_name: "sub_id3",
    table_name: "subid3",
    display_name: "sub_id3",
    type: "text",
    displayValue(value) {
      return value;
    },
    filterDisabled: true,
  },
  {
    param_name: "sub_id4",
    table_name: "subid4",
    display_name: "sub_id4",
    type: "text",
    displayValue(value) {
      return value;
    },
    filterDisabled: true,
  },
  {
    param_name: "sub_id5",
    table_name: "subid5",
    display_name: "sub_id5",
    type: "text",
    displayValue(value) {
      return value;
    },
    filterDisabled: true,
  }
];
