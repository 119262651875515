module.exports = {
    /*
    |--------------------------------------------------------------------------
    | Локализация страницы проверки
    |--------------------------------------------------------------------------
    |
    | Представленные ниже фразы используются для отображения различных сообщений
    | об ошибках при валидации классов. Некоторые из указанных правил могут иметь несколько
    | вариантов ответа. Вы можете свободно изменять любые сообщения в данном разделе.
    |
    */

    accepted            : 'Необходимо принять :attribute.',
    active_url          : 'Значение :attribute не является действительной ссылкой.',
    after               : 'Значение :attribute должно являться датой, которая наступает позднее :date.',
    alpha               : 'Значение :attribute может содержать только буквы.',
    alpha_dash          : 'Значение :attribute может содержать только буквы, цифры и знак тире.',
    alpha_num           : 'Значение :attribute может содержать только буквы и цифры.',
    array               : 'Значение :attribute должно являться множеством.',
    before              : 'Значение :attribute должно являться датой, которая наступает ранее :date.',
    between             : {
        numeric: 'Значение :attribute должно находиться в пределах от :min до :max.',
        file: 'Значение :attribute должно находиться в пределах от :min до :max килобайт.',
        string: 'Значение :attribute должно находиться в пределах от :min до :max символов.',
        array: 'Значение :attribute должно включать от :min до :max элементов.',
    },
    boolean             : 'Значение поля :attribute должно быть правильным или неправильным.',
    confirmed           : 'Подтверждение для :attribute не совпадает.',
    date                : 'Значение :attribute не является действительной датой.',
    date_format         : 'Значение :attribute не соответствует формату :format.',
    different           : 'Значения :attribute и :other не должны совпадать.',
    digits              : 'Значение :attribute должно состоять из :digits цифр.',
    digits_between      : 'Значение :attribute должно находиться в пределах от :min до :max цифр.',
    email               : 'Значение :attribute должно являться действительным адресом электронной почты.',
    exists              : 'Выбранное значение :attribute является недействительным.',
    filled              : 'Поле :attribute обязательно к заполнению.',
    image               : 'Значение :attribute должно быть изображением.',
    in                  : 'Выбранное значение :attribute недействиельно.',
    integer             : 'Значение :attribute должно являться целым числом.',
    ip                  : 'Значение :attribute должно являться действительным IP-адресом.',
    json                : 'Значение :attribute должно являться действительной строкой JSON.',
    max                 : {
        numeric: 'Значение :attribute не может быть выше :max.',
        file: 'Размер :attribute не может быть больше :max килобайт.',
        string: 'Значение :attribute не может быть длиннее :max символов.',
        array: 'Значение :attribute не может включать больше :max элементов.',
    },
    mimes               : 'Значение :attribute должно являться файлом следующего вида: :values.',
    min                 : {
        numeric: 'Значение :attribute не может быть меньше :min.',
        file: 'Значение :attribute не может быть меньше:min килобайт.',
        string: 'Значение :attribute не может быть короче :min символов.',
        array: 'Значение :attribute не может включать менее :min элементов.',
    },
    not_in              : 'Выбранное значение :attribute не является действительным.',
    numeric             : 'Значение :attribute должно являться числом.',
    regex               : 'Формат :attribute не является действительным.',
    required            : 'Поле :attribute является обязательным.',
    required_if         : 'Поле :attribute является обязательным, если :other имеет значение :value.',
    required_unless     : 'Поле :attribute является обязательным, только если :other не имеет значение :values.',
    required_with       : 'Поле :attribute является обязательным при наличии :values.',
    required_with_all   : 'Поле :attribute является обязательным при наличии :values.',
    required_without    : 'Поле :attribute является обязательным при отсутствии :values.',
    required_without_all: 'Поле :attribute является обязательным при отсутствии всех значений :values.',
    same                : 'Значения :attribute и :other должны совпадать.',
    size                : {
        numeric: 'Значение :attribute должно иметь размер :size.',
        file: 'Значение :attribute должно иметь размер :size килобайт.',
        string: 'Значение :attribute должно состоять из :size символов.',
        array: 'Значение :attribute должно включать :size элементов.',
    },
    string              : 'Значение :attribute должно являться строкой.',
    timezone            : 'Значение :attribute должно являться действительным часовым поясом.',
    unique              : 'Значение :attribute уже используется.',
    url                 : 'Формат значения :attribute не является действительным.',
    order_boost_one_profile: 'Бесплатное повышение популярности доступно только для одного профиля.',
    order_free_boost    : 'У Вас нет бесплатных повышений популярности.',
    order_not_completed : 'Заказ завершен, смена статуса не доступна.',
    order_not_cancelled : 'Заказ отменен, смена статуса не доступна.',
    order_active_update : 'Активный заказ не может быть отменен.',
    orders_use_white_label: 'White label используется в заказах: :orders',
    platform_white_label_url: 'Платформа должна содержаь white label url.',
    platform_white_label_mobile_url: 'Платформа должна содержаь мобильный white label url.',
    password_match      : 'Неверный пароль.',
    payment_connection   : 'Ошиибка соелинения с платжной системой. Пожалуйста повторите попытку позже.',

    order: {
        plan_required: 'Пожалуйста выберите план.',
    },

    /*
    |--------------------------------------------------------------------------
    | Локализация страницы проверки – пользовательские фразы
    |--------------------------------------------------------------------------
    |
    | В данном разделе вы можете настроить свои сообщения для подтверждения значений,
    | используя "attribute.rule" для обозначения полей. Такой подход позволяет
    | быстрее настроить языковой поле для выбранного параметра.
    |
    */

    custom: {
        'attribute-name': {
            'rule-name': 'custom-message',
        },
    },

    /*
    |--------------------------------------------------------------------------
    | Пользовательские параметры подтверждения
    |--------------------------------------------------------------------------
    |
    | Данные языковые поля будут использоваться для замены типовых плашек параметров,
    | чтобы указать более понятную информацию, например, адрес электронной почты
    | вместо "email". Такой подход позволяет сделать сообщения более понятными.    |
    */

    attributes: {
        plan_id: 'план',
        platform_id: 'платформа',
    },
}