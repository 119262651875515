const state = {
    errors: {},
}

const mutations = {
    SET_ERRORS: (state, payload) => {
        state.errors = payload;
    }
}

const actions = {
    SET_INPUT_ERRORS: (context, payload) => {
        context.commit('SET_ERRORS', payload);
    },

    RESET: (context) => {
        context.commit('SET_ERRORS', {});
    },
}

const getters = {
    getError: (state) => (err) => {
        if(state.errors[err]) return state.errors[err][0];
        return "";
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}