import TransactionService from "../../service/transaction/transaction.service";
import AbstractMultipleStore from "@/store/AbstractMultipleStore";

const transactionService = new TransactionService();

const transactionStore = {
  namespaced: true,
  state: {
    balance: [],
    payments: [],
    pagination: null,
    methods: {
      2: 'PayPal',
      5: 'Paxum',
      6: 'Wire Transafer',
      36: 'SecurionPay',
    },
  },
  getters: {
    pagination: state => {
      return state.pagination;
    },
    paymentMethods: state => {
      return state.methods;
    },
  },
  mutations: {
    setPagination(state, data) {
      state.pagination = data;
    }
  },
  actions: {
    loadBalance({commit}, conditions) {
      commit('setLoadingByName', {name: 'balance'});
      return transactionService.getBalance(conditions).then(data => {
        commit('setData', {name: 'balance', data: data.data, loading: false});
        commit('setPagination', data.meta.pagination);
      });
    },
    loadPayments({commit}, conditions) {
      commit('setLoadingByName', {name: 'payments'});
      return transactionService.getPayments(conditions).then(data => {
        commit('setData', {name: 'payments', 'data': data.data, 'loading': false});
        commit('setPagination', data.meta.pagination);
      });
    },
  },
};

export default AbstractMultipleStore.mergeInto(transactionStore);
