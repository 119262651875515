<template>
  <diva-card>
    <div class="text-center">
      <div class="mt-2">
        <img src="/img/divatraffic_logo.png"/>
      </div>
      <h1 class="mt-3">{{getTrans('messages.page_not_found')}}</h1>
      <h3 class="mt-3">{{getTrans('messages.url_error_text')}}</h3>
      <div class="text-center mt-4">
        <base-button type="primary" @click="escape">{{getTrans('messages.go_to_dashboard')}}</base-button>
      </div>
    </div>
  </diva-card>
</template>

<script>

export default {
  name: 'not-found',
  methods: {
    escape() {
      const route = this.$store.getters["User/isAuthenticated"] ? 'dashboard' : 'login';
      this.$router.push({ name: route });
    }
  }
};
</script>
