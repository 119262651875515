<template>
  <div class="diva-loader">
    <div class="wrapper" aria-busy="true" :aria-label="getTrans('messages.loading')" role="progressbar">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75">
        <g>
          <path id="logo-outer" data-name="Outer Line" d="M37.5,4.19A33.31,33.31,0,1,1,4.19,37.5,33.33,33.33,0,0,1,37.5,4.19M37.5,0A37.5,37.5,0,1,0,75,37.5,37.49,37.49,0,0,0,37.5,0Z" style="fill: #dadada"/>
          <path id="logo-inner" data-name="Inner Line" d="M37.5,56.25A18.75,18.75,0,1,1,56.25,37.5,18.67,18.67,0,0,1,37.5,56.25Zm0-34.64A15.89,15.89,0,1,0,53.39,37.5,15.8,15.8,0,0,0,37.5,21.61Z" style="fill: #dadada"/>
          <path id="logo-flare" data-name="Camera flare" d="M37.5,49.11V51A13.41,13.41,0,0,0,51,37.5H48.84C49.11,43.5,43.71,49.11,37.5,49.11Z" style="fill: #dadada"/>
          <rect id="logo-light" data-name="Camera light" x="33.73" y="10.44" width="7.53" height="4.5" rx="1.5" ry="1.5" style="fill: #dadada"/>
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: 'content-loader',
};
</script>
