import BaseService from '../BaseService';

export default class ModelService extends BaseService {
  async getModelPage(data = { page: 1 }) {
    let url = urls.getPage + `&page=${data.page}`;
    if (data.name_search) {
      url += getFilterString('name_search', data);
    }
    if (data.status != null) {
      url += getFilterString('status', data);
    }
    if (data.with_trashed) {
      url += getFilterString('with_trashed', data);
    }
    if (data.platforms) {
      url += getFilterString('platforms', data, true);
    }
    if (data.sorting) {
      url += `&${data.sorting}`;
    }
    return super.makeRequest('GET', url);
  }

  async getOneModel(id) {
    return super.makeRequest(
      'GET',
      `model/${id}?include[]=profiles&include[]=profilesId&include[]=viewers&include[]=profilesStatistic&include[]=viewers_history&include[]=currentBalance`
    );
  }

  async saveModel(payload) {
    return super.makeRequest(
      'PATCH',
      `model/${payload.id}?include[]=profilesId&include[]=viewers_history`,
      payload
    );
  }

  async createModel(payload) {
    return super.makeRequest('POST', urls.create, payload, true);
  }

  async addTokens(payload) {
    let url = urls.tokens.replace(/\{id\}/g, payload.id);
    return super.makeRequest('PATCH', url, { tokens: payload.tokens });
  }
}

const urls = {
  getPage:
    'model?include[]=profiles&include[]=performer&include[]=currentBalance&include[]=blockedCountries',
  create:
    'model?include[]=profilesId&include[]=profiles&include[]=currentBalance&include[]=blockedCountries',
  save:
    'model/{id}?include[]=profilesId&include[]=currentBalance&include[]=blockedCountries',
  tokens:
    'model/{id}/tokens?include[]=profilesId&include[]=currentBalance&include[]=blockedCountries',
  delete: 'model/{id}',
  restore: 'model/{id}/restore',
};

function getFilterString(name, data, array) {
  return (
    '&filter[' +
    name +
    ']' +
    (array ? '[]' : '') +
    '={data}'.replace(/\{data\}/g, data[name])
  );
}
