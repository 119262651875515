import { createStore } from 'vuex'

// import the auto exporter
import modules from './modules';

//const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules, // all your modules automatically imported
  //strict: debug,
  //plugins: debug ? [createLogger()] : [] // set logger only for development
})