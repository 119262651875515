import Service from "../../service/source/source.service";
import AbstractSingleStore from "@/store/AbstractSingleStore";

const service = new Service();

const store = {
  namespaced: true,
  state: {
    baseStateListName: 'sources',
    sources: {},
  },

  actions: {
    search: ({ state, commit }, payload) => {
      commit('setListLoading', true);
      service.search(payload).then((response) => {
        commit('setData', {name: state.baseStateListName, data: response.source.data, loading: false});
      });
    },
  },
  getters: {
    getSearchResults(state, getters, rootState) {
      if (!state.search) {
        return [];
      }
      if (rootState.Network.networks) {
        return state.search.map(r => { try { r.network = rootState.Network.networks.find(n => { return n.id == r.network_id }).title; return r } catch (e) { return r } })
      }
      else return state.search
    }
  }
};


export default AbstractSingleStore.mergeInto(store);
