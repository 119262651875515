import OrderService from "../../service/order/order.service";
import AbstractSingleStore from "@/store/AbstractSingleStore";
import AbstractMultipleStore from "@/store/AbstractMultipleStore";

const orderService = new OrderService();

const orderStore = {
  namespaced: true,
  state: {
    baseStateName: 'order',
    baseStateListName: 'orders',
    order: {},
    orders: {},
    ordersCount: {},
    statuses: {
      0: 'paused',
      1: 'active',
      2: 'cancelled',
      3: 'pending',
      4: 'completed',
    },
    defaultIncludes: ['profilesList', 'plan'],
  },
  getters: {
    statuses(store) {
      return store.statuses;
    },
    statusesByName(store) {
      const res = {};
      for (let k in store.statuses) {
        res[store.statuses[k]] = parseInt(k);
      }
      return res;
    }
  },
  actions: {
    getById({state, commit}, payload) {
      commit('setLoading');
      const include = (payload?.include || state.defaultIncludes);
      return orderService.getById(payload.id, {include: include}).then(order => {
        commit('setData', {data: order});
      }).finally(() => commit('setLoading', false));
    },

    getOrders({commit, state}, conditions = {}) {
      commit('setListLoading');
      const include = (conditions?.include || []).concat(state.defaultIncludes);
      conditions.include = include;
      return orderService.getOrders(conditions).then(response => {
        commit('setData', {name: state.baseStateListName, data: response.data, pagination: response.meta.pagination, loading: false});
      });
    },

    getOrdersCount({commit}) {
      commit('setLoadingByName', {name: 'ordersCount'});
      return orderService.getOrdersCount().then(response => {
        commit('setData', {name: 'ordersCount', data: response, loading: false});
      });
    },

    create({commit, state}, data) {
      commit('setLoading');
      return orderService.create(data, {include: state.defaultIncludes}).then(order => {
        commit('setData', {data: order});
      }).catch(error => {
        commit('setValidationErrors', error);
      }).finally(() => commit('setLoading', false));
    },

    update({commit, state}, data) {
      commit('setLoading');
      return orderService.update(data.id, data, {include: state.defaultIncludes}).then(order => {
        commit('setData', {data: order});
      }).catch(error => {
        commit('setValidationErrors', error);
      }).finally(() => commit('setLoading', false));
    },

    changeStatus({commit, state}, payload) {
      commit('setLoading');
      return orderService.changeStatus(payload.id, payload.status, {include: state.defaultIncludes}).then(order => {
        commit('setData', {data: order});
        commit('updateList', order);
      }).finally(() => commit('setLoading', false));
    },

    renew({commit, state}, id) {
      commit('setLoading');
      return orderService.renew(id, {include: state.defaultIncludes}).then(order => {
        commit('setData', {data: order});
        commit('updateList', order);
      }).finally(() => commit('setLoading', false));
    }
  }
};

export default AbstractMultipleStore.mergeInto(AbstractSingleStore.mergeInto(orderStore));
