import AbstractMultipleStore from "@/store/AbstractMultipleStore";
import DictionaryService from "../../service/dictionary/dictionary.service";

const dictionaryService = new DictionaryService();

const module = {
  namespaced: true,
  state: {
    requested: false,
  },
  actions: {
    get: ({ commit, state }) => {
      if (!state.requested) {
        return dictionaryService.getDictionary().then(response => {
          commit("Country/SET_COUNTRIES", response.data.data.country.data, { root: true });
          commit("Browser/SET_BROWSERS", response.data.data.browser.data, { root: true });
          commit("DeviceType/SET_DEVICE_TYPES", response.data.data.deviceType.data, { root: true });
          commit("DeviceBrand/SET_DEVICE_BRANDS", response.data.data.deviceBrand.data, { root: true });
          commit("Os/SET_OS", response.data.data.os.data, { root: true });
        });
      }
      return Promise.resolve();
    },
  },
};

export default AbstractMultipleStore.mergeInto(module);
