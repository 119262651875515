import BaseService from "../BaseService";

export default class ProfileService extends BaseService {
  constructor() {
    super();
    this.baseUrl = 'profile';
  }

  async getAllProfiles() {
    return super
      .makeRequest(
        "GET",
        `profile?all&include[]=performer`
      )
  }

  async getProfiles(conditions) {
    return super
      .makeRequest(
        'GET',
        {url: this.baseUrl, params: conditions}
      )
      .then((response) => {
        return response.data;
      });
  }

  async getById(id) {
    return super
      .makeRequest(
        'GET',
        {url: `${this.baseUrl}/${id}`}
      )
      .then((response) => {
        return response.data.data;
      });
  }

  async delete(id) {
    return super.makeRequest(
      "DELETE",
      `profile/${id}`
    );
  }

  async save(profile) {
    return super.makeRequest(
      "PATCH",
      `profile/${profile.id}?include[]=performer`,
      profile
    ).then(response => response.data.data);
  }

  async create(profile) {
    return super.makeRequest(
      "POST",
      "profile?include[]=performer",
      profile
    ).then(response => response.data.data);
  }

  async update(id, data, conditions) {
    return super.makeRequest(
        'PATCH',
        {url: `${this.baseUrl}/${id}`, params: conditions},
        data
      ).then(response => response.data.data);
  }
}
