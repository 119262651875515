<template>
  <nav :class="classes" class="navbar">
    <div :class="containerClasses">
      <slot name="brand"></slot>

      <slot name="toggle-button">
        <button
          class="navbar-toggler"
          v-if="hasMenu"
          type="button"
          @click="toggleMenu"
          aria-expanded="false"
          data-toggle="collapse"
          data-target="#navbar-collapse"
          aria-controls="navbar-collapse"
        >
          <i class="fas fa-bars"></i>
        </button>
      </slot>

      <div
        class="navbar-collapse navbar-custom-collapse collapse show"
        :class="menuClasses"
        v-show="show"
      >
        <slot :close-menu="closeMenu"></slot>
      </div>
    </div>
  </nav>
</template>
<script>
  export default {
    name: "base-nav",
    props: {
      show: {
        type: Boolean,
        default: false,
        description:
          "Whether navbar menu is shown (valid for viewports < specified by `expand` prop)",
      },
      transparent: {
        type: Boolean,
        default: false,
        description: "Whether navbar is transparent",
      },
      expand: {
        type: String,
        default: "lg",
        description: "Breakpoint where nav should expand",
      },
      menuClasses: {
        type: [String, Object, Array],
        default: "",
        description:
          "Navbar menu (items) classes. Can be used to align menu items to the right/left",
      },
      containerClasses: {
        type: [String, Object, Array],
        default: "container",
        description:
          "Container classes. Can be used to control container classes (contains both navbar brand and menu items)",
      },
      position: {
        type: String,
        default: "absolute",
      },
      type: {
        type: String,
        default: "",
        validator(value) {
          return [
            "",
            "dark",
            "success",
            "danger",
            "warning",
            "white",
            "primary",
            "light",
            "info",
            "vue",
          ].includes(value);
        },
        description: "Navbar color type",
      },
    },
    model: {
      prop: "show",
      event: "change",
    },
    computed: {
      classes() {
        let color = `bg-${this.type}`;
        let classes = [
          { "navbar-transparent": this.transparent },
          { [`navbar-expand-${this.expand}`]: this.expand },
        ];
        if (this.position) {
          classes.push(`navbar-${this.position}`);
        }
        if (!this.transparent) {
          classes.push(color);
        }
        return classes;
      },
      hasMenu() {
        return this.$slots.default;
      },
    },
    methods: {
      toggleMenu() {
        this.$emit('update:show', !this.show);
      },
      closeMenu() {
        this.$emit('update:show', false);
      },
    },
  };
</script>
<style></style>
