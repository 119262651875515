/**
 * Abstract store functionality that allows to have state data as object with
 * data, loading flag and validation errors.
 *
 * In store use setData to set all ('data', 'loading', 'errors') at once.
 * setLoading to mark data object as loading.
 * Use payload.name to define data object. Skip to use default state.baseStateName.
 */

export default {
  /**
   * Methods can be used without argument if state.baseStateName was set.
   */
  getters: {

  },
  mutations: {
    setData(state, payload) {
      let data = {};
      ['data', 'loading', 'errors', 'pagination'].forEach(function (key) {
        if (payload[key] !== undefined) {
          data[key] = payload[key];
        }
      });
      const name = payload.name || state.baseStateName;
      state[name] = data;
    },
    setList(state, payload) {
      let data = {};
      ['data', 'loading', 'errors', 'pagination'].forEach(function (key) {
        if (payload[key] !== undefined) {
          data[key] = payload[key];
        }
      });
      const name = payload.name || state.baseStateListName;
      state[name] = data;
    },
    resetData(state, name) {
      name = name ?? state.baseStateName;
      state[name] = {};
    },
    resetList(state, name) {
      name = name ?? state.baseStateListName;
      state[name] = {};
    }
  },
  actions: {
    resetData({commit}, name) {
      commit('resetData', name);
    },
    resetList({commit}, name) {
      commit('resetList', name);
    }
  },
  mergeInto(obj) {
    for (let k in this) {
      if (k === 'getters' || k === 'mutations' || k === 'actions') {
        obj[k] = Object.assign({}, this[k], obj[k]);
      } else {
        obj[k] = this[k];
      }
    }
    return obj;
  }
};
