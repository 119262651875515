<template>
  <full-screen-loader :fullScreen="true" v-if="loading"></full-screen-loader>
  <a class="nav-link dropdown-toggle" id="languageDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
     aria-expanded="false">
    <span class="nav-link-inner--text auth__nav-link-inner--lang" :class="`lang-${currentLang}`">{{ getTrans(`messages.${currentLang}`) }}</span>
  </a>
  <div class="dropdown-menu auth__header-dropdown-menu" aria-labelledby="languageDropdown">
    <div class="nav-link" @click="setLang('en')">
      <span class="nav-link-inner--text auth__nav-link-inner--lang lang-en">{{ getTrans('messages.en') }}</span>
    </div>
    <div class="nav-link" @click="setLang('es')">
      <span class="nav-link-inner--text auth__nav-link-inner--lang lang-es">{{ getTrans('messages.es') }}</span>
    </div>
    <div class="nav-link" @click="setLang('ru')">
      <span class="nav-link-inner--text auth__nav-link-inner--lang lang-ru">{{ getTrans('messages.ru') }}</span>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'LanguageDropdown',
  computed: {
    ...mapGetters('User', {
      loading: 'loading',
    }),
    currentLang() {
      return this.$store.getters['Lang/getCurrentLang'];
    }
  },
  methods: {
    setLang(lang) {
      if (this.$store.getters['User/getData']) {
        this.$store.dispatch('User/saveUser', {
          'locale': lang
        }).then(() => {
          this.$router.go(0);
        });
      } else {
        this.$store.dispatch('Lang/set', lang);
      }
    },
  }
}
</script>

<style scoped>

</style>
