module.exports = {
  dashboard: 'Panel',
  tokens: 'Tokens',
  wiretransfer: 'Transferencia bancaria',
  transactions: 'Transacciones',
  orders: 'Órdenes',
  order_stats: 'Estadísticas de órdenes',
  studio: 'Mi estudio',
  profiles: 'Perfiles',
  links: 'Vínculos',
  resources: 'Recursos',
  statistics: 'Estadísticas',
  packages: 'Paquetes',
  create_package: 'Crear paquete',
  settings: 'Ajustes',
  account: 'Cuenta',
  password: 'Contraseña',
  billing: 'Facturación',
  privacy: 'Privacidad',
  notifications: 'Notificaciones',
  subscription: 'Suscripción',
  camsite: 'Camsite',
  payouts: 'Pagos',
  tools: 'Herramientas',
  invite_friend: 'Invitar a una amiga',
  boost: 'Boostear mi cámara',
  boost_link: 'Boostear mi enlace ',
  models: 'Modelos',
  add_model: 'Agregar modelo',
  model_settings: 'Modelo Ajustes',
  model_account: 'Cuenta',
  model_profiles: 'Perfiles',
  model_tokens: 'Tokens',
  model_password: 'Contraseña',
  model_privacy: 'Privacidad',
  model_social: 'Social',
  not_found: 'Página no encontrada',
}
