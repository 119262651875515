import BaseService from '../BaseService'

export default class SourceService extends BaseService {
    constructor() {
        super();
        this.baseUrl = 'dictionary';
    }

    async search(payload) {
        let url = `${this.baseUrl}/?include[]=source`;
        payload.search ? url += '&filter[source][name]=' + payload.search : null
        payload.network ? url += '&filter[source][network][]=' + payload.network : null
        return super.makeRequest('GET',
          {url: url}
        ).then(response => {
            return response.data.data;
        });
    }
}