<template>
  <footer class="footer px-4 mx-2">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          <!-- eslint-disable -->
          <router-link to="/" class="footer__copyright--link">DivaTraffic</router-link>
              © {{ year }}
              Diva Technologies AS. Norway
          <!-- eslint-enable -->
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
