module.exports = {
    slogan: '¡Toma las riendas de tu destino!',
    subslogan: 'DivaTraffic lanza las herramientas más innovadoras de marketing para modelos de livecam.',
    comingsoon: '¡Próximamente!',
    comingsooninfo: 'Regístrate y te notificaremos cuando las nuevas característocas para cámaras web estén disponibles.',
    readmorebelow: 'o lee más información a continuación',
    getnotified: '¡Quiero notificaciones!',
    services: 'Nuestros servicios',
    servicesinfo: 'Comienza a usar DivaTraffic y aumenta el tráfico a tu camshow. ¡Es muy sencillo!',
    boostmycam: 'Boost my cam',
    boostmycaminfo: 'Aumentar el tráfico a tu camshow está a solo un clic de distancia. DivaTraffic ayuda a modelos y estudios a conseguir gran cantidad de espectadores para sus camshows.<br><br>Aumenta tu tráfico. ¡Aumenta tus ganancias!',
    divalinks: 'DivaLinks',
    divalinksinfo: 'DivaLinks es una herramienta que te ayudará a aumentar tu base de seguidores y te permitirá construir tu marca. Comparte tu contenido en redes sociales usando los links abreviados de Diva, que también incluyen publicidad para tu camshow cuando estés en línea.<br><br>¿A qué esperas? ¡Toma las riendas de tu destino!',
    camhead: 'CamHead',
    camheadinfo: 'CamHead es una nueva forma de promocionar tu camshow, la cual detecta cuándo estás disponible en línea y se asegura de que tu publicidad sea visible para la audiencia adecuada.<br><br>¡Crece y atrae al mundo hacia ti!',
    howitworks: 'Cómo funciona',
    howitworksinfo: 'Commenzar a usar DivaTraffic para incrementar tu tráfico web es muy sencillo.',
    signuporin: 'Inicia sesión o regístrate',
    signuporininfo: 'Accede al panel inicial de DivaTraffic iniciando sesión o registrándote.',
    boostorshare: 'Promociona tu camshow con Boost my cam o comparte DivaLinks',
    boostorshareinfo: 'Bien sea que quieras comprar tráfico o conseguirlo de manera gratuita, DivaTraffic es el lugar indicado.<br /> Utiliza boosts promocionales o comparte vínculos para incrementar el tráfico a tu camshow.',
    bosboostmycam: 'Boost My cam',
    bosboostmycaminfo: 'Estimula el tráfico a tu camshow. Puedes elegir entre múltiples opciones para personalizar el tipo de tráfico que quieres obtener.<br><br>Sólo escoge tu perfil de modelo, el paquete que deseas y el tamaño del boost.<br><br><b>Disfruta del incremento masivo de visitantes en la sala de tu camshow.</b>',
    sharedivalinks: 'comparte DivaLinks',
    sharedivalinksinfo: 'Acorta vínculos y compártelos. DivaLinks incluye tus propios anuncios personales en los vínculos que compartes. Incluye banners, o incluso Cam Heads, en tus vínculos.<br><br>El CamHead es un anuncio con una vista previa de tu camroom.<br><br><b>Aumenta la tasa de visitantes de manera gratuita con tan sólo compartir vínculos.</b>',
    enjoymorevisitors: 'Disfruta de más visitantes',
    enjoymorevisitorsinfo: 'Ahora que ya has usado Boost My Cam o DivaLinks, es sólo cuestión de tiempo para ver como tu sala se llena de visitantes.<br />Bien sea que necesites tráfico instantáneo o a largo plazo, DivaTraffic es la solución para ti.',
    sign_up_beta: 'Regístrarse',
    beta_info: 'Regístrate ahora para comenzar a usar nuestras herramientas.',
    get_started: '¡Comenzar!',
    subslogan_1: 'DivaTraffic te brinda las mejores',
    subslogan_2: 'herramientas de marketing para modelos de livecam.',
    video: 'VER VÍDEO PROMOCIONAL',
    step: 'Paso',
    are_you_ready: '¿Estás lista?',
    register: 'Regístrate ahora para comenzar a usar nuestras herramientas'
}