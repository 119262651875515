<template>
  <div class="card" v-if="$slots.filters">
    <div class="card-body">
      <div class="filters__container" :class="filterClasses">
        <slot name="filters"></slot>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "diva-card",
  props: {
    filterClasses: {
      type: Array,
      default: ()=>{
        return [];
      }
    }
  }
}
</script>

<style scoped>

</style>
