import AbstractStore from "@/store/AbstractStore";

/**
 * Extend of AbstractStore.
 * Abstract store functionality that allows to have state data as object with
 * data, loading flag and validation errors.
 *
 * state.baseStateName should be defined if you use single object.
 * state.baseStateListName should be defined if you use array of object.
 */

const store = {
  /**
   * Methods can be used without argument if state.baseStateName was set.
   */
  getters: {
    loading: state => {
      return state[state.baseStateName]?.['loading'];
    },
    listLoading: state => {
      return state[state.baseStateListName]?.['loading'];
    },
    getData: state => {
      return state[state.baseStateName]?.['data'];
    },
    getList: state => {
      return state[state.baseStateListName]?.['data'] || [];
    },
    getErrors: state => {
      return state[state.baseStateName]?.['errors'];
    },
    pagination: state => {
      return state[state.baseStateListName]?.['pagination'];
    },
  },
  mutations: {
    setLoading(state, val = true) {
      state[state.baseStateName]['loading'] = val;
    },
    setListLoading(state, val = true) {
      state[state.baseStateListName]['loading'] = val;
    },
    setErrors(state, errors) {
      state[state.baseStateName]['errors'] = errors;
    },
    setValidationErrors(state, error) {
      if (error.http_code === 400) {
        state[state.baseStateName]['errors'] = error.message;
      } else {
        throw error;
      }
    },
    setListValidationErrors(state, error) {
      if (error.http_code === 400) {
        state[state.baseStateListName]['errors'] = error.message;
      } else {
        throw error;
      }
    },
    updateList(state, updatedItem) {
      if (state[state.baseStateListName]?.data?.length) {
        state[state.baseStateListName].data.forEach((item, key) => {
          if (item.id === updatedItem.id) {
            state[state.baseStateListName].data[key] = updatedItem;
            return true;
          }
        });
      }
    },
    removeFromList(state, id) {
      if (state[state.baseStateListName]?.data.length) {
        state[state.baseStateListName].data.forEach((item, key) => {
          if (item.id === id) {
            state[state.baseStateListName].data.splice(key, 1);
            return true;
          }
        });
      }
    }
  }
};

export default AbstractStore.mergeInto(store);
