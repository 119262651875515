module.exports = {
    // Hero section
    slogan: 'Возьмите ваше будущее в свои руки!',
    subslogan: 'DivaTraffic запускает набор инновационных маркетинговых инструментов для веб-моделей.',

    // Info below Hero section
    comingsoon: 'Скоро!',
    comingsooninfo: 'Зарегистрируйтесь, и мы отправим вам уведомление, когда новые функции будут доступны для пользователей.',
    readmorebelow: 'или ознакомьтесь с информацией ниже',

    // Button texts
    getnotified: 'Будьте в курсе!',

    // Services section
    services: 'Наши услуги',
    servicesinfo: 'Начните работу с DivaTraffic, и увеличьте посещаемость вашего веб-шоу, ведь это невероятно просто!',

    // Boost my cam section
    boostmycam: 'Повышение популярности',
    boostmycaminfo: 'НАКОНЕЦ-ТО повысить объем трафика на вашем веб-канале можно всего за несколько кликов. DivaTraffic позволяет моделям и студиям привлекать большое количество новых зрителей, используя простые и понятные инструменты.<br><br>Увеличивайте объем трафика, и повышайте уровень доходов!',

    divalinks: 'DivaLinks',
    divalinksinfo: 'DivaLinks – это специальный инструмент, который позволяет значительно расширить базу ваших поклонников, позволяя повысить узнаваемость и популярность вашего личного бренда. Используйте короткие Diva-ссылки для публикаций в социальных сетях, получая возможность публикации дополнительной рекламы каждый раз, когда вы находитесь в сети.<br><br>Так чего же вы ждете? Возьмите ваше будущее в свои руки!',

    camhead: 'CamHead',
    camheadinfo: 'CamHead – это новый уникальный способ продвижения вашего веб-шоу. Он позволяет автоматически определять ваше присутствие в сети, и гарантирует, что заинтересованные пользователи обязательно увидят вас.<br><br>Откройте новые возможности, и весь мир окажется в ваших руках!',

    // How it works section
    howitworks: 'Как это работает',
    howitworksinfo: 'Начать сотрудничество с DivaTraffic для привлечения дополнительного трафика для вашего видео-шоу удивительно просто!',

    // -->> Sign up or in
    signuporin: 'Войдите или зарегистрируйтесь',
    signuporininfo: 'Войдите или зарегистрируйтесь, чтобы получить доступ к панели управления DivaTraffic.',

    // -->> Boost my cam or share DivaLinks
    boostorshare: 'Повышайте популярность и используйте DivaLinks',
    boostorshareinfo: 'Неважно, хотите ли вы докупить трафик, или получить его бесплатно, DivaTraffic подойдет именно вам!<br /> Используя возможность моментального повышения популярности, а также публикуя ссылки, вы сможете повысить популярность своего шоу в несколько раз. ',
    bosboostmycam: 'Повысить популярность моего канала',
    bosboostmycaminfo: 'Повышайте популярность вашего канала, мгновенно получая новых пользователей. Вы можете выбрать один из большого количества представленных инструментов, чтобы выбрать именно тот тип трафика, который вы хотите получить.<br><br>Выберите вашу учетную запись модели, необходимый набор и объем трафика..<br><br><b>Наслаждайтесь мгновенным увеличением количества посетителей вашего видео-чата!</b>',
    sharedivalinks: 'Распространяйте DivaLinks',
    sharedivalinksinfo: 'Сокращайте ссылки, и делитесь ими! DivaLinks позволяет добавить ваше личное объявление к каждой опубликованной ссылке. Дополните каждую ссылку баннером или собственным портретом CamHead!<br><br>Портрет CamHead – это реклама, которая используется для предварительного просмотра вашего видео-чата!<br><br><b>Повышайте популярность и количество посетителей совершенно БЕСПЛАТНО, публикуя ссылки!</b>',

    // Enjoy more visitors
    enjoymorevisitors: 'Наслаждайтесь большим количеством зрителей',
    enjoymorevisitorsinfo: 'После того, как вы повысите популярность вашего канала и используете ссылки DivaLinks, вы сможете с упоением наблюдать как ваш видео-чат буквально наполняется новыми посетителями, о которых вы могли лишь мечтать!<br />Неважно, требуется ли вам временный прирост трафика или постоянное увеличение, проект DivaTraffic поможет решить обе проблемы!',

    sign_up_beta: 'Регистрация',
    beta_info: 'Регистрируйтесь, и приступайте к работе!',
    get_started: 'Приступить!'
}