import BaseService from "../BaseService";

export default class DictionaryService extends BaseService {
  async get(commit) {
    return super
      .makeRequest(
        "GET",
        `dictionary?include[]=browser&include[]=deviceBrand&include[]=deviceType&include[]=os&include[]=country`
      )
      .then(response => {
        commit("Country/SET_COUNTRIES", response.data.data.country.data, {
          root: true
        });
        commit("Browser/SET_BROWSERS", response.data.data.browser.data, {
          root: true
        });
        commit(
          "DeviceType/SET_DEVICE_TYPES",
          response.data.data.deviceType.data,
          {
            root: true
          }
        );
        commit(
          "DeviceBrand/SET_DEVICE_BRANDS",
          response.data.data.deviceBrand.data,
          {
            root: true
          }
        );
        commit("Os/SET_OS", response.data.data.os.data, {
          root: true
        });
      })
      .catch(response => {
        // eslint-disable-next-line
        console.log(response);
        throw response;
      });
  }
  async getDictionary() {
    return super
      .makeRequest(
        "GET",
        {url: 'dictionary', params: {'include':['browser', 'deviceBrand','deviceType', 'os', 'country']}}
      )
  }
}
