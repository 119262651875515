module.exports = {
  dashboard: 'Панель управления',
  tokens: 'Токены',
  wiretransfer: 'Банковский перевод',
  transactions: 'Транзакции',
  orders: 'Заказы',
  order_stats: 'Статистика заказа',
  studio: 'Моя Студия',
  profiles: 'Профили',
  links: 'Ссылки',
  resources: 'Ресурсы',
  statistics: 'Статистика',
  packages: 'Пакеты',
  create_package: 'Создать пакет',
  settings: 'Настройки',
  account: 'Аккаунт',
  password: 'Пароль',
  billing: 'Биллинг',
  privacy: 'Конфиденциальность',
  notifications: 'Уведомления',
  subscription: 'Подписка',
  camsite: 'Сайты',
  payouts: 'Выплаты',
  tools: 'Инструменты',
  invite_friend: 'Пригласить друга',
  boost: 'Продвинуть мою комнату',
  boost_link: 'Продвинуть мою ссылку',
  models: 'Модели',
  add_model: 'Добавить модель',
  model_settings: 'Настройки модели',
  model_account: 'Аккаунт',
  model_profiles: 'Профили',
  model_tokens: 'Токены',
  model_password: 'Пароль',
  model_privacy: 'Конфиденциальность',
  model_social: 'Социальные сети',
  not_found: 'Страница не найдена',
}
