<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ 'bg-success navbar-dark': type === 'default' }"
  >
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
      <li class="nav-item d-sm-none">
        <a
          class="nav-link"
          href="#"
          data-action="search-show"
          data-target="#navbar-search-main"
        >
          <i class="ni ni-zoom-split-in"></i>
        </a>
      </li>
      <li class="nav-item dropdown">
        <language-dropdown></language-dropdown>
      </li>
    </ul>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <template v-slot:title-container>
          <a href="#" class="nav-link pr-0" @click.prevent>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img :alt="getTrans('messages.user_image')" src="/img/theme/team-4.jpg" />
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">{{user.name}}</span>
              </div>
            </div>
          </a>
        </template>

        <router-link :to="{name: 'settings'}" class="dropdown-item">
          <i class="ni ni-settings-gear-65"></i>
          <span>{{getTrans('messages.settings')}}</span>
        </router-link>
        <router-link :to="{name: 'transactions'}" class="dropdown-item">
          <i class="ni ni-credit-card"></i>
          <span>{{getTrans('messages.transactions')}}</span>
        </router-link>
        <div class="dropdown-divider"></div>
        <a href="#!" class="dropdown-item" @click.stop.prevent="logout">
          <i class="fas fa-spinner fa-spin" v-if="loading"></i>
          <i class="ni ni-user-run" v-else></i>
          <span>{{getTrans('messages.logout')}}</span>
        </a>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import BaseNav from "@/components/Navbar/BaseNav";
import LanguageDropdown from "@/components/LanguageDropdown";
import {mapGetters} from "vuex";

export default {
  components: {
    BaseNav,
    LanguageDropdown
  },
  props: {
    type: {
      type: String,
      default: "light", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    ...mapGetters('User', {
      user: 'getData',
    }),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    loading() {
      return this.$store.getters['User/loading'];
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout() {
      this.$store.dispatch("User/logout").then(() => {
        this.$router.push({name: 'Login'});
      });
    }
  },
};
</script>
