import PlanService from '@/service/plan/plan.service';
import AbstractSingleStore from '@/store/AbstractSingleStore';
import AbstractMultipleStore from '@/store/AbstractMultipleStore';

const service = new PlanService();

const module = {
  namespaced: true,
  state: {
    baseStateName: 'plan',
    baseStateListName: 'plans',
    plan: {},
    plans: {},
    plansShort: {},
  },
  getters: {
    getPackageById: (state) => (id) => {
      if (state[state.baseStateName]?.data?.id == id) {
        return state[state.baseStateName].data;
      } else {
        return state[state.baseStateListName]?.data?.find?.(p => p.id == id);
      }
    },
    getPackages(state) {
      if (state[state.baseStateListName].data) {
        return state[state.baseStateListName].data;
      }
      return [];
    },
    getPackagesForLinks(state) {
      if (state[state.baseStateListName].data) {
        return state[state.baseStateListName].data.filter(p => { return p.types && p.types.indexOf(2)>=0;});
      }
      return [];
    },
    getPackagesForProfiles(state) {
      if (state[state.baseStateListName].data) {
        return state[state.baseStateListName].data.filter(p => {
          return p.types && p.types.indexOf(1)>=0;});
      }
      return [];
    },
    plansShort(state) {
      return state.plansShort;
    }
  },
  actions: {
    getOne: ({commit}, id) => {
      return service.getPackage(id).then(data => {
        commit('setData', data.data);
      });
    },
    getPage: ({commit, state}, filter) => {
      commit('setListLoading', true);
      return service.getPage(filter).then((response) => {
        commit('setData', {name: state.baseStateListName, data: response.data, pagination: response.meta.pagination, loading: false});
      }).catch((error) => {
        commit('setValidationErrors', error);
      }).finally(() => {
        commit('setListLoading', false);
      });
    },
    save: (context, payload) => {
      return service.savePackage(context, payload);
    },
    create: (context, payload) => {
      return service.createPackage(context, payload);
    },
    delete: (context, payload) => {
      return service.deletePackage(context, payload);
    },

      /** daily_volume
       * {
       *  type: bid type
       *  targeting: {}
       * }
       */
    dailyVolume: (context, payload) => {
      return service.daily_volume(context, payload);
    },
    getPlansShort({commit}) {
      service.getPackages({short: 1}).then(response => {
        commit('setPlansShort', response.data);
      });
    },
    getPlans({commit, state}, conditions) {
      commit('setListLoading');
      return service.getPackages(conditions).then(response => {
        commit('setData', {name: state.baseStateListName, data: response.data, pagination: response.meta.pagination, loading: false});
      }).catch(error => {
        commit('setListLoading', false);
        throw error;
      });
    },
  },
  mutations: {
    setPlansShort(state, val) {
      state.plansShort = val;
    },
  }
};

export default AbstractMultipleStore.mergeInto(AbstractSingleStore.mergeInto(module));
