module.exports = {
    traffic: 'Traffic',
    filter_on_this: 'Filter on this',
    earnings_sum: 'Total earnings',
    add_filter: 'Add Filter',
    show_columns: 'Columns',
    sub_id_num: 'Sub ID {0}',
    sub_id1: 'Sub ID 1',
    sub_id2: 'Sub ID 2',
    sub_id3: 'Sub ID 3',
    sub_id4: 'Sub ID 4',
    sub_id5: 'Sub ID 5',
    roi: 'ROI',
    e_cpm: 'eCPM',
    clipsite: 'Clipsite',
    public: 'Public',
    all_devices: 'All devices',
    affiliate_your_own_whitelabel: 'Use your own whitelabel',
    select_program: 'Select Program',
    current_password: 'Current Password',
    payment_cycle: 'Payment period',
    account_type: 'Account type',
    next_payment: 'Next payment',
    no_network: 'No Network',
    no_country: 'No Country',
    no_order: 'No Order',
    no_package: 'No Package',
    no_tool: 'No Tool',
    no_source: 'No Source',
    no_camsite: 'No Camsite',
    no_device_type: 'No Device type',
    no_device_brand: 'No Brand',
    no_browser: 'No Browser',
    no_os: 'No OS',
    featured: 'featured',
    all_english: 'All English',
    add_camsite_profiles_to_account: 'Add camsite profiles to account',
    so_the_model_doesnt_have_to: "so your model doesn't have to",
    model_login_credentials: "Give the model login credetials if you want them to get access to the model account",
    // generic
    faq: 'FAQ',
    order: 'Order',
    information: 'Information',
    info: 'Info',
    integrations: 'Integrations',
    packages: 'Packages',
    supported_sites: 'Supported sites',
    supported_sites_text: 'The following sites are supported. If you would like to recommend a new site, please contact us.',
    statistics: 'Statistics',
    upgrade: 'Upgrade',
    promo_tools: 'Promo tools',
    transactions: 'Transactions',
    rtb: 'RTB',
    last_updated: 'Last updated',
    created: 'Created',
    item_created: 'Created',
    start: 'Start',
    stop: 'Stop',
    pause: 'Pause',
    activate: 'Activate',
    renew: 'Renew',
    renew_order: 'Renew Order',
    male: 'Male',
    female: 'Female',
    couple: 'Couple',
    online: 'Online',
    offline: 'Offline',
    manually: 'Manually',
    show_all: 'Show all',
    show_active_platforms: 'Show active',
    referrals: 'Referrals',
    notifications: 'Notifications',
    birthday: 'Birthday',
    total_referrals: 'Total referrals',
    earnings: 'Earnings',
    total_earnings: 'Total earnings',
    has_viewercount: 'Has viewercount',
    new: 'New',
    account: 'Account',
    plans: 'Plans',
    send: 'Send',
    boost: 'Boost',
    free_boost_count: '{1} 1 free boost|{*} :count free boosts',
    all_payments: 'All payments',
    view_invoice: 'View invoice',
    download_invoice: 'Download invoice',
    one_free_boost: '1 free boost',
    free_boosts: ':boosts free boosts',
    active_orders: 'Active orders',
    paused_orders: 'Paused orders',
    private: 'Private',
    away: 'Away',
    welcome_to_studio_button: 'Get started, add a model',
    go_to_url: 'Go to URL',
    cancel_and_refund: 'Cancel and refund',

    // platforms
    supported_camsites: 'Supported camsites',
    camsites_description: 'Below is a list of supported camsites and their status. We are continously working to add more camsites as they are requested. If you don\'t find a camsite you are broadcasting on, then send us a support ticket, and we will see if it can be integrated. Camsite owners are also encouraged to contact us. ',
    camsites_modelpitch: 'Not yet a cam model? No worries, you can sign up as a model at any of the platforms below. Just click the Become a model button on the site you want to join.',

    // /dashboard
    //Profiles welcome screen - shown when a model has no profiles added
    welcome_to: 'Welcome to DivaTraffic',
    welcome_to_subtitle: 'We\'ll help you get the traffic you need',
    welcome_to_info: 'DivaTraffic offers a lot of tools for you to boost your webcam when you are online. All you need to do is to<br>add your webcam profile to your DivaTraffic account, and you’re good to go.',
    welcome_to_final: 'You can try it out <span class="color-pink">for free!</span>',
    welcome_to_button: 'Get started, add a profile',
    //Studios welcome screen - shown when studios have no models/profiles
    welcome_to_studios_subtitle: 'We\'ll help you get your models the traffic they need',
    welcome_to_studios_info: 'DivaTraffic offers a lot of tools for you to boost your models webcam when they are online. All you need to do is to<br>add your models profiles to your DivaTraffic account, and you’re good to go.',
    welcome_to_studios_final: 'You can try it out <span class="color-pink">for free!</span>',
    welcome_to_studios_button: 'Get started, add a model profile',
    //misc
    welcome_beta: 'Welcome, :name! DivaTraffic is in beta modus and can be unstable at times. Please report any problem you might have, or make suggestions on how we can improve the services. We appreciate any feedback!',
    helpcenter: 'to go to our helpcenter!',
    click_here: 'Click here',
    dashboard: 'Dashboard',
    welcome: 'Welcome, :name',
    plugrush_status_title: 'PlugRush campaign status',
    plan: 'Plan',
    received: 'Received',
    progress: 'Progress',
    ordered: 'Ordered',
    visitors: 'Visitors',
    this_hour: 'This hour',
    hour_limit: 'Hour Limit',
    countries: 'Countries',
    country: 'Country',
    city: 'City',
    status: 'Status',
    action: 'Action',
    numb_links: 'Total number of links',
    numb_users: 'Total number of users',
    numb_performers: 'Total number of performers',
    numb_adzones: 'Total number of adzones',
    numb_actions: 'Total number of actions',
    numb_visits: 'Total number of visits',
    numb_studios: 'Total number of studios',
    numb_surveys:'Total number of surveys',
    numb_platforms: 'Total number of platforms',
    numb_codes: 'Total number of redeem codes',
    numb_transactions: 'Total number of transactions',
    pending_transactions: 'Number of pending transactions',
    summary: 'Summary',
    total_tokens: 'Total tokens',
    total_traffic: 'Total traffic',
    total_links: 'Total links',
    total_orders: 'Total orders',
    recent_orders: 'Recent orders',
    numb_logins: 'Total number of login attempts',
    manage_tokens: 'Manage tokens',
    manage_orders: 'Manage orders',
    boost_your_cam: 'Boost your cam',
    manage_links: 'Manage links',
    manage_performer: 'Manage performer',
    manage_performers: 'Manage performers',
    tokens_earned: 'Tokens earned',
    tokens_remaining: 'Tokens remaining',
    unique_models: 'unique models',
    visitors_received: 'visitors received',
    shortened_links: 'shortened links',
    shorten_url: 'Shorten URL',
    news: 'News',
    new_features: 'New features',
    boost_my_cam: 'Boost my cam',
    boost_my_link: 'Boost my link',
    boost_performers: 'Boost performers',
    boost_performers_text: 'Give your performers a boost the next time they go online',
    boost_cam_text: 'Get more people to your next show! Click the button below and boost your next performance',
    earn_tokens: 'Earn tokens',
    earn_tokens_text: 'Share links with your fans and followers and earn tokens for every click!',
    redeem_code: 'Redeem code',
    redeem_code_text: 'Enter your redeem code below to claim your free tokens!',
    redeem: 'Redeem',
    earn_free_tokens: 'Earn free tokens',
    get_started: 'Get started',
    description: 'Description',
    how_to_get_started: 'How to get started',
    how_to_get_started_text: 'Use tokens to to buy traffic to your performers live shows!',
    connected_profiles: 'connected profiles',
    manage_profiles: 'Manage profiles',
    api_settings: 'API settings',

    // /plans
    plan_intro: 'Divatraffic traffic plans',

    // Affiliate
    affiliate_stats: 'Affiliate stats',
    affiliate_link_desc: 'Use the following link to refer users to our platform and start earning money',
    affiliate_none_referred: 'No users referred yet.',
    affiliate_header: 'Here you can see who you referred and how to refer more users',

    // /sidebar
    sign_in: 'Sign in',
    log_out: 'Log out',
    settings: 'Settings',
    get_free_tokens: 'Get free tokens',
    boostmycam_tooltip: 'Get more people in your room the next time you go online',
    sharelinks_tooltip: 'Earn tokens by sharing links with your fans and followers',
    share_links: 'Share links',
    traffic_tooltip: 'Get more traffic, share links!',
    tokens: 'Tokens',
    orders: 'Orders',
    studio_models: 'Studio models',
    account_settings: 'Account settings',
    studio_settings: 'Studio settings',
    studio_control_enter: 'You are now in user control mode.',
    studio_not_found: 'Could not find your studio.',
    studio_no_access: 'Your studio does not have access to that user.',
    links: 'Links',
    divalinks: 'DivaLinks',
    adzones: 'Adzones',
    stats: 'Stats',
    platform_settings: 'Platform settings',
    models: 'Models',
    custom_plan: 'Custom plan',
    manage_models: 'Manage models',
    billing_information: 'Billing information',
    bank_name: 'Bank name',
    bank_address1: 'Bank address',
    bank_zip: 'Bank zip',
    bank_city: 'Bank city',
    bank_country: 'Bank country',
    bank_vat: 'VAT',
    deleted: 'Banned/Deleted',
    publisher: 'Publisher',


    // /boost
    boostcam_header_text: 'Boost your cam room with traffic (visitors) the next time you go online.',
    boost_packages: 'Traffic boost packages',
    out_of_tokens: 'Out of tokens?',
    out_of_tokens_text: 'Head over to the tokens page to get more tokens',
    get_tokens: 'Get tokens!',
    get_free_tokens_text: 'Complete tasks and get rewarded with free tokens you can use to buy boost packages',
    not_enough_tokens: 'You do not have enough tokens to do that.',
    boost_cam_short: 'Boost',
    order_settings: 'Order settings',
    order_cost: 'Order Cost',
    total_cost: 'Total Cost',
    order_not_found: 'Order not found',
    order_invalid_status: 'Only pause, pending and completed orders can be updated',
    autorenew: 'Autorenew',
    renew_forever: 'Renew forever',
    renewals_left: 'Renewals left',
    renewals: 'Renewals',
    autorenew_sub: 'Automatically renew order when budget is spent',
    autorenew_info: 'Read more about autorenewal of orders',
    renew_forever_text: 'By checking this option your order will automatically renew as long as you have tokens in your account.',
    order_total: 'Order total',
    accept: 'Accept',

    // /links
    my_links: 'My links',
    manage_shortened_links: 'Manage your shortened links',
    create_new: 'Create new',
    profile: 'Profile',
    link: 'Link',
    impressions: 'Impressions',
    clicks: 'Clicks',
    link_clicks: 'Link clicks',
    ad_clicks: 'Ad clicks',
    sales: 'Sales',
    conv_rate: 'Conv. rate',
    actions: 'Actions',
    total_views: 'Total views',
    total_clicks: 'Total clicks',
    links_info_title: 'Boost Links',
    links_info_text: 'With Boost My Link you can buy traffic to your profiles and content on clip sites, subscription sites and other content based websites that allows you to sell your photos, videos and have profiles.You can now buy traffic to such sites, the same way you can buy traffic to your livecam profiles.',
    read_more_about_links: 'Read more about links',
    url: 'URL',
    this_account: 'This account',
    // create
    create_link: 'Create a link',
    create_link_text: 'Enter a link you want to shorten',
    advanced_settings: 'Advanced settings',
    show_ad: 'Show ad on shortened links',
    ad_to_show: 'Ad to show',
    message: 'Message',
    ad_placeholder: 'Hi there! I\'m live on cam right now. Come join me',
    promote_cam: 'Promote my cam room',
    promote_diva: 'Promote my Diva landing page',
    when_online: 'When I am online',
    when_offline: 'When I am offline',
    go: 'Go!',
    link_created: 'Link created!',
    click_link_info: 'Anyone clicking on this link will see the page with your ad inside.',
    copy_link: 'Copy link',
    link_copied: 'Link copied',
    copy_link_details: 'Copy URL to clipboard',
    cant_copy: 'Unable to copy',
    save_changes: 'Save changes',
    share_on_networks: 'Share the link on these networks',
    come_visit: 'Hi there! Come visit my live webcam right now!',
    just_click: 'Just click here',
    link_protected_warning: 'This domain blocks new users with captcha and might give much lower numbers than others',

    // /tokens
    tokens_header_text: 'Use tokens to boost your cam with traffic (visitors).',
    tokens_history: 'Tokens history',
    activity: 'Activity',
    no_transactions: 'No transactions',
    overall: 'overall',
    but_tokens_text: 'Need more tokens? No problem! Head on over to the orders page and buy more tokens!',
    details: 'Details',
    get_more_tokens: 'Get more tokens',

    // /orders
    my_orders: 'My Orders',
    my_orders_header_text: 'Overview of your orders',
    order_status_0: 'Paused',
    order_status_1: 'Active',
    order_status_2: 'Cancelled',
    order_status_3: 'Pending',
    order_status_4: 'Completed',
    queued: 'Queued',
    queuetooltip: 'You are :pos in the queue for :plan.',
    refunded: 'Refunded',
    archive: 'Archive',
    total_visitors: 'Total visitors',
    price: 'Price',
    delivered_visitors: 'Delivered visitors',
    balance: 'Balance',
    start_date: 'Start date',
    no_active_orders: 'No active orders',
    viewers: 'Viewers',
    live_stats_enabled: 'This order is currently active, live statistics are enabled.',
    stats_not_active: 'This order is currently paused or has been completed.',
    order_queued: 'This order is queued. It\'s #:position in the queue.',
    order_queued_desc: 'One or more of your orders are currently queued due to high demand.',
    orders_plans: 'Traffic plan orders',
    show_paused: 'Paused orders',
    show_active: 'Active orders',
    show_completed: 'Completed orders',
    model: 'Model',
    order_status: 'Order status',
    performer: 'Performer',
    platform: 'Platform',
    destination: 'Destination',
    back_to_orders: 'Back to orders',

    // /studio/performers
    studio: 'Studio',
    performers: 'Performers',
    shortcuts: 'Shortcuts',
    administrate: 'Administrate',
    add: 'Add',
    control_user: 'Control user',
    search: 'Search',
    reset: 'Reset',
    close: 'Close',
    add_performers: 'Add performers',
    add_performers_text: 'Here you can associate performers with your studio by giving them a link or by sending them an email invitation.',
    all_models: 'All models',
    go_to_room: 'Go to room',
    sort_by_name: 'Sort by name',
    sort_by_online: 'Sort by online status',
    sort_by_viewer: 'Sort by viewer count',

    // /studio/settings
    settings_header_text: 'Change your settings here.',
    name: 'Name',
    contact_email: 'Contact email',
    contact_phone: 'Contact phone',
    website: 'Website',
    cancel: 'Cancel',
    save: 'Save',
    boost_cam: 'Boost cam',

    // /studio/models
    model_management_page: 'This is your models management page.',
    no_models_added: "You haven't added any models yet. Add your first model by clicking the button below.",
    add_model: 'Add model',
    add_a_model: 'Add a model',
    need_help: 'Need help?',
    hide_profiles: 'Hide profiles',
    hide_deleted: 'Hide deactivated',
    show_all_profiles: 'Show all profiles',
    platform_not_available: ':platform is not available for boosting at the moment',
    either: 'Either',
    any: 'Any',
    filter_profiles: 'Filter profiles',
    give_tokens: 'Give tokens',
    profile_name: 'Profile name',
    user_or_profile_name: 'User or profile name',
    model_count: '{1} 1 model|{*} :count models',
    model_info: 'Model info',
    profile_info: 'Profile info',
    select_your_platform: 'Select your platform',
    boost_profiles: 'Boost profiles',

    // /partials header
    home: 'Home',
    about: 'About',
    services: 'Services',
    how: 'How',
    contact: 'Contact',
    support: 'Support',
    sign_up: 'Sign up',
    // performer
    admin: 'Admin',
    grid: 'Grid',
    list: 'List',
    all_performers: 'All performers',
    identifier: 'Name/Identifier',
    all_platforms: 'All platforms',
    any_status: 'Any status',
    all_genders: 'All genders',
    users: 'Users',
    // sidebar-top
    toggle_nav: 'Toggle navigation',
    add_link: 'Add link',
    url_error: 'URL error',
    url_error_text: 'The url must be invalid, please check the url and try again.',

    // /profiles
    profiles: 'Profiles',
    camsite: 'Camsite',
    last_online: 'Last online',
    create: 'Create',
    add_new_profile: 'Add new profile',
    on_camsite: 'What camsite are you on?',
    model_name: 'What is your model name there?',
    add_new: 'Add new',
    no_profile: 'Connect Profile',
    profiles_count: '{1} 1 profile|{*} :count profiles',

    // /social
    social_header: 'Social Media Publisher',
    tools: 'Tools',
    social_pub: 'Social Publisher',
    social_update: 'Social Media Auto Updater and Scheduler',
    social_update_text: 'Use this tool to schedule and update all your social media accounts at the same time. You can also set it to automatically post to social media every time you go online. Get started!',

    // surveys
    survey: 'Survey',
    go_back: 'Go back',
    survey_thanks: 'Thank you for taking this survey, you will be rewarded with tokens upon completion.',
    survey_taken: 'You have already taken this survey.',

    // tools
    diva_toolkit: 'Diva Toolkit',
    toolkit_text: 'Use these tools to get more traffic (visitors) to your room, auto-update your social media, and earn tokens.',
    cambooster_text: 'You can use the CamBooster to jump start your cam shows with a stream of traffic (viewers) to your room, the next time you go online or when you specify.',
    divalinks_text: 'DivaLinks is an link shortener that will earn you tokens and get free traffic (visitors) to your cam room, and more fans and followers on your social media profiles. You will also earn tokens for every click. Tokens you can spend on boosting your room.',
    social_fanpage: 'Social Fanpage',
    social_fanpage_text: 'Lets you make a customizable fanpage that will help you get more fans and followers on your social media profiles, and more visitors to your next show. Let your fans know where to get all your content in one place.',
    setup_page: 'Setup my page',

    // user settings
    email: 'Email',
    full_name: 'Full name',
    new_password: 'New password',
    enter_new_passw: 'Enter new password',
    repeat_password: 'Repeat password',
    repeat_new_passw: 'Repeat new password',
    studio_permissions: 'Studio permissions',
    can_share_links: 'Can share links',
    can_boost_cam: 'Can boost cam',
    can_manage_orders: 'Can manage orders',
    can_use_social: 'Can use social media',

    // adzones
    tool: 'Tool',
    delete: 'Delete',
    // edit
    cam_to_adv: 'Cam sites to advertise',
    choose_cam: 'Choose a Camsite...',
    models_to_prio: 'Specify models you want to prioritize if they are online',
    choose_performer: 'Choose a performer...',
    copy_code: 'Copy the following code',
    // create
    create_adzone: 'Create new adzone',
    select_tool: 'Select tool',
    edit: 'Edit',
    next: 'Next',
    previous: 'Previous',

    // auth login
    login_header_text: 'Sign in and boost your cam today!',
    email_adress: 'E-mail address',
    remember: 'Remember me',
    reset_passw: 'Reset password',
    reset_passw_intro: 'Enter your new password below',
    email_passw_intro: 'Forgot your password? No problem, enter your email below and we\'ll send you a reset password link.',
    or_sign_in: 'Or sign in with',
    need_account: 'Need an account?',
    sign_up_now: 'Sign up now',
    password: 'Password',
    login_problem: 'Do you have problems signing in?',
    login_contact: 'Contact us and we\'ll help you out!',
    // register
    sign_up_text: 'Sign up and boost your cam today!',
    sign_up_text_studios: 'Hello there! Sign up and take control of your models traffic today!',
    sign_up_text_models: 'Sign up and boost your cam today!',
    sign_up_text_choose: 'Are you a model or a studio? Choose your account type below',
    confirm_passw: 'Confirm password',
    i_am_a: 'I am a',
    studio_name: 'Studio name',
    phone_number: 'Phone number',
    studio_website: 'Studio website',
    total_performers: 'Total performers',
    agree: 'I agree to the ',
    terms_conditions: 'Terms and Conditions',
    already_have_account: 'Already have an account?',
    sign_in_now: 'Sign in now',
    or_sign_up: 'Sign up with',
    register: 'Register',
    register_model_text: 'Create a new model account. This is for individual models and camgirls.',
    sign_up_model_button: 'Sign up as a model',
    register_studio_text: 'Create a new studio account. This is for studios who manage multiple models.',
    sign_up_studio_button: 'Sign up as a studio',
    register_affiliate_text: 'Create a new affiliate account. Use ad-tools from DivaTraffic to promote online models and earn money',
    sign_up_affiliate_button: 'Sign up as an affiliate',
    reset_passw_text: 'Send Password Reset Link',
    studio_Q: 'Representing a Studio?',
    are_you_studio: 'Go to the studio signup page and',
    studio_signup: 'Studio signup',
    model_Q: 'Model?',
    are_you_model: 'Are you a model?',
    model_signup: 'Model signup',
    register_problem: 'Do you have problems registering?',
    reg_studio: 'Register as a studio',
    reg_studio_sub: 'Register and boost your models camrooms!',
    reg_enter_details: 'Enter your details',
    reg_user_details: 'User details',
    reg_user_enter_details: 'Enter your account info',
    reg_studio_details: 'Studio details',
    reg_studio_enter_details: 'Enter your studio details',
    reg_name: 'Your name',
    reg_email: 'your@email.com',
    reg_password: 'Your password',
    reg_re_password: 'Retype your password',
    reg_studio_name: 'The name of the studio',
    reg_studio_email: 'The studio contact e-mail adress',
    reg_studio_phone: 'The studio contact number',
    reg_studio_country: 'Select your country',
    reg_studio_city: 'In what city is the studio',
    reg_total_performers: 'Please select...',
    reg_affiliate: 'Register as an affiliate',
    reg_affiliate_sub: 'Sign up to boost your models today!',
    contact_send_us: 'Send us a message',
    contact_having_problems: 'Having problems?',
    contact_faq: 'Check our FAQ',
    contact_subject: 'Subject',
    contact_subject_place: 'Message subject',
    contact_message: 'Message',
    contact_send_message: 'Send message',
    contact_contact_us: 'Contact us',
    contact_get_in_touch: 'Get in touch and we will do our best to help you out.',
    contact_information: 'Contact information',

    // /camsites
    camsites: 'Camsites',
    connect_camsite: 'Connect to your camsite',
    connec_camsite_text: 'Before you can boost your cam or earn tokens from sharing links, you need to configure your cam room. Please select the camsite you are on from the sites below, and click Connect to configure your webcam.',
    freemium: 'Freemium site',
    connect: 'Connect',

    // error page
    be_back: 'Be right back.',

    // /publisher
    total_income: 'Total income',
    num_adzones: 'Number of adzones',
    total_impressions: 'Total number of impressions',
    num_clicks: 'Total number of clicks',
    CTR: 'Click-through rate',

    // /verify
    verify_account: 'Verify your account',
    account_verification: 'Account verification',
    verify_text: 'Your account has not yet been verified. Please click the link in the verification email to verify your account. Check your spam folder if you can\'t find it in your inbox',

    //payments
    buy_tokens: 'Buy tokens',
    payments_header_text: 'Some text',
    invoice: 'Invoice',
    invoice_date: 'Invoice Date',
    item_list: 'Item List',
    total_price: 'Total Price',
    payment_via: 'Payment via',
    subtotal: 'Subtotal',

    //status page
    enabled: 'Enabled',
    disabled: 'Disabled',
    yes: 'Yes',
    no: 'No',
    gay: 'Gay',
    straight: 'Straight',
    trans: 'Trans',
    men: 'Men',
    women: 'Women',
    couples: 'Couples',
    status_title: 'Title',
    site_url: 'Site URL',
    affiliate_url: 'Affiliate URL',
    alexa_rank: 'Alexa rank',
    numb_unique_models: 'Number of unique models',
    online_models: 'Online models',
    model_api: 'Model API',
    model_referral: 'Model referral program',
    live_screenshot: 'Live screenshot',
    live_stream: 'Live stream',
    profile_pic: 'Profile picture',
    business_model: 'Business model',
    orientation: 'Orientation',
    gender: 'Gender',
    has_whitelabel: 'Has whitelabel',
    has_revshare: 'Has revshare',
    has_conv_tracking: 'Has conversion tracking',
    status_freemium: 'Freemium',
    premium: 'Premium',
    supported: 'Supported',
    not_supported: 'Not supported',

    // /Sign up
    select_account: 'Select your account type',

    // /About page
    know_more_heading: 'Want to know more about us?',
    know_more_info: 'Go to our contact page and we\'ll answer your questions',
    about_us: 'About us',
    about_info: 'Information about Diva Technologies AS and who we are',
    about_know_more: 'Want to know more?',
    about_goto: 'Go to our contact page and we\'ll answer your questions',
    about_history_and_goal_header: 'History and goal',
    about_history_and_goal: 'DivaTraffic is a product by Diva Technologies AS which was founded in 2016 and has its HQ in Bergen, Norway. DivaTraffic is a traffic network specializing in traffic and ad tools for the live streaming industry. It\'s a service for broadcasters and online content creators to increase their audience and get more traffic to their online streams and content.',
    about_our_team: 'Our team',

    // Other React pages
    years_old: ':years years old',
    user_count: ':users users',
    token_count: ':tokens tokens',
    viewer_count: ':viewers viewers',
    visitor_count: ':visitors visitors',
    model_profile: 'Model profile',
    make_your_selections: 'Make your selections',
    choose_boost_camgirl: 'Choose the model camgirl profile you want to boost',
    autoresume: 'Automatically resume order after private/offline',
    traffic_package: 'Traffic package',
    package_size: 'Package size',
    order_summary: 'Order summary',
    package: 'Package',
    size: 'Size',
    total: 'Total',
    complete_order: 'Complete Order',
    plan_info: ':plan info',
    select_camsite_and_nickname: 'Select camsite and type in the nickname for the profile you would like to add',
    nickname_at_platform: 'Nickname used at the platform',
    your_nickname: 'Your nickname',
    new_profile: 'New profile',
    unavailable: 'Unavailable',
    unavailable_description: 'Boost unavailable for this platform.',
    open_landingpage: 'Open landing page',
    no_access_tokens: 'You have not created any personal access tokens.',
    personal_access_token: 'Personal Access Token',
    personal_access_tokens: 'Personal Access Tokens',
    create_new_token: 'Create New Token',
    new_token: 'New token',
    personal_token_caution: 'Here is your new personal access token. This is the only time it will be shown so don\'t lose it! You may now use this token to make API requests.',
    authpopup_sign_in_message: 'Hello there! Sign in and start gaining webtraffic instantly!',
    authpopup_sign_up_message: 'Hello there! Sign up and start gaining webtraffic instantly!',
    or_sign_in_with: 'Or sign in with',
    create_new_link: 'Create new link',
    soon: 'Soon...',
    link_logged_out_message: 'You must register or log in to use ulocked features. Members get live statistics and more!',
    add_profile: 'Add profile',
    add_package: 'Add package',
    all_links: 'All links',
    buy_traffic: 'Buy traffic',
    share_link: 'Share link',
    customize: 'Customize',
    delete_link: 'Delete link',
    ad_type: 'Ad type',
    select_profile: 'Select profile',
    customize_your_link: 'Customize your link',
    link_share_message: 'Copy this address and share it! Anyone clicking on this link will see the page you shared with your ad inside.',
    copy: 'Copy',
    enter_address: 'Enter the webpage address',
    redirected_popups: 'Redirected popups',
    traffic_type: 'Traffic type',

    // Vue pages
    updated: 'Updated',
    budget: 'Budget',
    limits: 'Limits',
    orderprogress: 'Progress',
    profile_details: 'Profile details',
    user_details: 'User details',
    boost_profile: 'Boost profile',
    plan_details: 'Plan details',
    budget_limit: 'Budget and limit',
    select_platform: 'Select platform',
    performer_nickname: 'Performer nickname',
    camsite_header: 'What camsite is the model on?',
    camsite_subtitle: 'Select the camsite platform the model is performing on',
    model_header: 'Whats the models username?',
    model_subtitle: 'Enter the username the model uses on the camsite',
    model_details: 'Model details',
    model_details_sub: 'Enter the details for the new model',
    model_name_input: 'Model name',
    model_name_input_sub: 'Enter the full name for the model',
    image: 'Image',
    image_subtitle: 'Upload image of the model',
    image_subtitle_profile: 'Upload new profile image',
    image_upload: 'Upload image',
    select_image: 'Select image',
    image_crop: 'Crop image',
    email_subtitle: 'Only required if the model should be able to log into DivaTraffic and personally use the model account',
    save_model: 'Save model',
    model_invite: 'Send invitation to model?',
    model_invite_sub: 'By checking this option, the model will get an invitation to get her own account, and can login and use DivaTraffic from login and use DivaTraffic from their own account',
    camsite_profiles: 'Camsite profiles',
    camsite_profiles_sub: 'If the model is broadcasting on more than one camsite, you can add all the profiles here',
    boost_models: 'Boost models',
    social_accounts: 'Social accounts',
    social_accounts_sub: 'Enter the models social account details to get access to more features and even more tools to get more traffic',
    manage_settings: 'Manage the models settings here',
    general_settings: 'General settings',
    general_settings_sub: 'The models general settings',
    affiliate_settings: 'Affiliate',
    affiliate_settings_sub: 'Update your affiliate information',
    security_settings: 'Security',
    security_settings_sub: 'Manage your accounts security',
    deactivate: 'Deactivate this account',
    deactivate_sub: 'If you for some reason want to end this models account, this is the place to do it. Keep in mind, deleting this account will also deactivate all model profiles associated with it',
    deactivate_account: 'Deactivate account',
    change_password: 'Change password',
    old_passw: 'Old password',
    new_passw: 'New password',
    confirm_password: 'Confirm password',
    current_balance: 'CURRENT BALANCE',
    tokens_caps: 'TOKENS',
    collect_tokens: 'Collect tokens',
    select_give_amount: 'Select the amount of tokens you want to give to',
    select_collect_amount: 'Select the amount of tokens you want to collect from',
    performer_name: 'Performer name',
    privacy_settings: 'Privacy settings',
    privacy_settings_sub: 'The models privacy settings',
    update_avatar: 'Update your avatar',
    order_details: 'Order details',
    more_options: 'More options',
    bid: 'Bid',
    visitor_limit: 'Visitor limit',
    offline_redirect: 'Offline redirect',
    autostart: 'Autostart',
    autostart_sub: 'Automatically start paused order when model comes back online',
    autostart_info: 'Read more about autostart of orders',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    current_bid: 'Current bid',
    bid_info: 'Read more about bids',
    redirect_url: 'Redirect url',
    redirect_info: 'Read more about redirects and where to set the target url',
    budget_info: 'Read about budget and how it works',
    max_and_min: 'Max and min guests in room',
    max_and_min_info: 'Read about limiting your traffic',
    traffic_package_info: 'Detailed information about the :package package',
    profiles_sub: 'You can select multiple profiles',
    profiles_info: 'How to optimize your selection of model profiles',
    account_details: 'Account details',
    privacy: 'Privacy',
    affiliate: 'Affiliate',
    security: 'Security',
    social: 'Social',
    affiliate_add: 'Add your unique affiliate key / campaign id for each platform',
    blocked_countries: 'Blocked countries',
    blocked_countries_sub: 'Add countries you don\'t want to receive traffic from. This setting will be applied to all profiles belonging to this model',
    blocked_countries_subtitle: 'Add countries you don\'t want to receive traffic from',
    affiliate_setting: 'Affiliate settings',
    affiliate_key: 'Affiliate key (Will replace {aff_key} in the URL)',
    //affiliate_key_url': 'in the URL )',
    update_profile: 'Update profile',
    add_new_model: 'Add new model',
    add_new_package: 'Add new package',
    edit_model: 'Edit model',
    update_avatar_title: 'Update avatar',
    order_stats: 'Order statistics',
    select_all: 'Select all',
    deselect_all: 'Deselect all',
    boost_selected: 'Boost selected',
    cancelled_text: 'The order has been canceled and remaining funds has been transferred to your account',
    platform_count: ':platforms platforms',
    are_you_sure: 'Are you sure?',
    remaining_funds: 'The remaining funds will be transferred to your account',
    cancel_order: 'Cancel order!',
    back: 'Back',
    cancel_refund: 'Cancel and refund',
    all: 'All',
    create_order: 'Create order',
    user_settings: 'User settings',
    saved: 'Saved',
    settings_saved: 'User settings saved',
    error: 'Error',
    save_failed: 'Save failed',
    delete_account: 'Delete account',
    delete_your_account: 'Delete your account',
    delete_account_sub: 'If you want to delete your account, this is the place to do it. Keep in mind, deleting your account will also deactivate all model profiles associated with it',
    profile_info_sub: 'Enter your profile details below',
    saved_image: 'Image saved',
    error_something: 'Something went wrong',
    profile_saved: 'Profile info saved',
    language: 'Language',
    logout: 'Log out',
    avatar: 'Avatar',
    overview: 'Overview',
    order_index: 'Order index',
    daily_token_usage: 'Daily token usage',
    traffic_statistics: 'Traffic statistics',
    daily_visitors: 'Daily visitors',
    visitors_today: 'Visitors today',
    last_two_weeks: 'Last two weeks',
    full_statistics: 'Full statistics',
    weekly_visitors: 'Weekly visitors',
    visitors_this_week: 'Visitors this week',
    last_14_weeks: 'Last 14 weeks',
    monthly_visitors: 'Monthly visitors',
    visitors_this_month: 'Visitors this month',
    last_year: 'Last year',
    no_results: 'No results',
    no_performer_results_details: 'Find model profiles by searching using the search input above (Search by name), and narrowing it by using the dropdown (Platform).',
    any_platform: 'Any platform',
    online_status: 'Online status',
    model_name_header: 'Model name',
    transfer_complete: 'Transfer completed',
    delete_profile_text: 'This will permanently delete the profile :name.',
    delete_succeeded: 'Deleted!',
    profile_deleted: 'This profile was deleted.',
    deleted_profile: 'Deleted profile',
    age: 'Age',
    about_me: 'About me',
    guest_message: 'Guest message',
    not_available: 'Not available yet',
    coming_soon: 'Coming soon',
    general: 'General',
    targeting: 'Targeting',
    bids: 'Bids',
    delete_model_confirm: 'Deleting this account will also deactivate all model profiles associated with it',
    yes_delete_it: 'Yes, delete it!',
    account_deleted: 'This account has been deleted.',
    change_password_failed: 'Could not save your password.',
    edit_link: 'Edit link',
    edit_profile: 'Edit profile',
    show_n_profiles: '{1}Show 1 more profile|[*,*]Show :count more profiles',
    apply_targeting: 'Per thousand impressions. This applies to all your targeting',
    edit_package: 'Edit package',
    update: 'Update',
    package_description: 'Package description',
    package_title: 'Package title',
    give_package_name: 'Give the package a name',
    give_package_description: 'Give the package a description',
    default_bid: 'Default bid',
    dynamic_bidding: 'Dynamic bidding',
    customize_bid: 'Customize bids for specific targeting',
    add_bid: 'Add bid',
    sources: 'Sources',
    devices: 'Devices',
    browsers: 'Browsers',
    os: 'Os',
    targeting_options: 'Select all your targeting options',
    package_created: 'Package created',
    affiliate_room_url: 'Affiliate room URL',
    profiles_saved: 'Profile saved',
    packageview: 'Package view',
    package_details: 'Package details',
    per_thousand: 'per thousand impressions',
    custom_bids: 'Custom bids',
    operating_systems: 'Operating systems',
    package_updated: 'Package updated',
    update_failed: 'Update failed',
    order_created: 'Order created',
    something_wrong: 'Something went wrong',
    we_are_working_on_the_problem: 'We have been notified and are working on the problem',
    all_transactions: 'All transactions',
    into_account: 'Into account',
    out_account: 'Out of account',
    payments: 'Payments',
    amount: 'Amount',
    date: 'Date',
    comment: 'Comment',
    payment_method: 'Payment method',
    subscription_payment: 'Subscription payment',
    choose_subscription: "Choose the subscription that suits your needs",
    free_account_option_text: "If no subcription is what you prefer, don't worry! You will still have a few features that you will benefit from",
    remaining: 'remaining',
    promotools: 'Promo Tools',
    card_view: 'Card view',
    list_view: 'List view',
    select_profiles: 'Select profiles',
    select_package: 'Select traffic package',
    choose_package: 'Choose a traffic package here',
    all_profiles: 'All profiles',
    could_not_load: 'Could not load :type',
    bid_tooltip: 'Default value set to 100%. Increasing the bid means paying more for each visitor, which will give you traffic faster to your room but you will also spend your budget faster',
    maxviewers_tooltip: 'When "Max Viewers" is reached we will stop sending traffic until the viewer count is below this number again. Depending on the camsite and the targeting of the package, there is not a guarantee to reach this number, but to work as an upper limit',
    tokens_tooltip: 'This number represents the budget/cost you would like to spend on this order',
    how_does_it_work: 'How does it work?',
    basic: 'Basic',
    max_viewers: 'Max viewers',
    traffic_settings: 'Traffic settings',
    start_automatically: 'Start automatically',
    renew_automatically: 'Renew automatically',
    select_promo: 'Please select promo tool',
    select_promo_header: 'This javascript library controls all DivaTraffic Promo Tools. Configure the settings as you wish, then copy and paste the script code into your website.',
    camhead_info: 'CamHead is a floating ad tool that will show an online camroom on any page the script code is placed on',
    snippet_info: 'Snippet will show an online camroom on any page the script code is placed on',
    widget_info: 'The Native Cam Iframe will display multiple cam profiles in any size you want',
    select_camsite: 'Please select which camsites you want to send traffic to',
    tools_instruction: 'The below javascript controls all the promo tools provided by DivaTraffic. Copy the script and place the code right before the <strong class="text-info">&lt;head&gt;</strong> on the page you want the ads to show.',
    integration_script: 'Integration Script',
    viewers_room: 'Viewers in room',
    total_sent: 'Total sent',
    profiles_online: '{1} Profile online |{*} Profiles online',
    duration: 'Duration',
    hour: '{1} 1 hour|{*} :count hours',
    minutes: '{1} 1 minute|{*} :count minutes',
    tokens_left: 'tokens left',
    model_plural: '{1} Model|{*} Models',
    profile_plural: '{1} Profile |{*} Profiles',
    camsite_plural: '{1} Camsite |{*} Camsites',
    platforms_short: '{1}:platform|:count platforms',
    responsive: 'Responsive',
    fixed_size: 'Fixed size',
    height: 'Height',
    width: 'Width',
    select_rooms: 'Select rooms',
    xs_package: 'XSmall tokens bundle',
    sm_package: 'Small tokens bundle',
    md_package: 'Medium tokens bundle',
    lg_package: 'Large tokens bundle',
    xl_package: 'XL tokens bundle',
    xxl_package: 'XXL tokens bundle',
    xxxl_package: 'XXXL tokens bundle',
    custom_package: 'Custom tokens bundle',
    select_token_package: 'Select your token package',
    select_token_bundle: 'Select your token bundle',
    custom_amount: 'Custom amount',
    payment_details: 'Payment details',
    bundle_details: 'Bundle details',
    transaction_info: '* The transaction will be billed in Euros and the billing bank can apply additional exchange fees.',
    paysite_info: 'Paysite-cash Cardholder Support',
    payment_tokens: 'Payment for tokens',
    search_performer: 'Search by performer name',
    slower: 'slower',
    faster: 'faster',
    please_select_camsite: 'Please select camsite',
    no_models: 'No models found.',
    no_profiles: 'No profiles found.',
    no_orders: 'No orders found.',
    no_stats: 'No statistics found...',
    loading_models: 'Loading models...',
    loading_profiles: 'Loading profiles...',
    loading_orders: 'Loading orders...',
    loading_dashboard: 'Loading dashboard data...',
    loading_data: 'Loading data...',
    loading_stats: 'Loading statistics...',
    camhead_desc: 'CamHead is a code that will show a cam head advertising your online show. All you have to do is copy it, then paste it into the html of any website. It will show any online profile you have in your account.',
    snippet_desc: 'Snippet will show an online camroom on any page the script code is placed on.',
    widget_desc: 'The Native Cam Iframe will display multiple cam profiles in any size you want.',
    divalinks_desc: 'DivaLinks is a tool for shortening a link you want to share and that also puts an add of your camroom that appears when you are online',
    smartlink_desc: 'SmartLink is a URL that will redirect to any of your online profiles that you have in your studio. Share it on social media with your fans and members.',
    smartlink_affiliate_desc: 'SmartLink is a URL that will redirect to an online performer based on your settings. Share it on social media',
    camhead_script: 'The below javascript controls the CamHead. Copy the script and place the code right before the <strong class="text-info">&lt;/head&gt;</strong> on the page you want the ads to show.',
    snippet_script: 'The below javascript controls the Snippet. Copy the script and place the code right before the <strong class="text-info">&lt;/head&gt;</strong> on the page you want the ads to show.',
    widget_script_1: '1. Put this code right before the <strong class="text-info">&lt;/head&gt;</strong> on your page.',
    widget_script_2: '2. Place this code where you want the widget to appear',
    smartlink_adress: 'SmartLink address',
    smartlink_description: 'This is a smartLink that will redirect to an online performer based on your settings.',
    configure: 'Configure',
    choose_setting: 'Choose your preferred settings for the',
    select_camsite_sub: 'You can specify which camsites you want to display models from',
    select_rooms_sub: 'You can specify which rooms you want to display',
    static_responsive: 'Should widget be static or responsive?',
    whitelabels: 'Whitelables',
    add_whitelabel: 'Add whitelabel',
    add_new_whitelabel: 'Add new whitelabel',
    whitelabel_saved: 'Whitelabel saved',
    no_whitelabel: 'No whitelabel',
    model_user: 'User/Model',
    registrations: 'Registrations',
    rebills: 'Rebills',
    costs: 'Costs',
    ctr: 'Click Through Rate',
    number_of: 'Number of ',
    daily_impressions: 'Daily impressions',
    daily_clicks: 'Daily clicks',
    daily_registrations: 'Daily registrations',
    daily_sales: 'Daily sales',
    daily_rebills: 'Daily rebills',
    daily_ctr: 'Daily CTR',
    daily_redirects: 'Daily redirects',
    year: 'Year',
    referrer: 'Referrer',
    redirects: 'Redirects',
    redirect: 'Redirect',
    user: 'User',
    false: 'False',
    sorted_by: 'Sorted by',
    from: 'From',
    to: 'To',
    online_performer_count: '{1} 1 online performer | :count online performers',
    total_performer_count: '{1} 1 performer total | :count performers total',
    package_howto: 'Creating your own package can make a big difference to how the traffic works for you. Select targeting options you know is converting better for you and make sure you only receive traffic from these sources. Click <strong class="text-success">Start guide</strong> if you need help going through the process',
    start_guide: 'Start guide',
    how_it_works: 'How it works',
    audience: 'Audience',
    packagecreate: 'Create package',
    packageedit: 'Edit package',
    select: 'Select',
    start_boost: 'Start boost',
    please_select_package: 'Please select a package',
    select_package_sub: 'Click on a package from the left menu to select it and read more about the package specifications',
    delivered_visitor_count: '{1} 1 delivered visitor | :count delivered visitors',
    tokens_spent: ':tokens spent',
    dimension: 'Dimension',
    password_changed: 'Password changed',
    viewer_history: 'Viewer history',
    advanced: 'Advanced',
    traffic_options: 'Traffic options',
    budget_sizes: [
        'Small',
        'Medium',
        'Large',
        'XL',
        'XXL',
        'XXXL'
    ],
    gotoroom: 'Go to room',
    deleted_plan: 'Deleted plan',
    n_minutes_ago: '{0} Now|{1} 1 min ago|{*} :count mins ago',
    n_hours_ago: '{1} 1 hr ago|{*} :count hrs ago',
    n_days_ago: '{1} 1 day ago|{*} :count days ago',
    select_theme: 'Select theme',
    select_theme_sub: 'Select a widget theme',
    select_thumb: 'Select thumbnail size',
    select_thumb_sub: 'How big do you want the thumbnails to be?',
    background_color: 'Background color',
    select_background_color: 'Specify the color of the information bar below the image',
    select_details: 'Select details',
    select_details_sub: 'Specify what information to show',
    camsite_name: 'Camsite name',
    tool_model_name: 'Model name',
    widget_sub_1: 'This script controls the widgets',
    widget_sub_2: 'The widget will appear inside div tags with the specified class',
    script_tag_header: 'Script tag',
    script_tag_sub: 'Install the widget by following these steps',
    room_settings: 'Room settings',
    graph: 'Graph',
    visitors_sent: 'Visitors sent',
    room_limit: 'Room limit',
    online_viewers: 'Online viewers',
    device_type: 'Device type',
    device_brand: 'Device brand',
    browser: 'Browser',
    cancelling_order: 'Cancelling order',
    profile_added: 'Profile added',
    default_theme: 'Default theme',
    dark_theme: 'Dark theme',
    light_theme: 'Light theme',
    pink_theme: 'Pink theme',
    xsmall: 'XSmall',
    small: 'Small',
    large: 'Large',
    xlarge: 'XLarge',
    less_options: 'Less options',
    no_profiles_selected: 'No profiles selected',
    no_package_selected: 'No package selected',
    estimate_token: 'Estimate per token',
    estimate_traffic: 'Estimated traffic',
    estimate_popups: 'Estimated total popups',
    delivered_popups: 'Delivered popups',
    estimate_clicks: 'Estimated total clicks',
    delivered_clicks: 'Delivered clicks',
    type: 'Type',
    popups: 'Popups',
    select_whitelabel_camsite: 'Select which camsite the whitelabel belongs to',
    selected_profiles: 'Selected profiles',
    view: 'View',
    device_types: 'Device types',
    device_brands: 'Device brands',
    optional: 'optional',
    pay: 'Pay',
    wiretransfer: 'Wiretransfer',
    add_dimension: 'Add dimension',
    clear_filter: 'Clear filter',
    filtered_by: 'Filtered by ',
    date_year: 'Year',
    estimated_visitors: 'Estimated visitors per token',
    any_camsite: 'Any camsite',
    wire_success: 'Payment has been stored. We are waiting for Wire Transfer from you',
    securion_info: 'SecurionPay support',
    update_running_orders: 'Update running orders',
    networks: 'Networks',
    less_targetoptions: 'Click for less target options',
    more_targetoptions: 'Click for more target options',
    camsite_specific: 'Camsite specific',
    camsite_specific_sub: 'Select one or more to make this package work only with models from these camsites',
    main: 'Main',
    my_order: 'My order #',
    update_order: 'Update order',
    edit_order: 'Edit order',
    already_paid: 'Already paid',
    spent: 'Spent',
    remaining_on_order: 'Remaining on order',
    to_be_paid: 'To be paid',
    order_updated: 'Order updated',
    loading_tools: 'Loading tools',
    profiles_removed: '{1} 1 profile removed. Not compatible with this camsite specific package |{*} :count profiles removed. Not compatible with this camsite specific package',
    visit_room: 'Visit room',
    affiliatelink_text: 'You can have traffic sent to your own affiliate link by entering the info below. Your earnings will be in the camsites affiliate program, and you will not earn tokens per signup. Sales statistics will be unavailable',
    affil_key: 'Affiliate key',
    whitelabel_settings: 'Whitelabel settings',
    whitelabel_text: 'If you have your own whitelabel, you can have the traffic sent to it by specifying the settings below. You will not earn token for signups or be able to see conversion or sales statistics. Your earnings will be in the camsites affiliate program.',
    whitelabel_domain: 'Whitelabel domain',
    diva_tps: 'Diva TPS (token per signup)',
    diva_tps_text: 'Get paid in tokens every time someone registers or makes a purchase on the camsite',
    token_per_free: 'Tokens per free registration',
    token_per_purchase: 'Tokens per purchase',
    widget_preview: 'Widget preview',
    desktop: 'Desktop',
    mobile: 'Mobile',
    tablet: 'Tablet',
    estimate_visitors: 'Estimated visitors',
    per_token: 'per token',
    more: 'more',
    select_package_list: 'Select the package you want from the list below',
    my_packages: 'My packages',
    favourites: 'Favourites',
    targeting_all: 'Targeting all',
    all_countries: 'All countries',
    all_os: 'All OS',
    all_browsers: 'All browsers',
    all_networks: 'All networks',
    all_device_types: 'All device types',
    all_device_brands: 'All device brands',
    all_sources: 'All sources',
    filtered_targeting: 'Filtered targeting',
    click_a_targeting: 'Click a targeting option to view list of all targets for this plan',
    order_must_pause: 'The order has to be paused before you can edit',
    cpm_in_tokens: 'Cost per 1000 visitor (in tokens)',
    cost_in_tokens: 'Cost (in tokens)',
    send_invitation: 'Send invitation to model',
    send_password_reset: 'Send an email with a password reset to the model',
    password_reset: 'Password reset',
    model_no_email: 'Model has no email',
    email_sent: 'Email has been sent',
    on_the_camsite: 'on the camsite',
    target_audience: 'Target audience',
    target_audience_sub: 'Specify where you want to receive traffic from. You can add as many target options as you want',
    target_help: 'Creating your own package can make a big difference to how the traffic works for you. Select targeting options you know is converting better for you and make sure you only receive traffic from these sources.',
    bid_sub: 'The bid is the price you pay per 1000 visitor. If you want more traffic from specific targeting options, you can try increasing the bid. Higher bid usually means you will receive traffic that matches this targeting faster',
    targeting_price: 'Targeting price',
    add_targeting: 'Add targeting',
    select_audience: 'Select your audience',
    location: 'Location',
    title: 'Title',
    start_order: 'Start order',
    leave_paused: 'Leave paused',
    cancel_refund_order: 'Cancel and refund order',
    advanced_stats: 'Advanced statistics',
    order_progress: 'Order progress',
    spent_tokens: ':spent of :total tokens',
    edit_profiles: 'Edit profiles',
    settings_applied_all: 'The settings will be applied to all the selected profiles',
    set_max_viewers: 'Set max viewers',
    block_countries: 'Block countries',
    affiliate_program: 'Affiliate program',
    program: 'Program',
    no_affiliate_program: 'No affiliate program',
    affiliate_contact_us: 'If you want to use your own whitelabel or affiliate link, please get in touch with us <a href="https://divatraffic.zendesk.com/hc/en-us/requests/new" target="_blank">here</a>, and we can set it up for your account',
    duplicate: 'Duplicate',
    welcome_sub_1: 'DivaTraffic offers a lot of tools for you to boost webcams when the performer is online.',
    welcome_sub_2: 'All you need to do is to add a model to your account, and you\'re ready to boost your camroom.',
    lets_get_started: 'Let\'s get started' ,
    add_first_model: 'Add your first model',
    add_first_profile: 'Add your first profile',
    intro_to_dashboard: 'Introduction to Dashboard',
    intro_to_dashboard_sub_1: 'Once you get started this will be the DivaTraffic homepage.',
    intro_to_dashboard_sub_2: 'Here you will get a full overview of your current traffic situation.',
    all_data_glance: 'All your data at a glance',
    all_data_glance_sub: 'Cards will give you information about your models, orders and token status. Quickly see running orders or who\'s online.',
    your_recent_orders: 'Your recent orders',
    your_recent_orders_sub: 'The dashboard also provides a list of your recent orders, so you can easily see if you have any running orders and what the details are.',
    aff_key_saved: 'Affiliate key saved',
    aff_key: 'Affiliate key',
    go_to_old_diva: 'Go back to old DivaTraffic',
    need_targeting: 'You need to add targeting for your audience. Click "+ Add targeting" button to get started',
    country_count: '{1} 1 country selected|{*} :count countries selected',
    browser_count: '{1} 1 browser selected|{*} :count browsers selected',
    network_count: '{1} 1 network selected|{*} :count networks selected',
    source_count: '{1} 1 source selected|{*} :count sources selected',
    device_brand_count: '{1} 1 device brand selected|{*} :count device brands selected',
    device_type_count: '{1} 1 device type selected|{*} :count device types selected',
    os_count: '{1} 1 operating system selected|{*} :count operating systems selected',
    bids_count: '{1} 1 bid|{*} :count bids',
    customize_icon: 'Here you can customize your package icon to give it a personalized look and feel',
    icon: 'Icon',
    initials: 'Initials',
    color: 'Color',
    targeting_filters: 'Package targeting filters',
    drag_or_click: 'Drag or click a targeting category to add a filter to this page',
    search_for_websites: 'Search for websites you want traffic from and add them from the list below',
    remove: 'Remove',
    select_websites: 'Select websites',
    search_webpage: 'Search for webpages',
    all_networks_filter: 'All networks',
    search_browsers: 'Search browsers',
    search_countries: 'Search countries',
    search_device_brands: 'Search device brands',
    search_device_types: 'Search device types',
    search_networks: 'Search networks',
    search_os: 'Search OS',
    select_date_range: 'Select date range',
    choose_dates: 'Choose dates',
    apply: 'Apply',
    //Months
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    //Weekdays short
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    sun: 'Sun',
    advanced_available: 'Advanced statistics for this order is not available yet. Please check back again in a few minutes',
    network: 'Network',
    custom: 'Custom',
    select_traffic_options: 'Select traffic options',
    subscriptions: 'Subscriptions',
    current_user_balance: 'Current balance',
    purchase_history: 'Purchase history',
    all_purchases: 'All purchases',
    save_account_details: 'Save account details',
    save_privacy_settings: 'Save privacy settings',
    camhead: 'CamHead',
    snippet: 'Snippet',
    widget: 'Widget',
    smartlink: 'SmartLink',
    free: 'Free',
    deluxe: 'Deluxe',
    monthly: 'Monthly',
    yearly: 'Yearly',
    free_sub_desc: 'Test us out for free!',
    basic_sub_desc: 'Benefit from monthly traffic!',
    premium_sub_desc: 'Higher quality traffic every day',
    deluxe_sub_desc: 'The best traffic we can offer',
    save_2_months: 'save 2 months',
    per_month: 'per month',
    select_plan: 'Select plan',
    features: 'Features',
    studio_promo_tools: 'Studio Promo Tools',
    support_services: 'Support services',
    misc_services: 'Misc services',
    bonus_on_purchase: 'Bonus on token purchases',
    traffic_on_demand: 'Traffic on demand',
    traffic_on_subscription: 'Traffic on subscription',
    custom_traffic_packages: 'Custom traffic packages',
    email_support: 'Email support',
    free_tokens_month: 'Free tokens each month',
    live_chat_support: 'Live Chat support',
    profiles_included: 'Profiles included',
    studio_camhead: 'Studio CamHead',
    studio_widgets: 'Studio Native Cam Widgets',
    studio_console: 'Studio console',
    studio_smartlinks: 'Studio Smart Links',
    dedicated_support_manager: 'Dedicated Support Manager',
    traffic_analytics: 'Traffic Analytics',
    dedicated_traffic_manager: 'Dedicated traffic manager',
    dedicated_traffic_consultant: 'Dedicated traffic conslutant',
    traffic_to_affiliate: 'Traffic to affiliate link',
    traffic_to_whitelabel: 'Traffic to whitelabels',
    per_year: 'per year',
    popular: 'Popular',
    pricing: 'Pricing',
    available_subscription: 'Subscription membership is only available for studios at the moment. Independent model subscription plans are coming soon',
    payments_subscription: 'Payment for subscription',
    payment_for: 'Payment for ',
    subscription: 'Subscription',
    add_all: 'Add all',
    subscription_header: 'Subscription',
    studio_info: 'Studio information',
    phone: 'Phone',
    edit_details: 'Edit details',
    account_info: 'Account information',
    born: 'Born',
    upgrade_plan: 'Upgrade plan',
    available_upgrades: 'Available plans',
    product: 'Product',
    not_configured: 'Not configured',
    configure_camsites: 'Configure camsites',
    default_studio_settings: 'Default studio settings',
    add_blocked_countries: 'Add countries you don\'t want to receive traffic from. This setting will be applied to all your profiles.',
    edit_default_settings: 'Edit default settings',
    your_current_plan: 'Your current plan',
    cancel_subscription: 'Cancel subscription',
    camsite_settings: 'Camsite settings',
    setup_camsite_config: 'Set up your camsite configurations',
    aff_key_activated: 'Affiliate key activated',
    countries_blocked: '{1} 1 country blocked|{*} :count countries blocked',
    studio_avatar: 'Studio avatar',
    first_name: 'First name',
    last_name: 'Last name',
    address: 'Address',
    keep_account_secure: 'Keep your account secure',
    billing: 'Billing',
    edit_billing_info: 'Edit billing information',
    add_profile_first: 'You need to add at least 1 profile first',
    enter_your_aff_key_here: 'Enter your affiliate key here',
    open: 'Open',
    no_subscription: 'No subscription',
    select_free: 'Select free',
    available_to: 'Available to',
    subscription_bonus: 'Subscription bonus',
    downgrade: 'Downgrade',
    traffic_packages: 'Traffic packages',
    subscription_cancelled: 'Subscription cancelled',
    cancelling_subscription: 'Cancelling subscription',
    payment_declined: 'Your payment has been declined',
    subscription_updated: 'Your subscription has been updated',
    something_went_wrong: 'Something went wrong.',
    postbacks: 'Postbacks',
    no_camsite_profile: 'No camsite profile found!',
    no_camsite_profile_sub: 'We could not find any camsite profiles connected to this model',
    user_image: 'User image',
    estimated_traffic: 'Estimated traffic',
    package_price: 'Package price',
    order_title: 'Order title',
    traffic_stops: 'Traffic is automatically stopped if you go offline or into a private show.',
    traffic_stops_sub: 'If you have an order running and you go offline or into a private show, the order is stopped. When you come back online it starts again - automatically!',
    limit_visitors: 'If you want to limit the number of visitors we’ll send to your room, you can!',
    limit_visitors_sub: 'Every profile can be configured to a max number of visitors in the room. If you want to set a limit for your room, you can do it in the profile settings',
    no_traffic_package: 'No traffic package selected. Please select a package from the list below',
    extra_large: 'Extra Large',
    all_packages: 'All packages',
    my_favourites: 'My favourites',
    no_profiles_selected_boost: 'No profiles selected. Please select the profile you want to boost',
    no_profiles_found: 'No profiles found. Please add a profile before boosting',
    no_models_found: 'No profiles found. Please add a model before boosting',
    estimated_viewers: 'Estimated total viewers',
    viewers_per_token: 'Viewers per token',
    pro: 'Pro',
    go_to_dashboard: 'Go to dashboard',
    stay_on_free: 'Stay on free',
    please_select_subscription: 'Please select a subscription type first',
    select_subscription_before_dash: 'Please select a subscription type before moving to dashboard',
    insufficient_funds: 'Insufficient funds',
    pay_and_boost: 'Buy :price tokens and start boost',
    minimum_payment: 'Minimum payment is $5 for 50 tokens',
    pay_five: 'Pay $5',
    not_possible_change_plan: 'It is not possible to change package on an order. Only package size can be edited',
    see_all_profiles: 'See all profiles',
    my_studio: 'My studio',
    camsite_selected: '{1} 1 camsite selected |{*} :count camsites selected',

    // New frontpage
    new_easy: 'The new & easy way',
    get_more_viewers: 'to get more viewers',
    on_sub_demand: 'on subscription & on demand',
    most_popular: 'Most popular',
    viewers_on_sub: 'Viewers on subscription',
    automatic_viewers: 'Automatically get more viewers when you are online',
    models_from: 'Models from',
    studios_from: 'Studios from',
    month: 'month',
    save_two_months: 'Save 2 months if you sign up for one year',
    get_viewers_subscr: 'Get viewers on subscription',
    continuous_traffic: 'Continuous enabled traffic',
    boosting_my_cam: 'Boost My Cam',
    automatic_start_stop: 'Automatic start and stop traffic',
    max_visitors_room_limit: 'Max visitors in room limit',
    bonus_on_token: 'Bonus on token purchases',
    viewers_on_demand: 'Viewers on demand',
    order_viewers_boost: 'Order viewers and boost cam on demand',
    starts_at: 'Starts at',
    pr_token: 'pr token',
    save_by_purchasing_tokens: 'Save by purchasing more tokens each time',
    get_viewers_on_demand: 'Get viewers on demand',
    see_all_pricing: 'See all pricing options',
    what_we_offer: 'What we offer',
    our_great_services: 'Our great services',
    get_started_with_diva: 'Get started with DivaTraffic and increase traffic to your camshow. It\'s super easy!',
    traffic_subscriptions: 'Traffic Subscriptions',
    traffic_subscriptions_sub: 'By subscribing to DivaTraffic, your will get a lot of benefits and added features. Your profiles will receive traffic automatically whenever you are online.',
    all_subscriptions: 'All subscriptions',
    boosting_my_cam_sub: 'FINALLY, getting a boost of traffic to your camshow is just a click away. DivaTraffic is empowering models and studios to easily drive lots of people into your camshow.',
    raise_traffic_earning: 'Raise your Traffic, Raise your Earnings!',
    try_it_now: 'Try it now',
    camhead_sub: 'CamHead is a new and unique way of advertising your camshow. It detects when you are online and available, and makes sure that your ad is visible for the correct audience.',
    empower_yourself: 'Empower yourself and bring the world to you!',
    choose_plan: 'Choose your plan',
    choose_plan_sub: 'There’s a plan for every size and type of team. Choose the one that fits<br>your traffic demands. Contact us if you need more information.',
    studios: 'Studios',
    subscr_for_multiple: 'Subscriptions for multiple models',
    studio_subscriptions: 'Studio subscriptions',
    lots_more: 'And lots more!',
    subscr_for_individual: 'Subscriptions for individual models',
    model_subscriptions: 'Model subscriptions',
    platforms: 'PLATFORMS',
    list_of_supported_camsites: 'We work with the best of the best in the business. Here\'s a list of some of the platforms we are supporting.',
    and_more: '...and more!',
    benefit_from: 'Benefit from monthly traffic!',
    high_quality_traffic: 'High quality traffic every day',
    best_traffic_we_offer: 'The best traffic we can offer',
    contact_us: 'Contact us',
    help: 'Help',
    en: 'English',
    es: 'Español',
    ru: 'Русский',
    languages: 'Languages',

    widget_code: 'Widget code',
    widget_code_text: 'Select one or more of the various widget sizes and place the div tag where you want the widget to show on your page',
    preview: 'Preview',
    banner_rectangle: 'Banner rectangle',
    banner_small_rectangle: 'Banner small rectangle',
    banner_skyscraper: 'Banner skyscraper',
    banner_large_leaderboard: 'Banner large leaderboard',
    widget_settings: 'Widget settings',
    no_profiles_selected_widget: 'Select profiles if you want to show specific models in the tool',
    postbacks_desc: 'All parameters set as GET attributes to URL, but depending on selected postback method we\'ll duplicate parameters in request body if it\'s supported by method (e.g. POST)',
    postbacks_ex: 'Example postback URL',
    add_new_postback: 'Add new postback',
    event: 'Event',
    method: 'Method',
    enter_postback_url: 'Enter your postback URL',
    url_parameters: 'URL parameters',
    aff_income_amount: 'Affiliate income amount',
    sub_account_id: 'Subaccount id. Can be used to specify affiliate inside 3rd party tracking system',
    sub_account_id2: 'Subaccount id 2. Can be used to specify affiliate inside 3rd party tracking system',
    user_ip_address: 'User IP address',
    transaction_type: 'Transaction type',
    admin_subscription: 'Admin Subscription',
    list_too_long: 'List is too long',
    traffic_speed: 'Traffic speed',
    slow: 'Slow',
    fast: 'Fast',
    slow_speed: 'Slower Than Normal',
    normal_speed: 'Normal Speed',
    faster_than_normal: 'Faster Than Normal',
    even_faster: 'Even Faster',
    faster_than_light: 'Extreme: Warning tokens will be flying!',
    speed_tooltip: 'This option regulates how fast we send traffic into your room. For some camsites it can be an advantage to get lots of people fast to become more visible, and for other sites a slower stream of viewers is preferred',
    change: 'Change',
    ad_views: 'Ad views',
    traffic_this_month: 'Traffic this month',
    visit: 'Visit',
    admin_service_status: 'Service status',
    select_camsites: 'Select camsites',
    done: 'Done',
    average_bid: 'Average bid',
    traffic_sent: 'Traffic sent',
    fallback_url: 'Fallback URL',
    set_fallback: 'Set a fallback url for when no models is online',
    enter_url: 'Enter URL',
    saving: 'Saving',
    configure_default_camsite_settings: 'On this page you can configure your default settings for each camsite.',
    default_max_viewers: 'Default max viewers',
    bank_routing_number: 'Bank routing number',
    bank_swift_code: 'Bank SWIFT code',
    zip: 'Zip',
    select_payment_method: 'Select a payment method',
    enter_your: 'Enter your',
    your_first_name: 'Your first name',
    your_last_name: 'Your last name',
    your_address: 'Your address',
    select_country: 'Select country',
    your_bank_vat: 'Your bank VAT',
    local_area_number: 'Local area number',
    your_city: 'Your city',
    minimum_payout_amount: 'Minimum payout amount',
    select_minimum_payout_amount: 'Select minimum payout amount',
    custom_size: 'Custom size',
    traffic_summary: 'Traffic summary',
    subscription_stats: 'Subscription stats',
    ondemand: 'On demand',
    upgrade_subscription: 'Upgrade subscription',
    upgrade_sub_create_packages: 'Please upgrade your subscription to create your own custom traffic package',
    no_packages_found: 'No packages found',
    view_details: 'View details',
    ondemand_stats: 'Stats for traffic on demand',
    daily_earnings: 'Daily earnings',
    block: 'Block',
    blocked: 'Blocked',
    subscription_expired: 'Your subscription has expired',
    payouts: 'Payouts',
    end_period: 'End of period',
    custom_tracking: 'Custom tracking',
    custom_tracking_added: 'Custom tracking added',
    add_tracking: 'Add custom tracking parameters',
    open_rate: 'Open rate',
    opens: 'Opens',
    additional_settings: 'Additional settings',
    customize_tool: 'Customize this tool even further',
    additional_settings_added: 'Additional settings added',
    selected: 'Selected',
    delay_set: '{1} Delay set to 1 second|{*} Delay set to :count seconds',
    sound_selected: 'Sound selected',
    add_delay: 'You can add a delay to the CamHead so that it loads a number of seconds after the page is finished loading',
    add_sound: 'You can add a message sound to the tool that plays when it is loaded',
    sound: 'Sound',
    sound_num: 'Sound {0}',
    order_count: 'Order count',
    filter_by_plan: 'Filter by plan',
    models_per_page: 'Models per page',
    select_traffic_type: 'Select traffic type',
    no_traffic_type_selected: 'No traffic type selected',
    filter_minute: 'Minute',
    filter_hour: 'Hour',
    filter_day: 'Day',
    no_stats_order: 'No stats found for this order',
    native_ad_traffic: 'Native Ad Traffic',
    pop_traffic: 'Pop Traffic',
    pop_traffic_description_new:'Pop traffic is a mix of popups and popunders that opens in a new window or tab on a users computer when they visit a website. Your room is opened directly and the user doesn\'t have to click any ad to enter your room',
    pop_traffic_description: 'Pop traffic is delivered as popups or popunders from thousands of different adult websites, via all the different ad exchanges and traffic networks that DivaTraffic gets its traffic from. When you start your order, your cam room will instantly start showing as a pop on many of the sites, until you have received the amount of traffic you have requested.<br> If you go offline or into private, your order will automatically pause until you are online and in free chat again.<br>Popup traffic is good for getting a lot of traffic fast, since the users aren’t making a choice to go to your room, but is redirected there by just visiting a website.<br>Keep in mind that popups are often closed right away by the users unless the content they see grabs their attention within the first couple of seconds. So we advise you to do what you can to catch their attention and stand out from the crowd, while the traffic is being sent.',
    native_ad_description: 'Native ad traffic is delivered via clicked image ads that are shown on thousands of different adult websites via different ad exchanges and traffic networks that DivaTraffic gets its traffic from. When you buy native ad traffic, you are paying for ad displays (impressions) and the users have to click your image before being sent to your room.<br>The ad that the users will see will either be a live screenshot of your room, or the profile pitcure you use on the camsite, depending on which camsite you are buying traffic to.<br>Unlike pop traffic, display traffic requires the users to make a click before entering your room, so it will not be as useful in terms of getting a lot of traffic fast, but it means that the users you do get, will have made a choice to visit you, based on the image they see, and it should result in higher quality of traffic.<br>We can not guarantee how many will click on your image, only how many times it will be displayed.',
    pop_traffic_selected: 'Pop traffic selected',
    fallback_options: 'Fallback options',
    offline_models_settings: 'Settings for when there are not enough online models',
    relax: 'Relax',
    repeat: 'Repeat',
    relax_description: 'If there is not enough models online to fill the widget, this settings will show duplicates of the ones who are online to fill the rest of the space. If no option is selected \'relax\' is the default setting.',
    repeat_description: 'If there is not enough models online to fill the widget, this settings will show random models to fill the rest of the space. You will still earn affiliate commission from all sales generated by the widget. If no option is selected this is the default setting.',
    select_gender: 'Select gender',
    widget_documentation: 'Widget documentation',
    widget_docu_text: 'You can customize the widget yourself using data attributes on the div elements in your html page that you want to use as a cam widget.<br>The following data attributes are allowed on div elements.',
    attribute_name: 'Attribute name',
    values: 'Values',
    width_in_pixels: 'The width in pixels',
    height_in_pixels: 'The height in pixels',
    thumb_sizes: 'Thumbnail sizes',
    data_platform_desc: 'Name of camsites to show. Comma seperator and capital first letter of name',
    leave_empty_auto: 'Leave empty for auto',
    data_gender_desc: 'Which gender to prioritize',
    data_theme_desc: 'Which theme to use',
    more_coming_soon: 'More coming soon',
    source: 'Source',
    maxviewers_unavailable: 'Max viewers is unavailable for this camsite',
    maxviewers_unavailable_for: 'Max viewers is unavailable for:',
    tokens_added_to_account: 'Tokens have been added to your account',
    tokens_will_be_added_to_account: 'Tokens will be added to your account in few minutes',
    all_camsites: 'All camsites',
    ondemand_aff_commission: 'On demand affiliate commission',
    promo_tool_aff_commission: 'Promo tools affiliate commission',
    native_cam_widgets: 'Native Cam Widgets',
    wl_activated: 'Whitelabel activated',
    enter_name_wl: 'Enter name of whitelabel',
    enter_domain_wl: 'Enter domain of whitelabel',
    wl_title: 'Whitelabel title',
    wl_domain: 'Whitelabel domain',
    send_to_whitelabel: 'Send :platform traffic to whitelabel',
    deleting: 'Deleting',
    delete_failed: 'Deletion failed',
    save_wl_failed: 'Saving whitelabel failed',
    create_wl_failed: 'Creating whitelabel failed',
    traffic_last_14_days: 'Traffic last 14 days',
    feature_require_pro_subscription: 'This feature requires a paid subscription',
    info_about_aff_key: 'If you enter your affiliate key for :platform in the field to the left, then the traffic you purchase will be sent using your own affiliate link. Keep in mind that this means that you will get affiliate commission via :platform and not in DivaTraffic and you will not be able to see any statistics about sales, registrations or rebills on your DivaTraffic statistics page <br> <a target="_blank" href="https://divatraffic.zendesk.com/hc/en-us/sections/360001373174-How-to-configure-affiliate-keys">Click here to read more about how to find your affiliate key</a>',
    max_viewers_info: 'The Max Viewers setting lets you specify what the max amount of viewers you want to have in the room when you are buying traffic on demand. Traffic will automatically stop being sent to the room when the viewer count reaches the max you have specified, and will resume when you have less than this',
    whitelabel_info_text: 'When enabling whitelabel setting for :platform, all traffic you buy on demand will be sent to your whitelabel instead of :platform. Keep in mind that this means that you will not be able to get any earnings or statistics about registrations, sales or rebills in DivaTraffic, and you will be paid affiliate commission directly from :platform',
    email_address: 'E-mail address',
    register_here: 'Register here',
    affiliate_signup: 'Affiliate signup',
    back_to_login: 'Back to login',
    no_subscription_text: 'Without a subscription, you will only have access to our',
    profiles_indexed: 'Profiles indexed on diva.network',
    promotools_commission: 'Promo tools affiliate commission',
    whitelabel_aff_commission: 'Whitelabel affiliate commission',
    studio_featured_in_aff_program: 'Studio featured in affiliate program',
    profiles_shown_in_tools: 'Your profiles will be shown in our affiliate tools',
    camwidgets: 'Cam Widgets',
    profiles_featured: 'Profiles rotated in our affiliate program',
    admin_camsites: 'Admin Camsites',
    available: 'Available',
    add_camsite: 'Add camsite',
    room_url: 'Room URL',
    embed_url: 'Embed URL',
    embed_code: 'Embed code',
    api_url: 'API URL',
    api_listfinder: 'API list finder',
    api_valuefinder: 'API value finder',
    api_transformers: 'API transformers',
    program_url: 'Program URL',
    invite_link: 'Invite link',
    invite_link_desc: 'Use this link to invite users to register at DivaTraffic and earn a commission based on their spending',
    invite_link_description: 'Do you have any friends that you think could benefit from using DivaTraffic? Send them the invite link below, and earn a commission for each person you invite to DivaTraffic. You will earn 10% commission on each person you invite who purchases a subscription on DivaTraffic. Your earnings will be paid after each subscription is renewed or expires, and you will get your earnings paid in tokens which will be added to your account balance.',
    invited_users: 'Your invited users',
    link_to: 'Link to',
    frontpage: 'Frontpage',
    register_page: 'Register page',
    model_register: 'Model register page',
    studio_register: 'Studio register page',
    affiliate_register: 'Affiliate register page',
    invite_redirect: 'Select where you want the invite link to redirect to',
    select_camsite_program: 'Go to Camsite Settings to configure what program to use for each camsite.',
    delete_platform_text: 'Are you sure you want to delete :title?',
    mobile_url: 'Mobile URL',
    resources: 'Resources',
    read_more: 'Read more',
    ynot_awards: 'The YNOT Cam Awards highlights the women and men who perform in the adult interactive community.',
    bucharest_awards: 'The Award Galla takes place at the Bucharest Summit each year and recognizes the top studios, models, camming sites and professionals.',
    lovense: 'Lovense is a sex toy manufacturer known for its smart sex toys, which can be controlled via Bluetooth using a mobile app.',
    community: 'Community',
    toys_equipment: 'Toys & Equipment',
    events: 'Events',
    awards: 'Awards',
    software: 'Software',
    content: 'Content',
    stripperweb: 'Forum for strippers and other members of the adult industry. Has a section for camming related discussion.',
    ynot_network: 'Complete social network with a news feed, forum, messaging, photos, videos, classifieds and more.',
    xbiz: 'B2B forum for the adult industry. You’ll find site owners, content producers, technology solution providers, affiliates, models and more on XBIZ.',
    wecamgirls: 'WeCamgirls is a community where cammodels can talk to their adult business colleagues. A safe place to interact, discuss and connect, without customers peeking in. You can also exchange tips and tricks or review all kinds of different cam related businesses.',
    ambercutie: 'AmberCutie is a professional camgirl that runs a forum on her site. It’s a great place to connect with other camgirls, ask for advice and share reviews, thoughts and strategies.',
    ohmibod: 'OhMiBod is the creator of the original iPod® vibrator. Headquartered in New Hampshire, USA, the female-owned and operated company is driving the evolution of the pleasure industry with an expansive range of high quality, body-safe products that combine technology with sensuality.',
    kiiroo: 'Kiiroo is a high-tech designer sex toy company that gives you the ability to feel someone else in a whole new way.',
    terpon: 'Terpon is a camera and digital interface manufacturing company that specializes Virtual Reality and fully immersive technology for the adult entertainment industry.',
    aw_summit: 'AWSummit is the event where you can learn and network with the best and the brightest in our industry. AWSummit is no ordinary show - it is the ultimate business & conference experience!',
    lalexpo: 'The Definitive B2B Adult show of Central and South America. The only live cam award show in South America. Meet a totally new market and be part of the only business show in the adult industry for professionals that also have more than 600 models and webcams studios around the world.',
    bucharest_summit: 'Bucharest Summit is naturally taking place in Bucharest because the Romanian capital is an industry hotspot, priding itself with top business, talented models and innovative know-how. Experienced speakers will take you on a journey, unveiling all the secrets and sharing their expertise in a diverse range of topics.',
    live_cam_awards: 'Live Cam Awards is an annual award show that was launched for the first time in 2015, being at that time the first award show to exclusively honor the performers and companies who serve the now highly-competitive webcam industry.',
    webcam_startup: 'Webcam Startup is the largest model resource site on the internet. Webcam Startup brings in a wealth of knowledge from a wide range of contributors and staff, each with their own experience and respective knowledge. Plus, all the industry news and announcements.',
    aw_news: 'AW News is the largest live cam industry news source. It’s your provider of the freshest news in the live cam biz.',
    manycam: 'ManyCam is a free webcam software and video switcher that allows you to enhance your video chats and create amazing live streams on multiple platforms at the same time.',
    loomly: 'Loomly is a simple social media calendar tool.',
    hootsuite: 'Hootsuite is a social media management platform. The system’s user interface takes the form of a dashboard and supports social network integrations for Twitter, Facebook, Instagram, LinkedIn, Google+ and YouTube.',
    modelcentro: 'ModelCentro is a platform that helps you monetize and grow your existing fan base into monthly recurring revenues. It’s 100% FREE to set up a Personal Paid Fan Site.',
    manyvids: 'ManyVids.com is a platform designed specifically to help MV Stars generate a healthy passive income. MV is a “one-stop shop”, where your fans can find everything you have to offer.',
    clips4sale: 'Clips4Sale (C4S) is an American adult video content selling website. It is known for fetish content and was the first downloadable clip site of its kind',
    pineapple_support: 'Pineapple Support provides a 24/7 emotional support system for all adult industry performers & producers.',
    ynot_cam: 'YNOT Cam is an online magazine that offers tips and advice to camgirls.',
    infinity_telecom: 'Infinity Telecom provides internet and webcam equipment for studios in Romania',
    bcams: 'BCams bring together websites, cam studios, independent models and a whole range of connected businesses from IT and financial companies to photographs, make-up artists, interior designers and marketing professionals. BCAMS It’s a great opportunity for networking in a friendly and neutral environment.',
    aw_awards: 'The AW Awards and Conference is one of the world’s largest camming award show and event. The AW Awards recognizes the best in the camming industry, including models, studios and businesses.',
    onelink: 'If you had one shot, one opportunity, OneLink, Would you use it or just let it slip?<br/>Never change your bio link again. OneLink makes you link to all your online stuff on one simple page.',
    resources_welcome: 'Welcome',
    resource_welcome_text: 'In order to have success in the live cam industry as a studio or a broadcaster, it is important to use the right tools and services that lets you work more effectively. On these pages we have gathered a collection of recommended services that will help you achieve your goals more effectively, and increase your chances of becoming successful with your business. The services are offered in a range of categories that includes communities, news outlets and events where you can meet and learn from other professionals in the industry, to recommended tools and services that lets you increase your earnings by making money while you are offline, or improve your reach on social media, as well as where to find the right products and equipment you need in order to become a professional performer or studio.<br/>If you have a product or service that you would like us to recommend, please contact us at <a style="font-size: 18px" href="mailto:support@divatraffic.com">support@divatraffic.com</a> and let us know about it.',
    max_daterange: 'Max date range is 31 days. Please select a different range',
    europeansummit: 'Founded in 2009, The European Summit brings together professionals from the online entertainment industry, e-commerce, financial, gaming, gambling, dating, forex, binary options, health, nutraceuticals and many others for an intensive 3-day networking event.',
    reset_email_sent: 'An email with a password reset link has been sent to your address. If you do not receive your email within a few minutes be sure to check your spam folder',
    reset_token: 'Reset token',
    token: 'Token',
    reset_success: 'Your password has been successfully reset',
    loading_packages: 'Loading packages',
    email_not_verified: 'Your email has not been verified',
    click_to_verify: 'Click here to verify your account',
    email_verification_required: 'Email verification required',
    verification_text: 'A verification email has been sent to <span style="color: #4D96D1">:email</span>. Please check your inbox for an email with subject "DivaTraffic email verification". If you can not find the email, please make sure to check your email spam or junk folder. If you did not receive the email, you can re-send the verification email from here.',
    resend_email: 'Re-send email',
    wrong_email: 'If you entered the wrong email address, <a href="settings/account" style="font-weight: 600">click here</a> to update your email address',
    bidinfo: 'Bid info',
    bidinfo_text: 'The bid is the amount you pay for 1000 visitors matching the targeting filters you have specified above. Traffic is bought in real time and works like an auction where the advertiser with the highest bid for each user wins. A very low bid makes the traffic cheaper, but might result in you not winning many bids, and you might not receive much traffic. A higher bid increases the likelyhood of you winning, but also makes the traffic more expensive.',
    price_pr_1000: 'Price per 1000 users',
    estimated_traffic_pr_token: 'Estimated traffic per token',
    verified: 'Verified',
    not_verified: 'Not verified',
    email_status: 'Email status',
    extralunchmoney: 'ExtraLunchMoney.com (ELM) is a digital marketplace for buyers to directly interact with adult content creators. Although the focus of the site is amateur porn, custom fetish clips, and amateur adult content, we welcome a wide range of adult content including audio and written work from amateurs and professionals alike. Real amateur porn and custom adult content direct from the performers themselves.',
    invite_friend: 'Invite a friend',
    total_so_far: 'Total so far',
    select_affiliate_program: 'Here you can select which affiliate program you want to send traffic to.',
    immediately: 'Immediately',
    auto_renew_order: 'Auto-renew order when it is finished',
    renewal_time: 'Set the time between renewals',
    number_of_renewals: 'Total Renewals',
    autorenew_description: 'Notice: By checking the auto-renew option, your order will restart automatically after it has finished, and the amount of tokens your order costs, will be charged from your token balance. Be aware that if you select a package that delivers traffic fast and chose a short time interval, you might end up spending your tokens very quickly. The order will <span style="font-weight: 600">NOT</span> be renewed if you run out of tokens or if the order is paused or cancelled.',
    renews_minutes: '{1} Renews in 1 minute | {*} Renews in :count minutes',
    renews_hours: 'Renews in :count hours',
    notification_option: 'Notification options',
    configure_email_notifications: 'Configure when you will receive email notifications',
    balance_low: 'My balance is getting low',
    auto_renew_insufficient_funds: 'When I have insufficient funds for auto renewal',
    subscription_expires: 'When my subscription expires',
    order_renewed: 'When an order is renewed',
    earnings_payout: 'When I get earnings payout',
    updates_to_divatraffic: 'When there are new updates or changes to DivaTraffic',
    renews_any_moment: 'Renews any moment',
    initializing_order: 'Initializing order, traffic will be sent shortly.. please wait...',
    pop_ads: 'Pop ads are delivered as popups or popunders from thousands of different adult websites, via all the different ad exchanges and traffic networks that DivaTraffic gets its traffic from. Pop ads are good for getting a lot of traffic fast, since the users aren’t making a choice to go to the cam room, but is redirected there by just visiting a website.',
    native_ads: 'Native ads are delivered via clicked image ads that are shown on thousands of different adult websites via different ad exchanges and traffic networks that DivaTraffic gets its traffic from.',
    native_ad_traffic_selected: 'Native ad traffic selected',
    premium_plan: 'Premium plan',
    deluxe_plan: 'Pro plan',
    free_plan: 'Free plan',
    basic_plan: 'Basic plan',
    edituser: 'Edit user',
    admin_users: 'Admin users',
    package_youtube: 'Click here to watch a YouTube tutorial on how to create packages',
    watch_tutorial: 'Watch tutorial',
    download: 'Download',
    show_in_ads: 'Show in ads',
    show_in_ads_details: 'Profiles will be promoted in affiliate ads and on diva.network',
    number_of_profiles: ':count of :total profiles selected',
    create_network: 'Create network',
    estimated_ad_views: 'Estimated ad views',
    total_ad_views: 'Total ad views',
    diva_network: 'diva.network',
    profiles_in_ads: 'Profiles shown in subscription ads',
    restart_order: 'Restart order',
    restart_moment: 'The order will restart in a moment',
    traffic_reports: 'Check out our traffic reports to help select targeting',
    daily_volume: 'Daily volume',
    won: 'Won',
    score: 'Score',
    banktransfer: 'Bank transfer',
    banktransfer_success: 'Payment has been stored. We are waiting for Bank Transfer from you',
    total_to_pay: 'Transaction amount',
    studio_performer: 'Studio performer',
    fee: 'Fee',
    gift: 'Gift',
    sub_amount: 'Amount',
    sub_amount_disclaimer: 'Amount (positive or negative) will be used for internal transaction. 0 means, that no transaction will be created. Empty amount means, that positive transaction will be created with amount equals to plan amount.',
    traffic_type_pop: 'Pop',
    traffic_type_native: 'Native',
    traffic_type_push: 'Push',
    push_traffic_description: 'Native ad traffic is delivered via clicked image ads that are shown on thousands of different adult websites via different ad exchanges and traffic networks that DivaTraffic gets its traffic from. When you buy native ad traffic, you are paying for ad displays (impressions) and the users have to click your image before being sent to your room.<br>The ad that the users will see will either be a live screenshot of your room, or the profile pitcure you use on the camsite, depending on which camsite you are buying traffic to.<br>Unlike pop traffic, display traffic requires the users to make a click before entering your room, so it will not be as useful in terms of getting a lot of traffic fast, but it means that the users you do get, will have made a choice to visit you, based on the image they see, and it should result in higher quality of traffic.<br>We can not guarantee how many will click on your image, only how many times it will be displayed.',
    push_traffic_selected: 'Push traffic selected',
    push_ads: '',
    push_traffic: 'Push Traffic',
    native_traffic: 'Native Traffic',
    set_password:"Change Password",
    show_password: "Set Password",
    password_confirmation: 'Confirm Password',
    user_deleted: 'User deleted, refresh page to view updated list',
    user_deleted_failed: 'Unable to delete',
    user_deleted_warning: 'Are you sure you want to delete user?',
    user_deleted_confirm_text: 'Delete User',
    user_deleted_cancel_text: 'Cancel',
    payment_instructions: 'Payment instructions',
    payment_instructions_detailed: 'Please pay the Total amount to the account shown below. When the money has been received in the bank account, your transaction will be approved, and tokens will be added to your account. Bank transfers can take 1-3 business days to be approved',
    i_have_paid: 'I have paid',
    account_information: 'Account information',
    event_date: 'Date',
    hide_columns: 'Hide columns',
    validation_error: 'Validation Error',
    studio_orders: 'Studio orders',
    model_orders: 'Model orders',
    nothing_found: 'Nothing found',
    page_not_found: 'Page not found',

    // Sorting
    sorting: 'Sorting',
    name_az: 'Name A-Z',
    name_za: 'Name Z-A',
    viewers_per_token_09: 'Viewers per token 0-9',
    viewers_per_token_90: 'Viewers per token 9-0',
    newest_first: 'Newest first',
    oldest_first: 'Oldest first',
    online_offline: 'Online/Offline',
    online_only: 'Online only',
    include_deactivated: 'Include deactivated',


    // Content
    load_more: 'Load More',
    loading: 'Loading',
    choose_file: 'Choose file',

    // Validation
    minimum_symbols_4: 'Minimum 4 symbols'
};
