const state = {
  deviceBrands: null
};

const mutations = {
  SET_DEVICE_BRANDS: (state, payload) => {
    state.deviceBrands = payload;
  }
};

const actions = {
  get: ({ dispatch }) => {
    return dispatch("Dictionary/get", null, {root: true})
  },
};

const getters = {
  getDeviceBrands(state) {
    return state.deviceBrands;
  },
  getDeviceBrandById: state => id => {
    return state.deviceBrands.find(b => {
      return id == b.id;
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
