<template>
  <div
    class="fullscreen-loader__wrapper"
    :class="[
      fullScreen ? 'position-fixed' : '',
    ]"
  >
    <ContentLoader></ContentLoader>
  </div>
</template>
<script>

import ContentLoader from "@/components/Loaders/ContentLoader";

export default {
  name: 'content-loader',
  props: {
    fullScreen: {
      type: Boolean,
      default: false,
      description: 'Loader should take whole screen or only current content part',
    },
  },
  components: {
    ContentLoader
  }
};
</script>
