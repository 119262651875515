import PerformerService from "../../service/performer/performer.service";
import AbstractSingleStore from "@/store/AbstractSingleStore";

const performerService = new PerformerService();

const performerStore = {
  namespaced: true,
  state: {
    baseStateListName: 'performers',
    performers: {},
  },

  actions: {
    search: ({ state, commit }, payload) => { //{search: string, platform_id: int}
      commit('setListLoading', true);
      performerService.search(payload).then((response) => {
        commit('setData', {name: state.baseStateListName, data: response.data, loading: false});
      });
    }
  }
};


export default AbstractSingleStore.mergeInto(performerStore);
