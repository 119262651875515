import AbstractSingleStore from "@/store/AbstractSingleStore";
import WhitelabelService from "../../service/user/user.service";

const whitelabelService = new WhitelabelService();


const storeModule = {
  namespaced: true,
  state: {
    baseStateListName: 'whitelabels',
    whitelabels: {
      data: [],
      loading: false,
    },
  },
  actions: {
    save: ({commit}, payload) => {
      commit('setLoading');
      if (payload.id) {
        // if it exists save, else create
        return whitelabelService.save(payload).then(data => {
          commit("setData", data);
        }).catch(error => {
          commit('setValidationErrors', error);
          return Promise.reject(error);
        });
      } else {
        return whitelabelService.create(payload).then(data => {
          commit("setData", data);
        }).catch(error => {
          commit('setValidationErrors', error);
          return Promise.reject(error);
        });
      }
      
    },
    
    getList: ({commit}) => {
      commit('setListLoading');
      return whitelabelService.getAll().then(data => {
        commit("setList", data);
      });
    },
  },
};

export default AbstractSingleStore.mergeInto(storeModule);
