import { createWebHistory, createRouter } from "vue-router";
import routes from "./routes";
import store from '../store'

// configure router
const router = new createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
  mode: "history",
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (store.getters["User/isAuthenticated"]) {
    next();
  } else if (!store.getters["User/triedLogin"]) {
    store.dispatch('User/get')
        .then(() => {
          next();
        })
        .catch(() => {
          next();
        });
  } else {
    next();
  }
});

export default router;
