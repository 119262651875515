module.exports = {
    slogan: 'Take charge of your destiny!',
    subslogan_1: 'DivaTraffic provides you with the most',
    subslogan_2: 'innovative marketing tools for livecam models.',
    video: 'WATCH PROMO VIDEO',

    // Info below Hero section
    comingsoon: 'Coming soon!',
    comingsooninfo: 'Sign up and we\'ll notify you when the new webcam features are ready.',
    readmorebelow: 'or read more below',

    // Button texts
    getnotified: 'Get notified!',

    // Services section
    services: 'Our Services',
    servicesinfo: 'Get started with DivaTraffic and increase traffic to your camshow, it\'s super easy!',

    // Boost my cam section
    boostmycam: 'Boost my cam',
    boostmycaminfo: 'FINALLY, getting a boost of traffic to your camshow is just a click away. DivaTraffic is empowering models and studios to easily drive lots of people into your camshow.<br><br>Raise your Traffic, Raise your Earnings!',

    divalinks: 'DivaLinks',
    divalinksinfo: 'DivaLinks is a tool that will help you grow your fanbase and enable you to better build yourself as a brand. Share your content on social media using Diva shortened links and it will also include an advertisement for your camshow when you go online.<br><br>What are you waiting for? Take charge of your destiny!',

    camhead: 'CamHead',
    camheadinfo: 'CamHead is a new and unique way of advertising your camshow. It detects when you are online and available, and makes sure that your ad is visible for the correct audience.<br><br>Empower yourself and bring the world to you!',

    // How it works section
    howitworks: 'How It Works',
    howitworksinfo: 'Getting started with DivaTraffic to increase web traffic to your camshow is supereasy!',
    step: 'Step',

    // -->> Sign up or in
    signuporin: 'Sign up or sign in',
    signuporininfo: 'Access the DivaTraffic dashboard by signing in or up.',

    // -->> Boost my cam or share DivaLinks
    boostorshare: 'Boost my cam or share DivaLinks',
    boostorshareinfo: 'Whether you want to buy traffic or get it for free, DivaTraffic is the place to be!<br /> Using instant boosts or sharing links will increase traffic to your camshow. ',
    bosboostmycam: 'Boost my cam',
    bosboostmycaminfo: 'Instantly boost your camshow with visitors. You can choose from multiple options to customize what kind of traffic you would like to get.<br><br>Just choose your model profile, the package you desire and the size of the boost.<br><br><b>Enjoy the massive increase of visitors in your webcam room!</b>',
    sharedivalinks: 'Share DivaLinks',
    sharedivalinksinfo: 'Shorten links and share them! DivaLinks includes your own personal ads to the links you share. Equip the links with a banner or even a CamHead!<br><br>The CamHead is an ad that looks like a live preview of your camroom!<br><br><b>Increase your visitor rate for FREE by sharing links!</b>',

    // Enjoy more visitors
    enjoymorevisitors: 'Enjoy more visitors',
    enjoymorevisitorsinfo: 'Now that you are finished with Boost My Cam or DivaLinks, watch as your camroom fills up with more visitors than ever before!<br />Whether it’s instant or long-term traffic needs, DivaTraffic is the solution for you!',

    sign_up_beta: 'Sign up',
    beta_info: 'Register now to start using our features!',
    get_started: 'Get started!',
    are_you_ready: 'Are you ready?',
    register: 'Register now to start using our features!',

    restore_user: "Restore account",
    restore_user_text: "The account has been disabled, do you want to restore it?",
    restore_user_notice: "Press 'restore' to start the process to restoring your account.",
    restore_btn: "Restore",
    restore_cancel_btn: "Cancel", 
    
    restore_response_success: "Success",
    restore_response_success_text: "An email has been sent, please check your email for instructions on how to restore your account.",
    restore_response_error: "Error",
    restore_response_error_text: "An error occured while trying to restore your account",

    restore_token: "Restoring account",
    restore_token_success: "Success",
    restore_token_success_text: "Account successfully restored",
    restore_token_error: "Error",
    restore_token_error_text: "Error occured while trying to restore account",
}