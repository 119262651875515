module.exports = {
    traffic: 'Tráfico',
    filter_on_this: 'Filtrar',
    earnings_sum: 'Ganancias Totales',
    add_filter: 'Añadir filtro',
    show_columns: 'columnas',
    roi: 'ROI',
    e_cpm: 'eCPM',
    clipsite: 'Sitio de clips',
    public: 'Público',
    all_devices: 'Todos los dispositivos',
    affiliate_your_own_whitelabel: 'Usa tu propia marca blanca',
    select_program: 'Seleccionar programa',
    current_password: 'Contraseña actual',
    payment_cycle: 'Ciclo de pago',
    account_type: 'Tipo de cuenta',
    next_payment: 'Siguiente pago',
    choose_subscription: "Elige la suscripción que se adapte a tus necesidades",
    free_account_option_text: "Si lo que prefieres es no suscribirte, ¡no te preocupes! Todavía tendrá algunas características de las que se beneficiará",

    order: 'Orden',
    information: 'Información',
    last_updated: 'Última actualización',
    created: 'Creado',
    item_created: 'Creado',
    start: 'Iniciar',
    stop: 'Parar',
    pause: 'Pausar',
    activate: 'Activar',
    renew: 'Renovar',
    renew_order: 'Renovar Orden',
    male: 'Hombre',
    female: 'Mujer',
    couple: 'Pareja',
    online: 'Online',
    offline: 'Offline',
    manually: 'A mano',
    show_all: 'Mostrar todos',
    show_active_platforms: 'Mostrar activas',
    referrals: 'Referidos',
    notifications: 'Notificaciones',
    birthday: 'Fecha de nacimiento',
    tokens: 'Tokens',
    total_referrals: 'Total de referidos',
    earnings: 'Ingresos',
    total_earnings: 'Total de ingresos',
    has_viewercount: 'Conteo de visualizaciones',
    new: 'Nuevo',
    account: 'Cuenta',
    plans: 'Planes',
    supported_camsites: 'Camsites soportados',
    camsites_description: 'A continuación encontrarás una lista de los camsites soportados y su estado. Trabajamos continuamente para agregar más sitios, siempre y cuando se nos solicite. Si no encuentras el camsite desde donde estás transmitiendo, envíanos un ticket de soporte y veremos si podemos integrarlo. También anímamos a los dueños de los camsites a que nos contacten. ',
    camsites_modelpitch: '¿Aún no eres modelo? No te preocupes. Puedes registrarte en cualquiera de las siguientes plataformas. Sólo pincha en el botón "Conviértete en modelo" del sitio al que quieras unirte.',
    welcome_to: 'Bienvenido a DivaTraffic',
    welcome_to_subtitle: 'Te ayudaremos a conseguir el tráfico que necesitas.',
    welcome_to_info: 'DivaTraffic te ofrece un montón de herramientas para aumentar el tráfico a tu webcam siempre que estás online. Todo lo que necesitas hacer es<br>agregar tu perfil de webcam a tu cuenta de DivaTraffic y estarás lista para empezar.',
    welcome_to_final: 'Puedes probarlo <span class="color-pink">sin costo alguno.</span>',
    welcome_to_button: 'Comienza ahora. Agrega un perfil',
    welcome_to_studios_subtitle: 'Te ayudamos a que tus modelos consigan el tráfico que necesitan',
    welcome_to_studios_info: 'DivaTraffic te ofrece un montón de herramientas para aumentar el tráfico de webcam de tus modelos, siempre que estén online. Todo lo que necesitas hacer es<br>agregar los perfiles de webcam de tus modelos a tu cuenta de DivaTraffic y estaremos listo para empezar.',
    welcome_to_studios_final: 'Puedes probarlo <span class="color-pink">sin costo alguno</span>',
    welcome_to_studios_button: 'Comienza ahora. Agrega un perfil de modelo',
    welcome_beta: '¡Bienvenido(a), :name! DivaTraffic está en modo beta y en ocasiones puede ser inestable. Por favor, reporta cualquier problema que tengas o haz sugerencias que nos ayuden a mejorar nuestros servicios. Nos gustaría escuchar de ti.',
    helpcenter: 'para ir a nuestro centro de ayuda.',
    click_here: 'Pinchar aquí',
    dashboard: 'Panel',
    welcome: 'Bienvenido (a), :name',
    plugrush_status_title: 'Estado de campaña PlugRush',
    plan: 'Plan',
    received: 'Recibido',
    progress: 'Progreso',
    ordered: 'Ordenado',
    visitors: 'Visitantes',
    this_hour: 'Esta hora',
    hour_limit: 'Límite de hora',
    countries: 'Países',
    status: 'Estado',
    action: 'Acción',
    numb_links: 'Número total de vínculos',
    numb_users: 'Número total de usuarios',
    numb_performers: 'Número total de modelos',
    numb_adzones: 'Número total de adzones',
    numb_actions: 'Número total de acciones',
    numb_visits: 'Número total de visitas',
    numb_studios: 'Número total de estudios',
    numb_surveys: 'Número total de encuestas',
    numb_platforms: 'Número total de plataformas',
    numb_codes: 'Número total de códigos canjeados',
    numb_transactions: 'Número total de transacciones',
    pending_transactions: 'Número  de transacciones pendientes',
    summary: 'Resumen',
    total_tokens: 'Tokens totales',
    total_traffic: 'Tráfico total',
    total_links: 'Vínculos totales',
    total_orders: 'Órdener totales',
    numb_logins: 'Número total de intentos de inicio de sesión',
    manage_tokens: 'Administrar tokens',
    manage_orders: 'Administrar órdenes',
    boost_your_cam: 'Boost your cam',
    manage_links: 'Administrar vínculos',
    manage_performer: 'Administrar modelo',
    manage_performers: 'Administrar modelos',
    tokens_earned: 'Tokens obtenidos',
    tokens_remaining: 'Tokens restantes',
    unique_models: 'Modelos únicas',
    visitors_received: 'Visitantes recibidos',
    shortened_links: 'Vínculos acortados',
    shorten_url: 'Acortar URL',
    news: 'Noticias',
    new_features: 'Nuevas características',
    boost_my_cam: 'Boostear mi cámara',
    boost_my_link: 'Boostear mi enlace',
    boost_performers: 'Boostear modelos ',
    boost_performers_text: 'Entrega boosts a tus modelos la próxima vez que estén online',
    boost_cam_text: 'Consigue más personas para tu siguiente show. Pincha el siguiente botón y promueve tu próxima presentación',
    earn_tokens: 'Ganar tokens',
    earn_tokens_text: 'Comparte vínculos con tus fans y seguidores y gana tokens por cada clic.',
    redeem_code: 'Canjear códigos',
    redeem_code_text: 'Ingresa tu código a continuación y obtén tus tokens gratuitos.',
    redeem: 'Canjear',
    earn_free_tokens: 'Canjear tokens gratuitos',
    get_started: 'Comenzar',
    description: 'Descripción',
    how_to_get_started: 'Como comenzar',
    how_to_get_started_text: 'Usa tokens para comprar tokens para los shows en vivo de tus modelos',
    connected_profiles: 'Perfiles conectados',
    manage_profiles: 'Administrar perfiles',
    api_settings: 'Opciones de API',
    plan_intro: 'Planes de tráficos de Divatraffic',
    affiliate_stats: 'Estadísticas de afiliado',
    affiliate_link_desc: 'Usa el siguiente vínculo para referir usuarios a nuestra platanforma y ganar dinero',
    affiliate_none_referred: 'Aún no hay usuarios referidos.',
    affiliate_header: 'Aquí puedes ver a quién has referido y cómo referir más usuarios.',
    sign_in: 'Inicar sesión',
    log_out: 'Cerrar sesión',
    settings: 'Ajustes',
    get_free_tokens: 'Obtener tokens gratis',
    get_more_tokens: 'Obtén más tokens',
    boostmycam_tooltip: 'Consigue más tráfico en tu sala la próxima vez que estés online',
    sharelinks_tooltip: 'Gana tokens compartiendo vínculos con tus fans y seguidores',
    share_links: 'Compartir vínculos',
    buy_tokens: 'Comprar tokens',
    traffic_tooltip: 'Obtén más tráfico. Comparte vínculos.',
    orders: 'Órdenes',
    studio_models: 'Modelos de estudio',
    account_settings: 'Ajustes de cuenta',
    studio_settings: 'Ajustes de estudio',
    studio_control_enter: 'Ahora estás en modo de control de usuario.',
    studio_not_found: 'No se puede encontrar tu estudio.',
    studio_no_access: 'Tu estudio no tiene acceso a ese usuario.',
    links: 'Vínculos',
    divalinks: 'DivaLinks',
    adzones: 'Adzones',
    stats: 'Estadísticas',
    platform_settings: 'Ajustes de plataforma',
    models: 'Modelos',
    custom_plan: 'Plan personalizado',
    manage_models: 'Administrar modelos',
    billing_information: 'Información de pago',
    bank_name: 'Nombre',
    bank_address1: 'Dirección',
    bank_zip: 'Código postal',
    bank_city: 'Ciudad',
    bank_country: 'País',
    bank_vat: 'VAT',
    deleted: 'Expulsado/Eliminado',
    publisher: 'Publicador',
    boostcam_header_text: 'Promueve tu sala con tráfico (visitantes) la próxima vez que estés online.',
    boost_packages: 'Paquetes de aumento de tráfico',
    out_of_tokens: '¿Te has quedado sin tokens?',
    out_of_tokens_text: 'Ve a las páginas de tokens y consigue más',
    get_tokens: 'Consigue tokens',
    get_free_tokens_text: 'Completa tareas y obtén tokens gratis como recompensa, que luego podrás usar para comprar paquetes de aumento de tráfico',
    not_enough_tokens: 'No tienes suficientes tokens para esto.',
    boost_cam_short: 'Boostear',
    order_settings: 'Configuración de pedidos',
    order_cost: 'Costo de la orden',
    total_cost: 'Coste total',
    order_not_found: 'Orden no encontrada',
    order_invalid_status: 'Sólo se pueden actualizar los órdenes en pausa, pendientes y completados',
    autorenew: 'Autorenovar',
    renew_forever: 'Renovar para siempre',
    renewals_left: 'Quedan renovaciones',
    renewals: 'Renovaciones',
    autorenew_sub: 'Renovar automáticamente cuando se agote el presupuesto',
    autorenew_info: 'Leer más acerca de la autorenovación de órdenes',
    renew_forever_text: 'Al marcar esta opción, su pedido se renovará automáticamente siempre que tenga tokens en su cuenta.',
    order_total: 'Total de la orden',
    accept: 'Aceptar',

    my_links: 'Mis vínculos',
    manage_shortened_links: 'Administrar tus vínculos abreviados',
    create_new: 'Crear nuevo',
    profile: 'Perfil',
    link: 'Vínculo',
    impressions: 'Impresiones',
    clicks: 'Clics',
    link_clicks: 'Clics a vínculos',
    ad_clicks: 'Clics a anuncios',
    sales: 'Ventas',
    conv_rate: 'Tasa de conversión',
    actions: 'Acciones',
    total_views: 'Total de visualizaciones',
    total_clicks: 'Total de clics',
    create_link: 'Crear un vínculo',
    create_link_text: 'Ingresa el vínculo que quieras abreviar',
    advanced_settings: 'Ajustes avanzados',
    show_ad: 'Mostrar anuncios en vínculos abreviados',
    ad_to_show: 'Anuncio para mostrar',
    message: 'Mensaje',
    ad_placeholder: '¡Hola! Estoy en vivo ahora mismo. ¡Acompáñame!',
    promote_cam: 'Promover mi sala',
    promote_diva: 'Promover mi página de destino',
    when_online: 'Cuando estoy online',
    when_offline: 'Cuando estoy offline',
    go: '¡Vamos!',
    link_created: 'Vínculo creado',
    click_link_info: 'Todos los que pinchen sobre este vínculo verán la página con tu anuncio.',
    copy_link: 'Copiar vínnculo',
    link_copied: 'Enlace copiado',
    copy_link_details: 'Copiar URL al portapapeles',
    cant_copy: 'No se puede copiar',
    save_changes: 'Guardar cambios',
    share_on_networks: 'Compartir el vínculo con estas redes',
    come_visit: '¡Hola! Ven y acompáñame con mi webcam en vivo ahora mismo',
    just_click: 'Sólo pincha aquí',
    tokens_header_text: 'Usa tokens para conseguir tráfico (visitantes) para tu cámara.',
    tokens_history: 'Historial de Tokens',
    activity: 'Actividad',
    no_transactions: 'Sin transacciones',
    overall: 'Total',
    but_tokens_text: '¿Necesitas más tokens? ¡No hay problema! VE a la página de órdenes y compra más tokens.',
    details: 'Detalles',
    my_orders: 'Mis órdenes',
    my_orders_header_text: 'Resumen de tus órdenes',
    order_status_0: 'Pausado',
    order_status_1: 'Activo',
    order_status_2: 'Cancelado',
    order_status_3: 'Pendiente',
    order_status_4: 'Completado',
    queued: 'En cola',
    queuetooltip: 'Eres el número :pos en la cola para :plan.',
    refunded: 'Reembolsado',
    archive: 'Archivo',
    total_visitors: 'Total de visitantes',
    price: 'Precio',
    delivered_visitors: 'Visitantes recibidos',
    balance: 'Balance',
    start_date: 'Fecha de inicio',
    no_active_orders: 'No hay órdenes activas',
    viewers: 'Espectadores',
    live_stats_enabled: 'Esta órden está activa. Las estadísticas en vivo están activadas.',
    stats_not_active: 'Está orden está pausado o ha sido completada.',
    order_queued: 'Esta orden está en cola. Está en la posición #:position en la cola.',
    order_queued_desc: 'Una o mas órdenes están en cola debido a la alta demanda.',
    orders_plans: 'Ordenes del plan de tráfico',
    show_paused: 'Órdenes pausadas',
    show_active: 'Órdenes activas',
    show_completed: 'Órdenes completadas',
    model: 'Modelo',
    order_status: 'Estado de la orden',
    performer: 'Modelo',
    platform: 'Plataforma',
    destination: 'Destino ',
    back_to_orders: 'Volver a Orden',
    studio: 'Estudio',
    performers: 'Modelos',
    shortcuts: 'Atajos',
    administrate: 'Administrar',
    add: 'Agregar',
    control_user: 'Usuario de control',
    search: 'Buscar',
    reset: 'Reiniciar',
    close: 'Cerrar',
    add_performers: 'Agrgar modelos',
    add_performers_text: 'Aquí puedes asociar modelos a tu estudio, enviándoles un vínculo o una invitación por correo electrónico.',
    all_models: 'Todas las modelos',
    go_to_room: 'Ir a mi sala',
    sort_by_name: 'Ordenar por nombre',
    sort_by_online: 'Ordenar por estado online',
    sort_by_viewer: 'Ordenar por número de espectadores',
    settings_header_text: 'Cambia tu configuración aquí.',
    name: 'Nombre',
    contact_email: 'Correo electrónico de contacto',
    contact_phone: 'Teléfono de contacto',
    website: 'Sitio web',
    cancel: 'Cancelar',
    save: 'Guardar',
    boost_cam: 'Boostear cámara',
    model_management_page: 'Esta es tu página de administración de modelos.',
    no_models_added: 'Aún no has añadido a ninguna modelo. Agreaga a tu primera modelo pinchando el botón de abajo.',
    add_model: 'Agregar modelo',
    add_a_model: 'Agrega una modelo',
    need_help: '¿Necesitas ayuda?',
    hide_profiles: 'Esconder perfiles',
    show_all_profiles: 'Mostrar todos los perfiles',
    platform_not_available: ':platform no está disponible para boostear en este momento',
    either: 'Tampoco',
    any: 'cualquiera',
    filter_profiles: 'Filtrar perfiles',
    give_tokens: 'Entregar tokens',
    profile_name: 'Nombre de perfil',
    user_or_profile_name: 'Nombre de usuario o perfil',
    home: 'Inicio',
    sign_up: 'Regístrarse',
    admin: 'Admin',
    grid: 'Cuadrícula',
    list: 'Lista',
    all_performers: 'Todas las modelos',
    identifier: 'Nombre/Identifcador',
    all_platforms: 'Todas las plataformas',
    any_status: 'Cualquier estado',
    all_genders: 'Todos los sexos',
    users: 'Usuarios',
    toggle_nav: 'Activar naegación',
    add_link: 'Agregar vínculo',
    url_error: 'Error de URL',
    url_error_text: 'La url debe ser válida. Por favor, revisa la url e intenta de nuevo.',
    profiles: 'Perfiles',
    camsite: 'Camsite',
    last_online: 'Última vez online',
    create: 'Crear',
    add_new_profile: 'Agregar nuevo perfil',
    on_camsite: '¿En qué camsite estás?',
    model_name: '¿Cuál es tu nombre de modelo allí?',
    add_new: 'Agregar nuevo',
    no_profile: 'Conectar perfil',
    social_header: 'Publicador en redes sociales',
    tools: 'Herramientas',
    social_pub: 'Publicador social',
    social_update: 'Actualizador automático y programador de medios sociales',
    social_update_text: 'Usa esta herramienta para agendar y actualizar todas tus cuentas de redes sociales al mismo tiempo. También puedes programar para publicar automáticamente en redes sociales cada vez que estés online. ¡Comencemos!',
    survey: 'Encuesta',
    go_back: 'Ir a atrás',
    survey_thanks: 'Gracias por hacer esta encuesta. Recibirás tokens como recompensa por haberla completado.',
    survey_taken: 'Ya has hecho esta encuesta.',
    diva_toolkit: 'Kit de herramientas Diva',
    toolkit_text: 'Usa estas herramientas para llevar más tráfico (visitantes) a tu sala, auto-actualizar tus redes sociales y ganar tokens.',
    cambooster_text: 'Puedes usar el CamBooster para iniciar tus shows con una corriente de tráfico (espectadores) en tu sala, la próxima vez que estés online o cuando lo especifiques.',
    divalinks_text: 'DivaLinks es un abreviador de vínculos que te hará ganar tokens y obtener tráfico (visitantes) gratuitos a tu sala, al igual que más fans y seguidores en tus perfiles de redes sociales. También puedes ganar tokens por cada clic. Luego puedes gastar estos tokens para promover tu sala.',
    social_fanpage: 'Fanpage',
    social_fanpage_text: 'Te deja hacer una fanpage personalizada que te ayudará a obtener más fans y seguidores en tus perfiles de redes sociales y más visitantes para tu próxima presentación. Avísales a tus fans dónde pueden obtener todo tu contenido en un solo lugar.',
    setup_page: 'Configurar mi página',
    email: 'Correo electrónico',
    full_name: 'Nombre completo',
    new_password: 'Nueva contraseña',
    enter_new_passw: 'Ingresar nueva contraseña',
    repeat_password: 'Repetir contraseña',
    repeat_new_passw: 'Repetir nueva contraseña',
    studio_permissions: 'Permisos de estudio',
    can_share_links: 'Puede compartir vínculos',
    can_boost_cam: 'Puede boostar cámara',
    can_manage_orders: 'Puede administrar órdenes',
    can_use_social: 'Puede usar redes sociales',
    tool: 'Herramienta',
    delete: 'Eliminar',
    cam_to_adv: 'Cam sites para promocionar',
    choose_cam: 'Escoge un Camsite...',
    models_to_prio: 'Especifica las modelos que quieres priorizar si están online',
    choose_performer: 'Escoge una modelo...',
    copy_code: 'Copiar el siguiente código',
    create_adzone: 'Crear nueva adzone',
    select_tool: 'Seleccionar herramienta',
    title: 'Título...',
    edit: 'Editar',
    next: 'Siguiente',
    login_header_text: 'Regístrate y boostea tu cámara ahora mismo.',
    email_adress: 'Dirección de correo electrónico',
    remember: 'Recordarme',
    reset_passw: 'Reiniciar contraseña',
    reset_passw_intro: 'Ingresa tu nueva contraseña a continuación',
    email_passw_intro: '¿Olvidaste tu contraseña? No hay problema. Ingresa tu correo electrónico a continuación y te enviaremos un vínculo para reiniciarla.',
    or_sign_in: 'O inicia sesión con',
    need_account: '¿Necesitas unacuenta?',
    sign_up_now: 'Regístrate ahora',
    password: 'Contraseña',
    sign_up_text: 'Regístrate y boostea tu cámara ahora mismo',
    sign_up_text_studios: '¡Hola! Regístrate y controla el tráfico de tus modelos ahora mismo.',
    sign_up_text_models: 'Regístrate y boostea tu cámara ahora mismo!',
    sign_up_text_choose: '¿Eres modelo o un estudio? Escoge tu tipo de cuenta a continuación',
    confirm_passw: 'Confirmar contraseña',
    i_am_a: 'Yo soy',
    studio_name: 'Nombre del estudio',
    phone_number: 'Número telefónico',
    studio_website: 'Sitio web del estudio',
    total_performers: 'Total de modelos',
    agree: 'Estoy de acuerdo con :link',
    terms_conditions: 'Términos y condiciones',
    already_have_account: '¿Ya tienes una cuenta?',
    sign_in_now: 'Ingresa ahora',
    or_sign_up: 'O regístrate con',
    reset_passw_text: 'Enviar vínculo para reinicio de contraseña',
    studio_Q: '¿Representas a un estudio?',
    are_you_studio: 'Ve a la página de registro de estudio',
    studio_signup: 'Registro de estudio',
    model_Q: '¿Modelo?',
    are_you_model: '¿Eres modelo?',
    model_signup: 'Registro de modelo',
    camsites: 'Camsites',
    connect_camsite: 'Conectar a tu camsite',
    connec_camsite_text: 'Antes de boostear tu cámara o ganar tokens por haber compartido vínculos, necesitas configurar tu sala. Por favor, selecciona tu camsite de los siguientes sitios y pincha Conectar para configurar tu webcam.',
    freemium: 'Sitio Freemium',
    connect: 'Conectar',
    be_back: 'Volvemos enseguida.',
    total_income: 'Total de ingresos',
    num_adzones: 'Número de adzones',
    total_impressions: 'Número total de impresiones',
    num_clicks: 'Número total de clics',
    CTR: 'Proporción de clics',
    verify_account: 'Verifica tu cuenta',
    account_verification: 'Verficación de cuenta',
    verify_text: 'Tu cuenta aún no ha sido verificada. Por favor, pinche sobre el vínculo de correo electrónico de verificación para verificar tu cuenta. Revisa tu carpeta de spam o correo no deseado si no encuentras el correo en tu bandeja de entradax',
    payments_header_text: 'Algún texto',
    invoice: 'Factura',
    invoice_date: 'Fecha de la factura',
    item_list: 'Lista de articulos',
    total_price: 'Precio total',
    payment_via: 'Pago vía',
    subtotal: 'Total parcial',
    enabled: 'Activado',
    yes: 'Sí',
    no: 'No',
    gay: 'Gay',
    straight: 'Hetero',
    trans: 'Trans',
    men: 'Hombre',
    women: 'Mujer',
    couples: 'Parejas',
    status_title: 'Título',
    site_url: 'URL del sitio',
    affiliate_url: 'URL afiliado',
    alexa_rank: 'Rank Alexa',
    numb_unique_models: 'Número de modelos únicas',
    online_models: 'Modelos online',
    model_api: 'API de la modelo',
    model_referral: 'Programa de referido de la modelo',
    live_screenshot: 'Captura de pantalla ',
    live_stream: 'Transmisión en vivo',
    profile_pic: 'Foto de perfil',
    business_model: 'Modelo de negocio',
    orientation: 'Orientación',
    gender: 'Género',
    has_whitelabel: 'Tiene whitelabel',
    has_revshare: 'Tiene revshare',
    has_conv_tracking: 'Tiene rastreo de conversión',
    status_freemium: 'Freemium',
    premium: 'Premium',
    supported: 'Soportado',
    not_supported: 'No soportado',
    select_account: 'Selecciona tu tipo de cuenta',
    know_more_heading: '¿Quieres saber más sobre nosotros?',
    know_more_info: 'Ve a nuestra página de contacto y contestaremos tus preguntas',
    years_old: ':years años de edad',
    user_count: ':users usuarios',
    token_count: ':tokens tokens',
    viewer_count: ':viewers espectadores',
    visitor_count: ':visitors visitantes',
    model_profile: 'Perfil de la modelo',
    make_your_selections: 'Haz tus selecciones',
    choose_boost_camgirl: 'Elige el perfil de la modelo que quieres boostear',
    autoresume: 'Resumir el orden automáticamente luego de privado/offline',
    traffic_package: 'Paquete de tráfico',
    package_size: 'Tamaño de paquete',
    order_summary: 'Resumen de la orden',
    package: 'Paquete',
    size: 'Tamaño',
    total: 'Total',
    complete_order: 'Orden completa',
    plan_info: 'Informacíón del :plan',
    select_camsite_and_nickname: 'Selecciona el camsite y escribe el nickname del perfil que quieres agregar.',
    nickname_at_platform: 'Nickname usado en la plataforma',
    your_nickname: 'Tu nickname',
    new_profile: 'Nuevo perfil',
    unavailable: 'Increíble',
    unavailable_description: 'Boosteo no disponible para esta plataforma.',
    open_landingpage: 'Abrir página de destino',
    no_access_tokens: 'No has creado ningún token de acceso personal.',
    personal_access_token: 'Token de acceso personal',
    personal_access_tokens: 'Token de acceso personales',
    create_new_token: 'Crear nuevo token',
    new_token: 'Nuevo token',
    personal_token_caution: 'Aquí está tu nuevo token de acceso personal. Esta es la única vez que se mostrará, así que no lo pierda. Ahora puede usar este token para hacer peticiones API.',
    authpopup_sign_in_message: '¡Hola! ¡Inicia sesión y comienza a ganar tráfico instantáneamente!',
    authpopup_sign_up_message: '¡Hola! ¡Regístrate y comienza a ganar tráfico instantáneamente!',
    or_sign_in_with: 'O inicia sesión con',
    create_new_link: 'Crear nuevo vínculo',
    soon: 'Próximamente...',
    link_logged_out_message: 'Debes registrarte o iniciar sesión para usar las características desbloqueadas. ¡Los miembros obtienen estadísticas en vivo y mucho más!',
    add_profile: 'Agregar perfil',
    all_links: 'Todos los vínculos',
    buy_traffic: 'Comprar tráfico',
    share_link: 'Compartir vínculo',
    customize: 'Personalizar',
    delete_link: 'Borrar vínculo',
    ad_type: 'Tipo de Ad ',
    select_profile: 'Seleccionar perfil',
    customize_your_link: 'Personalizar tu vínculo',
    link_share_message: '¡Copia esta dirección y compártela! Cualquiera que pinche sobre este vínculo verá la página que has compartido junto con el ad.',
    copy: 'Copiar',
    enter_address: 'Ingresa la dirección del sitio web',
    redirected_popups: 'Popups redirigidos',
    traffic_type: 'Tipo de tráfico',
    integrations: 'Integraciones',
    packages: 'Paquetes',
    supported_sites: 'Sitios compatibles',
    statistics: 'Estadísticas',
    upgrade: 'Actualizar',
    promo_tools: 'Herramientas de promoción',
    transactions: 'Transacciones',
    rtb: 'RTB',
    send: 'Enviar',
    boost: 'Boostear',
    free_boost_count: '{1} 1 boost gratutio|{*} :count boosts gratuitos',
    all_payments: 'Todos los pagos',
    view_invoice: 'Ver factura',
    download_invoice: 'Descargar factura',
    one_free_boost: '1 boost gratis',
    free_boosts: ':boosts boosts gratis',
    active_orders: 'Órdenes activas',
    paused_orders: 'Órdenes pausadas',
    private: 'Privado',
    away: 'No disponible',
    welcome_to_studio_button: 'Empieza y agrega una modelo',
    go_to_url: 'Ir a la URL',
    cancel_and_refund: 'Cancelación y reembolso',
    country: 'País',
    city: 'Ciudad',
    recent_orders: 'Órdenes recientes',
    model_count: '{1} 1 modelo|{*} :count de modelos',
    model_info: 'Información de modelo',
    profile_info: 'Información del perfil',
    select_your_platform: 'Selecciona tu plataforma',
    boost_profiles: 'Boostear perfiles',
    about: 'Acerca de',
    services: 'Servicios',
    how: 'Cómo',
    contact: 'Contacto',
    support: 'Soporte',
    profiles_count: '{1} 1 perfil|{*} :count de perfiles',
    previous: 'Anterior',
    login_problem: '¿TIenes problemas para iniciar sesión?',
    login_contact: 'Contáctanos y te ayudaremos',
    register: 'Registrarse',
    register_problem: '¿Tienes problemas para registrarte?',
    reg_studio: 'Registrarse como estudio',
    reg_studio_sub: 'Registrarse y boostear los camrooms de las modelos',
    reg_enter_details: 'Ingresa tus detalles',
    reg_user_details: 'Detalles del usuario',
    reg_user_enter_details: 'Ingresa la información de tu cuenta',
    reg_studio_details: 'Detalles del estudio',
    reg_studio_enter_details: 'Ingresa los detalles del estudio',
    reg_name: 'Tu nombre',
    reg_email: 'tu@correoelectrónico.com',
    reg_password: 'Tu contraseña',
    reg_re_password: 'Reingresa tu contraseña',
    reg_studio_name: 'Nombre del estudio',
    reg_studio_email: 'Dirección de correo electrónico del estudio',
    reg_studio_phone: 'Número de contacto del estudio',
    reg_studio_country: 'Selecciona tu país',
    reg_studio_city: 'Ciudad donde está situado el estudio',
    reg_total_performers: 'Por favor, seleccione...',
    contact_send_us: 'Envíanos un mensaje',
    contact_having_problems: '¿Tienes problemas?',
    contact_faq: 'Revisa nuestra lista de preguntas frecuentes',
    contact_subject: 'Asunto',
    contact_subject_place: 'Asunto del mensaje',
    contact_message: 'Mensaje',
    contact_send_message: 'Enviar mensaje',
    contact_contact_us: 'Contáctanos',
    disabled: 'Desactivado',
    about_us: 'Acerca de nosotros',
    about_info: 'Información de Diva Technologies AS y quiénes somos',
    about_know_more: '¿Quieres saber más?',
    about_goto: 'Ve a nuestra página de contacto y responderemos todas tus preguntas',
    add_package: 'Agregar paquete',
    updated: 'Actualizado',
    budget: 'Prespuesto',
    limits: 'Límites',
    orderprogress: 'Progreso',
    profile_details: 'Detalles del perfil',
    user_details: 'Detalles del usuario',
    boost_profile: 'Booster perfil',
    plan_details: 'Detalles del plan',
    budget_limit: 'Presupuesto y límite',
    select_platform: 'Seleccionar plataforma',
    performer_nickname: 'Nickname de la modelo',
    camsite_header: '¿Camsite de la modelo?',
    camsite_subtitle: 'Selecciona el camsite dónde se presenta la modelo',
    model_header: 'Nombre de usuario de la modelo',
    model_subtitle: 'Ingresa el nombre de usuario de la modelo en el camsite',
    model_details: 'Detalles de la modelo',
    model_details_sub: 'Ingresa los detalles de la nueva modelo',
    model_name_input: 'Nombre de la modelo',
    model_name_input_sub: 'Ingresa el nombre completo de la modelo',
    image: 'Imagen',
    image_subtitle: 'Carga una imagen de la modelo',
    image_subtitle_profile: 'Cargar nueva imagen de perfil',
    image_upload: 'Cargar imagen',
    select_image: 'Seleccionar imagen',
    image_crop: 'Cortar image',
    email_subtitle: 'Solo se necesita si la modelo va a iniciar sesión en DivaTraffic para usar la cuenta de modelo personalmente',
    save_model: 'Guardar modelo',
    model_invite: 'Enviar invitación a la modelo',
    model_invite_sub: 'Al seleccionar esta opción, la modelo recibirá una invitación a su cuenta y podrá iniciar sesión en DivaTraffic desde donde podrá utilizar su perfil',
    camsite_profiles: 'Perfiles de camsite',
    camsite_profiles_sub: 'Si la modelo transmite vídeo en más de un camsite, podrás agregar todos los perfiles aquí',
    boost_models: 'Boostear modelos',
    social_accounts: 'Cuentas en redes sociales',
    social_accounts_sub: 'Ingresa los detalles de las cuentas de redes sociales de la modelo para tener acceso a más herramientas que te ayudarán a obtener más tráfico',
    manage_settings: 'Administra las opciones de las modelos aquí',
    general_settings: 'Ajustes generales',
    general_settings_sub: 'Ajustes generales para las modelos',
    affiliate_settings: 'Afiliación',
    affiliate_settings_sub: 'Actualiza tu información de afiliado',
    security_settings: 'Seguridad',
    security_settings_sub: 'Administra la seguridad de tus cuentas',
    deactivate: 'Desactivar esta cuenta',
    deactivate_sub: 'Si por alguna razón quieres desactivar esta cuenta de modelo, este es el lugar para hacerlo. Ten en cuenta que eliminar esta cuenta también desactivará todos los perfiles asociados a ella',
    deactivate_account: 'Desactivar cuenta',
    change_password: 'Cambiar contraseña',
    old_passw: 'Contraseña anterior',
    new_passw: 'Contraseña nueva',
    confirm_password: 'Confirmar contraseña',
    current_balance: 'BALANCE ACTUAL',
    tokens_caps: 'TOKENS',
    collect_tokens: 'Recoger tokens',
    select_give_amount: 'Selecciona la cantidad de tokens que quieres entregar',
    select_collect_amount: 'Selecciona la cantidad de tokens que quieres recoger',
    performer_name: 'Nombre de la modelo',
    privacy_settings: 'Ajustes de privacidad',
    privacy_settings_sub: 'Ajustes de privacidad de las modelos',
    update_avatar: 'Actualiza tu avatar',
    order_details: 'Detalles de la orden',
    more_options: 'Más opciones',
    bid: 'Ofertas',
    visitor_limit: 'Límite de visitantes',
    offline_redirect: 'Redirigir Offline',
    autostart: 'Autoiniciar',
    autostart_sub: 'Iniciar automáticamente las órdenes pausadas cuando la modelo vuelva a estar en línea',
    autostart_info: 'Leer más acerca del autoinicio de órdenes',
    low: 'Bajo',
    medium: 'Medio',
    high: 'Alto',
    current_bid: 'Oferta actual',
    bid_info: 'Leer más acerca de las ofertas',
    redirect_url: 'Redirigir url',
    redirect_info: 'Leer más acerca de las redirecciones y acerca de dónde establecer la url de destino',
    budget_info: 'Leer más sobre el presupuesto y cómo funciona',
    max_and_min: 'Máximo y mínimo de invitados en un camroom',
    max_and_min_info: 'Leer más sobre la limitación de tráfico',
    traffic_package_info: 'Información detallada sobre el :package paquete',
    profiles_sub: 'Puedes seleccionar perfiles multiples',
    profiles_info: 'Cómo optimizar tu selección de perfiles de modelos',
    account_details: 'Detalles de cuenta',
    privacy: 'Privacidad',
    affiliate: 'Afiliado',
    security: 'Seguridad',
    social: 'Social',
    affiliate_add: 'Agrega tu clave única de afiliado / id de campaña para cada platafora',
    blocked_countries: 'Países bloqueados',
    blocked_countries_sub: 'Agrega países de los que no quieras recibir tráfico. Esta configuración tendrá efecto en todos los perfiles pertecenientes a esta modelo',
    blocked_countries_subtitle: 'Agrega países de los que no quieras recibir tráfico',
    affiliate_setting: 'Ajustes de afiliado',
    affiliate_key: 'Clave de afiliado (reemplazará la {aff_key} en la URL)',
    update_profile: 'Actualizar perfil',
    add_new_model: 'Agregar nueva modelo',
    add_new_package: 'Agregar nuevo paquete',
    edit_model: 'Editar modelo',
    update_avatar_title: 'Actualizar avatar',
    order_stats: 'Estadísticas de órdenes',
    select_all: 'Seleccionar todo',
    deselect_all: 'Deseleccionar todo',
    boost_selected: 'Boost seleccionado',
    cancelled_text: 'La orden ha sido cancelado y los fondos restantes han sido transferidos a tu cuenta',
    platform_count: ':platforms plataformas',
    are_you_sure: '¿Estás segura(o)?',
    remaining_funds: 'Los fondos restantes han sido transferidos a tu cuenta',
    cancel_order: 'Cancelar orden',
    back: 'Atrás',
    cancel_refund: 'Cancelar y reembolsar',
    all: 'Todo',
    create_order: 'Crear orden',
    user_settings: 'Ajustes de usuario',
    saved: 'Guardado',
    settings_saved: 'Ajustes de usuario guardados',
    error: 'Error',
    save_failed: 'Error al guardar',
    delete_account: 'Eliminar cuenta',
    delete_your_account: 'Borrar tu cuenta',
    delete_account_sub: 'Si por alguna razón quieres eliminar esta cuenta, este es el lugar para hacerlo. Ten en cuenta que eliminar esta cuenta también desactivará todos los perfiles de modelos asociados a ella',
    profile_info_sub: 'Ingresa tus detalles de perfil',
    saved_image: 'Imagen guardada',
    error_something: 'Algo salió mal',
    profile_saved: 'Información de perfil guardada',
    language: 'Cerrar sesión',
    avatar: 'Avatar',
    overview: 'Resumen',
    order_index: 'Índice de órdenes',
    daily_token_usage: 'Uso diario de tokens',
    traffic_statistics: 'Estadísticas de tráfico',
    daily_visitors: 'Visitantes diarios',
    visitors_today: 'Visitantes de hoy',
    last_two_weeks: 'Últimas dos semanas',
    full_statistics: 'Estadísticas completas',
    weekly_visitors: 'Visitantes semanales',
    visitors_this_week: 'Visitantes de esta semana',
    last_14_weeks: 'Últimas 14 semanas',
    monthly_visitors: 'Visitantes mensuales',
    visitors_this_month: 'Visitantes este mes',
    last_year: 'Año anterior',
    no_results: 'No hay resultados',
    no_performer_results_details: 'Encuentre perfiles de modelos buscando usando la entrada de búsqueda anterior (Buscar por nombre) y limitándolo usando el menú desplegable (Plataforma).',
    any_platform: 'Cualquier plataforma',
    online_status: 'Estatus en línea',
    model_name_header: 'Nombre de la modelo',
    transfer_complete: 'Transferencia completada',
    delete_profile_text: 'Esto eliminará :name del perfil permanentemente.',
    delete_succeeded: '¡Eliminado!',
    profile_deleted: 'Este perfil fue eliminado.',
    deleted_profile: 'Perfil eliminado',
    age: 'Edad',
    about_me: 'Acerca de mí',
    guest_message: 'Mensaje invitado',
    not_available: 'Aún no disponible',
    coming_soon: 'Próximamente',
    general: 'General',
    targeting: 'Targeting',
    bids: 'Ofertas',
    delete_model_confirm: 'Eliminar esta cuenta también desactivará todos los perfiles de modelos asociados a ella',
    yes_delete_it: 'Sí, eliminar',
    account_deleted: 'Esta cuenta ha sido eliminada.',
    change_password_failed: 'No se pudo guardar la contraseña.',
    edit_profile: 'Editar perfile',
    show_n_profiles: '{1}Mostrar 1 perfil más|[*,*]Mostrar :count perfiles más',
    apply_targeting: 'Por cada mil impresiones. Esto aplica para todo tu targeting',
    edit_package: 'Editar paquete',
    update: 'Actualizar',
    package_description: 'Descripción del paquete',
    package_title: 'Título del paquete',
    give_package_name: 'Nombre del paquete',
    give_package_description: 'Descripción del paquete',
    default_bid: 'Oferta por defecto',
    dynamic_bidding: 'Oferta dinámica',
    customize_bid: 'Personalizar ofertas para targeting específico',
    add_bid: 'Agregar ofertas',
    sources: 'Fuentes',
    devices: 'Dispositivos',
    browsers: 'Explotadores',
    os: 'Sistemas operativos',
    targeting_options: 'Selecciona tus opciones de targeting',
    package_created: 'Paquete creado',
    affiliate_room_url: 'Afiliar URL de camroom',
    profiles_saved: 'Perfil guardado',
    packageview: 'Vista de paquete',
    package_details: 'Detalles de paquete',
    per_thousand: 'por cada mil impresiones',
    custom_bids: 'Ofertas personalizadas',
    operating_systems: 'Sistemas operativos',
    package_updated: 'Paquete actualizado',
    update_failed: 'Error en la actualización',
    order_created: 'Orden creada',
    something_wrong: 'Algo saió mal',
    all_transactions: 'Todas las transacciones',
    into_account: 'En la cuenta',
    out_account: 'Fuera de la cuenta',
    payments: 'Pagos',
    amount: 'Cantidad',
    date: 'Fecha',
    comment: 'Comentario',
    payment_method: 'Método de pago',
    subscription_payment: 'Pago de suscripción',
    remaining: 'restante',
    promotools: 'Herramientes de promoción',
    card_view: 'Vista de tarjetas',
    list_view: 'Vista de lista',
    select_profiles: 'Seleccionar perfiles',
    select_package: 'Seleccionar paquete de tráfico',
    choose_package: 'Elige un paquete de tráfico aquí',
    all_profiles: 'Todos los perfiles',
    could_not_load: 'No se pudo cargar :type',
    bid_tooltip: 'Valor predeterminado establecido en 100%. Incrementar la oferta implica pagar más por cada visitante, lo que otorga más tráfico de manera más rápida a tu camroom, pero también agota el presupuesto más rápido.',
    maxviewers_tooltip: 'Cuando se alcancen los "Max Viewers" dejaremos de enviar tráfico hasta que el conteo de visitantes esté por debajo de este número otra vez. Dependiendo del camsite y el targeting del paquete, no hay garantía de alcanzar este número.',
    tokens_tooltip: 'Este número representa el presupuesto/costo que le gustaría gastar en esta orden',
    how_does_it_work: '¿Cómo funciona?',
    basic: 'Básico',
    max_viewers: 'Visitantes máximos',
    traffic_settings: 'Ajustes de tráfico',
    start_automatically: 'Iniciar automáticamente',
    renew_automatically: 'Renovar automáticamente',
    select_promo: 'Selecciona la herramienta de promoción',
    select_promo_header: 'Esta librería javascript controla todas las herramientas de promoción de DivaTraffic. Configura los ajustes que desees, luego copia y pega el código en tu sitio web.',
    camhead_info: 'CamHead es una herramienta de publicidad flotante que mostrará la camroom en línea en cualquier página donde pegues el código',
    snippet_info: 'Snippet mostrará un camroom en línea en cualquier página donde pegues el código',
    widget_info: 'Native Cam Iframe mostrará múltiples perfiles en el tamaño que desees',
    select_camsite: 'Seleccione a cuáles camsites quieres enviar tráfico',
    tools_instruction: 'El siguiente javascript controla todas las herramientas de promoción proporcionadas por DivaTraffic. Copia el código y pégalo justo antes de <strong class="text-info">&lt;head&gt;</strong> en la página en la que quieres mostrar tus anuncios.',
    integration_script: 'Script de integración',
    viewers_room: 'Espectadores en la sala',
    total_sent: 'Total de enviados',
    profiles_online: '{1} Perfil en línea |{*} Perfiles en línea',
    duration: 'Duración',
    hour: '{1} 1 hora|{*} :count horas',
    minutes: '{1} 1 minuto|{*} :count minutos',
    tokens_left: 'tokens restantes',
    model_plural: '{1} Modelo|{*} Modelos',
    profile_plural: '{1} Perfil |{*} Perfiles',
    camsite_plural: '{1} Camsite |{*} Camsites',
    platforms_short: '{1}:plataforma|:count plataformas',
    responsive: 'Receptivo',
    fixed_size: 'Tamaño ajustado',
    height: 'Altura',
    width: 'Ancho',
    select_rooms: 'Seleccionar camrooms',
    sm_package: 'Combo pequeño de tokens',
    md_package: 'Combo mediano de tokens',
    lg_package: 'Combo grande de tokens',
    xl_package: 'Combo XL de tokens',
    xxl_package: 'Combo XXL de tokens',
    custom_package: 'Combo personalizado de tokens',
    select_token_package: 'Selecciona tu paquete de tokens',
    select_token_bundle: 'Selecciona tu combo de tokens',
    custom_amount: 'Cantidad personalizada',
    payment_details: 'Detalles del pago',
    bundle_details: 'Detalles del combo',
    transaction_info: '* La transacccón será cargada en Euros y el banco emisor puede aplicar cargos adicionales de cambio de moneda.',
    paysite_info: 'Soporte para el comprador',
    payment_tokens: 'Pagar por tokens',
    search_performer: 'Buscar por nombre de modelo',
    slower: 'más lento',
    faster: 'más rápido',
    please_select_camsite: 'Seleccione el camsite',
    no_models: 'No se encontraron modelos.',
    no_profiles: 'No se encontraron perfiles.',
    no_orders: 'No se encontraron órdenes.',
    no_stats: 'No se encontraron estadísticas...',
    loading_models: 'Cargando modelos...',
    loading_profiles: 'Cargando perfiles...',
    loading_orders: 'Cargando órdenes...',
    loading_dashboard: 'Cargando datos del panel de control...',
    loading_data: 'Cargando datos...',
    loading_stats: 'Cargando estadísticas...',
    camhead_desc: 'CamHead es un código que mostrará una publicidad cam head para tu show en línea. Todo lo que tienes que hacer es copiarlo y pegarlo en el html de cualquier sitio web. Mostrará cualquier perfil que tengas en tu cuenta.',
    snippet_desc: 'Snippet mostrará un camroom en línea en cada página donde escribas el código.',
    widget_desc: 'Native Cam Iframe mostrará múltiples perfiles en el tamaño que desees.',
    divalinks_desc: 'DivaLinks es una herramienta para acortar el vínculo que quieras compartir y que también muestra la cámara que aparece cuando estás en línea.',
    smartlink_desc: 'SmartLink es una URL que te redirigirá a culaquiera de tus perfiles en línea que tengas en tu estudio. Compártelo en redes sociales con seguidores y otros miembros.',
    camhead_script: 'El siguiente javascript controla a CamHead. Copia el script y pega el código justo antes de <strong class="text-info">&lt;/head&gt;</strong> en la página en la que quieres mostrar tus anuncios.',
    snippet_script: 'El siguiente javascript controla a Snippet. Copia el script y pega el código justo antes de <strong class="text-info">&lt;/head&gt;</strong> en la página en la que quieres mostrar tus anuncios.',
    widget_script_1: '1. Pega este código justo antes de <strong class="text-info">&lt;/head&gt;</strong> en tu página.',
    widget_script_2: '2. Pega este código donde quieras que aparezca el widget',
    smartlink_adress: 'Dirección de SmartLink',
    smartlink_description: 'Este es el SmartLink de Diva Studios Ltd. que te redirigirá a un perfil en línea aleatorio que tengas en tu cuenta. Compártelo en redes sociales con tus seguidores y otros miembros.',
    configure: 'Configurar',
    choose_setting: 'Selecciones sus ajustes preferidos para el/la',
    select_camsite_sub: 'Puedes especificar los camsites de las modelos que quieres mostrar',
    select_rooms_sub: 'Puedes especificar cuáles camrooms quieres mostrar',
    static_responsive: '¿El widget debe ser estático o receptivo?',
    whitelabels: 'Whitelables',
    add_whitelabel: 'Agregar whitelabel',
    add_new_whitelabel: 'Agregar nuevo whitelabel',
    whitelabel_saved: 'Whitelabel guardar',
    no_whitelabel: 'No hay whitelabels',
    model_user: 'Usuario/Modelo',
    registrations: 'Registros',
    rebills: 'Cargos recurrentes',
    costs: 'Costos',
    ctr: 'Tasa por clics',
    number_of: 'Número de',
    daily_impressions: 'Impresiones diarias',
    daily_clicks: 'Clics diarios',
    daily_registrations: 'Registros diarios',
    daily_sales: 'Ventas diarias',
    daily_rebills: 'Cargos recurrentes diarios',
    daily_ctr: 'CTR Diario',
    daily_redirects: 'DRedirecciones diarias',
    year: 'Año',
    referrer: 'Remitente',
    redirects: 'Redirige',
    redirect: 'Redirige',
    user: 'Usuario',
    false: 'Falso',
    sorted_by: 'Ordenado por',
    from: 'De',
    to: 'Para',
    online_performer_count: '{1} 1 modelo en línea | :count modelos en línea',
    total_performer_count: '{1} 1 modelo en total | :count modelos en total',
    package_howto: 'Crear tu propio paquete puede marcar una gran diferencia a la manera en que el tráfico hace su trabajo. Seleccionar opciones de targeting que conozcas será más beneficioso para tu conversión y garantizará que solo recibas tráfico de estas fuentes. Pincha <strong class="text-success">Start guide</strong> si necesitas ayuda para completar el proceso',
    start_guide: 'Guía de inicio',
    how_it_works: 'Cómo funciona',
    audience: 'Audiencia',
    packagecreate: 'Crear paquete',
    packageedit: 'Editar paquete',
    select: 'Selecciones',
    start_boost: 'Comenzar boost',
    please_select_package: 'Selecciona un paquete',
    select_package_sub: 'Pincha sobre un paquete del menú izquierdo para seleccionarlo y leer más sobre sus especificaciones',
    delivered_visitor_count: '{1} 1 visitante conseguido | :count visitantes conseguidos',
    tokens_spent: ':tokens gastados',
    dimension: 'Dimensión',
    password_changed: 'Contraseña cambiada',
    viewer_history: 'Historial de espectadores',
    advanced: 'Avanzado',
    traffic_options: 'Opciones de tráfico',
    budget_sizes: [
    'Pequeño',
    'Mediano',
    'Grande',
    'XL',
    'XXL',
    'XXXL'
],
    gotoroom: 'Ir a camroom',
    deleted_plan: 'Plan eliminado',
    n_minutes_ago: '{0} Ahora|{1} hace 1 min|{*} :count minutos atrás',
    n_hours_ago: '{1} hace 1 hr|{*} :count horas atrás',
    n_days_ago: '{1} hace 1 día|{*} :count días atrás',
    select_theme: 'Seleccionar tema',
    select_theme_sub: 'Seleccionar el tema del widget',
    select_thumb: 'Seleccionar tamaño de vista previa',
    select_thumb_sub: '¿Cuál tamaño deseas para las vistas previas?',
    background_color: 'Color de fondo',
    select_background_color: 'Especifica el color para la barra de infomración debajo de la imagen',
    select_details: 'Seleccionar detalles',
    select_details_sub: 'Especifica la información que deseas mostrar',
    camsite_name: 'Nombre del Camsite',
    tool_model_name: 'Nombre de la modelo',
    widget_sub_1: 'Este script controla los widgets',
    widget_sub_2: 'El widget aparecerá dentro de las etiquetas div con la clase especificada',
    script_tag_header: 'Etiqueta de Script',
    script_tag_sub: 'Instalar el widget siguiendo los siguientes pasos',
    room_settings: 'Ajustes de camroom',
    graph: 'Gráfico',
    visitors_sent: 'Visitantes enviados',
    room_limit: 'Límite de camroom',
    online_viewers: 'Espectadores en línea',
    device_type: 'Tipo de dispositivo',
    device_brand: 'Marca',
    browser: 'Explorador',
    cancelling_order: 'Cancelar orden',
    profile_added: 'Perfil añadido',
    default_theme: 'Tema por defecto',
    dark_theme: 'Tema oscuro',
    light_theme: 'Tema ligero',
    pink_theme: 'Tema rosa',
    xsmall: 'Extra pequeño',
    small: 'Pequño',
    large: 'Grande',
    xlarge: 'Extra grande',
    less_options: 'Menos opciones',
    no_profiles_selected: 'No hay perfiles seleccionados',
    no_package_selected: 'No hay paquetes seleccionados',
    estimate_token: 'Estimado por token',
    estimate_traffic: 'Tráfuco estimado',
    estimate_popups: 'Popups totales estimados',
    delivered_popups: 'Popups entregadas',
    estimate_clicks: 'Clics totales estimados',
    delivered_clicks: 'Clics entregados',
    type: 'Tipe',
    popups: 'Ventanas emergentes',
    select_whitelabel_camsite: 'Seleccione el camsite al que pertenece el whitelabel',
    selected_profiles: 'Perfiles seleccionados',
    view: 'Vista',
    device_types: 'Tipos de dispositivos',
    device_brands: 'Marcas del dispositivo',
    optional: 'opcional',
    pay: 'Pagar',
    wiretransfer: 'Transferencia bancaria',
    add_dimension: 'Agregar dimensión',
    clear_filter: 'Borrar filtro',
    filtered_by: 'Filtrado por',
    date_year: 'Año',
    estimated_visitors: 'Estimado de visitantes por token',
    any_camsite: 'Cualquier camsite',
    wire_success: 'El pago ha sido almacenado. Esperamos por tu transferencia bancaria',
    securion_info: 'Soporte de SecurionPay',
    update_running_orders: 'Actualizar órdenes de ejecución',
    networks: 'Redes',
    less_targetoptions: 'Pinchar para menos opciones de targeting',
    more_targetoptions: 'Pinchar para más opciones de targeting',
    camsite_specific: 'Camsite específico',
    camsite_specific_sub: 'Seleccione uno o más para que este paquete funcione solo con modelos de estos camsites específicos',
    main: 'Principal',
    my_order: 'Mi orden #',
    update_order: 'Actualizar orden',
    edit_order: 'Editar orden',
    already_paid: 'Previamente pagado',
    spent: 'Gastado',
    remaining_on_order: 'Restante en la orden',
    to_be_paid: 'A ser pagado',
    order_updated: 'Orden actualizada',
    loading_tools: 'Cargando herramientas',
    profiles_removed: '{1} 1 perfil eliminado. No compatible con este paquete |{*} :count perfiles eliminados. No compatible con este paquete',
    visit_room: 'Sala de visitas',
    affiliatelink_text: 'Puedes enviar tráfico a tu propio vínculo de afiliado con tan solo ingresar la información solicitada a continuación. Tus ganancias estarán en el programa de afiliado de los camsites y no ganarás tokens por registrarte. Las estadñísticasde ventas no estarán disponibles',
    affil_key: 'Clave de afiliado',
    whitelabel_settings: 'Ajustes de Whitelabel',
    whitelabel_text: 'Si tienes tu propio whitelabel, puedes enviarle tráfico especificando los detalles a continuación. No ganarás tokens por registros ni serás capaz de ver la conversión o las estadísticas de ventas. Tus ganancias estarán en el programa de aflliado de los camsites.',
    whitelabel_domain: 'Dominio de Whitelabel domain',
    diva_tps: 'Diva TPS (token por registro)',
    diva_tps_text: 'Recibe pagos en tokens cada vez que alguien se registre o haga una compra en el camsite',
    token_per_free: 'Tokens por registro gratis',
    token_per_purchase: 'Tokens por compra',
    widget_preview: 'Vista previa de Widget',
    desktop: 'Escritorio',
    mobile: 'Móvil',
    tablet: 'Tablet',
    estimate_visitors: 'Estimado de visitantes',
    per_token: 'por token',
    more: 'más',
    select_package_list: 'Selecciona el paquete que quieras de la lista a continuación',
    my_packages: 'Mis paquetes',
    favourites: 'Favoritos',
    targeting_all: 'Establecer Targeting para todo',
    all_countries: 'Todos los países countries',
    all_os: 'Todos los sistemas operativos',
    all_browsers: 'Todos los exploradores',
    all_networks: 'Todas las redes',
    all_device_types: 'Todos los tipos de dispositivos',
    all_device_brands: 'Todas las marcas de dispositivos',
    all_sources: 'Todas las fuentes',
    filtered_targeting: 'Filtrar el targeting',
    click_a_targeting: 'Pincha una opción de targeting para verla lista de todos los targets para este plan',
    order_must_pause: 'La orden ha sido pausado antes de que puedas editar',
    cpm_in_tokens: 'Costo por 1000 visitantes (en tokens)',
    cost_in_tokens: 'Costo (en tokens)',
    send_invitation: 'Enviar invitación a una modelo',
    send_password_reset: 'Enviar un correo electrónico a la modelo con un reinicio de contraseña',
    password_reset: 'Reinicio de contraseña reset',
    model_no_email: 'La modelo no tiene correo electrónico',
    email_sent: 'El correo electrónico ha sido enviado',
    on_the_camsite: 'en el camsite',
    target_audience: 'Audiencia target',
    target_audience_sub: 'Especifica desde dónde quieres recibir el tráfico. Puedes agregar tantas opciones de target como quieras',
    target_help: 'Crear tu propio paquete puede marcar una gran diferencia a la manera en que el tráfico hace su trabajo. Seleccionar opciones de targeting que conozcas será más beneficioso para tu conversión y garantizará que solo recibas tráfico de estas fuentes.',
    bid_sub: 'La oferta es el precio que pagas por cada 1000 visitantes. Si quieres más tráfico de opciones de targeting específicas, puedes subir la oferta. Las ofertas más altas implican que recibirás más tráfico de este targeting en menos tiempo',
    targeting_price: 'Precio del Targeting',
    add_targeting: 'Agregar targeting',
    select_audience: 'Seleciona tu audiencia',
    location: 'Ubicación',
    start_order: 'Comenzar orden',
    leave_paused: 'Dejar en pausa',
    cancel_refund_order: 'Cancelar y reembolsar orden',
    advanced_stats: 'Estadísticas avanzadas',
    order_progress: 'Progreso de la ordens',
    spent_tokens: ':spent de :total tokens',
    edit_profiles: 'Editar perfiles',
    settings_applied_all: 'Estas opciones se aplicarán a todos los perfiles seleccionados',
    set_max_viewers: 'Establecer el máximo de espectadores',
    block_countries: 'Bloquear países',
    affiliate_program: 'Programa de afiliado',
    program: 'Programa',
    no_affiliate_program: 'No hay programa de afiiliado',
    affiliate_contact_us: 'Si quieres usar tu propio vínculo de whitelabel o de afiliado, por favor, contáctanos <a href="https://divatraffic.zendesk.com/hc/en-us/requests/new" target="_blank">here</a>, y lo haremos por ti',
    duplicate: 'Duplicado',
    smartlink_affiliate_desc:'Enlace  inteligente, es una URL que redirigirá a la modelo en línea segçun su configuración. Compártelo en las redes solciales',
    welcome_sub_1:'DivaTraffic ofrece muchas herramientas para que la modelo potencialice su trasmisión en línea',
    welcome_sub_2:'Todo lo que necesitas hacer es agregar a la modelo a tu cuenta, he impulsar tu sala de chat',
    lets_get_started:'Vamos a comenzar',
    add_first_model:'Primero agregue a la modelo',
    add_first_profile:'Agregue primero su perfil',
    intro_to_dashboard:'Introducción a la plataforma',
    intro_to_dashboard_sub_1:'Una vez inicie esta será la página de DivaTraffic',
    intro_to_dashboard_sub_2:'Aquí tendrá una descripcción completa del tráfico actual',
    all_data_glance:'Todos tus datos a un click',
    all_data_glance_sub:'Las tarjetas  le darán información sobre sus modelos,pedidos y estados del token. Ver rapidamente quien está en línea.',
    your_recent_orders:'Sus pedidos recientes',
    your_recent_orders_sub:'La plataforma también provee una lista de sus ordenes recientes, para ver si tiene ordenes en curso y sus detalles',
    aff_key_saved:'Clave guardada del afiliado',
    aff_key:'Clave del afiliado',
    go_to_old_diva:'Volver a la antigua DivaTraffic',
    need_targeting:'Necesita selecionar el objetivo para dirigir el tráfico. Click en el botón "+ agregar objetivo" para iniciar.',
    country_count:'{1} 1 país seleccionado|{*} :count países seleccionado',
    browser_count:'{1} 1 navegador seleccionado|{*} :count navegador seleccionado',
    network_count:'{1} 1 rede seleccionadas|{*} :count redes seleccionadas',
    source_count:'{1} 1 fuente seleccionadas|{*} :count fuentes seleccionadas',
    device_brand_count:'{1} 1 marca de dispositivo seleccionado|{*} :count marcas de dispositivo seleccionado',
    device_type_count:'{1} 1 tipos de dispositivo seleccionado|{*} :count tipo de dispositivo seleccionado',
    os_count:'{1} 1 sistema operativo seleccionado|{*} :count sistemas operativo seleccionado',
    bids_count:'{1} 1 oferta|{*} :count ofertas',
    customize_icon:'Aquí puede personalizar el icono de su paquete para darle una apariencia mas personal',
    initials:'Iniciales',
    color:'Color',
    targeting_filters:'Filtros del paquete seleccionado',
    drag_or_click:'Arrastar o dar click en la categoría para agregar un filtro en la página',
    search_for_websites:' Buscar los sitios web en los que desea agregar el trafico de la lista de abajo',
    remove:'Quitar',
    select_websites:'Selecionar sitios web',
    search_webpage:'Buscar páginas web',
    all_networks_filter:'Todas las redes - filtro de redes',
    search_browsers:'Buscar navegadores',
    search_countries:'Buscar Paises',
    search_device_brands:'Buscar marca dispositivo seleccionado',
    search_device_types:'Buscar tipo de dispositivo seleccionado',
    search_networks:'Buscar redes',
    search_os:'Buscar sistema operativo',
    select_date_range:'Seleccionar rango de la fecha',
    choose_dates:'Elegir fechas',
    apply:'Aplicar',
    january:'Enero',
    february:'Febrero',
    march:'Marzo',
    april:'Abril',
    may:'Mayo',
    june:'Junio',
    july:'Julio',
    august:'Agosto',
    september:'Septiembre',
    october:'Octubre',
    november:'Noviembre',
    december:'Diciembre',
    mon:'Lunes',
    tue:'Martes',
    wed:'Miércoles',
    thu:'Jueves',
    fri:'Viernes',
    sat:'Sábado',
    sun:'Domingo',
    advanced_available:'Las estadísticas avanzadas de este período aún no están disponibles. Por favor regrese en unos minutos',
    network:'Red',
    custom:'Personalizar',
    select_traffic_options:'Selecionar opciones de tráfico',
    subscriptions:'Suscripciones',
    current_user_balance:'Saldo Actual',
    purchase_history:'Historial de compras',
    all_purchases:'Todas las compras',
    save_account_details:'Guardar detalles de la cuenta',
    save_privacy_settings:'Guardar ajustes privados',
    camhead:'Encabezado',
    snippet: 'Snippet',
    widget:'Acceso directo',
    smartlink:'Enlace inteligente',
    free:'Gratis',
    deluxe:'De lujo',
    monthly:'Mensual',
    yearly:'Anual',
    free_sub_desc:'Calificanos, es gratis!',
    basic_sub_desc:'Benefíciese del tráfico mensual!',
    premium_sub_desc:'Tráfico de mayor cálidad cada día',
    deluxe_sub_desc:'El mejor tráfico que podemos ofrecer',
    save_2_months:'ahorra 2 meses',
    per_month:'Por mes',
    select_plan:'Seleccionar plan',
    features:'Caracteristicas',
    studio_promo_tools: 'Studio Promo Tools',
    support_services:'Servios de soporte',
    misc_services: 'Misc services',
    bonus_on_purchase:'Compra de bonus o token',
    traffic_on_demand:'Demanda de tráfico',
    traffic_on_subscription:'Tráfico en suscripción',
    custom_traffic_packages:'Paquetes de tráfico personalizados',
    email_support:'Email de soporte',
    free_tokens_month:'Tokens gratis cada mes',
    live_chat_support:'Chat de soporte en vivo',
    profiles_included:'Perfiles incluidos',
    studio_camhead: 'Studio CamHead',
    studio_widgets: 'Studio Native Cam Widgets',
    studio_console:'Consola de estudio',
    studio_smartlinks: 'Studio Smart Links',
    dedicated_support_manager:'Adminsitrador de soporte dedicado',
    traffic_analytics:'Analisis de tráfico',
    dedicated_traffic_manager:'Administrador de tráfico',
    dedicated_traffic_consultant:'Asesor de tráfico',
    traffic_to_affiliate:'Enlace de tráfico al afiliado',
    traffic_to_whitelabel: 'Traffic to whitelabels',
    per_year:'Por año',
    popular:'Popular',
    pricing:'Precios',
    available_subscription:'La membresía está disponible en el momento sólo para los estudios. Próximamente,los planes de suscripción de modelos independientes.',
    payments_subscription:'Pago por suscripción',
    payment_for:'Pago por',
    subscription:'Suscripción',
    add_all:'Añadir todo',
    subscription_header:'Suscripción',
    studio_info:'Información de estudio',
    phone:'Teléfono',
    edit_details:'Editar detalles',
    account_info:'Información de la cuenta',
    born:'Nacimiento',
    upgrade_plan:'Plan de actualización',
    available_upgrades:'Plan disponible',
    product:'Producto',
    not_configured:'No configurado',
    configure_camsites:'Configuracion del sitio web',
    default_studio_settings:'Ajsutes de studio predeterminados',
    add_blocked_countries:'Agreguelos paises de los que no quiere recibir tráfico. Esta condiguración será aplicada en su perfil.',
    edit_default_settings:'Editar ajustes predeterminados',
    your_current_plan:'Su plan actual',
    cancel_subscription:'Cancelar suscripción',
    camsite_settings:'austes del sitio web',
    setup_camsite_config: 'Set up your camsite configurations',
    aff_key_activated:'Activación de la clave del afiliado',
    countries_blocked:'{1} 1 país bloqueado |{*} :count de países bloqueados',
    studio_avatar:'Avatar de estudio',
    first_name:'Nombre',
    last_name:'Apellido',
    address:'Dirección',
    keep_account_secure:'Manten tu cuenta segura',
    billing:'Facturación',
    edit_billing_info:'Editar información de la factura',
    add_profile_first:'Necesita por lo meno agregar 1 perfil primero',
    enter_your_aff_key_here:'Ingrese su clave de afiliado aquí',
    open:'Abierto',
    no_subscription:'No suscrito',
    select_free:'Seleccción gratis',
    available_to:'Disponible para',
    subscription_bonus:'Bonus de suscripción',
    downgrade:'Degradar',
    traffic_packages:'Paquetes de tráfico',
    subscription_cancelled:'Suscripción cancelada',
    cancelling_subscription:'Cancelando suscripción',
    payment_declined:'Su pago ha sido rechazado',
    subscription_updated:'Su suscripción  ha sido actualizada',
    something_went_wrong:'Algo salió mal',
    postbacks: 'Postbacks',
    no_camsite_profile:'No se encontró perfil del sitio web',
    no_camsite_profile_sub:'No hemos podido encontrar ningún perfil de sitio de cámara conectado a este modelo.',
    user_image:'imagen del usuario',
    estimated_traffic:'Tráfico estimado',
    package_price:'Precio del paquete',
    order_title: 'Еítulo del pedido',
    traffic_stops:'El tráfico se detiene automaticamente, si está fuera de línea o en un show privado.',
    traffic_stops_sub:'Si tienes una orden en curso y estás fuera de línea o en un show privado, la orden se detendra. Cuando regreses a estar en línea comenzará de nuevo - automaticamente!',
    limit_visitors:'Si desea limitar el número de visitantes en su sala , puede hacerlo!',
    limit_visitors_sub:'En cada perfil puede configurar un número máximo de visitantes en la sala. Si desea establecer un límite para la sala, puede hacerlo en la configuración del perfil',
    no_traffic_package:' Paquete de tráfico no seleccionado.Por favor seleccione su paquete de la lista de abajo',
    extra_large:'Extra largo',
    all_packages:'Todos los paquetes',
    my_favourites:'Mis favoritos',
    no_profiles_selected_boost:'Perfil no selecionado. Por favor seleccione el perfl al que desea impulsar',
    no_profiles_found:'Perfil no encontrado. Por favor agregue el perfil a impulsar',
    no_models_found:' Perfil no encontrado. Por favor  agregue a la modelo, antes de impulsar',
    estimated_viewers:'Vista total estimada de espectadores',
    viewers_per_token:'Espectadores por token',
    pro:'Pro',
    go_to_dashboard: 'Ir a panel de control',
    stay_on_free:'Permanecer en libre',
    please_select_subscription:'Por favor seleccione primero el tipo de suscripción',
    select_subscription_before_dash:'Por favor seleccione el tipo de suscripción antes de mover al panel del control',
    insufficient_funds:'Fondos insuficientes',
    pay_and_boost:'Comprar : precio del token y empezar',
    minimum_payment:'Pago mínimo  es $5 por 50 tokens',
    pay_five:'Pagar $5',
    not_possible_change_plan:'No es posible cambiar el paquete en un pedido. Solo se puede editar el tamaño del paquete',
    see_all_profiles:'Ver todos los perfiles',
    my_studio:'Mi estudio',
    camsite_selected:'{1} 1 sitio de cámara seleccionado |{*} :count del sitio seleccionadas',
    new_easy:'La nueva y fácil manera',
    get_more_viewers:'Obtener más espectadores',
    on_sub_demand:'En suscripción y bajo demanda',
    most_popular:'Más popular',
    viewers_on_sub:'Espectadores suscritos',
    automatic_viewers:'Automaticamente ten más espectadores cuando estés en línea',
    models_from:'Modelos de',
    studios_from:'Estudios de',
    month:'Mes',
    save_two_months:'Ahorre dos meses si se inscribe un año',
    get_viewers_subscr:'Obtener más espectadores en suscribción',
    continuous_traffic:'Tráfico continue habilitado',
    boosting_my_cam:'Impulsar mi cámara',
    automatic_start_stop:'Inicio y pausa automática del tráfico',
    max_visitors_room_limit:'Límite máximo de visitantes en la sala',
    bonus_on_token:'Bono por compra de token',
    viewers_on_demand:'Trasmisiones más populares',
    order_viewers_boost:'Organice las trasmisiones, he impulse la que tenga mas demanda',
    starts_at:'Empezar a',
    pr_token:'por token',
    save_by_purchasing_tokens:'Ahorra comrando más fichas cada vez',
    get_viewers_on_demand: 'Get viewers on demand',
    see_all_pricing:'Ver todas las opciones de precios',
    what_we_offer:'Que ofrecemos',
    our_great_services:' Nuestros garndes servicios',
    get_started_with_diva:'Cominece con DivaTraffic he incrementa el tráfico en tu show. Es super fácil',
    traffic_subscriptions:'Suscripciones de tráfico',
    traffic_subscriptions_sub:'Al suscribirse a DivaTraffic, obtendrá muchos beneficios y funciones adicionales. Tus perfiles recibirán tráfico automáticamente cuando estés en línea.',
    all_subscriptions:'Todas las suscripciones',
    boosting_my_cam_sub:'FINALMENTE,obtener un impulso de tráfico para su show de cámara está a solo un clic de distancia. DivaTraffic está habilitando a las modelos y estudios para atraer fácilmente a mucha gente a tu show.',
    raise_traffic_earning:'Aumenta tu tráfico, aumenta tus ingresos',
    try_it_now:'Inténtalo ahora',
    camhead_sub:'El encabezado describre, tu trasmisión. Detecta cuando estás en línea y disponible,y se encarga que seas visible para la audiencia correcta.',
    empower_yourself:'Empoderate y trae el mundo a ti',
    choose_plan:'Elije tu plan',
    choose_plan_sub:'Hay un plan para cada tamaño y tipo de equipo. Elija el que se adapte a sus demandas de tráfico. Contáctenos si necesita más información.',
    studios:'Estudios',
    subscr_for_multiple:'Suscribciones para multiples modelos',
    studio_subscriptions:'Suscripcion de estudio',
    lots_more:'Y mucho mas!',
    subscr_for_individual:'Suscripción individual para modelos',
    model_subscriptions:'Suscripciones de  modelo',
    platforms:'PLATAFORMAS',
    list_of_supported_camsites:'Nosotros trabajamos con lo mejor, de lo mejor de la industria. Aquí una lista de algunas plataformas que estamos apoyando.',
    and_more:'Y mas.',
    benefit_from:'Beneficios mensuales por tráfico',
    high_quality_traffic:'Alta calidad de tráfico todos los días',
    best_traffic_we_offer:'El mejor tráfico que podemos ofrecer',
    contact_us:'Contáctenos',
    help:'Ayuda',
    en: 'English',
    es: 'Español',
    ru: 'Русский',
    languages:'Idiomas',
    widget_code:'Código del widget',
    widget_code_text:'Seleccione uno o más de los distintos tamaños de widgets y coloque la etiqueta div en la que desea que se muestre en su página.',
    preview:'Vista rápida',
    banner_rectangle:'Banner rectangular',
    banner_small_rectangle:'Banner rectangular pequeño',
    banner_skyscraper:'Banner lateral',
    banner_large_leaderboard:'Banner superior',
    widget_settings:'Configuració del widget',
    no_profiles_selected_widget:'Seleccione perfiles si usted desea mostrar modelos especificas en la herramienta',
    postbacks_desc: 'All parameters set as GET attributes to URL, but depending on selected postback method we\'ll duplicate parameters in request body if it\'s supported by method (e.g. POST)',
    postbacks_ex:'Ejemplo devolución de datos',
    add_new_postback:'Agregar nueva devolución de datos',
    event:'Evento',
    method:'Método',
    enter_postback_url:'Ingrese su devolucón de datos',
    url_parameters:'Parámetros',
    aff_income_amount:'Monto de ingreso del afiliado',
    sub_account_id:'ID de la subcuenta puede utilizarse para especificar el afiliado dentro del sistema de seguimiento de terceros',
    sub_account_id2:'ID de subcuenta 2. Puede usarse para especificar el afiliado dentro del sistema de seguimiento de terceros',
    user_ip_address:'Dirección IP del usuario',
    transaction_type:'Tipo de trasacción',
    admin_subscription:'Suscripción del admisnitrador',
    list_too_long:'Lista demasiado larga',
    traffic_speed:'Velocidad del tráfico',
    slow:'Lento',
    fast:'Rápido',
    slow_speed: 'Más lenta de lo normal',
    normal_speed: 'Normal Speed',
    faster_than_normal: 'Velocidad normal',
    even_faster: 'Aun más rápido',
    faster_than_light: 'Extremo: ¡Las fichas de advertencia volarán! ',
    speed_tooltip:'Esta opción regula la rapidez con la que enviamos el tráfico a su sala. Para algunas cámaras, puede ser una ventaja conseguir que muchas personas se vuelvan más visibles rápidamente, y para otros sitios se prefiere un flujo más lento de espectadores.',
    change:'Cambiar',
    ad_views:' Vista de anuncios',
    traffic_this_month:'Tráfico este mes',
    visit:'Visitas',
    admin_service_status:'Estado del servicio',
    select_camsites:'Seleccionar cámaras',
    done:'Hecho',
    average_bid:'Oferta media',
    traffic_sent:'Tráfico enviado',
    fallback_url:'URL alternativa',
    set_fallback:'Establecer la URL alternativa, para cuando hay modelos en línea',
    enter_url:'ingresar URL',
    saving:'Guaardando',
    configure_default_camsite_settings:'En está página puede configurar los ajustes predetermiandos para cada cámara',
    default_max_viewers:'Espectadores máximos predetermiandos',
    bank_routing_number:'Número de ruta bancaria',
    bank_swift_code:'Código SWIFT del banco',
    zip:'Zip',
    select_payment_method:'Selecionar método de pago',
    enter_your:'Ingrese su',
    your_first_name:'Ingrese su primer nombre',
    your_last_name:'Ingrese su apellido',
    your_address:'Dirección',
    select_country:'Seleccionar país',
    your_bank_vat:'Cuenta bancaria',
    local_area_number:'Número local de área',
    your_city:'Ciudad',
    minimum_payout_amount:'Pago mínimo',
    select_minimum_payout_amount:'Seleccione un monto mínimo de pago.',
    custom_size:'Tamaño personalizado',
    traffic_summary:'Resumen de tráfico',
    subscription_stats:'Estado de la suscripcción',
    ondemand:'Bajo demanda',
    upgrade_subscription:'Actualización de la suscripción',
    upgrade_sub_create_packages:'Actulizar su suscripción para crear su propio paquete de tráfico personalizado',
    no_packages_found:'Paquetes no encontrados',
    view_details:'Ver detalles',
    ondemand_stats:'Estadísticas de tráfico bajo demanda',
    daily_earnings:'Ganancias diarias',
    block:'Bloquear',
    blocked:'Bloqueado',
    subscription_expired:'Su suscripción ha expirado',
    payouts:'Pagos',
    end_period:'Fin del período',
    custom_tracking:'Tráfico personalizado',
    custom_tracking_added:'Tráfico personalizado añadido',
    add_tracking:'Añadir parámetros de tráfico',
    open_rate:'Tarifa inicial',
    opens:'Iniciar',
    additional_settings:'Ajustes adicionales',
    customize_tool:'Personaliza esta herramienta aún más',
    additional_settings_added:'Ajustes adicionales agregados',
    selected:'Seleccionado',
    delay_set:'{1} Retrazo establecido 1 segundo|{*} Retrazo establecido en :count segundos',
    sound_selected:'Sonido seleccionado',
    add_delay:'Puede aregar un retrazo en el encabezado para que se cargue unos segundos despúes de conectarse',
    add_sound:'Puede añadir un mensaje de sonido a esta herramienta que se va reproducir cuando ya haya cargado',
    sound:'Sonido',
    order_count:'Recuento de pedidos',
    filter_by_plan:'Filtro del plan',
    models_per_page:'Modelos por página',
    select_traffic_type:'Seleccionar tipo de tráfico',
    no_traffic_type_selected:'Tipo de tráfico no seleccionado',
    filter_minute:'Minuto',
    filter_hour:'Hora',
    filter_day:'Día',
    no_stats_order:'Estadisticas del pedido no encontradas',
    native_ad_traffic: 'Native Ad Traffic',
    pop_traffic:'Tráfico Pop',
    pop_traffic_description:'El tráfico pop se entrega como popups o popunders de miles de diferentes sitios web para adultos, a través de todos los diferentes intercambios de anuncios y redes de tráfico de los que DivaTraffic recibe su tráfico. Cuando inicie su pedido, su cámara comenzará a mostrarse instantáneamente en muchos de los sitios, hasta que haya recibido la cantidad de tráfico que ha solicitado. <br> Si se desconecta o se hace en privado,su pedido se detendrá automáticamente. hasta que vuelva a estar en línea y en el chat gratis. <br> El tráfico emergente es bueno para obtener mucho tráfico rápido, ya que los usuarios no están haciendo una elección para ir a su habitación, pero se redirigen allí simplemente visitando un sitio web. <br> Tenga en cuenta que los usuarios suelen cerrar las ventanas emergentes de inmediato a menos que el contenido que ven llame su atención en los primeros segundos. Así que te aconsejamos que hagas lo que puedas para llamar su atención y destacar entre la multitud, mientras se envía el tráfico ".',
    native_ad_description:'El tráfico de anuncios nativos se entrega a través de anuncios de imagen con clics que se muestran en miles de sitios web para adultos a través de diferentes intercambios de anuncios y redes de tráfico de las que DivaTraffic recibe su tráfico. Cuando compra tráfico de anuncios nativos, está pagando por las presentaciones de anuncios (impresiones) y los usuarios tienen que hacer clic en su imagen antes de enviarse a su habitación. <br> El anuncio que verán los usuarios será una captura de pantalla en vivo de su habitación , o el perfil que usa en el sitio web, dependiendo del sitio web donde compre el tráfico. A diferencia del tráfico de pop, el tráfico de la pantalla requiere que los usuarios hagan un clic antes de ingresar a su habitación, por lo que no será tan útil en términos de obtener mucho tráfico rápido, pero significa que los usuarios que reciba, habrán tomado la decisión de visitarlo, según la imagen que vean, y debería dar como resultado una mayor calidad de tráfico. <br> No podemos garantizar cuántos harán clic en su imagen, solo cuántas veces se mostrará.',
    pop_traffic_selected:'Tráfico pop seleccionado',
    fallback_options:'Opciones de reserva',
    offline_models_settings:'Ajustes para cuando no hay suficientes modelos en línea',
    relax:'Relajarse',
    repeat:'Repetir',
    relax_description:'Si no hay suficientes modelos en línea para llenar el widget, esta configuración mostrará duplicados de los que están en línea para llenar el resto del espacio. Si no se selecciona ninguna opción, la configuración predeterminada es "relajarse".',
    repeat_description:'Si no hay suficientes modelos en línea para llenar el widget, esta configuración mostrará modelos aleatorias para llenar el resto del espacio. Seguirá ganando comisiones de afiliación de todas las ventas generadas por el widget. Si no se selecciona ninguna opción, esta es la configuración predeterminada.',
    select_gender:'Seleccionar género',
    widget_documentation:'Documentacion del widget',
    widget_docu_text: 'You can customize the widget yourself using data attributes on the div elements in your html page that you want to use as a cam widget.<br>The following data attributes are allowed on div elements.',
    attribute_name: 'Attribute name',
    values:'Valores',
    width_in_pixels:'Ancho del pixel',
    height_in_pixels:'Altura del pixel',
    thumb_sizes:'Tamañaos miniatura',
    data_platform_desc:'Nombre del sitio web a mostrar. Separador de coma y primera letra del nombre en mayúscula',
    leave_empty_auto:'Dejar vacío para auto',
    data_gender_desc:'Cual género priorizar',
    data_theme_desc:'Cual tema usar',
    more_coming_soon:'Más próximanmente',
    source:'Fuente',
    maxviewers_unavailable:'Sala nodisponible, max espectadores',
    maxviewers_unavailable_for:'No está disponible max espectadores por:',
    tokens_added_to_account:'Los tokens fueron añadidos a su cuenta',
    tokens_will_be_added_to_account: 'Los tokens se agregarán a tu cuenta en pocos minutos',
    all_camsites:'Todos los sitios web',
    ondemand_aff_commission:'Comisión de afiliado bajo demanda',
    promo_tool_aff_commission:'Promo herramientas para la comisión del afiliado',
    native_cam_widgets:' Widgets cámaras nativas',
    wl_activated:'Whitelabel activo',
    enter_name_wl:'Ingrese el nombre del whitelabel',
    enter_domain_wl:'Ingrese el dominio de whitelabel',
    wl_title:'Título del whitelabel',
    wl_domain:'Dominio de whitelabel',
    send_to_whitelabel:'Enivar :plataforma de tráfico para whitelabel',
    deleting:'Eliminando',
    delete_failed:'Eliminación fallida',
    save_wl_failed:'Guardando whitelabel fallida',
    create_wl_failed:'Creando whitelabel fallida',
    traffic_last_14_days:'Tráfico últimos 14 días',
    feature_require_pro_subscription:'Esta carácteristica ,requiere suscripción pro',
    info_about_aff_key:'Si ingresa su clave de afiliado para: plataforma en el campo a la izquierda, entonces el tráfico que compre se enviará a través de su propio enlace de afiliado. Tenga en cuenta que esto significa que obtendrá una comisión de afiliación a través de: plataforma y no en DivaTraffic y no podrá ver ninguna estadística sobre ventas, registros o renovaciones en su página de estadísticas de DivaTraffic <br> <a target="_blank" href="https://divatraffic.zendesk.com/hc/en-us/sections/360001373174-How-to-configure-affiliate-keys"> Click aquí si quiere leer más de cómo encontrar su afilición',
    max_viewers_info: 'The Max Viewers setting lets you specify what the max amount of viewers you want to have in the room when you are buying traffic on demand. Traffic will automatically stop being sent to the room when the viewer count reaches the max you have specified, and will resume when you have less than this',
    whitelabel_info_text: 'When enabling whitelabel setting for :platform, all traffic you buy on demand will be sent to your whitelabel instead of :platform. Keep in mind that this means that you will not be able to get any earnings or statistics about registrations, sales or rebills in DivaTraffic, and you will be paid affiliate commission directly from :platform',
    email_address:'Dirección de correo electronico',
    register_here:'Regístrate aquí',
    affiliate_signup:'Registro de afiliados',
    back_to_login:'Atrás para iniciar sesión',
    no_subscription_text:'Sin suscripcción, sólo tendrá acceso a nosotros',
    profiles_indexed:'Perfil clasificado en diva.network',
    promotools_commission:'Promo herramientas de comisión de afiliados',
    whitelabel_aff_commission:'Comisión al afiliado whitelabel',
    studio_featured_in_aff_program:'Estudio presentado en el programa de afiliados',
    profiles_shown_in_tools:'Sus perfiles se mostrarán en su programa de afiliado',
    camwidgets:'Cámara de widgets',
    profiles_featured:'Perfiles rotados en nuestro progama de afiliados',
    admin_camsites:'Administración sitios web',
    available:'Disponible',
    add_camsite:'Agregar sitio web',
    room_url:'URL de la habitación',
    embed_url:'Insertar URL',
    embed_code:'Imsertar código',
    api_url:'API URL',
    api_listfinder:'Lista de buscador API',
    api_valuefinder:'Buscador de valor API',
    api_transformers: 'API transformers',
    program_url:'Programa  URL',
    invite_link:'Enlace de invitación',
    invite_link_desc:'Utilice este enlace para invitar usuarios en DivaTraffic y gane comisión basado en su gasto',
    invite_link_description:'¿Tiene algún amigo que piense que podría beneficiarse del uso de DivaTraffic? Envíeles el enlace de invitación y gane una comisión por cada persona que invite a DivaTraffic.Ganará una comisión del 10% por cada persona que invite que compre una suscripción en DivaTraffic. Sus ganancias se pagarán después de que se renueve o caduque cada suscripción, y recibirá sus ganancias pagadas en tokens que se agregarán al saldo de su cuenta.',
    invited_users:'Sus usuarios invitados',
    link_to:'Enlace para',
    frontpage:'Página principal',
    register_page:'Página de registro',
    model_register:'Página de registro modelos',
    studio_register:'Página de estudio de registro',
    affiliate_register:'Página de registro de afiliados',
    invite_redirect:'Seleccione dónde desea que el enlace de la invitación se redirija a',
    select_camsite_program:'Vaya a la configuración del sitio web para ver que programa usar en cada sitio.',
    delete_platform_text:'Está seguro que quiere eliminar :título ?',
    mobile_url:'URL móvil',
    resources:'Recursos',
    read_more:'Leer más',
    ynot_awards:'Los YNOT Cam Awards destacan a las mujeres y hombres que actuan interactivamente en la comunidad adulta.',
    bucharest_awards:'La gala de los premios se lleva a cabo en Bucharest Summit cada año, y reconoce a los mejores estudios, modelos sitios web profesionales',
    lovense:'Lovense es un fabricante de juguetes sexuales conocido por sus juguetes sexuales inteligentes, que se pueden controlar a través de Bluetooth mediante una aplicación móvil.',
    community:'Comunidad',
    toys_equipment:'Juguetes y equipo',
    events:'Eventos',
    awards:'Premios',
    software:'Software',
    content:'Contenido',
    stripperweb:'Foro para strippers y otros miembros de la industria adulta. Tiene una sección para la discusión relacionada con la cámara.',
    ynot_network:'Redes sociales completas con noticias, foros, mensajes, fotos, videos, anuncios clasificados y más.',
    xbiz:'Foro B2B para la industria adulta. Encontrará propietarios de sitios, productores de contenido, proveedores de soluciones de tecnología, afiliados, modelos y más en XBIZ.',
    wecamgirls:'WeCamgirls es una comunidad donde los cammodels pueden hablar con sus colegas de negocios adultos. Un lugar seguro para interactuar, discutir y conectarse, sin que los usuarios echen un vistazo. También puede intercambiar consejos y trucos o revisar todo tipo de negocios relacionados con cámaras.',
    ambercutie:'AmberCutie es una chica profesional que dirige su propio foro. Es una gran lugar  para conectarse con otras camgirls, pedir consejos y compartir opiniones, pensamientos y estrategias.',
    ohmibod:'OhMiBod es el creador del vibrador original para iPod®. Con sede central en New Hampshire, EE. UU., La compañía de propiedad y gestión femenina está impulsando la evolución de la industria del placer con una amplia gama de productos de alta calidad y seguros para el cuerpo que combinan tecnología y sensualidad.',
    kiiroo:'Kiiroo es una empresa de diseño de juguetes sexuales de alta tecnología que brinda la posibilidad de sentir a otra persona de una manera completamente nueva.',
    terpon:'Terpon es una empresa de fabricación de cámaras e interfaces digitales que se especializa en realidad virtual y tecnología totalmente inmersiva para la industria del entretenimiento para adultos.',
    aw_summit:'AWsummit es un evento donde puedes aprender y relacionarte conlos mejores y más brillantes de nuestra industria. AWSummit no es un espectáculo común, es la mejor experiencia de negocios y conferencias.',
    lalexpo:'El programa definitivo de B2B para adultos de América Central y del Sur. El único programa en vivo de premios de cámaras en América del Sur. Conozca un Nuevo mercado total y sea parte del único Show de negocios en la industria para adultos para profesionales que también tienen más de 600 modelos de estudios y cámaras web del mundo.',
    bucharest_summit:'Bucarest Summit, naturalmente, tendrá lugar en bucarest debido a qué la capital rumana está en el punto caliente de la industria,están orgullosos por ser los primeros en el negocio, por sus talentosas modelos y sus conocimientos innovadores. Los oradores mas experimentados enseñaran sus secretos  y compartirán su experiencia en diversos temas.',
    live_cam_awards:'Live Cam Awards es un espectáculo anual de premios que se lanzó por primera vez en 2015, siendo en ese momento el primer espectáculo de premios para honrar exclusivamente a los artistas y compañías que sirven al ahora altamente competitivo negocio de cámaras web.',
    webcam_startup:'Webcam Startup es el sitio de recursos de modelos más grande de Internet. Webcam Startup aporta una gran cantidad de conocimiento de una amplia gama de colaboradores, cada uno con su propia experiencia y conocimiento respectivo. Además, todas las noticias y anuncios de la industria.',
    aw_news:'AW News es la fuente de noticias más grande de la industria de cámaras en vivo. Es un proveedor de las noticias más  recientes en el negocio  de cámaras en vivo',
    manycam:'ManyCam es un software gratuito de cámara web y divisor de video  que permite mejorar los chats de video y crear increíbles transmisiones en vivo en múltiples plataformas al mismo tiempo',
    loomly:'Loomly es una sencilla herramienta de calendario de redes sociales',
    hootsuite:'Hootsuite es una plataforma de gestión de redes sociales. La interfaz de usuario del sistema adopta la forma de un panel de control y admite integraciones de redes sociales para Twitter,Facebook,Instagram,LinkedIn,Google+ y YouTube',
    modelcentro:'ModelCentro es una plataforma que ayuda a monetizar y hacer crecer su base de fans existente en ingresos mensuales recurrentes. Es  100$ GRATUITO para configurar un sitio de fans con pago personal',
    manyvids:'ManyVids.com es una plataforma diseñada específicamente para ayudar MV starts y generar un sano y pasivo ingreso. MV es una "ventanilla única" dónde tus fans, van a encontrar todo lo que tienes para ofrecer',
    clips4sale:'Clips4Sale (C4S) es un sitio web estadounidense de venta de contenido de videos para adultos. Es conocido por su contenido fetiche y fue el primer sitio de clips descargable de su tipo.',
    pineapple_support:'Pineapple proporciona un sistema de apoyo emocional 24 /7 para todos los artistas y productores de la industria',
    ynot_cam:'YNOT Cam es una revista en línea que ofrece consejos y sugerencias para las modelos',
    infinity_telecom:'Infinity Telecom proporciona internet y equipos de cámara web para estudios en Rumania',
    bcams:'BCams reúne sitios web, estudios, modelos independientes y toda una gama de negocios conectados, desde empresas fiscales y de IT hasta fotografías, maquilladores, diseñadores de interiores y profesionales de marketing. BCAMS es una gran oportunidad para establecer redes en un entorno amigable y neutral.',
    aw_awards:'Los AW Awards and Conference es uno de los espectáculos y eventos más grandes del mundo. Los AW Awards reconocen a los mejores en la industria de las cámaras, incluidos modelos, estudios y empresas.',
    onelink:'Si tuvieras la oportunidad,OneLink , lo usaría o simplemente lo omitiría ? <br/>  Nunca vuelvas a cambiar el enlace de tu biografía. OneLink te hace enlazar a todas tus cosas en línea en una página simple',
    resources_welcome:' Bienvenido',
    resource_welcome_text:'Para tener éxito en la industria de las cámaras en vivo como un estudio o una modelo, es importante utilizar las herramientas y los servicios adecuados que le permitan trabajar con mayor eficacia. En estas páginas, hemos recopilado una colección de servicios recomendados que lo ayudarán a alcanzar sus objetivos de manera más efectiva y  aumentar sus posibilidades de tener éxito con su negocio. Los servicios se ofrecen en una variedad de categorías que incluyen comunidades, medios de noticias y eventos donde puede reunirse y aprender de otros profesionales de la industria, a herramientas y servicios recomendados que le permiten aumentar sus ganancias al hacer dinero mientras está desconectado, o mejore su alcance en las redes sociales, así como dónde encontrar los productos y equipos adecuados que necesita para convertirse en un artista o estudio profesional. <br/> Si tiene un producto o servicio que desea que le recomendemos, por favor contáctenos en <a style="font-size: 18px" href="mailto:support@divatraffic.com"> support@divatraffic.com </a> y háganoslo saber.',
    max_daterange:'El rango máximo de fechas es de 31 días. Por favor seleccione una gama diferente',
    europeansummit:'Fundada en 2009, The European Summit reúne a profesionales de la industria del entretenimiento en línea, comercio electrónico, finanzas, juegos, apuestas, citas, forex, opciones binarias, salud, nutracéuticos y muchos otros para un evento intensivo de redes de 3 días.',
    reset_email_sent:'Se ha enviado un correo electrónico con un enlace de restablecimiento de contraseña a su dirección. Si no recibe un correo electrónico dentro de unos minutos, asegúrese de revisar su carpeta de correo no deseado',
    reset_token:'Reiniciar token',
    token:'Token',
    reset_success:'Su contraseña ha sido restablecida',
    loading_packages:'Cargando paquetes',
    email_not_verified:'Su correo no ha sido verificado',
    click_to_verify:'Click aquí para verificar su cuenta',
    email_verification_required:'Verificación del correo requerida',
    verification_text:'Se ha enviado un correo electrónico de verificación a <span style="color: #4D96D1">: correo electrónico </span>. Por favor revise su bandeja de entrada para un correo electrónico con el asunto "Verificación de correo electrónico DivaTraffic". Si no puede encontrar el correo electrónico, asegúrese de revisar su carpeta de correo no deseado. Si no recibió el correo electrónico, puede reenviar el correo electrónico de verificación desde aquí.',
    resend_email:'Reenviar correo',
    wrong_email:'Si ingresó la dirección de correo electrónico incorrecta, <a href="settings/account" style="font-weight: 600"> haga clic aquí </a> para actualizar su dirección de correo electrónico.',
    bidinfo:'Información de la oferta',
    bidinfo_text:' La oferta es la cantidad que pafa por cada 1000 visitantes que coinciden con los filtros de orientación que ha especificado anteriormente. El tráfico es comprado en tiempo real y funciona como una subasta en la que gana la oferta más alta para cada usuario. Una oferta baja hace que el tráfico sea más barato, pero puede que no rfeciba muchas ofertas y que no reciba mucho tráfico. Una oferta más alta aumenta la posibilidad que gane pero también que aumente el tráfico.',
    price_pr_1000:'Precio por 1000 usuarios',
    estimated_traffic_pr_token:'Tráfico estimado por token',
    verified: 'Verificado',
    not_verified: 'No verificado',
    email_status: 'Estado del correo electrónico',
    extralunchmoney: 'ExtraLunchMoney.com (ELM) es un mercado digital para compradores que interactúan directamente con creadores de contenido para adultos. Si bien el enfoque del sitio es la pornografía amateur, los clips fetish personalizados y el contenido para adultos aficionados, damos la bienvenida a una amplia gama de contenido para adultos que incluye audio y trabajos escritos de aficionados y profesionales. Real porno amateur y contenido personalizado para adultos directamente de los propios artistas.',
    invite_friend: 'Invita a un amigo',
    total_so_far: 'Total hasta ahora',
    select_affiliate_program: 'Aquí puede seleccionar el programa de afiliación al que desea enviar el tráfico.',
    immediately: 'Inmediatamente',
    auto_renew_order: 'Renovar automáticamente este pedido cuando haya finalizado.',
    renewal_time: 'Establecer el tiempo entre renovaciones',
    number_of_renewals: 'Renovaciones totales ',
    autorenew_description: 'Aviso: Al marcar la opción de renovación automática, su pedido se reiniciará automáticamente una vez que haya finalizado, y la cantidad de tokens que costará su pedido se cargará de su saldo de token. Tenga en cuenta que si selecciona un paquete que entrega tráfico rápidamente y elige un intervalo de tiempo corto, podría terminar gastando sus tokens muy rápidamente. La orden <span style="font-weight: 600">NO</span> se renovará si te quedas sin tokens o si la orden se detiene o cancela.',
    renews_minutes: '{1} Se renueva en 1 minuto. | {*} Se renueva en :count minutos',
    renews_hours: 'Se renueva en :count horas',
    notification_option: 'Opciones de notificación',
    configure_email_notifications: 'Configure cuándo recibirá notificaciones por correo electrónico',
    balance_low: 'El saldo de mi cuenta se está reduciendo.',
    auto_renew_insufficient_funds: 'Cuando no tengo fondos suficientes para la renovación automática',
    subscription_expires: 'Cuando caduque mi suscripción',
    order_renewed: 'Cuando se renueva un pedido.',
    earnings_payout: 'Cuando obtengo ganancias',
    updates_to_divatraffic: 'Cuando hay nuevas actualizaciones o cambios a DivaTraffic',
    renews_any_moment: 'Renueva en cualquier momento',
    initializing_order: 'Inicializando el pedido, el tráfico será enviado en breve .. por favor espere ...',
    pop_ads: 'Los anuncios pop se envían como popups o popunders de miles de sitios web para adultos diferentes, a través de todos los diferentes intercambios de anuncios y redes de tráfico de los que DivaTraffic recibe su tráfico. Los anuncios pop son buenos para obtener mucho tráfico rápido, ya que los usuarios no están haciendo una elección para ir a la sala de cámaras, pero son redirigidos allí simplemente visitando un sitio web.',
    native_ads: 'Los anuncios nativos se envían a través de anuncios gráficos con clics que se muestran en miles de sitios web para adultos diferentes a través de diferentes intercambios de anuncios y redes de tráfico de las que DivaTraffic recibe su tráfico.',
    native_ad_traffic_selected: 'Tráfico de anuncios nativo seleccionado',
    premium_plan: 'Plan premium',
    deluxe_plan: 'Plan pro',
    free_plan: 'Plan gratis',
    basic_plan: 'Plan basico',
    edituser: 'Editar usuario',
    admin_users: 'Usuarios admin',
    package_youtube: 'Haga clic aquí para ver un tutorial de YouTube sobre cómo crear paquetes',
    watch_tutorial: 'Ver el tutorial',
    download: 'Descargar',
    show_in_ads: 'Mostrar en anuncios',
    show_in_ads_details: 'Los perfiles se promocionarán en anuncios de afiliados y en diva.network',
    validation_error: 'Error de validacion',
    studio_orders: 'Pedidos de estudio',
    model_orders: 'Pedidos de modelos',
    nothing_found: 'Nada Encontrado',
    page_not_found: 'Página no encontrada',

    // Sorting
    sorting: 'Clasificación',
    name_az: 'Nombre A-Z',
    name_za: 'Nombre Z-A',
    viewers_per_token_09: 'Espectadores por token 0-9',
    viewers_per_token_90: 'Espectadores por token 9-0',
    newest_first: 'Fecha: reciente a antiguo(a)',
    oldest_first: 'Fecha: antiguo(a) a reciente',
    online_offline: 'Conectado / Desconectado',
    online_only: 'Excluir fuera de línea', //exclude offline
    include_deactivated: 'Incluir desactivado',

    // Content
    load_more: 'Carga más',
    loading: 'Cargando',
    choose_file: 'Elija el archivo',

    // /links
    supported_sites_text: 'Los siguientes sitios son compatibles. Si desea recomendar un nuevo sitio, por favor contáctenos.',
    links_info_text: 'Con "Impulsar mi enlace" puede comprar tráfico a sus perfiles y contenido en sitios de clips, sitios de suscripción y otros sitios web basados ​​en contenido que le permiten vender sus fotos, videos y tener perfiles. Ahora puede comprar tráfico a dichos sitios, lo mismo forma en que puede comprar tráfico a sus perfiles de cámara en vivo.',
    read_more_about_links: 'Leer más sobre enlaces',
    url: 'URL',
    this_account: 'Esta cuenta',
    link_protected_warning: 'Este dominio bloquea nuevos usuarios con captcha y puede dar números mucho más bajos que otros',
}
