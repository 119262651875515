import CamsiteService from '../../service/camsite/camsite.service'

const camsiteService = new CamsiteService();

const state = {
    camsites: null,
}

const mutations = {
    SET_CAMSITES: (state, payload) => {
        state.camsites = payload;
    },
}

const actions = {
    get: ({ commit }) => {
        if (!state.camsites) {
            return camsiteService.getCamsites(commit);
        }
        return Promise.resolve();
    },
    forceGet: ({ commit }) => {
        return camsiteService.getCamsites(commit);
    },
}

const getters = {
    getCamsites(state) {
        if (Array.isArray(state.camsites)) {
            return state.camsites.filter(p=> {
                return p.type==1;
            });
        }
        return [];
    },
    getClipsites(state) {
        if (Array.isArray(state.camsites)) {
            return state.camsites.filter(p=> {
                return p.type==2;
            });
        }
        return [];
    },
    getCamsite: (state) => (id) => {
        if (state.camsites) {
            let c = state.camsites.data.find(c => {
                return c.id == id;
            })
            return c;
        }
        return { title: "" }
    },
    getCamsitesForUser(state, getters, rootState, rootGetters) {
        if (!state.camsites || !rootGetters['Profile/getProfiles']) {
            return [];
        }
        
        let camsitesIds = [];
        for (var i in rootGetters['Profile/getProfiles']){
            let id = rootGetters['Profile/getProfiles'][i].performer.data.platform_id;
            if (camsitesIds.indexOf(id) < 0) {
                camsitesIds.push(id);
            }
        }

        let camsites = state.camsites.data.filter(c => {
            return camsitesIds.indexOf(c.id) >= 0;
        });

        return camsites;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}