import AbstractStore from "@/store/AbstractStore";

const state = {
  baseStateListName: 'countries',
  countries: {
    data: [],
    loading: false,
  }
};

const mutations = {
  SET_COUNTRIES: (state, payload) => {
    state.countries.data = payload;
  }
};

const actions = {
  get: ({ dispatch }) => {
    return dispatch("Dictionary/get", null, {root: true})
  }
};

const getters = {
  getCountries: (state) => {
    if (state?.countries?.data) {
      return state.countries.data;
    } else {
      return [];
    }
  },
  getCountryById: state => id => {
    return state.countries.data.find(c => {
      return id == c.id;
    })
  },
  getCountryCodes() {
    return staticData.countryCodes
  },
  getAllCountryCodesForMap() {
    var codes = {};
    staticData.countryCodes.forEach(c => {
      codes[c['alpha-3']] = 2000
    }); return codes;
  }
};

export default AbstractStore.mergeInto({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
});

const staticData = {
  countryCodes: [
    {
      "alpha-2": "AF",
      "alpha-3": "AFG",
    },
    {
      "alpha-2": "AX",
      "alpha-3": "ALA",
    },
    {
      "alpha-2": "AL",
      "alpha-3": "ALB",
    },
    {
      "alpha-2": "DZ",
      "alpha-3": "DZA",
    },
    {
      "alpha-2": "AS",
      "alpha-3": "ASM",
    },
    {
      "alpha-2": "AD",
      "alpha-3": "AND",
    },
    {
      "alpha-2": "AO",
      "alpha-3": "AGO",
    },
    {
      "alpha-2": "AI",
      "alpha-3": "AIA",
    },
    {
      "alpha-2": "AQ",
      "alpha-3": "ATA",
    },
    {
      "alpha-2": "AG",
      "alpha-3": "ATG",
    },
    {
      "alpha-2": "AR",
      "alpha-3": "ARG",
    },
    {
      "alpha-2": "AM",
      "alpha-3": "ARM",
    },
    {
      "alpha-2": "AW",
      "alpha-3": "ABW",
    },
    {
      "alpha-2": "AU",
      "alpha-3": "AUS",
    },
    {
      "alpha-2": "AT",
      "alpha-3": "AUT",
    },
    {
      "alpha-2": "AZ",
      "alpha-3": "AZE",
    },
    {
      "alpha-2": "BS",
      "alpha-3": "BHS",
    },
    {
      "alpha-2": "BH",
      "alpha-3": "BHR",
    },
    {
      "alpha-2": "BD",
      "alpha-3": "BGD",
    },
    {
      "alpha-2": "BB",
      "alpha-3": "BRB",
    },
    {
      "alpha-2": "BY",
      "alpha-3": "BLR",
    },
    {
      "alpha-2": "BE",
      "alpha-3": "BEL",
    },
    {
      "alpha-2": "BZ",
      "alpha-3": "BLZ",
    },
    {
      "alpha-2": "BJ",
      "alpha-3": "BEN",
    },
    {
      "alpha-2": "BM",
      "alpha-3": "BMU",
    },
    {
      "alpha-2": "BT",
      "alpha-3": "BTN",
    },
    {
      "alpha-2": "BO",
      "alpha-3": "BOL",
    },
    {
      "alpha-2": "BQ",
      "alpha-3": "BES",
    },
    {
      "alpha-2": "BA",
      "alpha-3": "BIH",
    },
    {
      "alpha-2": "BW",
      "alpha-3": "BWA",
    },
    {
      "alpha-2": "BV",
      "alpha-3": "BVT",
    },
    {
      "alpha-2": "BR",
      "alpha-3": "BRA",
    },
    {
      "alpha-2": "IO",
      "alpha-3": "IOT",
    },
    {
      "alpha-2": "BN",
      "alpha-3": "BRN",
    },
    {
      "alpha-2": "BG",
      "alpha-3": "BGR",
    },
    {
      "alpha-2": "BF",
      "alpha-3": "BFA",
    },
    {
      "alpha-2": "BI",
      "alpha-3": "BDI",
    },
    {
      "alpha-2": "CV",
      "alpha-3": "CPV",
    },
    {
      "alpha-2": "KH",
      "alpha-3": "KHM",
    },
    {
      "alpha-2": "CM",
      "alpha-3": "CMR",
    },
    {
      "alpha-2": "CA",
      "alpha-3": "CAN",
    },
    {
      "alpha-2": "KY",
      "alpha-3": "CYM",
    },
    {
      "alpha-2": "CF",
      "alpha-3": "CAF",
    },
    {
      "alpha-2": "TD",
      "alpha-3": "TCD",
    },
    {
      "alpha-2": "CL",
      "alpha-3": "CHL",
    },
    {
      "alpha-2": "CN",
      "alpha-3": "CHN",
    },
    {
      "alpha-2": "CX",
      "alpha-3": "CXR",
    },
    {
      "alpha-2": "CC",
      "alpha-3": "CCK",
    },
    {
      "alpha-2": "CO",
      "alpha-3": "COL",
    },
    {
      "alpha-2": "KM",
      "alpha-3": "COM",
    },
    {
      "alpha-2": "CG",
      "alpha-3": "COG",
    },
    {
      "alpha-2": "CD",
      "alpha-3": "COD",
    },
    {
      "alpha-2": "CK",
      "alpha-3": "COK",
    },
    {
      "alpha-2": "CR",
      "alpha-3": "CRI",
    },
    {
      "alpha-2": "CI",
      "alpha-3": "CIV",
    },
    {
      "alpha-2": "HR",
      "alpha-3": "HRV",
    },
    {
      "alpha-2": "CU",
      "alpha-3": "CUB",
    },
    {
      "alpha-2": "CW",
      "alpha-3": "CUW",
    },
    {
      "alpha-2": "CY",
      "alpha-3": "CYP",
    },
    {
      "alpha-2": "CZ",
      "alpha-3": "CZE",
    },
    {
      "alpha-2": "DK",
      "alpha-3": "DNK",
    },
    {
      "alpha-2": "DJ",
      "alpha-3": "DJI",
    },
    {
      "alpha-2": "DM",
      "alpha-3": "DMA",
    },
    {
      "alpha-2": "DO",
      "alpha-3": "DOM",
    },
    {
      "alpha-2": "EC",
      "alpha-3": "ECU",
    },
    {
      "alpha-2": "EG",
      "alpha-3": "EGY",
    },
    {
      "alpha-2": "SV",
      "alpha-3": "SLV",
    },
    {
      "alpha-2": "GQ",
      "alpha-3": "GNQ",
    },
    {
      "alpha-2": "ER",
      "alpha-3": "ERI",
    },
    {
      "alpha-2": "EE",
      "alpha-3": "EST",
    },
    {
      "alpha-2": "SZ",
      "alpha-3": "SWZ",
    },
    {
      "alpha-2": "ET",
      "alpha-3": "ETH",
    },
    {
      "alpha-2": "FK",
      "alpha-3": "FLK",
    },
    {
      "alpha-2": "FO",
      "alpha-3": "FRO",
    },
    {
      "alpha-2": "FJ",
      "alpha-3": "FJI",
    },
    {
      "alpha-2": "FI",
      "alpha-3": "FIN",
    },
    {
      "alpha-2": "FR",
      "alpha-3": "FRA",
    },
    {
      "alpha-2": "GF",
      "alpha-3": "GUF",
    },
    {
      "alpha-2": "PF",
      "alpha-3": "PYF",
    },
    {
      "alpha-2": "TF",
      "alpha-3": "ATF",
    },
    {
      "alpha-2": "GA",
      "alpha-3": "GAB",
    },
    {
      "alpha-2": "GM",
      "alpha-3": "GMB",
    },
    {
      "alpha-2": "GE",
      "alpha-3": "GEO",
    },
    {
      "alpha-2": "DE",
      "alpha-3": "DEU",
    },
    {
      "alpha-2": "GH",
      "alpha-3": "GHA",
    },
    {
      "alpha-2": "GI",
      "alpha-3": "GIB",
    },
    {
      "alpha-2": "GR",
      "alpha-3": "GRC",
    },
    {
      "alpha-2": "GL",
      "alpha-3": "GRL",
    },
    {
      "alpha-2": "GD",
      "alpha-3": "GRD",
    },
    {
      "alpha-2": "GP",
      "alpha-3": "GLP",
    },
    {
      "alpha-2": "GU",
      "alpha-3": "GUM",
    },
    {
      "alpha-2": "GT",
      "alpha-3": "GTM",
    },
    {
      "alpha-2": "GG",
      "alpha-3": "GGY",
    },
    {
      "alpha-2": "GN",
      "alpha-3": "GIN",
    },
    {
      "alpha-2": "GW",
      "alpha-3": "GNB",
    },
    {
      "alpha-2": "GY",
      "alpha-3": "GUY",
    },
    {
      "alpha-2": "HT",
      "alpha-3": "HTI",
    },
    {
      "alpha-2": "HM",
      "alpha-3": "HMD",
    },
    {
      "alpha-2": "VA",
      "alpha-3": "VAT",
    },
    {
      "alpha-2": "HN",
      "alpha-3": "HND",
    },
    {
      "alpha-2": "HK",
      "alpha-3": "HKG",
    },
    {
      "alpha-2": "HU",
      "alpha-3": "HUN",
    },
    {
      "alpha-2": "IS",
      "alpha-3": "ISL",
    },
    {
      "alpha-2": "IN",
      "alpha-3": "IND",
    },
    {
      "alpha-2": "ID",
      "alpha-3": "IDN",
    },
    {
      "alpha-2": "IR",
      "alpha-3": "IRN",
    },
    {
      "alpha-2": "IQ",
      "alpha-3": "IRQ",
    },
    {
      "alpha-2": "IE",
      "alpha-3": "IRL",
    },
    {
      "alpha-2": "IM",
      "alpha-3": "IMN",
    },
    {
      "alpha-2": "IL",
      "alpha-3": "ISR",
    },
    {
      "alpha-2": "IT",
      "alpha-3": "ITA",
    },
    {
      "alpha-2": "JM",
      "alpha-3": "JAM",
    },
    {
      "alpha-2": "JP",
      "alpha-3": "JPN",
    },
    {
      "alpha-2": "JE",
      "alpha-3": "JEY",
    },
    {
      "alpha-2": "JO",
      "alpha-3": "JOR",
    },
    {
      "alpha-2": "KZ",
      "alpha-3": "KAZ",
    },
    {
      "alpha-2": "KE",
      "alpha-3": "KEN",
    },
    {
      "alpha-2": "KI",
      "alpha-3": "KIR",
    },
    {
      "alpha-2": "KP",
      "alpha-3": "PRK",
    },
    {
      "alpha-2": "KR",
      "alpha-3": "KOR",
    },
    {
      "alpha-2": "KW",
      "alpha-3": "KWT",
    },
    {
      "alpha-2": "KG",
      "alpha-3": "KGZ",
    },
    {
      "alpha-2": "LA",
      "alpha-3": "LAO",
    },
    {
      "alpha-2": "LV",
      "alpha-3": "LVA",
    },
    {
      "alpha-2": "LB",
      "alpha-3": "LBN",
    },
    {
      "alpha-2": "LS",
      "alpha-3": "LSO",
    },
    {
      "alpha-2": "LR",
      "alpha-3": "LBR",
    },
    {
      "alpha-2": "LY",
      "alpha-3": "LBY",
    },
    {
      "alpha-2": "LI",
      "alpha-3": "LIE",
    },
    {
      "alpha-2": "LT",
      "alpha-3": "LTU",
    },
    {
      "alpha-2": "LU",
      "alpha-3": "LUX",
    },
    {
      "alpha-2": "MO",
      "alpha-3": "MAC",
    },
    {
      "alpha-2": "MG",
      "alpha-3": "MDG",
    },
    {
      "alpha-2": "MW",
      "alpha-3": "MWI",
    },
    {
      "alpha-2": "MY",
      "alpha-3": "MYS",
    },
    {
      "alpha-2": "MV",
      "alpha-3": "MDV",
    },
    {
      "alpha-2": "ML",
      "alpha-3": "MLI",
    },
    {
      "alpha-2": "MT",
      "alpha-3": "MLT",
    },
    {
      "alpha-2": "MH",
      "alpha-3": "MHL",
    },
    {
      "alpha-2": "MQ",
      "alpha-3": "MTQ",
    },
    {
      "alpha-2": "MR",
      "alpha-3": "MRT",
    },
    {
      "alpha-2": "MU",
      "alpha-3": "MUS",
    },
    {
      "alpha-2": "YT",
      "alpha-3": "MYT",
    },
    {
      "alpha-2": "MX",
      "alpha-3": "MEX",
    },
    {
      "alpha-2": "FM",
      "alpha-3": "FSM",
    },
    {
      "alpha-2": "MD",
      "alpha-3": "MDA",
    },
    {
      "alpha-2": "MC",
      "alpha-3": "MCO",
    },
    {
      "alpha-2": "MN",
      "alpha-3": "MNG",
    },
    {
      "alpha-2": "ME",
      "alpha-3": "MNE",
    },
    {
      "alpha-2": "MS",
      "alpha-3": "MSR",
    },
    {
      "alpha-2": "MA",
      "alpha-3": "MAR",
    },
    {
      "alpha-2": "MZ",
      "alpha-3": "MOZ",
    },
    {
      "alpha-2": "MM",
      "alpha-3": "MMR",
    },
    {
      "alpha-2": "NA",
      "alpha-3": "NAM",
    },
    {
      "alpha-2": "NR",
      "alpha-3": "NRU",
    },
    {
      "alpha-2": "NP",
      "alpha-3": "NPL",
    },
    {
      "alpha-2": "NL",
      "alpha-3": "NLD",
    },
    {
      "alpha-2": "NC",
      "alpha-3": "NCL",
    },
    {
      "alpha-2": "NZ",
      "alpha-3": "NZL",
    },
    {
      "alpha-2": "NI",
      "alpha-3": "NIC",
    },
    {
      "alpha-2": "NE",
      "alpha-3": "NER",
    },
    {
      "alpha-2": "NG",
      "alpha-3": "NGA",
    },
    {
      "alpha-2": "NU",
      "alpha-3": "NIU",
    },
    {
      "alpha-2": "NF",
      "alpha-3": "NFK",
    },
    {
      "alpha-2": "MK",
      "alpha-3": "MKD",
    },
    {
      "alpha-2": "MP",
      "alpha-3": "MNP",
    },
    {
      "alpha-2": "NO",
      "alpha-3": "NOR",
    },
    {
      "alpha-2": "OM",
      "alpha-3": "OMN",
    },
    {
      "alpha-2": "PK",
      "alpha-3": "PAK",
    },
    {
      "alpha-2": "PW",
      "alpha-3": "PLW",
    },
    {
      "alpha-2": "PS",
      "alpha-3": "PSE",
    },
    {
      "alpha-2": "PA",
      "alpha-3": "PAN",
    },
    {
      "alpha-2": "PG",
      "alpha-3": "PNG",
    },
    {
      "alpha-2": "PY",
      "alpha-3": "PRY",
    },
    {
      "alpha-2": "PE",
      "alpha-3": "PER",
    },
    {
      "alpha-2": "PH",
      "alpha-3": "PHL",
    },
    {
      "alpha-2": "PN",
      "alpha-3": "PCN",
    },
    {
      "alpha-2": "PL",
      "alpha-3": "POL",
    },
    {
      "alpha-2": "PT",
      "alpha-3": "PRT",
    },
    {
      "alpha-2": "PR",
      "alpha-3": "PRI",
    },
    {
      "alpha-2": "QA",
      "alpha-3": "QAT",
    },
    {
      "alpha-2": "RE",
      "alpha-3": "REU",
    },
    {
      "alpha-2": "RO",
      "alpha-3": "ROU",
    },
    {
      "alpha-2": "RU",
      "alpha-3": "RUS",
    },
    {
      "alpha-2": "RW",
      "alpha-3": "RWA",
    },
    {
      "alpha-2": "BL",
      "alpha-3": "BLM",
    },
    {
      "alpha-2": "SH",
      "alpha-3": "SHN",
    },
    {
      "alpha-2": "KN",
      "alpha-3": "KNA",
    },
    {
      "alpha-2": "LC",
      "alpha-3": "LCA",
    },
    {
      "alpha-2": "MF",
      "alpha-3": "MAF",
    },
    {
      "alpha-2": "PM",
      "alpha-3": "SPM",
    },
    {
      "alpha-2": "VC",
      "alpha-3": "VCT",
    },
    {
      "alpha-2": "WS",
      "alpha-3": "WSM",
    },
    {
      "alpha-2": "SM",
      "alpha-3": "SMR",
    },
    {
      "alpha-2": "ST",
      "alpha-3": "STP",
    },
    {
      "alpha-2": "SA",
      "alpha-3": "SAU",
    },
    {
      "alpha-2": "SN",
      "alpha-3": "SEN",
    },
    {
      "alpha-2": "RS",
      "alpha-3": "SRB",
    },
    {
      "alpha-2": "SC",
      "alpha-3": "SYC",
    },
    {
      "alpha-2": "SL",
      "alpha-3": "SLE",
    },
    {
      "alpha-2": "SG",
      "alpha-3": "SGP",
    },
    {
      "alpha-2": "SX",
      "alpha-3": "SXM",
    },
    {
      "alpha-2": "SK",
      "alpha-3": "SVK",
    },
    {
      "alpha-2": "SI",
      "alpha-3": "SVN",
    },
    {
      "alpha-2": "SB",
      "alpha-3": "SLB",
    },
    {
      "alpha-2": "SO",
      "alpha-3": "SOM",
    },
    {
      "alpha-2": "ZA",
      "alpha-3": "ZAF",
    },
    {
      "alpha-2": "GS",
      "alpha-3": "SGS",
    },
    {
      "alpha-2": "SS",
      "alpha-3": "SSD",
    },
    {
      "alpha-2": "ES",
      "alpha-3": "ESP",
    },
    {
      "alpha-2": "LK",
      "alpha-3": "LKA",
    },
    {
      "alpha-2": "SD",
      "alpha-3": "SDN",
    },
    {
      "alpha-2": "SR",
      "alpha-3": "SUR",
    },
    {
      "alpha-2": "SJ",
      "alpha-3": "SJM",
    },
    {
      "alpha-2": "SE",
      "alpha-3": "SWE",
    },
    {
      "alpha-2": "CH",
      "alpha-3": "CHE",
    },
    {
      "alpha-2": "SY",
      "alpha-3": "SYR",
    },
    {
      "alpha-2": "TW",
      "alpha-3": "TWN",
    },
    {
      "alpha-2": "TJ",
      "alpha-3": "TJK",
    },
    {
      "alpha-2": "TZ",
      "alpha-3": "TZA",
    },
    {
      "alpha-2": "TH",
      "alpha-3": "THA",
    },
    {
      "alpha-2": "TL",
      "alpha-3": "TLS",
    },
    {
      "alpha-2": "TG",
      "alpha-3": "TGO",
    },
    {
      "alpha-2": "TK",
      "alpha-3": "TKL",
    },
    {
      "alpha-2": "TO",
      "alpha-3": "TON",
    },
    {
      "alpha-2": "TT",
      "alpha-3": "TTO",
    },
    {
      "alpha-2": "TN",
      "alpha-3": "TUN",
    },
    {
      "alpha-2": "TR",
      "alpha-3": "TUR",
    },
    {
      "alpha-2": "TM",
      "alpha-3": "TKM",
    },
    {
      "alpha-2": "TC",
      "alpha-3": "TCA",
    },
    {
      "alpha-2": "TV",
      "alpha-3": "TUV",
    },
    {
      "alpha-2": "UG",
      "alpha-3": "UGA",
    },
    {
      "alpha-2": "UA",
      "alpha-3": "UKR",
    },
    {
      "alpha-2": "AE",
      "alpha-3": "ARE",
    },
    {
      "alpha-2": "GB",
      "alpha-3": "GBR",
    },
    {
      "alpha-2": "US",
      "alpha-3": "USA",
    },
    {
      "alpha-2": "UM",
      "alpha-3": "UMI",
    },
    {
      "alpha-2": "UY",
      "alpha-3": "URY",
    },
    {
      "alpha-2": "UZ",
      "alpha-3": "UZB",
    },
    {
      "alpha-2": "VU",
      "alpha-3": "VUT",
    },
    {
      "alpha-2": "VE",
      "alpha-3": "VEN",
    },
    {
      "alpha-2": "VN",
      "alpha-3": "VNM",
    },
    {
      "alpha-2": "VG",
      "alpha-3": "VGB",
    },
    {
      "alpha-2": "VI",
      "alpha-3": "VIR",
    },
    {
      "alpha-2": "WF",
      "alpha-3": "WLF",
    },
    {
      "alpha-2": "EH",
      "alpha-3": "ESH",
    },
    {
      "alpha-2": "YE",
      "alpha-3": "YEM",
    },
    {
      "alpha-2": "ZM",
      "alpha-3": "ZMB",
    },
    {
      "alpha-2": "ZW",
      "alpha-3": "ZWE",
    }
  ]
};
