import NetworkService from '../../service/network/network.service'

const state = {
    networks: null,
}

const mutations = {
    SET_NETWORKS: (state, payload) => {
        state.networks = payload;
    },
}

const actions = {
    GET: ({ commit }) => {
        if (!state.networks) {
            return new NetworkService().getNetworks(commit);
        }
        return Promise.resolve();
    },
    FORCE_GET: ({ commit }) => {
        return new NetworkService().getNetworks(commit);
    },
}

const getters = {
    getNetworks(state) {
        return state.networks;
    },
    getNetworkById:
        (state) => (id) => {
            if (state.networks) {
                let c = state.networks.find(c => {
                    return c.id == id;
                })
                return c;
            }
            return { title: "" }
        }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}