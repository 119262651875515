<template>
  <div class="wrapper">
    <side-bar>
      <template v-slot:links>
        <div class="container">
          <div class="row d-flex align-items-center ml-2 mb-3">
            <div class="boost-btn mb-2">
              <router-link :to="{ name: 'boost' }">
                <base-button block type="primary">
                  <i class="fa fa-rocket"></i>
                  {{ getTrans("messages.boost_my_cam") }}
                </base-button>
              </router-link>
            </div>
            <div class="boost-btn">
              <router-link :to="{ name: 'boost_link', params: { link: true } }">
                <base-button block type="primary">
                  <i class="fa fa-link"></i>
                  {{getTrans("messages.boost_my_link")}}
                </base-button>
              </router-link>
            </div>
          </div>
        </div>
        <sidebar-item
          v-if="display('dashboard')"
          :link="{ route: 'dashboard', icon: 'ni ni-shop' }"
        ></sidebar-item>
        <sidebar-item
          v-if="display('tokens')"
          :link="{
            route: 'tokens',
            icon: 'ni ni-money-coins',
            badge: /*getUser.currentBalance*/ 0,
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="display('transactions')"
          :link="{ route: 'transactions', icon: 'ni ni-credit-card' }"
        ></sidebar-item>
        <sidebar-item
          v-if="display('orders')"
          :link="{ route: 'orders', icon: 'ni ni-archive-2' }"
        ></sidebar-item>
        <sidebar-item
          v-if="display('studio')"
          :link="{ route: 'studio', icon: 'ni ni-badge' }"
        ></sidebar-item>
        <sidebar-item
          v-if="display('profiles')"
          :link="{ route: 'profiles', icon: 'ni ni-badge' }"
        ></sidebar-item>
        <sidebar-item
          v-if="display('links')"
          :link="{ route: 'links', icon: 'ni ni-badge' }"
        ></sidebar-item>
        <sidebar-item
          v-if="display('resources')"
          :link="{ route: 'resources', icon: 'ni ni-cart' }"
        ></sidebar-item>
        <sidebar-item
          v-if="display('statistics')"
          :link="{ route: 'statistics', icon: 'ni ni-chart-bar-32' }"
        ></sidebar-item>
        <sidebar-item
          v-if="display('packages')"
          :link="{ route: 'packages', icon: 'ni ni-app' }"
        ></sidebar-item>
        <sidebar-item
          :link="{ name: 'settings', icon: 'ni ni-settings-gear-65' }"
        >
          <sidebar-item :link="{ route: 'account' }"></sidebar-item>
          <sidebar-item :link="{ route: 'password' }"></sidebar-item>
          <sidebar-item :link="{ route: 'billing' }"></sidebar-item>
          <sidebar-item :link="{ route: 'privacy' }"></sidebar-item>
          <sidebar-item
            v-if="display('notifications')"
            :link="{ route: 'notifications' }"
          ></sidebar-item>
          <sidebar-item
            v-if="display('subscription')"
            :link="{ route: 'subscription' }"
          ></sidebar-item>
          <sidebar-item
            v-if="display('camsite')"
            :link="{ route: 'camsite' }"
          ></sidebar-item>
          <sidebar-item
            v-if="display('payouts')"
            :link="{ route: 'payouts' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          v-if="display('tools')"
          :link="{ route: 'tools', icon: 'ni ni-settings' }"
        ></sidebar-item>
        <sidebar-item
          v-if="display('invite_friend')"
          :link="{ route: 'invite_friend', icon: 'fas fa-users' }"
        ></sidebar-item>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <div>
          <base-header
            v-if="!$route.meta.hasCustomHeader"
            class="pb-6"
            type="pink"
          >
            <div class="row align-items-center py-4">
              <div class="col-lg-6 col-7">
                <nav class="d-none d-md-inline-block ml-md-4">
                  <route-bread-crumb :items="$route.meta.breadCrumbs"></route-bread-crumb>
                </nav>
              </div>

              <div class="col-5 col-lg-6 text-right">
                <base-button
                  size="md"
                  type="primary"
                  @click="$route.meta.buttonFunction(that())"
                  v-if="$route.meta.buttonText"
                >
                  <i
                    :class="$route.meta.buttonIcon"
                    v-if="$route.meta.buttonIcon"
                  ></i>
                  {{ getTrans($route.meta.buttonText) }}
                </base-button>
              </div>
            </div>
          </base-header>
          <!-- your content here -->
          <div
            class="container-fluid mt--6 position-relative"
            v-if="!$route.meta.hasCustomHeader"
          >
            <router-view :key="$route.fullPath"></router-view>
          </div>
          <router-view v-else :key="$route.fullPath"></router-view>
        </div>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import BaseHeader from "@/components/BaseHeader";
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BaseHeader,
    DashboardNavbar,
    ContentFooter,
    RouteBreadCrumb,
  },
  computed: {
    ...mapGetters("User", { getUser: "getData", can: "can" }),
  },
  methods: {
    that() {
      return this;
    },
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    //checks route meta roles in routes.js against user roles
    display(routeName) {
      try {
        let route = this.$router.resolve({ name: routeName });
        return route.name === routeName &&
          route.meta &&
          route.meta.permission &&
          !this.can(route.meta.permission)
          ? false
          : true;
      } catch (e) {
        return false;
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}
</script>

<style scoped></style>
