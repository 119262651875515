import BaseService from '../BaseService'
export default class SubscriptionPlanService extends BaseService {
    constructor() { 
      super(); 
      this.baseUrl = 'subscription_plan'; 
    }
    
    async getSubscriptionPlans() {
      return super.makeRequest('GET', this.baseUrl);
    }

    async cancelPlan(planId) {
      return super.makeRequest('DELETE', `${this.baseUrl}/${planId}/unsubscribe`);
    }

    async buyPlan(plan) {
      return super.makeRequest('POST', `${this.baseUrl}/${plan.id}/subscribe`, {
        'payment_method': 36,
        dataType: 'json'
      });
    }

    async freePlan(plan) {
      return super.makeRequest('POST', `${this.baseUrl}/${plan.id}/subscribe`);
    }

    async confirmPayment(chargeId) {
      return super.makeRequest('POST', 'payment/securion_pay_confirm_payment', {
        'paymentAccountId': 36,
        'tokenId': chargeId,
        dataType: 'json',
      })
    }
}
