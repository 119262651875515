import BaseService from '../BaseService'

export default class CamsiteService extends BaseService {
    async getCamsites(commit) {
        return super.makeRequest('GET', `platform?include[]=programs`).then((response) => {
            commit('SET_CAMSITES', response.data);
        }).catch((response) => {
            // eslint-disable-next-line
            console.log(response);
            throw response
        });
    }
}
