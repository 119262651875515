module.exports = {
    boost: {
        title: 'Give the boost a title',
        profiles: 'You need to select which profiles you want to send traffic to. You can select multiple profiles at the same time.',
        packages: 'Select what traffic package you want to purchase traffic from. A package has configuration for what type of traffic it is and what it costs.',
        options: 'Select how many tokens you want to spend on this order. <br> You can also set a max limit of viewers. This means that when the limit is reached we will stop sending traffic until the viewer count is below this number again. Depending on the camsite and the targeting of the package, there is not a guarantee to reach this number, but to work as an upper limit',
        autostart: 'When a model goes offline or into private, the order will be paused. With this option the order will automatically resume when model comes back online',
        autorenew: 'This option will automatically create a new identical order when the budget has been spent',
        create: 'Click to complete your order'
    },
    model_intro: {
        header: 'No models added',
        text: 'Before we can boost your models, we need to know who they are and which sites they have profiles on.',
        button: 'Click here to add a model to your account'
    },
    profile_intro: {
        header: 'No profiles added',
        text: 'Before we can boost you, we need to know which sites you\'re registered on and who you are there.',
        button: 'Click here to add a profile to your account'
    },
    tokens_intro: {
        header: 'No tokens',
        text: 'Boosts on DivaTraffic are paid for by tokens.',
        button: 'Click here to buy tokens'
    },
    orders_intro: {
        'header': 'You\'re ready for your first boost!',
        'text': 'Now you can start a boost for the model you added.',
        'button': 'Click here to boost your model'
    },
    add_model: {
        name: 'Start by entering the full name for the model',
        email: 'The E-mail field is not required but if you want this model to have access to DivaTraffic and manage her own account, you can add an email address here.',
        performer: 'First select the camsite platform the model is performing on, then enter the username the model uses on this camsite',
        image: 'You can also select to upload an image of the model',
        save: 'Click to save this model'
    },
    add_package: {
        name: 'Start by giving your package a name and a description',
        camsites: 'You can choose to make this package work only for models from the selected camsites. Leave empty to make it work for all camsites',
        targeting_tooltip: 'To create your own package you have to select the targeting options you want to receive traffic from',
        selecting: 'You can select as many options as you like. Leave the field empty to select all',
        bids: 'After you have made all you targeting selections, go to Bids for pricing',
        get_started: 'Click this button to get started'
    },
    bids: {
        default_bid: 'The bid is the cost in $ for every 1000 visitor that matches your targeting',
        dynamic_bids: 'If you want to customize how much you want to pay for a specific targeting within your plan, you can turn on dynamic bidding',
        add_bid: 'Adding bids can be very useful to increase earnings. Add a higher bid for targeting that converts better to receive more of this traffic and faster',
        click: 'Click to add a bid',
        custom_bid: 'Select the targeting and bid. You can add as many custom bids as you like',
    }
}