module.exports = {
    traffic: 'Трафик',
    filter_on_this: 'Отфильтровать по',
    earnings_sum: 'Общий заработок',
    add_filter: 'Добавить фильтр',
    show_columns: 'Колонки',
    e_cpm: 'eCPM',
    clipsite: 'Камсайт',
    public: 'Публичный',
    all_devices: 'Все устройства',
    affiliate_your_own_whitelabel: 'Используйте свой whitelabel',
    select_program: 'Выберите программу',
    current_password: 'Текущий пароль',
    payment_cycle: 'Периодичность платежа',
    account_type: 'Тип аккаунта',
    next_payment: 'Следующий платёж',
    bonus_on_purchase: 'Бонус',
    choose_subscription: "Выберите подписку, которая соответствует вашим потребностям",
    free_account_option_text: "Если вы не хотите покупать подписку, не волнуйтесь! У вас по-прежнему будет несколько функций, которые вам пригодятся",
    // generic
    faq: 'FAQ',
    order: 'Заказ',
    information: 'Информация',
    info: 'Информация',
    integrations: 'Интеграции',
    packages: 'Пакеты',
    supported_sites: 'Поддерживаемые сайты',
    supported_sites_text: 'Следующие сайты поддерживаются. Свяжитесь с нами, если хотите порекомендовать другой сайт.',
    links_info_text: 'Используя "Прокачать мою ссылку" вы можете купить трафик для ваших профилей и контента на клипсайтах, сайтах с подпиской и других контент ориентированных сайтов, которые позволяют продавать ваши фото и видео, имея профиль. Теперь вы можете купить трафик для таких сайтов так же, как вы покупаете трафик для ваших камсайтов.',
    read_more_about_links: 'Больше про ссылки',
    url: 'URL',
    this_account: 'Этот аккаунт',
    statistics: 'Статистика',
    upgrade: 'Улучшить',
    promo_tools: 'Промо инструменты',
    transactions: 'Транзакции',
    rtb: 'RTB',
    last_updated: 'Последнее обновление',
    created: 'Создано',
    item_created: 'Создан',
    start: 'Старт',
    stop: 'Стоп',
    pause: 'Пауза',
    activate: 'Активировать',
    renew: 'Рестарт',
    renew_order: 'Рестарт Заказа',
    male: 'Мужской',
    female: 'Женский',
    couple: 'Пара',
    online: 'В сети',
    offline: 'Не в сети',
    manually: 'Вручную',
    show_all: 'Показать всех',
    show_active_platforms: 'Показать активных',
    referrals: 'Рефералы',
    notifications: 'Уведомления',
    birthday: 'День рождения',
    tokens: 'Токенов',
    total_referrals: 'Всего рефералов',
    earnings: 'Заработок',
    total_earnings: 'Общий заработок',
    has_viewercount: 'Есть счетчик просмотров',
    new: 'Новый',
    account: 'Учетная запись',
    plans: 'Тарифы',
    send: 'Послать',
    boost: 'Буст',
    free_boost_count: '{1} 1 бесплатный буст|{*} :count бесплатных бустов',
    all_payments: 'Все платежи',
    view_invoice: 'Показать счёт',
    download_invoice: 'Скачать счёт',
    one_free_boost: '1 бесплатный буст',
    free_boosts: ':boosts бесплатных бустов',
    active_orders: 'Активные заказы',
    paused_orders: 'Приостановленные заказы',
    private: 'Приватный',
    away: 'Отошёл',
    welcome_to_studio_button: 'Начните, добавьте модель',
    go_to_url: 'Перейти к URL',
    cancel_and_refund: 'Отмена и возврат',

    // platforms
    supported_camsites: 'Поддерживаемые сайты',
    camsites_description: 'Ниже представлен перечень поддерживаемых сайтов для веб-моделей. Мы постоянно работаем над расширением данного перечня по запросам пользователей. Если вы не смогли найти сайт, который используете в работе, обязательно создайте обращение в нашу службу поддержки, а мы сделаем все возможное, чтобы добавить его в систему. Мы также с нетерпением ждем обращений от владельцев сайтов для веб-моделей.',
    camsites_modelpitch: 'Вы еще не веб-модель? Не стоит переживать, вы можете зарегистрироваться в качестве веб-модели на одном из сайтов, представленных ниже. Просто нажмите кнопку СТАТЬ МОДЕЛЬЮ на выбранном вами сайте.',

    // /dashboard
    //Profiles welcome screen - shown when a model has no profiles added
    welcome_to: 'Добро пожаловать в DivaTraffic',
    welcome_to_subtitle: 'Мы поможем получить тот трафик, который нужен именно вам',
    welcome_to_info: 'Проект DivaTraffic предлагает широкий выбор инструментов, которые позволят прокачать ваш видеоканал, когда вы находитесь в сети. Все, что вам нужно сделать - <br>добавить вашу учетную запись веб-модели в личном кабинете DivaTraffic, после этого вы сможете приступить к работе.',
    welcome_to_final: 'Вы можете попробовать систему в деле <span class="color-pink">совершенно бесплатно!</span>',
    welcome_to_button: 'Приступайте к работе, и добавляйте учетную запись',
    //Studios welcome screen - shown when studios have no models/profiles
    welcome_to_studios_subtitle: 'Мы поможем вашим моделям получить именно тот трафик, который им нужен',
    welcome_to_studios_info: 'Проект DivaTraffic предлагает широкий выбор инструментов, которые позволят прокачать видеоканалы ваших моделей, когда они находятся в сети. Все, что вам нужно сделать - <br>добавить учетные записи ваших веб-моделей в личном кабинете DivaTraffic, после этого вы сможете приступить к работе.',
    welcome_to_studios_final: 'Вы можете попробовать систему в деле <span class="color-pink">совершенно бесплатно!</span>',
    welcome_to_studios_button: 'Приступайте к работе, и добавляйте учетную запись модели',
    //misc
    welcome_beta: 'Здравствуйте, :name! Проект DivaTraffic работает в тестовом режиме и может время от времени быть не очень стабильным. Пожалуйста, сообщите нам о любых проблемах, с которыми вы столкнулись, а также о том, как мы можем сделать наш проект еще лучше. Мы будем рады каждому мнению!',
    helpcenter: 'чтобы перейти в центр помощи!',
    click_here: 'Нажмите здесь',
    dashboard: 'Панель управления',
    welcome: 'Здравствуйте, :name',
    plugrush_status_title: 'Состояние кампании PlugRush',
    plan: 'Пакет',
    received: 'Получено',
    progress: 'Ход выполнения',
    ordered: 'Заказано',
    visitors: 'Посетители',
    this_hour: 'В этом часе',
    hour_limit: 'Часовое ограничение',
    countries: 'Страны',
    country: 'Страна',
    city: 'Город',
    status: 'Статус',
    action: 'Действие',
    numb_links: 'Общее количество ссылок',
    numb_users: 'Общее количество пользователей',
    numb_performers: 'Общее количество моделей',
    numb_adzones: 'Общее количество рекламных мест',
    numb_actions: 'Общее количество действий',
    numb_visits: 'Общее количество посещений',
    numb_studios: 'Общее количество студий',
    numb_surveys:'Общее количество опросов',
    numb_platforms: 'Общее количество платформ',
    numb_codes: 'Общее количество использованных кодов',
    numb_transactions: 'Общее количество транзакций',
    pending_transactions: 'Количество транзакций в обработке',
    summary: 'Сводка',
    total_tokens: 'Всего токенов',
    total_traffic: 'Общий трафик',
    total_links: 'Всего ссылок',
    total_orders: 'Всего заказов',
    recent_orders: 'Недавние заказы',
    numb_logins: 'Общее количество попыток входа',
    manage_tokens: 'Управление токенами',
    manage_orders: 'Управление заказами',
    boost_your_cam: 'Прокачайте вашу камеру',
    manage_links: 'Управление ссылками',
    manage_performer: 'Управление моделью',
    manage_performers: 'Управление моделями',
    tokens_earned: 'Токенов заработано',
    tokens_remaining: 'Остаток токенов',
    unique_models: 'уникальных моделей',
    visitors_received: 'посетителей привлечено',
    shortened_links: 'сокращенных ссылок',
    shorten_url: 'Сократить ссылку',
    news: 'Новости',
    new_features: 'Новые функции',
    boost_my_cam: 'Прокачать мою комнату',
    boost_my_link: 'Прокачать мою ссылку',
    boost_performers: 'Прокачать выступающих',
    boost_performers_text: 'Прокачайте популярность ваших моделей, когда они окажутся в сети в следующий раз',
    boost_cam_text: 'Получите гораздо больше зрителей для вашего следующего шоу! Нажмите кнопку ниже, и прокачайте ваше следующее выступление',
    earn_tokens: 'Зарабатывайте токены',
    earn_tokens_text: 'Делитесь ссылками с вашими фанатами и подписчиками, получая токены за каждый клик!',
    redeem_code: 'Использовать код',
    redeem_code_text: 'Введите ваш код, чтобы получить бесплатные токены!',
    redeem: 'Использовать',
    earn_free_tokens: 'Заработать бесплатные токены',
    get_started: 'Приступая к работе',
    description: 'Описания',
    how_to_get_started: 'Как сделать первые шаги',
    how_to_get_started_text: 'Используйте токены, чтобы докупить трафик для выступлений ваших моделей!',
    connected_profiles: 'подключенных учетных записей',
    manage_profiles: 'Управление учетными записями',
    api_settings: 'Настройки API',

    // /plans
    plan_intro: 'Тарифные планы Divatraffic',

    // Affiliate
    affiliate_stats: 'Партнерская статистика',
    affiliate_link_desc: 'Используйте данную ссылку, чтобы приглашать пользователей в нашу систему, и начните зарабатывать деньги',
    affiliate_none_referred: 'Вы еще не пригласили ни одного пользователя.',
    affiliate_header: 'В данном разделе вы увидите приглашенных пользователей, а также узнаете, как пригласить других пользователей',

    // /sidebar
    sign_in: 'Войти',
    log_out: 'Выйти',
    settings: 'Настройки',
    get_free_tokens: 'Получить бесплатные жетоны',
    get_more_tokens: 'Получить больше жетонов',
    boostmycam_tooltip: 'Получите гораздо больше зрителей, когда выйдете в сеть в следующий раз',
    sharelinks_tooltip: 'Зарабатывайте токены, распространяя ваши ссылки среди поклонников и подписчиков',
    share_links: 'Распространяйте ссылки',
    buy_tokens: 'Купить токены',
    traffic_tooltip: 'Увеличивайте объем трафика, распространяйте ссылки!',
    orders: 'Заказы',
    studio_models: 'Модели студии',
    account_settings: 'Настройки учетной записи',
    studio_settings: 'Настройки студии',
    studio_control_enter: 'Вы перешли в режим управления пользователем.',
    studio_not_found: 'Не удалось найти вашу студию.',
    studio_no_access: 'У вашей студии нет доступа к данному пользователю.',
    links: 'Ссылки',
    divalinks: 'DivaLinks',
    adzones: 'Рекламные блоки',
    stats: 'Статистика',
    platform_settings: 'Настройки платформы',
    models: 'Модели',
    custom_plan: 'Индивидуальный тариф',
    manage_models: 'Управление моделями',
    billing_information: 'Платежная информация',
    bank_name: 'Имя',
    bank_address1: 'Адрес',
    bank_zip: 'Индекс',
    bank_city: 'Город',
    bank_country: 'Страна',
    bank_vat: 'НДС',
    deleted: 'Заблокирован или удален',
    publisher: 'Публикатор',

    // /boost
    boostcam_header_text: 'Прокачайте ваш видео-канал, добавив трафик (посетителей) в следующий раз, когда вы будете в режиме онлайн.',
    boost_packages: 'Пакеты трафика для прокачки',
    out_of_tokens: 'Закончились токены?',
    out_of_tokens_text: 'Отправляйтесь на страницу с информацией о токенах, чтобы получить еще больше токенов',
    get_tokens: 'Получить токены!',
    get_free_tokens_text: 'Выполняйте задания и получайте награду в виде бесплатных токенов, которые вы можете использовать для покупки пакетов трафика',
    not_enough_tokens: 'У вас недостаточно токенов для выполнения данного действия.',
    boost_cam_short: 'Прокачать',
    order_settings: 'Настройки заказа',
    order_cost: 'Цена заказа',
    total_cost: 'Всего',
    order_not_found: 'Заказ не найден',
    order_invalid_status: 'Только заказы в статусе приостановлен, в ожидании, завершён, могут быть изменены',
    autorenew: 'Авто-обновление',
    renew_forever: 'Постоянно обновлять',
    renewals_left: 'Осталось обновлений',
    renewals: 'Обновлений',
    autorenew_sub: 'Автоматически обновлять заказ когда исчерпан бюджет',
    autorenew_info: 'Читать про авто-обновлении заказов',
    renew_forever_text: 'Заказ будет автоматически обновляться пока у вас есть токены.',
    order_total: 'Итог по заказу',
    accept: 'Принять',

    // /links
    my_links: 'Мои ссылки',
    manage_shortened_links: 'Управляйте вашими короткими ссылками',
    create_new: 'Создать новый',
    profile: 'Профиль',
    link: 'Ссылка',
    impressions: 'Показов',
    clicks: 'Кликов',
    link_clicks: 'Кликов на ссылку',
    ad_clicks: 'Рекламные клики',
    sales: 'Продажи',
    conv_rate: 'Конверсия',
    actions: 'Действия',
    total_views: 'Всего просмотров',
    total_clicks: 'Всего кликов',
    // create
    create_link: 'Создать ссылку',
    create_link_text: 'Введите ссылку, которую вы хотите сократить',
    advanced_settings: 'Расширенные настройки',
    show_ad: 'Показать рекламу в коротких ссылках',
    ad_to_show: 'Реклама шоу',
    message: 'Сообщение',
    ad_placeholder: 'Привет! Прямо сейчас я в видео-чате. Присоединяйся',
    promote_cam: 'Рекламировать мой видео-чат',
    promote_diva: 'Рекламировать мою лендинговую страницу в системе Diva',
    when_online: 'Когда я в сети',
    when_offline: 'Когда я не в сети',
    go: 'Вперед!',
    link_created: 'Ссылка создана!',
    click_link_info: 'Любой человек, нажавший на ссылку, увидит страницу с вашей рекламой внутри.',
    copy_link: 'Копировать ссылку',
    link_copied: 'Ссылка скопирована',
    copy_link_details: 'Скопировать URL в буфер обмена',
    cant_copy: 'Не удалось скопировать',
    save_changes: 'Сохранить изменения',
    share_on_networks: 'Распространяйте вашу ссылку в этих сетях',
    come_visit: 'Привет! Приглашаю тебя в мое онлайн-шоу прямо сейчас!',
    just_click: 'Просто нажмите здесь',

    // /tokens
    tokens_header_text: 'Используйте токены, чтобы увеличить объем трафика вашего канала (количество посетителей).',
    tokens_history: 'История токенов',
    activity: 'Активность',
    no_transactions: 'Транзакций нет',
    overall: 'всего',
    but_tokens_text: 'Нужно больше токенов? Никаких проблем! Отправляйтесь в раздел токенов, чтобы приобрести дополнительные токены!',
    details: 'Подробно',

    // /orders
    my_orders: 'Мои заказы',
    my_orders_header_text: 'Обзор ваших заказов',
    order_status_0: 'Приостановлен',
    order_status_1: 'Активный',
    order_status_2: 'Отменён',
    order_status_3: 'В ожидании',
    order_status_4: 'Завершён',
    queued: 'В очереди',
    queuetooltip: 'Вы на :pos месте в очереди за :plan.',
    refunded: 'Средства возвращены',
    archive: 'Архив',
    total_visitors: 'Всего посетителей',
    price: 'Цена',
    delivered_visitors: 'Доставленные посетители',
    balance: 'Баланс',
    start_date: 'Дата начала',
    no_active_orders: 'Нет активных заказов',
    viewers: 'Зрители',
    live_stats_enabled: 'Данный заказ сейчас активен, доступна статистика в реальном времени.',
    stats_not_active: 'Данный заказ приостановлен или завершен.',
    order_queued: 'Данный заказ находится в очереди. Он находится на #:position месте в очереди.',
    order_queued_desc: 'Один или несколько ваших заказов поставлены в очередь из-за большой нагрузки.',
    orders_plans: 'Заказы пакетов трафика',
    show_paused: 'Приостановленные заказы',
    show_active: 'Активные заказы',
    show_completed: 'Завершенные заказы',
    model: 'Модель',
    order_status: 'Состояние заказа',
    performer: 'Артист',
    platform: 'Платформа',
    destination: 'Назначение',
    back_to_orders: 'Назад к заказам',

    // /studio/performers
    studio: 'Студия',
    performers: 'Артисты',
    shortcuts: 'Ярлыки',
    administrate: 'Администрировать',
    add: 'Добавить',
    control_user: 'Управление пользователем',
    search: 'Поиск',
    reset: 'Сброс',
    close: 'Закрыть',
    add_performers: 'Добавить артистов',
    add_performers_text: 'В этом разделе вы можете добавить артистов в вашу студию, отправив им ссылку или электронное приглашение.',
    all_models: 'Все модели',
    go_to_room: 'Перейти в чат',
    sort_by_name: 'Упорядочить по имени',
    sort_by_online: 'Упорядочить по статусу',
    sort_by_viewer: 'Упорядочить по количеству просмотров',

    // /studio/settings
    settings_header_text: 'Измените ваши настройки в этом разделе.',
    name: 'Имя',
    contact_email: 'Контактный адрес',
    contact_phone: 'Контактный телефон',
    website: 'Сайт',
    cancel: 'Отмена',
    save: 'Сохранить',
    boost_cam: 'Прокачать камеру',

    // /studio/models
    model_management_page: 'Вы находитесь в разделе управления вашими моделями.',
    no_models_added: "Вы еще не добавили ни одной модели. Добавьте вашу первую модель, нажав кнопку, расположенную ниже.",
    add_model: 'Добавить модель',
    add_a_model: 'Добавить модель',
    need_help: 'Требуется помощь?',
    hide_profiles: 'Скрыть профили',
    show_all_profiles: 'Показать все профили',
    platform_not_available: ':platform не поддерживает повышение трафика в настоящее время',
    either: 'Оба',
    any: 'Любой',
    filter_profiles: 'Фильтр профилей',
    give_tokens: 'Дать токены',
    profile_name: 'Имя профиля',
    user_or_profile_name: 'Имя пользователя или профиля',
    model_count: '{1} 1 модель|{*} :count модели',
    model_info: 'Модель',
    profile_info: 'Информация о профиле',
    select_your_platform: 'Выберите вашу платформу',
    boost_profiles: 'Буст профиля',
    profile_header_status: 'Статус',
    profile_status_online: 'Онлайн',
    profile_status_offline: 'Не в сети',
    profile_status_manual: 'Манульный',

    // /partials header
    home: 'Главная',
    about: 'О нас',
    services: 'Сервисы',
    how: 'Как',
    contact: 'Контакты',
    support: 'Поддержка',
    sign_up: 'Регистрация',
    // performer
    admin: 'Администратор',
    grid: 'Сетка',
    list: 'Список',
    all_performers: 'Все модели',
    identifier: 'Имя или идентификатор',
    all_platforms: 'Все платформы',
    any_status: 'Любой статус',
    all_genders: 'Оба пола',
    users: 'Пользователи',
    // sidebar-top
    toggle_nav: 'Переключить навигацию',
    add_link: 'Добавить ссылку',
    url_error: 'Ошибка ссылка',
    url_error_text: 'Ссылка должна быть действительной. Пожалуйста, проверьте ссылку, и повторите попытку.',

    // /profiles
    profiles: 'Профили',
    camsite: 'Сайт для трансляций',
    last_online: 'Был(а) в сети',
    create: 'Создать',
    add_new_profile: 'Добавить новый профиль',
    on_camsite: 'Какой сайт вы используете для трансляций?',
    model_name: 'Какое имя использует ваша модель на этом сайте?',
    add_new: 'Добавить',
    no_profile: 'Подключить профиль',
    profiles_count: '{1} 1 профиль|{*} :count профили',

    // /social
    social_header: 'Инструмент для публикации в социальных сетях',
    tools: 'Инструменты',
    social_pub: 'Социальный публикатор',
    social_update: 'Инструмент для автоматического обновления и планирования записей в социальных сетях',
    social_update_text: 'Используйте данный инструмент, чтобы запланировать или обновить записи во всех учетных записях в социальных сетях одновременно. Кроме того, вы можете настроить автоматическую публикацию каждый раз, когда вы будете выходить в режим онлайн. Приступайте к работе!',

    // surveys
    survey: 'Опрос',
    go_back: 'Назад',
    survey_thanks: 'Благодарим за участие в опросе. После завершения вы получите бесплатные токены.',
    survey_taken: 'Вы уже принимали участие в данном опросе.',

    // tools
    diva_toolkit: 'Набор инструментов Diva',
    toolkit_text: 'Используйте эти инструменты, чтобы увеличить объем трафика (посетителей) для вашего чата, обновляйте записи в социальных сетях в автоматическом режиме, и зарабатывайте токены.',
    cambooster_text: 'Вы можете использовать прокачку видеоканала, чтобы начать следующее шоу с мощного потока трафика (зрителей), который будет активирован при следующем входе в сеть или в установленное вами время.',
    divalinks_text: 'DivaLinks – это система, позволяющая создавать короткие ссылки, зарабатывать токены, и получать бесплатный трафик (посетителей) в ваш видео-чат, а также новых фанатов и подписчиков ваших страничек в социальных сетях. Кроме того, вы будете получать токены за каждый клик. Вы можете использовать токены для покупки дополнительного трафика для вашего канала.',
    social_fanpage: 'Социальная фан-страница',
    social_fanpage_text: 'Позволяет создать индивидуальную страницу для фанатов, которая поможет получить дополнительных подписчиков в социальных сетях, а также увеличить количество зрителей вашего следующего шоу. Позвольте своим фанатам найти всю информацию о вас в одном месте.',
    setup_page: 'Настроить мою страницу',

    // user settings
    email: 'Эл. почта',
    full_name: 'Полное имя',
    new_password: 'Новый пароль',
    enter_new_passw: 'Введите новый пароль',
    repeat_password: 'Подтвердите пароль',
    repeat_new_passw: 'Подтвердите новый пароль',
    studio_permissions: 'Разрешения студии',
    can_share_links: 'Может делиться ссылками',
    can_boost_cam: 'Может прокачивать канал',
    can_manage_orders: 'Может управлять заказами',
    can_use_social: 'Может использовать соц. сети',

    // adzones
    tool: 'Инструмент',
    delete: 'Удалить',
    // edit
    cam_to_adv: 'Рекламируемые сайты для трансляций',
    choose_cam: 'Выберите сайт для трансляций...',
    models_to_prio: 'Укажите моделей, которые имеют более высокий приоритет при нахождении в режиме онлайн',
    choose_performer: 'Выберите артиста...',
    copy_code: 'Скопируйте данный код',
    // create
    create_adzone: 'Создать новый рекламный блок',
    select_tool: 'Выберите инструмент',
    title: 'Название...',
    edit: 'Изменить',
    next: 'Далее',

    // auth login
    login_header_text: 'Зарегистрируйтесь, и прокачайте вашу камеру уже сегодня!',
    email_adress: 'Адрес эл. почты',
    remember: 'Запомнить меня',
    reset_passw: 'Сбросить пароль',
    reset_passw_intro: 'Введите ваш новый пароль ниже',
    email_passw_intro: 'Забыли свой пароль? Никаких проблем! Просто укажите адрес вашей электронной почты, и мы направим вам ссылку для сброса пароля.',
    or_sign_in: 'Или войдите, используя',
    need_account: 'Нет учетной записи?',
    sign_up_now: 'Регистрируйтесь прямо сейчас',
    password: 'Пароль',
    login_problem: 'Проблемы при входе?',
    login_contact: 'Свяжитесь с нами и мы вам поможем!',
    // register
    sign_up_text: 'Зарегистрируйтесь, и прокачайте вашу камеру уже сегодня!',
    sign_up_text_studios: 'Здравствуйте! Зарегистрируйтесь, и получите контроль над трафиком ваших моделей уже сегодня!',
    sign_up_text_models: 'Зарегистрируйтесь, и прокачайте вашу камеру уже сегодня!',
    sign_up_text_choose: 'Вы модель или представитель студии? Выберите тип учетной записи ниже',
    confirm_passw: 'Подтвердите пароль',
    i_am_a: 'Я - ',
    studio_name: 'Название студии',
    phone_number: 'Номер телефона',
    studio_website: 'Сайт студии',
    total_performers: 'Количество артистов',
    agree: 'Я принимаю :link',
    terms_conditions: 'Условия Использования',
    already_have_account: 'Уже есть учетная запись?',
    sign_in_now: 'Войдите прямо сейчас',
    or_sign_up: 'Или зарегистрируйтесь, используя',
    register: 'Зарегистрироваться',
    register_model_text: 'Создайте новый аккаунт для модели. Для самостоятельных моделей.',
    sign_up_model_button: 'Зарегистрироваться как модель',
    register_studio_text: 'Создайте новый аккаунт для студии. Для студий с несколькими моделями.',
    sign_up_studio_button: 'Зарегистрироваться как студия',
    register_affiliate_text: 'Создать новый аффилиат аккаунт. Искользуйте рекламные инструменты от DivaTraffic для продвижения онлайн моделей и зарабатывания денег',
    sign_up_affiliate_button: 'Зарегистрироваться как аффилиат',
    reset_passw_text: 'Отправить ссылку для восстановления пароля',
    studio_Q: 'Представляете студию?',
    are_you_studio: 'Перейдите на страницу регистрации студии, и',
    studio_signup: 'Регистрация студии',
    model_Q: 'Модель?',
    are_you_model: 'Вы - модель?',
    model_signup: 'Регистрация модели',
    register_problem: 'Проблема при регистрации?',
    reg_studio: 'Зарегистрироваться как студия',
    reg_studio_sub: 'Пегистрируйтеся и умеличивайте количество поситетилей в комнатах ваших моделей!',
    reg_enter_details: 'Введите данные',
    reg_user_details: 'Данные пользователя',
    reg_user_enter_details: 'Введите информацию по вашему аккаунту',
    reg_studio_details: 'Информация о студии',
    reg_studio_enter_details: 'Введите информацию о студии вашей студии',
    reg_name: 'Ваше имя',
    reg_email: 'your@email.com',
    reg_password: 'Ваш пароль',
    reg_re_password: 'Повторите ваш пароль',
    reg_studio_name: 'Название студии',
    reg_studio_email: 'Контактный адрес электронной почты студии',
    reg_studio_phone: 'Контактный телефонный номер студии',
    reg_studio_country: 'Выберите вашу страну',
    reg_studio_city: 'В каком городе расположена ваша студия',
    reg_total_performers: 'Пожалуйста выберите...',
    reg_affiliate: 'Зарегистрироваться как аффилиат',
    reg_affiliate_sub: 'Зарегистрируйтесь и начните буст ваших моделей уже сейчас!',
    contact_send_us: 'Пошлите нам сообщение',
    contact_having_problems: 'Проблемы?',
    contact_faq: 'Прочитайте FAQ',
    contact_subject: 'Тема',
    contact_subject_place: 'Тема письма',
    contact_message: 'Сообщение',
    contact_send_message: 'Отправить письмо',
    contact_contact_us: 'Связаться с нами',
    contact_get_in_touch: 'Напишите нам и мы вам поможем.',
    contact_information: 'Контактная информация',

    // /camsites
    camsites: 'Сайты для трансляции',
    connect_camsite: 'Подключите ваш сайт для трансляций',
    connec_camsite_text: 'Прежде чем вы сможете получать дополнительный трафик и зарабатывать токены, размещая ссылки, вам необходимо настроить ваш видеочат. Пожалуйста, выберите сайт, который вы используете для трансляций, после чего нажмите ПОДКЛЮЧИТЬ, чтобы настроить ваш видео-канал.',
    freemium: 'Условно-бесплатный сайт',
    connect: 'Подключить',

    // error page
    be_back: 'Мы скоро вернемся.',

    // /publisher
    total_income: 'Общая прибыль',
    num_adzones: 'Количество рекламных мест',
    total_impressions: 'Общее количество показов',
    num_clicks: 'Общее количество кликов',
    CTR: 'Коэффициент откликов',

    // /verify
    verify_account: 'Подтвердите вашу учетную запись',
    account_verification: 'Подтверждение учетной записи',
    verify_text: 'Ваша учетная запись еще не подтверждена. Пожалуйста, перейдите по ссылке в отправленном вам письме, чтобы активировать учетную запись. Если вы не можете найти письмо, обязательно проверьте папку для нежелательных писем',

    //payments
    payments_header_text: 'Какой-то текст',
    invoice: 'Квитанция',
    invoice_date: 'Дата проведения',
    item_list: 'Название',
    total_price: 'Сумма',
    payment_via: 'Оплата через',
    subtotal: 'Всего',

    //status page
    enabled: 'Включено',
    disabled: 'Выключено',
    yes: 'Да',
    no: 'Нет',
    gay: 'Гей',
    straight: 'Гетеро',
    trans: 'Транс',
    men: 'Мужчины',
    women: 'Женщины',
    couples: 'Пары',
    status_title: 'Название',
    site_url: 'Адрес сайта',
    affiliate_url: 'Партнерская ссылка',
    alexa_rank: 'Рейтинг Alexa',
    numb_unique_models: 'Количество уникальных моделей',
    online_models: 'Модели онлайн',
    model_api: 'API модели',
    model_referral: 'Партнерская программа модели',
    live_screenshot: 'Живой скриншот',
    live_stream: 'Онлайн-вещание',
    profile_pic: 'Изображение профиля',
    business_model: 'Бизнес модель',
    orientation: 'Ориентация',
    gender: 'Пол',
    has_whitelabel: 'Работает под брендом',
    has_revshare: 'Получает процент от прибыли',
    has_conv_tracking: 'Использует отслеживание конверсии',
    status_freemium: 'Условно-бесплатный',
    premium: 'Премиум',
    supported: 'Поддерживается',
    not_supported: 'Не поддерживается',

    // /Sign up
    select_account: 'Выберите тип вашей учетной записи',

    // /About page
    know_more_heading: 'Хотите узнать о нас больше?',
    know_more_info: 'Используйте раздел контактов, и мы ответим на все ваши вопросы',
    about_us: 'О нас',
    about_info: 'Информация Diva Technologies AS и чем мы занимаемся',
    about_know_more: 'Хотите узнать больше?',
    about_goto: 'Зайдите на страницу с контактами и мы ответим на ваши вопросы',
    about_history_and_goal_header: 'История и цели',
    about_history_and_goal: 'DivaTraffic это продут, созданный Diva Technologies AS, которая была основана в 2016 году с штаб-квартирой в Бергене, Норвегии. DivaTraffic это сеть, специализирующаяся на трафике и инструментах для индустрии онлайн стриминга. Это сервис для вэб-моделей и создателей онлайн контента, который помогает увеличить их аудиторию и получить больше трафика для своих стримов и контента.',
    about_our_team: 'Наша команда',

    // Other React pages
    years_old: ':years лет',
    user_count: ':users пользователей',
    token_count: ':tokens токенов',
    viewer_count: ':viewers зрителей',
    visitor_count: ':visitors посетителей',
    model_profile: 'Профиль модели',
    make_your_selections: 'Сделайте выбор',
    choose_boost_camgirl: 'Выберите профиль модели-девушки, который вы хотите прокачать',
    autoresume: 'Автоматическое возобновление после приватного чата или выход в онлайн',
    traffic_package: 'Пакет трафика',
    package_size: 'Объем пакета',
    order_summary: 'Информация о заказе',
    package: 'Пакет',
    size: 'Размер',
    total: 'Всего',
    complete_order: 'Завершить заказ',
    plan_info: 'Информация о :plan',
    select_camsite_and_nickname: 'Выберите сайт для трансляций, и укажите имя учетной записи, которую вы хотите добавить',
    nickname_at_platform: 'Имя пользователя в рамках платформы',
    your_nickname: 'Ваше имя пользователя',
    new_profile: 'Новый профиль',
    unavailable: 'Недоступно',
    unavailable_description: 'Прокачка недоступна для данной платформы.',
    open_landingpage: 'Открыть лендинговую страницу',
    no_access_tokens: 'Вы еще не создали токенов для индивидуального доступа.',
    personal_access_token: 'Токен для индивидуального доступа',
    personal_access_tokens: 'Токены для индивидуального доступа',
    create_new_token: 'Создать новый токен',
    new_token: 'Новый токен',
    personal_token_caution: 'Это ваш новый токен для индивидуального доступа. Он будет показан только сейчас, поэтому сохраните его! Теперь вы можете использовать этот токен для создания API-запросов.',
    authpopup_sign_in_message: 'Здравствуйте! Войдите, и начните получать мгновенный и мощный поток трафика!',
    authpopup_sign_up_message: 'Здравствуйте! Зарегистрируйтесь, и начните получать мгновенный и мощный поток трафика!',
    or_sign_in_with: 'Или войдите, используя',
    create_new_link: 'Создать новую ссылку',
    soon: 'Скоро...',
    link_logged_out_message: 'Вы должны зарегистрироваться или войти, чтобы использовать заблокированные функции. Зарегистрированные пользователи имеют доступ к статистике и другим инструментам!',
    add_profile: 'Добавить профиль',
    add_package: 'Добавить пакет',
    all_links: 'Все ссылки',
    buy_traffic: 'Купить трафик',
    share_link: 'Поделиться ссылкой',
    customize: 'Настроить',
    delete_link: 'Удалить ссылку',
    ad_type: 'Тип рекламы',
    select_profile: 'Выбрать профиль',
    customize_your_link: 'Настройте вашу ссылку',
    link_share_message: 'Скопируйте данный адрес, и начните распространять его! Каждый пользователь, который нажмет на ссылку, увидит страницу с вашей рекламой внутри.',
    copy: 'Копировать',
    enter_address: 'Введите адрес сайта',
    redirected_popups: 'Переадресованные всплывающие окна',
    traffic_type: 'Тип трафика',
    payment_method: 'Способ оплаты',
    subscription_payment: 'Платёж за подписку',

    // Vue pages
    updated: 'Обновить',
    budget: 'Бюджет',
    limits: 'Лимиты',
    orderprogress: 'Прогресс',
    profile_details: 'Информация о профиле',
    user_details: 'Информация о пользователе',
    boost_profile: 'Буст профиля',
    plan_details: 'Информаци о пакете',
    budget_limit: 'Бюджет и лимиты',
    select_platform: 'Выберите платформу',
    performer_nickname: 'Ник модели на камсайте',
    camsite_header: 'Какой камсайт использует модель?',
    camsite_subtitle: 'Выьерите камсайт, который использует модель',
    model_header: 'Какое имя пользователя модели?',
    model_subtitle: 'Введите имя ползователя, которое модель использует на камсайте',
    model_details: 'Информация о модели',
    model_details_sub: 'Введите информеция для новой модели',
    model_name_input: 'Имя модели',
    model_name_input_sub: 'Введите полное имя модели',
    image: 'Фото',
    image_subtitle: 'Загрузите фото модели',
    image_subtitle_profile: 'Загрузите новое фото профиля',
    image_upload: 'Загрузить фото',
    select_image: 'Выбрать фото',
    image_crop: 'Обрезать фото',
    email_subtitle: 'Обязательно только если модель должза иметь возможность залогиниться на DivaTraffic и самостоятельно использовать аккаунт',
    save_model: 'Сохранить модель',
    model_invite: 'Послать пригашение модели?',
    model_invite_sub: 'Если отметить эту опйию, модель получит приглашение для своего собственного аккаунта и сможет сможет залогиниться на DivaTraffic и использовать свой собственный аккаунт',
    camsite_profiles: 'Профили на камсайтах',
    camsite_profiles_sub: 'Если модель работает с несколькими камсайтами, вы можете добавить профиль здесь',
    boost_models: 'Буст модели',
    social_accounts: 'Аккаунты в социальных сетях',
    social_accounts_sub: 'Добавьте аккаунты в социальных сетях моделей и получите доступ к большему количеству функций и инструментов, чтоб получить больше трафика',
    manage_settings: 'Управление настройками модели',
    general_settings: 'Общие настройки',
    general_settings_sub: 'Общие настройки модели',
    affiliate_settings: 'Аффилиат',
    affiliate_settings_sub: 'Измените вашу аффилиат информацию',
    security_settings: 'Безопасность',
    security_settings_sub: 'Управление безпасностью профиля',
    deactivate: 'Деактиворовать этот аккаунт',
    deactivate_sub: 'Тут вы можете деактиворовать аккаунт модели. Имейте ввиду, что удаление аккаунта так же деактиворует все профили, ассоциированные с этой моделью',
    deactivate_account: 'Деактивировать аккаунт',
    change_password: 'Изменить пароль',
    old_passw: 'Старый пароль',
    new_passw: 'Новый пароль',
    confirm_password: 'Подтвердите пароль',
    current_balance: 'ТЕКУЩИЙ БАЛАНС',
    tokens_caps: 'ТОКЕНЫ',
    collect_tokens: 'Забрать токены',
    select_give_amount: 'Выберете какое количество токенов вы хотите дать',
    select_collect_amount: 'Выберете какое количество токенов вы хотите забрать',
    performer_name: 'Имя модели',
    privacy_settings: 'Настройки приватности',
    privacy_settings_sub: 'Настройки приватности модели',
    update_avatar: 'Измените ваш аватар',
    order_details: 'Информация о заказе',
    more_options: 'Больше опций',
    bid: 'Ставка',
    visitor_limit: 'Предел количества пользователей',
    offline_redirect: 'Оффлайн редиректы',
    autostart: 'Автостарт',
    autostart_sub: 'Автоматически начинать приостановленный заказ, когда модель становится онлайн',
    autostart_info: 'Читать про автостарт заказов',
    low: 'Низкий',
    medium: 'Средний',
    high: 'Высокий',
    current_bid: 'Текущая ставка',
    bid_info: 'Больше информации о ставках',
    redirect_url: 'URL перенаправления',
    redirect_info: 'Читать про перенаправления (редиректы) и как установить целевой url',
    budget_info: 'Читать про бюджет и как это работает',
    max_and_min: 'Максимальное и минимальное количество гостей в комнате',
    max_and_min_info: 'Читать про ораничения вашего трафика',
    traffic_package_info: 'Детальная информация о :package пакете',
    profiles_sub: 'Вы можете выбрать несколько профилей',
    profiles_info: 'Как оптимизировать выбор профилей моделей',
    account_details: 'Информацио о аккаунте',
    privacy: 'Приватность',
    affiliate: 'Аффилиат',
    security: 'Безопасность',
    social: 'Социальные сети',
    affiliate_add: 'Добавьте свой уникальный аффилиат ключ / campaign id для каждой платформы',
    blocked_countries: 'Заблокированные страны',
    blocked_countries_sub: 'Добавьте страны, из которых вы не хотите получать трафик. Эти настройки будут применены ко всем профилям, связанным в этой моделью',
    blocked_countries_subtitle: 'Добавьте страны, из которых вы не хотите получать трафик',
    affiliate_setting: 'Аффилиат настройки',
    affiliate_key: 'Аффилиат ключ (заменит aff_key} в URL)',
    update_profile: 'Изменить профиль',
    add_new_model: 'Добавить новую модель',
    add_new_package: 'Добавить новый пакет',
    edit_model: 'Редактировать модель',
    update_avatar_title: 'Изменить аватар',
    order_stats: 'Статистика заказа',
    select_all: 'Выбрать всё',
    deselect_all: 'Сбросить выбор',
    boost_selected: 'Буст выбранных',
    cancelled_text: 'Заказ был отменён и оставшиеся средства переведены на ваш аккаунт',
    platform_count: ':platforms платформы',
    are_you_sure: 'Вы уверены?',
    remaining_funds: 'Оставшиеся средства будут переведены на ваш аккаунт',
    cancel_order: 'Отменить заказ!',
    back: 'Назад',
    cancel_refund: 'Отмена и возврат',
    all: 'Все',
    create_order: 'Создать заказ',
    user_settings: 'Настройки пользователя',
    saved: 'Сохранено',
    settings_saved: 'Настройки пользователя сохранены',
    error: 'Ошибка',
    save_failed: 'Ошибка при сохранении',
    delete_account: 'Удалить аккаунт',
    delete_your_account: 'Удалить ваш аккаунт',
    delete_account_sub: 'Тут вы можете удалить свой аккаунт. Имейте ввиду, что удаление аккаунта так же деактиворует все профили моделей, ассоциированные с ним',
    profile_info_sub: 'Введите информацию по вашему профилю',
    saved_image: 'Изображение сохранено',
    error_something: 'Что-то пошло не так',
    profile_saved: 'Информацию профиля сохранена',
    language: 'Язык',
    logout: 'Выйти',
    avatar: 'Аватар',
    overview: 'Обзор',
    order_index: 'Индекс заказа',
    daily_token_usage: 'Ежедневное использование токенов',
    traffic_statistics: 'Статистика трафика',
    daily_visitors: 'Ежедневные посетители',
    visitors_today: 'Посетители сегодня',
    last_two_weeks: 'Последние две недели',
    full_statistics: 'Полная статистика',
    weekly_visitors: 'Еженедельные посетители',
    visitors_this_week: 'Посетители за эту неделю',
    last_14_weeks: 'Последние 14 недель',
    monthly_visitors: 'Посетители по месяцам',
    visitors_this_month: 'Посетители за этот месяц',
    last_year: 'Прошлый год',
    no_results: 'Нет результатов',
    no_performer_results_details: 'Найдите профили моделей, выполнив поиск с помощью поля поиска выше (Поиск по имени) и сузив его с помощью списка (Платформа).',
    any_platform: 'Любая платформа',
    online_status: 'Онлайн статус',
    model_name_header: 'Имя модели',
    transfer_complete: 'Трансфер завершён',
    delete_profile_text: 'Это навсегда удалить профиль :name.',
    delete_succeeded: 'Удалено!',
    profile_deleted: 'Этот профиль был удалён.',
    deleted_profile: 'Удалить профиль',
    age: 'Возраст',
    about_me: 'Про меня',
    guest_message: 'Сообщение для гостей',
    not_available: 'Еще недоступно',
    coming_soon: 'Скоро будет',
    general: 'Общие',
    targeting: 'Таргетинг',
    bids: 'Ставки',
    delete_model_confirm: 'Удаление этого аккаунта так же деактивирует все профили моделей, всязанные с ним',
    yes_delete_it: 'Да, удалить!',
    account_deleted: 'Этот аккаунт был удалён.',
    change_password_failed: 'Ошибка при изменении пароля.',
    edit_profile: 'Изменить пофиль',
    show_n_profiles: '{1}Показать ещё 1 профиль|[*,*]Показать ещё :count профилей',
    apply_targeting: 'За тысячу впечатлений. Это относится ко всем вашим таргетингам',
    edit_package: 'Изменить пакет',
    update: 'Изменить',
    package_description: 'Описание пакета',
    package_title: 'Название пакета',
    give_package_name: 'Назовите пакет',
    give_package_description: 'Добавьте описание пакета',
    default_bid: 'Ставка по умолчанию',
    dynamic_bidding: 'Динамические торги',
    customize_bid: 'Настройте ставки для конкретного таргетинга',
    add_bid: 'Добавить ставку',
    sources: 'Источники',
    devices: 'Устройства',
    browsers: 'Браузеры',
    os: 'Ос',
    targeting_options: 'Выберите все варианты таргетинга',
    package_created: 'Пакет создан',
    affiliate_room_url: 'Аффилиат URL комнаты',
    profiles_saved: 'Профиль сохранён',
    packageview: 'Посмотреть пакет',
    package_details: 'Подробности о пакете',
    per_thousand: 'за тысячу показов',
    custom_bids: 'Кастомные ставки',
    operating_systems: 'Операционные системы',
    package_updated: 'Пакет изменён',
    update_failed: 'Ошибка при изменении',
    order_created: 'Заказ создан',
    something_wrong: 'Что-то пошло не так',
    all_transactions: 'Все транзакции',
    into_account: 'Поступления',
    out_account: 'Расходы',
    payments: 'Платежи',
    amount: 'Сумма',
    date: 'Дата',
    comment: 'Комментарий',
    remaining: 'остаток',
    promotools: 'Рекламные инструменты',
    card_view: 'Карточками',
    list_view: 'Списком',
    select_profiles: 'Выберите профили',
    select_package: 'Выберите пакет',
    choose_package: 'Выберите пакет тут',
    all_profiles: 'Все профили',
    could_not_load: 'Невозможно загрузить :type',
    bid_tooltip: 'Значение по умолчанию - 100%. Увеличение ставки означает, что вы платите больше за каждого посетителя. Благодаря этому вы получите более быстрый трафик, но вы так же быстрее исчерпаете свой бюджет',
    bid_percent: 'Ставка %',
    maxviewers_tooltip: 'Когда значение "Максимум пользователей" достигнуто, мы прекратим слать трафик, пока количество пользователей не уменьшится. В зависимости от камсайта и таргетинга пакета, нет гарантии, что вы достигнете этого лимита, но в любом случае он будет использован, как максимальное значение',
    tokens_tooltip: 'Это число отображает бюджет/цену, которую вы хотите потратить на этот заказ',
    how_does_it_work: 'Как это работает?',
    basic: 'Базовый',
    max_viewers: 'Максимум пользователей',
    traffic_settings: 'Настройки трафика',
    start_automatically: 'Автоматический старт',
    renew_automatically: 'Автоматический рестарт',
    select_promo: 'Пожалуйста, выберите промо-инструмент',
    select_promo_header: 'Эта javascript библиотека контролирует все промо-инструменты DivaTraffic. Измените настройки как вам нужно, затем скопируйте и вставьте скрипт на ваш сайт.',
    camhead_info: 'CamHead это плавающий промо-инструмент, который отображает онлайн комнату на любой странице, где вы используете скрипт',
    snippet_info: 'Snippet отобразит онлайн комнату на любой странице, где вы используете скрипт',
    widget_info: 'Native Cam Iframe отобразит несколько профилей в любом размере',
    select_camsite: 'Пожалуйста выберите на какой камсайт вы хотите послать трафик',
    tools_instruction: 'Javascript скрипт ниже контролирует все промо-инструменты, предоставляемые DivaTraffic. Скопируйте скрипт и вставьте код перед <strong class="text-info">&lt;head&gt;</strong> на странице, где вы хотите отобразить промо-инструмент.',
    integration_script: 'Интеграционный Script',
    viewers_room: 'Пользователей в комнате',
    total_sent: 'Всего послано',
    profiles_online: '{1} Профиль онлайн |{*} Профилей онлайн',
    duration: 'Продолжительность',
    hour: '{1} 1 час|{*} :count часов',
    minutes: '{1} 1 минута|{*} :count минуты',
    tokens_left: 'осталось токенов',
    model_plural: '{1} Модель|{*} Модели',
    profile_plural: '{1} Профиль |{*} Профили',
    camsite_plural: '{1} Камсайт |{*} Камсайты',
    platforms_short: '{1}:platform|:count платформы',
    responsive: 'Респонсив',
    fixed_size: 'Фиксированный размер',
    height: 'Высота',
    width: 'Ширина',
    select_rooms: 'Веберите комнату',
    xs_package: 'XSmall пакет токенов',
    sm_package: 'Small пакет токенов',
    md_package: 'Medium пакет токенов',
    lg_package: 'Large пакет токенов',
    xl_package: 'XL пакет токенов',
    xxl_package: 'XXL пакет токенов',
    xxxl_package: 'XXXL пакет токенов',
    custom_package: 'Кастомный пакет токенов',
    select_token_package: 'Выберите свой пакет токенов',
    select_token_bundle: 'Выберите свой пакет токенов',
    custom_amount: 'Другая сумма',
    payment_details: 'Детали платежа',
    bundle_details: 'Информация о пакете',
    transaction_info: '* Счет за транзакцию будет выставлен в евро, и банк может взимать дополнительную комиссию за обмен.',
    paysite_info: 'Поддержка для держателей карт Paysite-cash',
    payment_tokens: 'Оплата токенов',
    search_performer: 'Поиск по имени модели',
    slower: 'медленнее',
    faster: 'быстрее',
    please_select_camsite: 'Пожалуйста выберите камсайт',
    no_models: 'Модели не найдены.',
    no_profiles: 'Профили не найдены.',
    no_orders: 'Заказы не найдены.',
    no_stats: 'Статистика не найдена...',
    loading_models: 'Загрузка моделей...',
    loading_profiles: 'Загрузка профилей...',
    loading_orders: 'Загрузка заказов...',
    loading_dashboard: 'Загрузка панели управления...',
    loading_data: 'Загрузка данных...',
    loading_stats: 'Загрузка статистики...',
    camhead_desc: 'CamHead это код, который покажет рекламу вашего онлайн шоу. Всё что вам нужно сделать, это вставить его в html код любого сайта. Он покажет любой онлайн профиль в вашем аккаунте.',
    snippet_desc: 'Snippet покажет онлайн комнату на любой странице, где код скрипта присутствует.',
    widget_desc: 'Native Cam Iframe отобразит несколько профилей в любом размере.',
    divalinks_desc: 'DivaLinks это инструмент для укорачивания ссылки, которой вы хотите поделиться, он так же добавляет рекламувашей комнаты, когда вы онлайн',
    smartlink_desc: 'SmartLink is a URL that will redirect to any of your online profiles that you have in your studio. Share it on social media with your fans and members.',
    smartlink_affiliate_desc: 'SmartLink is a URL that will redirect to an online performer based on your settings. Share it on social media',
    camhead_script: 'The below javascript controls the CamHead. Copy the script and place the code right before the <strong class="text-info">&lt;/head&gt;</strong> on the page you want the ads to show.',
    snippet_script: 'The below javascript controls the Snippet. Copy the script and place the code right before the <strong class="text-info">&lt;/head&gt;</strong> on the page you want the ads to show.',
    widget_script_1: '1. Put this code right before the <strong class="text-info">&lt;/head&gt;</strong> on your page.',
    widget_script_2: '2. Place this code where you want the widget to appear',
    smartlink_adress: 'SmartLink address',
    smartlink_description: 'This is a smartLink that will redirect to an online performer based on your settings.',
    configure: 'Configure',
    choose_setting: 'Choose your preferred settings for the',
    select_camsite_sub: 'You can specify which camsites you want to display models from',
    select_rooms_sub: 'You can specify which rooms you want to display',
    static_responsive: 'Should widget be static or responsive?',
    whitelabels: 'Whitelables',
    add_whitelabel: 'Add whitelabel',
    add_new_whitelabel: 'Add new whitelabel',
    whitelabel_saved: 'Whitelabel saved',
    no_whitelabel: 'No whitelabel',
    model_user: 'User/Model',
    registrations: 'Registrations',
    rebills: 'Rebills',
    costs: 'Costs',
    ctr: 'Click Through Rate',
    number_of: 'Number of ',
    daily_impressions: 'Daily impressions',
    daily_clicks: 'Daily clicks',
    daily_registrations: 'Daily registrations',
    daily_sales: 'Daily sales',
    daily_rebills: 'Daily rebills',
    daily_ctr: 'Daily CTR',
    daily_redirects: 'Daily redirects',
    year: 'Year',
    referrer: 'Referrer',
    redirects: 'Redirects',
    redirect: 'Redirect',
    user: 'User',
    false: 'False',
    sorted_by: 'Sorted by',
    from: 'From',
    to: 'To',
    online_performer_count: '{1} 1 online performer | :count online performers',
    total_performer_count: '{1} 1 performer total | :count performers total',
    package_howto: 'Creating your own package can make a big difference to how the traffic works for you. Select targeting options you know is converting better for you and make sure you only receive traffic from these sources. Click <strong class="text-success">Start guide</strong> if you need help going through the process',
    start_guide: 'Start guide',
    how_it_works: 'Как это работает',
    audience: 'Audience',
    packagecreate: 'Create package',
    packageedit: 'Edit package',
    select: 'Select',
    start_boost: 'Start boost',
    please_select_package: 'Please select a package',
    select_package_sub: 'Click on a package from the left menu to select it and read more about the package specifications',
    delivered_visitor_count: '{1} 1 delivered visitor | :count delivered visitors',
    tokens_spent: ':tokens spent',
    dimension: 'Dimension',
    password_changed: 'Password changed',
    viewer_history: 'Viewer history',
    advanced: 'Advanced',
    traffic_options: 'Traffic options',
    budget_sizes: [
        'Small',
        'Medium',
        'Large',
        'XL',
        'XXL',
        'XXXL'
    ],
    gotoroom: 'Go to room',
    deleted_plan: 'Deleted plan',
    n_minutes_ago: '{0} Now|{1} 1 min ago|{*} :count mins ago',
    n_hours_ago: '{1} 1 hr ago|{*} :count hrs ago',
    n_days_ago: '{1} 1 day ago|{*} :count days ago',
    select_theme: 'Select theme',
    select_theme_sub: 'Select a widget theme',
    select_thumb: 'Select thumbnail size',
    select_thumb_sub: 'How big do you want the thumbnails to be?',
    background_color: 'Background color',
    select_background_color: 'Specify the color of the information bar below the image',
    select_details: 'Select details',
    select_details_sub: 'Specify what information to show',
    camsite_name: 'Camsite name',
    tool_model_name: 'Model name',
    widget_sub_1: 'This script controls the widgets',
    widget_sub_2: 'The widget will appear inside div tags with the specified class',
    script_tag_header: 'Script tag',
    script_tag_sub: 'Install the widget by following these steps',
    room_settings: 'Настройки комнаты',
    room_status: 'Статус комнаты',
    graph: 'Graph',
    visitors_sent: 'Ventanas emergentes recibidas',
    room_limit: 'Лимит комнаты',
    online_viewers: 'Пользователей онлайн',
    device_type: 'Device type',
    device_brand: 'Device brand',
    browser: 'Browser',
    cancelling_order: 'Cancelling order',
    profile_added: 'Profile added',
    default_theme: 'Default theme',
    dark_theme: 'Dark theme',
    light_theme: 'Light theme',
    pink_theme: 'Pink theme',
    xsmall: 'XSmall',
    small: 'Small',
    large: 'Large',
    xlarge: 'XLarge',
    less_options: 'Less options',
    no_profiles_selected: 'No profiles selected',
    no_package_selected: 'No package selected',
    estimate_token: 'Estimate per token',
    estimate_traffic: 'Расчетный трафик',
    estimate_popups: 'Примерное общее количество просмотров',
    delivered_popups: 'Доставнено просмотров',
    estimate_clicks: 'Примерное общее количество кликов',
    delivered_clicks: 'Доставлено кликов',
    type: 'Type',
    popups: 'Popups',
    select_whitelabel_camsite: 'Select which camsite the whitelabel belongs to',
    selected_profiles: 'Выбранные профили',
    view: 'View',
    device_types: 'Device types',
    device_brands: 'Device brands',
    optional: 'optional',
    pay: 'Pay',
    wiretransfer: 'Банковский перевод',
    add_dimension: 'Add dimension',
    clear_filter: 'Clear filter',
    filtered_by: 'Filtered by ',
    date_year: 'Year',
    estimated_visitors: 'Estimated visitors per token',
    any_camsite: 'Any camsite',
    wire_success: 'Payment has been stored. We are waiting for Wire Transfer from you',
    securion_info: 'SecurionPay support',
    update_running_orders: 'Update running orders',
    networks: 'Networks',
    less_targetoptions: 'Click for less target options',
    more_targetoptions: 'Click for more target options',
    camsite_specific: 'Camsite specific',
    camsite_specific_sub: 'Select one or more to make this package work only with models from these camsites',
    main: 'Main',
    my_order: 'My order #',
    update_order: 'Update order',
    edit_order: 'Edit order',
    already_paid: 'Already paid',
    spent: 'Spent',
    remaining_on_order: 'Remaining on order',
    to_be_paid: 'To be paid',
    order_updated: 'Заказ изменён',
    loading_tools: 'Loading tools',
    profiles_removed: '{1} 1 profile removed. Not compatible with this camsite specific package |{*} :count profiles removed. Not compatible with this camsite specific package',
    visit_room: 'Visit room',
    affiliatelink_text: 'You can have traffic sent to your own affiliate link by entering the info below. Your earnings will be in the camsites affiliate program, and you will not earn tokens per signup. Sales statistics will be unavailable',
    affil_key: 'Affiliate key',
    whitelabel_settings: 'Whitelabel settings',
    whitelabel_text: 'If you have your own whitelabel, you can have the traffic sent to it by specifying the settings below. You will not earn token for signups or be able to see conversion or sales statistics. Your earnings will be in the camsites affiliate program.',
    whitelabel_domain: 'Whitelabel domain',
    diva_tps: 'Diva TPS (token per signup)',
    diva_tps_text: 'Get paid in tokens every time someone registers or makes a purchase on the camsite',
    token_per_free: 'Tokens per free registration',
    token_per_purchase: 'Tokens per purchase',
    widget_preview: 'Widget preview',
    desktop: 'Desktop',
    mobile: 'Mobile',
    tablet: 'Tablet',
    estimate_visitors: 'Estimated visitors',
    per_token: 'per token',
    more: 'more',
    select_package_list: 'Select the package you want from the list below',
    my_packages: 'Мои пакеты',
    favourites: 'Favourites',
    targeting_all: 'Targeting all',
    all_countries: 'All countries',
    all_os: 'All OS',
    all_browsers: 'All browsers',
    all_networks: 'All networks',
    all_device_types: 'All device types',
    all_device_brands: 'All device brands',
    all_sources: 'All sources',
    filtered_targeting: 'Filtered targeting',
    click_a_targeting: 'Click a targeting option to view list of all targets for this plan',
    order_must_pause: 'The order has to be paused before you can edit',
    cpm_in_tokens: 'Cost per 1000 visitor (in tokens)',
    cost_in_tokens: 'Cost (in tokens)',
    send_invitation: 'Send invitation to model',
    send_password_reset: 'Send an email with a password reset to the model',
    password_reset: 'Password reset',
    model_no_email: 'Model has no email',
    email_sent: 'Email has been sent',
    on_the_camsite: 'on the camsite',
    target_audience: 'Target audience',
    target_audience_sub: 'Specify where you want to receive traffic from. You can add as many target options as you want',
    target_help: 'Creating your own package can make a big difference to how the traffic works for you. Select targeting options you know is converting better for you and make sure you only receive traffic from these sources.',
    bid_sub: 'The bid is the price you pay per 1000 visitor. If you want more traffic from specific targeting options, you can try increasing the bid. Higher bid usually means you will receive traffic that matches this targeting faster',
    targeting_price: 'Targeting price',
    add_targeting: 'Add targeting',
    select_audience: 'Select your audience',
    location: 'Location',
    start_order: 'Запустить заказ',
    leave_paused: 'Оставить на паузе',
    cancel_refund_order: 'Cancel and refund order',
    advanced_stats: 'Подробная статистика',
    order_progress: 'Order progress',
    spent_tokens: ':spent of :total tokens',
    edit_profiles: 'Edit profiles',
    settings_applied_all: 'The settings will be applied to all the selected profiles',
    set_max_viewers: 'Set max viewers',
    block_countries: 'Block countries',
    affiliate_program: 'Affiliate program',
    program: 'Program',
    no_affiliate_program: 'No affiliate program',
    affiliate_contact_us: 'If you want to use your own whitelabel or affiliate link, please get in touch with us <a href="https://support.divatraffic.com" target="_blank">here</a>, and we can set it up for your account',
    duplicate: 'Duplicate',
    welcome_sub_1: 'DivaTraffic offers a lot of tools for you to boost webcams when the performer is online.',
    welcome_sub_2: 'All you need to do is to add a model to your account, and you\'re ready to boost your camroom.',
    lets_get_started: 'Let\'s get started' ,
    add_first_model: 'Add your first model',
    add_first_profile: 'Add your first profile',
    intro_to_dashboard: 'Introduction to Dashboard',
    intro_to_dashboard_sub_1: 'Once you get started this will be the DivaTraffic homepage.',
    intro_to_dashboard_sub_2: 'Here you will get a full overview of your current traffic situation.',
    all_data_glance: 'All your data at a glance',
    all_data_glance_sub: 'Cards will give you information about your models, orders and token status. Quickly see running orders or who\'s online.',
    your_recent_orders: 'Your recent orders',
    your_recent_orders_sub: 'The dashboard also provides a list of your recent orders, so you can easily see if you have any running orders and what the details are.',
    aff_key_saved: 'Affiliate key saved',
    aff_key: 'Affiliate key',
    go_to_old_diva: 'Go back to old DivaTraffic',
    need_targeting: 'You need to add targeting for your audience. Click "+ Add targeting" button to get started',
    country_count: '{1} 1 country selected|{*} :count countries selected',
    browser_count: '{1} 1 browser selected|{*} :count browsers selected',
    network_count: '{1} 1 network selected|{*} :count networks selected',
    source_count: '{1} 1 source selected|{*} :count sources selected',
    device_brand_count: '{1} 1 device brand selected|{*} :count device brands selected',
    device_type_count: '{1} 1 device type selected|{*} :count device types selected',
    os_count: '{1} 1 operating system selected|{*} :count operating systems selected',
    bids_count: '{1} 1 bid|{*} :count bids',
    customize_icon: 'Here you can customize your package icon to give it a personalized look and feel',
    icon: 'Icon',
    initials: 'Initials',
    color: 'Color',
    targeting_filters: 'Package targeting filters',
    drag_or_click: 'Drag or click a targeting category to add a filter to this page',
    search_for_websites: 'Search for websites you want traffic from and add them from the list below',
    remove: 'Remove',
    select_websites: 'Select websites',
    search_webpage: 'Search for webpages',
    all_networks_filter: 'All networks',
    search_browsers: 'Search browsers',
    search_countries: 'Search countries',
    search_device_brands: 'Search device brands',
    search_device_types: 'Search device types',
    search_networks: 'Search networks',
    search_os: 'Search OS',
    select_date_range: 'Select date range',
    choose_dates: 'Choose dates',
    apply: 'Apply',
    // Months
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    // Weekdays short
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    sun: 'Sun',
    advanced_available: 'Advanced statistics for this order is not available yet. Please check back again in a few minutes',
    network: 'Network',
    custom: 'Custom',
    select_traffic_options: 'Select traffic options',
    subscriptions: 'Подписки',
    current_user_balance: 'Current balance',
    purchase_history: 'Purchase history',
    all_purchases: 'All purchases',
    save_account_details: 'Save account details',
    save_privacy_settings: 'Save privacy settings',
    camhead: 'CamHead',
    snippet: 'Snippet',
    widget: 'Widget',
    smartlink: 'SmartLink',
    free: 'Free',
    deluxe: 'Deluxe',
    monthly: 'Monthly',
    yearly: 'Yearly',
    free_sub_desc: 'Test us out for free!',
    basic_sub_desc: 'Benefit from monthly traffic!',
    premium_sub_desc: 'Higher quality traffic every day',
    deluxe_sub_desc: 'The best traffic we can offer',
    save_2_months: 'save 2 months',
    per_month: 'per month',
    select_plan: 'Select plan',
    features: 'Features',
    studio_promo_tools: 'Studio Promo Tools',
    support_services: 'Support services',
    misc_services: 'Misc services',
    traffic_on_demand: 'Трафик по запросу',
    traffic_on_subscription: 'Трафик по подписке',
    custom_traffic_packages: 'Custom traffic packages',
    email_support: 'Email support',
    free_tokens_month: 'Free tokens each month',
    live_chat_support: 'Live Chat support',
    profiles_included: 'Profiles included',
    studio_camhead: 'Studio CamHead',
    studio_widgets: 'Studio Native Cam Widgets',
    studio_console: 'Studio console',
    studio_smartlinks: 'Studio Smart Links',
    dedicated_support_manager: 'Dedicated Support Manager',
    traffic_analytics: 'Traffic Analytics',
    dedicated_traffic_manager: 'Dedicated traffic manager',
    dedicated_traffic_consultant: 'Dedicated traffic conslutant',
    traffic_to_affiliate: 'Traffic to affiliate link',
    traffic_to_whitelabel: 'Traffic to whitelabels',
    per_year: 'per year',
    popular: 'Popular',
    pricing: 'Цены',
    available_subscription: 'Subscription membership is only available for studios at the moment. Independent model subscription plans are coming soon',
    payments_subscription: 'Payment for subscription',
    payment_for: 'Payment for ',
    subscription: 'Подписка',
    add_all: 'Add all',
    subscription_header: 'Subscription',
    studio_info: 'Studio information',
    phone: 'Phone',
    edit_details: 'Edit details',
    account_info: 'Account information',
    born: 'Born',
    upgrade_plan: 'Upgrade plan',
    available_upgrades: 'Available plans',
    product: 'Product',
    not_configured: 'Not configured',
    configure_camsites: 'Configure camsites',
    default_studio_settings: 'Default studio settings',
    add_blocked_countries: 'Add countries you don\'t want to receive traffic from. This setting will be applied to all your profiles.',
    edit_default_settings: 'Edit default settings',
    your_current_plan: 'Your current plan',
    cancel_subscription: 'Cancel subscription',
    camsite_settings: 'Camsite settings',
    setup_camsite_config: 'Set up your camsite configurations',
    aff_key_activated: 'Affiliate key activated',
    countries_blocked: '{1} 1 country blocked|{*} :count countries blocked',
    studio_avatar: 'Studio avatar',
    first_name: 'First name',
    last_name: 'Last name',
    address: 'Address',
    keep_account_secure: 'Keep your account secure',
    billing: 'Billing',
    edit_billing_info: 'Edit billing information',
    add_profile_first: 'You need to add at least 1 profile first',
    enter_your_aff_key_here: 'Enter your affiliate key here',
    open: 'Open',
    no_subscription: 'No subscription',
    select_free: 'Select free',
    available_to: 'Available to',
    subscription_bonus: 'Subscription bonus',
    downgrade: 'Downgrade',
    traffic_packages: 'Traffic packages',
    subscription_cancelled: 'Subscription cancelled',
    cancelling_subscription: 'Cancelling subscription',
    payment_declined: 'Your payment has been declined',
    subscription_updated: 'Your subscription has been updated',
    something_went_wrong: 'Something went wrong.',
    postbacks: 'Postbacks',
    no_camsite_profile: 'No camsite profile found!',
    no_camsite_profile_sub: 'We could not find any camsite profiles connected to this model',
    user_image: 'User image',
    estimated_traffic: 'Estimated traffic',
    package_price: 'Package price',
    order_title: 'Название заказа',
    traffic_stops: 'Трафик автоматически остановится, когда вы уйдёте в офлайн или приват.',
    traffic_stops_sub: 'Если ваш заказ активен и вы уходите в офлайн или приват, заказ будет приостановлен. Когда вы станете онлайн, заказ автоматически возобновится!',
    limit_visitors: 'Вы можете ограничить количество посетителей в вашей комнате!',
    limit_visitors_sub: 'Вы можете настроить максимальное количество посетителей в комнате для каждого профиля. Вы можете это сделать в настройках профиля.',
    no_traffic_package: 'No traffic package selected. Please select a package from the list below',
    extra_large: 'Extra Large',
    all_packages: 'Все пакеты',
    my_favourites: 'My favourites',
    no_profiles_selected_boost: 'No profiles selected. Please select the profile you want to boost',
    no_profiles_found: 'No profiles found. Please add a profile before boosting',
    no_models_found: 'No profiles found. Please add a model before boosting',
    estimated_viewers: 'Примерное количество просмотров',
    viewers_per_token: 'Просмотров за токен',
    pro: 'Pro',
    go_to_dashboard: 'Вернуться на панель управления',
    stay_on_free: 'Stay on free',
    please_select_subscription: 'Please select a subscription type first',
    select_subscription_before_dash: 'Please select a subscription type before moving to dashboard',
    insufficient_funds: 'Insufficient funds',
    pay_and_boost: 'Buy :price tokens and start boost',
    minimum_payment: 'Minimum payment is $5 for 50 tokens',
    pay_five: 'Pay $5',
    not_possible_change_plan: 'It is not possible to change package on an order. Only package size can be edited',
    see_all_profiles: 'See all profiles',
    my_studio: 'My studio',
    camsite_selected: '{1} 1 camsite selected |{*} :count camsites selected',

    // New frontpage
    new_easy: 'The new & easy way',
    get_more_viewers: 'to get more viewers',
    on_sub_demand: 'on subscription & on demand',
    most_popular: 'Most popular',
    viewers_on_sub: 'Viewers on subscription',
    automatic_viewers: 'Automatically get more viewers when you are online',
    models_from: 'Models from',
    studios_from: 'Studios from',
    month: 'month',
    save_two_months: 'Save 2 months if you sign up for one year',
    get_viewers_subscr: 'Get viewers on subscription',
    continuous_traffic: 'Continuous enabled traffic',
    boosting_my_cam: 'Boost My Cam',
    automatic_start_stop: 'Automatic start and stop traffic',
    max_visitors_room_limit: 'Max visitors in room limit',
    bonus_on_token: 'Bonus on token purchases',
    viewers_on_demand: 'Viewers on demand',
    order_viewers_boost: 'Order viewers and boost cam on demand',
    starts_at: 'Starts at',
    pr_token: 'pr token',
    save_by_purchasing_tokens: 'Save by purchasing more tokens each time',
    get_viewers_on_demand: 'Get viewers on demand',
    see_all_pricing: 'See all pricing options',
    what_we_offer: 'What we offer',
    our_great_services: 'Our great services',
    get_started_with_diva: 'Get started with DivaTraffic and increase traffic to your camshow. It\'s super easy!',
    traffic_subscriptions: 'Traffic Subscriptions',
    traffic_subscriptions_sub: 'By subscribing to DivaTraffic, your will get a lot of benefits and added features. Your profiles will receive traffic automatically whenever you are online.',
    all_subscriptions: 'All subscriptions',
    boosting_my_cam_sub: 'FINALLY, getting a boost of traffic to your camshow is just a click away. DivaTraffic is empowering models and studios to easily drive lots of people into your camshow.',
    raise_traffic_earning: 'Raise your Traffic, Raise your Earnings!',
    try_it_now: 'Try it now',
    camhead_sub: 'CamHead is a new and unique way of advertising your camshow. It detects when you are online and available, and makes sure that your ad is visible for the correct audience.',
    empower_yourself: 'Empower yourself and bring the world to you!',
    choose_plan: 'Choose your plan',
    choose_plan_sub: 'There’s a plan for every size and type of team. Choose the one that fits<br>your traffic demands. Contact us if you need more information.',
    studios: 'Studios',
    subscr_for_multiple: 'Subscriptions for multiple models',
    studio_subscriptions: 'Studio subscriptions',
    lots_more: 'And lots more!',
    subscr_for_individual: 'Subscriptions for individual models',
    model_subscriptions: 'Model subscriptions',
    platforms: 'Платформы',
    list_of_supported_camsites: 'We work with the best of the best in the business. Here\'s a list of some of the platforms we are supporting.',
    and_more: '...and more!',
    benefit_from: 'Benefit from monthly traffic!',
    high_quality_traffic: 'High quality traffic every day',
    best_traffic_we_offer: 'The best traffic we can offer',
    contact_us: 'Contact us',
    help: 'Помощь',
    en: 'English',
    es: 'Español',
    ru: 'Русский',
    languages: 'Languages',

    widget_code: 'Widget code',
    widget_code_text: 'Select one or more of the various widget sizes and place the div tag where you want the widget to show on your page',
    preview: 'Preview',
    banner_rectangle: 'Banner rectangle',
    banner_small_rectangle: 'Banner small rectangle',
    banner_skyscraper: 'Banner skyscraper',
    banner_large_leaderboard: 'Banner large leaderboard',
    widget_settings: 'Widget settings',
    no_profiles_selected_widget: 'Select profiles if you want to show specific models in the tool',
    postbacks_desc: 'All parameters set as GET attributes to URL, but depending on selected postback method we\'ll duplicate parameters in request body if it\'s supported by method (e.g. POST)',
    postbacks_ex: 'Example postback URL',
    add_new_postback: 'Add new postback',
    event: 'Event',
    method: 'Method',
    enter_postback_url: 'Enter your postback URL',
    url_parameters: 'URL parameters',
    aff_income_amount: 'Affiliate income amount',
    sub_account_id: 'Subaccount id. Can be used to specify affiliate inside 3rd party tracking system',
    sub_account_id2: 'Subaccount id 2. Can be used to specify affiliate inside 3rd party tracking system',
    user_ip_address: 'User IP address',
    transaction_type: 'Transaction type',
    admin_subscription: 'Admin Subscription',
    list_too_long: 'List is too long',
    traffic_speed: 'Скорость трафика',
    slow: 'Медленно',
    fast: 'Быстро',
    slow_speed: 'Медленнее обычного',
    normal_speed: 'Нормальная скорость',
    faster_than_normal: 'Быстрее обычного',
    even_faster: 'Еще быстрее',
    faster_than_light: 'Экстрим: Внимание, токены будут потрачены быстро!',
    speed_tooltip: 'This option regulates how fast we send traffic into your room. For some camsites it can be an advantage to get lots of people fast to become more visible, and for other sites a slower stream of viewers is preferred',
    change: 'Change',
    ad_views: 'Просмотры',
    traffic_this_month: 'Traffic this month',
    visit: 'Visit',
    admin_service_status: 'Service status',
    select_camsites: 'Select camsites',
    done: 'Done',
    average_bid: 'Average bid',
    traffic_sent: 'Traffic sent',
    fallback_url: 'Fallback URL',
    set_fallback: 'Set a fallback url for when no models is online',
    enter_url: 'Enter URL',
    saving: 'Saving',
    configure_default_camsite_settings: 'On this page you can configure your default settings for each camsite.',
    default_max_viewers: 'Default max viewers',
    bank_routing_number: 'Bank routing number',
    bank_swift_code: 'Bank SWIFT code',
    zip: 'Zip',
    select_payment_method: 'Select a payment method',
    enter_your: 'Enter your',
    your_first_name: 'Your first name',
    your_last_name: 'Your last name',
    your_address: 'Your address',
    select_country: 'Select country',
    your_bank_vat: 'Your bank VAT',
    local_area_number: 'Local area number',
    your_city: 'Your city',
    minimum_payout_amount: 'Minimum payout amount',
    select_minimum_payout_amount: 'Select minimum payout amount',
    custom_size: 'Custom size',
    traffic_summary: 'Traffic summary',
    subscription_stats: 'Subscription stats',
    ondemand: 'On demand',
    upgrade_subscription: 'Upgrade subscription',
    upgrade_sub_create_packages: 'Please upgrade your subscription to create your own custom traffic package',
    no_packages_found: 'No packages found',
    view_details: 'View details',
    ondemand_stats: 'Stats for traffic on demand',
    daily_earnings: 'Daily earnings',
    block: 'Block',
    blocked: 'Blocked',
    subscription_expired: 'Your subscription has expired',
    payouts: 'Payouts',
    end_period: 'End of period',
    custom_tracking: 'Custom tracking',
    custom_tracking_added: 'Custom tracking added',
    add_tracking: 'Add custom tracking parameters',
    open_rate: 'Open rate',
    opens: 'Opens',
    additional_settings: 'Additional settings',
    customize_tool: 'Customize this tool even further',
    additional_settings_added: 'Additional settings added',
    selected: 'Selected',
    delay_set: '{1} Delay set to 1 second|{*} Delay set to :count seconds',
    sound_selected: 'Sound selected',
    add_delay: 'You can add a delay to the CamHead so that it loads a number of seconds after the page is finished loading',
    add_sound: 'You can add a message sound to the tool that plays when it is loaded',
    sound: 'Sound',
    order_count: 'Order count',
    filter_by_plan: 'Filter by plan',
    models_per_page: 'Models per page',
    select_traffic_type: 'Select traffic type',
    no_traffic_type_selected: 'No traffic type selected',
    filter_minute: 'Minute',
    filter_hour: 'Hour',
    filter_day: 'Day',
    no_stats_order: 'No stats found for this order',
    native_ad_traffic: 'Native Ad Traffic',
    pop_traffic: 'Pop Traffic',
    pop_traffic_description: 'Pop traffic is delivered as popups or popunders from thousands of different adult websites, via all the different ad exchanges and traffic networks that DivaTraffic gets its traffic from. When you start your order, your cam room will instantly start showing as a pop on many of the sites, until you have received the amount of traffic you have requested.<br> If you go offline or into private, your order will automatically pause until you are online and in free chat again.<br>Popup traffic is good for getting a lot of traffic fast, since the users aren’t making a choice to go to your room, but is redirected there by just visiting a website.<br>Keep in mind that popups are often closed right away by the users unless the content they see grabs their attention within the first couple of seconds. So we advise you to do what you can to catch their attention and stand out from the crowd, while the traffic is being sent.',
    native_ad_description: 'Native ad traffic is delivered via clicked image ads that are shown on thousands of different adult websites via different ad exchanges and traffic networks that DivaTraffic gets its traffic from. When you buy native ad traffic, you are paying for ad displays (impressions) and the users have to click your image before being sent to your room.<br>The ad that the users will see will either be a live screenshot of your room, or the profile pitcure you use on the camsite, depending on which camsite you are buying traffic to.<br>Unlike pop traffic, display traffic requires the users to make a click before entering your room, so it will not be as useful in terms of getting a lot of traffic fast, but it means that the users you do get, will have made a choice to visit you, based on the image they see, and it should result in higher quality of traffic.<br>We can not guarantee how many will click on your image, only how many times it will be displayed.',
    pop_traffic_selected: 'Pop traffic selected',
    fallback_options: 'Fallback options',
    offline_models_settings: 'Settings for when there are not enough online models',
    relax: 'Relax',
    repeat: 'Repeat',
    relax_description: 'If there is not enough models online to fill the widget, this settings will show duplicates of the ones who are online to fill the rest of the space. If no option is selected \'relax\' is the default setting.',
    repeat_description: 'If there is not enough models online to fill the widget, this settings will show random models to fill the rest of the space. You will still earn affiliate commission from all sales generated by the widget. If no option is selected this is the default setting.',
    select_gender: 'Select gender',
    widget_documentation: 'Widget documentation',
    widget_docu_text: 'You can customize the widget yourself using data attributes on the div elements in your html page that you want to use as a cam widget.<br>The following data attributes are allowed on div elements.',
    attribute_name: 'Attribute name',
    values: 'Values',
    width_in_pixels: 'The width in pixels',
    height_in_pixels: 'The height in pixels',
    thumb_sizes: 'Thumbnail sizes',
    data_platform_desc: 'Name of camsites to show. Comma seperator and capital first letter of name',
    leave_empty_auto: 'Leave empty for auto',
    data_gender_desc: 'Which gender to prioritize',
    data_theme_desc: 'Which theme to use',
    more_coming_soon: 'More coming soon',
    source: 'Source',
    maxviewers_unavailable: 'Max viewers is unavailable for this camsite',
    maxviewers_unavailable_for: 'Max viewers is unavailable for:',
    tokens_added_to_account: 'Tokens have been added to your account',
    tokens_will_be_added_to_account: 'Tokens will be added to your account in few minutes',
    all_camsites: 'All camsites',
    ondemand_aff_commission: 'On demand affiliate commission',
    promo_tool_aff_commission: 'Promo tools affiliate commission',
    native_cam_widgets: 'Native Cam Widgets',
    wl_activated: 'Whitelabel activated',
    wl_pending: 'Whitelabel pending',
    enter_name_wl: 'Enter name of whitelabel',
    enter_domain_wl: 'Enter domain of whitelabel',
    wl_title: 'Whitelabel title',
    wl_domain: 'Whitelabel domain',
    send_to_whitelabel: 'Send :platform traffic to whitelabel',
    deleting: 'Deleting',
    delete_failed: 'Deletion failed',
    save_wl_failed: 'Saving whitelabel failed',
    create_wl_failed: 'Creating whitelabel failed',
    traffic_last_14_days: 'Traffic last 14 days',
    feature_require_pro_subscription: 'This feature requires a paid subscription',
    info_about_aff_key: 'If you enter your affiliate key for :platform in the field to the left, then the traffic you purchase will be sent using your own affiliate link. Keep in mind that this means that you will get affiliate commission via :platform and not in DivaTraffic and you will not be able to see any statistics about sales, registrations or rebills on your DivaTraffic statistics page <br> <a target="_blank" href="https://support.divatraffic.com/en/help-center/article/how-to-use-your-own-affiliate-links">Click here to read more about how to find your affiliate key</a>',
    max_viewers_info: 'The Max Viewers setting lets you specify what the max amount of viewers you want to have in the room when you are buying traffic on demand. Traffic will automatically stop being sent to the room when the viewer count reaches the max you have specified, and will resume when you have less than this',
    whitelabel_info_text: 'When enabling whitelabel setting for :platform, all traffic you buy on demand will be sent to your whitelabel instead of :platform. Keep in mind that this means that you will not be able to get any earnings or statistics about registrations, sales or rebills in DivaTraffic, and you will be paid affiliate commission directly from :platform',
    email_address: 'E-mail address',
    register_here: 'Register here',
    affiliate_signup: 'Affiliate signup',
    back_to_login: 'Back to login',
    no_subscription_text: 'Without a subscription, you will only have access to our',
    profiles_indexed: 'Profiles indexed on diva.network',
    promotools_commission: 'Promo tools affiliate commission',
    whitelabel_aff_commission: 'Whitelabel affiliate commission',
    studio_featured_in_aff_program: 'Studio featured in affiliate program',
    profiles_shown_in_tools: 'Your profiles will be shown in our affiliate tools',
    camwidgets: 'Cam Widgets',
    profiles_featured: 'Profiles rotated in our affiliate program',
    admin_camsites: 'Admin Camsites',
    available: 'Available',
    add_camsite: 'Add camsite',
    room_url: 'Room URL',
    embed_url: 'Embed URL',
    embed_code: 'Embed code',
    api_url: 'API URL',
    api_listfinder: 'API list finder',
    api_valuefinder: 'API value finder',
    api_transformers: 'API transformers',
    program_url: 'Program URL',
    invite_link: 'Invite link',
    invite_link_desc: 'Use this link to invite users to register at DivaTraffic and earn a commission based on their spending',
    invite_link_description: 'Do you have any friends that you think could benefit from using DivaTraffic? Send them the invite link below, and earn a commission for each person you invite to DivaTraffic. You will earn 10% commission on each person you invite who purchases a subscription on DivaTraffic. Your earnings will be paid after each subscription is renewed or expires, and you will get your earnings paid in tokens which will be added to your account balance.',
    invited_users: 'Your invited users',
    link_to: 'Link to',
    frontpage: 'Frontpage',
    register_page: 'Register page',
    model_register: 'Model register page',
    studio_register: 'Studio register page',
    affiliate_register: 'Affiliate register page',
    invite_redirect: 'Select where you want the invite link to redirect to',
    select_camsite_program: 'Go to Camsite Settings to configure what program to use for each camsite.',
    delete_platform_text: 'Are you sure you want to delete :title?',
    mobile_url: 'Mobile URL',
    resources: 'Resources',
    read_more: 'Read more',
    ynot_awards: 'The YNOT Cam Awards highlights the women and men who perform in the adult interactive community.',
    bucharest_awards: 'The Award Galla takes place at the Bucharest Summit each year and recognizes the top studios, models, camming sites and professionals.',
    lovense: 'Lovense is a sex toy manufacturer known for its smart sex toys, which can be controlled via Bluetooth using a mobile app.',
    community: 'Community',
    toys_equipment: 'Toys & Equipment',
    events: 'Events',
    awards: 'Awards',
    software: 'Software',
    content: 'Content',
    stripperweb: 'Forum for strippers and other members of the adult industry. Has a section for camming related discussion.',
    ynot_network: 'Complete social network with a news feed, forum, messaging, photos, videos, classifieds and more.',
    xbiz: 'B2B forum for the adult industry. You’ll find site owners, content producers, technology solution providers, affiliates, models and more on XBIZ.',
    wecamgirls: 'WeCamgirls is a community where cammodels can talk to their adult business colleagues. A safe place to interact, discuss and connect, without customers peeking in. You can also exchange tips and tricks or review all kinds of different cam related businesses.',
    ambercutie: 'AmberCutie is a professional camgirl that runs a forum on her site. It’s a great place to connect with other camgirls, ask for advice and share reviews, thoughts and strategies.',
    ohmibod: 'OhMiBod is the creator of the original iPod® vibrator. Headquartered in New Hampshire, USA, the female-owned and operated company is driving the evolution of the pleasure industry with an expansive range of high quality, body-safe products that combine technology with sensuality.',
    kiiroo: 'Kiiroo is a high-tech designer sex toy company that gives you the ability to feel someone else in a whole new way.',
    terpon: 'Terpon is a camera and digital interface manufacturing company that specializes Virtual Reality and fully immersive technology for the adult entertainment industry.',
    aw_summit: 'AWSummit is the event where you can learn and network with the best and the brightest in our industry. AWSummit is no ordinary show - it is the ultimate business & conference experience!',
    lalexpo: 'The Definitive B2B Adult show of Central and South America. The only live cam award show in South America. Meet a totally new market and be part of the only business show in the adult industry for professionals that also have more than 600 models and webcams studios around the world.',
    bucharest_summit: 'Bucharest Summit is naturally taking place in Bucharest because the Romanian capital is an industry hotspot, priding itself with top business, talented models and innovative know-how. Experienced speakers will take you on a journey, unveiling all the secrets and sharing their expertise in a diverse range of topics.',
    live_cam_awards: 'Live Cam Awards is an annual award show that was launched for the first time in 2015, being at that time the first award show to exclusively honor the performers and companies who serve the now highly-competitive webcam industry.',
    webcam_startup: 'Webcam Startup is the largest model resource site on the internet. Webcam Startup brings in a wealth of knowledge from a wide range of contributors and staff, each with their own experience and respective knowledge. Plus, all the industry news and announcements.',
    aw_news: 'AW News is the largest live cam industry news source. It’s your provider of the freshest news in the live cam biz.',
    manycam: 'ManyCam is a free webcam software and video switcher that allows you to enhance your video chats and create amazing live streams on multiple platforms at the same time.',
    loomly: 'Loomly is a simple social media calendar tool.',
    hootsuite: 'Hootsuite is a social media management platform. The system’s user interface takes the form of a dashboard and supports social network integrations for Twitter, Facebook, Instagram, LinkedIn, Google+ and YouTube.',
    modelcentro: 'ModelCentro is a platform that helps you monetize and grow your existing fan base into monthly recurring revenues. It’s 100% FREE to set up a Personal Paid Fan Site.',
    manyvids: 'ManyVids.com is a platform designed specifically to help MV Stars generate a healthy passive income. MV is a “one-stop shop”, where your fans can find everything you have to offer.',
    clips4sale: 'Clips4Sale (C4S) is an American adult video content selling website. It is known for fetish content and was the first downloadable clip site of its kind',
    pineapple_support: 'Pineapple Support provides a 24/7 emotional support system for all adult industry performers & producers.',
    ynot_cam: 'YNOT Cam is an online magazine that offers tips and advice to camgirls.',
    infinity_telecom: 'Infinity Telecom provides internet and webcam equipment for studios in Romania',
    bcams: 'BCams bring together websites, cam studios, independent models and a whole range of connected businesses from IT and financial companies to photographs, make-up artists, interior designers and marketing professionals. BCAMS It’s a great opportunity for networking in a friendly and neutral environment.',
    aw_awards: 'The AW Awards and Conference is one of the world’s largest camming award show and event. The AW Awards recognizes the best in the camming industry, including models, studios and businesses.',
    onelink: 'If you had one shot, one opportunity, OneLink, Would you use it or just let it slip?<br/>Never change your bio link again. OneLink makes you link to all your online stuff on one simple page.',
    resources_welcome: 'Welcome',
    resource_welcome_text: 'In order to have success in the live cam industry as a studio or a broadcaster, it is important to use the right tools and services that lets you work more effectively. On these pages we have gathered a collection of recommended services that will help you achieve your goals more effectively, and increase your chances of becoming successful with your business. The services are offered in a range of categories that includes communities, news outlets and events where you can meet and learn from other professionals in the industry, to recommended tools and services that lets you increase your earnings by making money while you are offline, or improve your reach on social media, as well as where to find the right products and equipment you need in order to become a professional performer or studio.<br/>If you have a product or service that you would like us to recommend, please contact us at <a style="font-size: 18px" href="mailto:support@divatraffic.com">support@divatraffic.com</a> and let us know about it.',
    max_daterange: 'Max date range is 31 days. Please select a different range',
    europeansummit: 'Founded in 2009, The European Summit brings together professionals from the online entertainment industry, e-commerce, financial, gaming, gambling, dating, forex, binary options, health, nutraceuticals and many others for an intensive 3-day networking event.',
    reset_email_sent: 'An email with a password reset link has been sent to your address. If you do not receive your email within a few minutes be sure to check your spam folder',
    reset_token: 'Reset token',
    token: 'Token',
    reset_success: 'Your password has been successfully reset',
    loading_packages: 'Загрузка пакетов...',
    loading_platforms: 'Зарузка платформ',
    email_not_verified: 'Your email has not been verified',
    click_to_verify: 'Click here to verify your account',
    email_verification_required: 'Email verification required',
    verification_text: 'A verification email has been sent to <span style="color: #4D96D1">:email</span>. Please check your inbox for an email with subject "DivaTraffic email verification". If you can not find the email, please make sure to check your email spam or junk folder. If you did not receive the email, you can re-send the verification email from here.',
    resend_email: 'Re-send email',
    wrong_email: 'If you entered the wrong email address, <a href="settings/account" style="font-weight: 600">click here</a> to update your email address',
    bidinfo: 'Bid info',
    bidinfo_text: 'The bid is the amount you pay for 1000 visitors matching the targeting filters you have specified above. Traffic is bought in real time and works like an auction where the advertiser with the highest bid for each user wins. A very low bid makes the traffic cheaper, but might result in you not winning many bids, and you might not receive much traffic. A higher bid increases the likelyhood of you winning, but also makes the traffic more expensive.',
    price_pr_1000: 'Price per 1000 users',
    estimated_traffic_pr_token: 'Estimated traffic per token',
    verified: 'Verified',
    not_verified: 'Not verified',
    email_status: 'Email status',
    extralunchmoney: 'ExtraLunchMoney.com (ELM) is a digital marketplace for buyers to directly interact with adult content creators. Although the focus of the site is amateur porn, custom fetish clips, and amateur adult content, we welcome a wide range of adult content including audio and written work from amateurs and professionals alike. Real amateur porn and custom adult content direct from the performers themselves.',
    invite_friend: 'Invite a friend',
    total_so_far: 'Total so far',
    select_affiliate_program: 'Here you can select which affiliate program you want to send traffic to.',
    immediately: 'Immediately',
    auto_renew_order: 'Автоматически обновить заказ когда он завершится',
    renewal_time: 'Установите время между обновлениями',
    number_of_renewals: 'Всего обновлений',
    autorenew_description: 'Внимание: При авто обновлении ваш заказ будет перезапущен автоматически после завершения, и сумма заказа будет списана еще раз с вашего баланса. Учтите, что если вы выберете пакет с быстрым трафиком и короткое время между автоматическим обновлением заказа, вы можете быстро потратить свои токены. Заказ <span style="font-weight: 600">НЕ</span> будет автоматически обновлён, если у вас недостаточно токенов или заказ стоит на паузе или отменён.',
    renews_minutes: '{1} Renews in 1 minute | {*} Renews in :count minutes',
    renews_hours: 'Renews in :count hours',
    notification_option: 'Notification options',
    configure_email_notifications: 'Configure when you will receive email notifications',
    balance_low: 'My balance is getting low',
    auto_renew_insufficient_funds: 'When I have insufficient funds for auto renewal',
    subscription_expires: 'When my subscription expires',
    order_renewed: 'When an order is renewed',
    earnings_payout: 'When I get earnings payout',
    updates_to_divatraffic: 'When there are new updates or changes to DivaTraffic',
    renews_any_moment: 'Renews any moment',
    initializing_order: 'Initializing order, traffic will be sent shortly.. please wait...',
    pop_ads: 'Pop ads are delivered as popups or popunders from thousands of different adult websites, via all the different ad exchanges and traffic networks that DivaTraffic gets its traffic from. Pop ads are good for getting a lot of traffic fast, since the users aren’t making a choice to go to the cam room, but is redirected there by just visiting a website.',
    native_ads: 'Native ads are delivered via clicked image ads that are shown on thousands of different adult websites via different ad exchanges and traffic networks that DivaTraffic gets its traffic from.',
    native_ad_traffic_selected: 'Native ad traffic selected',
    premium_plan: 'Premium plan',
    deluxe_plan: 'Pro plan',
    free_plan: 'Free plan',
    basic_plan: 'Basic plan',
    edituser: 'Edit user',
    admin_users: 'Admin users',
    package_youtube: 'Click here to watch a YouTube tutorial on how to create packages',
    watch_tutorial: 'Watch tutorial',
    download: 'Download',
    show_in_ads: 'Показывать в объявлениях',
    show_in_ads_details: 'Профили буду рекламироваться в партнёрских объявлениях и на diva.network',
    show_in_ads_mobile: 'Ads',
    number_of_profiles: ':count of :total profiles selected',
    create_network: 'Create network',
    estimated_ad_views: 'Estimated ad views',
    total_ad_views: 'Total ad views',
    diva_network: 'diva.network',
    profiles_in_ads: 'Profiles shown in subscription ads',
    restart_order: 'Restart order',
    restart_moment: 'The order will restart in a moment',
    traffic_reports: 'Check out our traffic reports to help select targeting',
    daily_volume: 'Daily volume',
    won: 'Won',
    score: 'Score',
    banktransfer: 'Bank transfer',
    banktransfer_success: 'Payment has been stored. We are waiting for Bank Transfer from you',
    total_to_pay: 'Total amount',
    studio_performer: 'Studio performer',
    fee: 'Fee',
    gift: 'Gift',
    sub_amount: 'Amount',
    sub_amount_disclaimer: 'Amount (positive or negative) will be used for internal transaction. 0 means, that no transaction will be created. Empty amount means, that positive transaction will be created with amount equals to plan amount.',
    traffic_type_pop: 'Pop',
    traffic_type_native: 'Native',
    traffic_type_push: 'Push',
    push_traffic_description: 'Native ad traffic is delivered via clicked image ads that are shown on thousands of different adult websites via different ad exchanges and traffic networks that DivaTraffic gets its traffic from. When you buy native ad traffic, you are paying for ad displays (impressions) and the users have to click your image before being sent to your room.<br>The ad that the users will see will either be a live screenshot of your room, or the profile pitcure you use on the camsite, depending on which camsite you are buying traffic to.<br>Unlike pop traffic, display traffic requires the users to make a click before entering your room, so it will not be as useful in terms of getting a lot of traffic fast, but it means that the users you do get, will have made a choice to visit you, based on the image they see, and it should result in higher quality of traffic.<br>We can not guarantee how many will click on your image, only how many times it will be displayed.',
    push_traffic_selected: 'Push traffic selected',
    push_ads: '',
    push_traffic: 'Push Traffic',
    native_traffic: 'Native Traffic',
    set_password:"Change Password",
    show_password: "Set Password",
    password_confirmation: 'Confirm Password',
    user_deleted: 'User deleted, refresh page to view updated list',
    user_deleted_failed: 'Unable to delete',
    user_deleted_warning: 'Are you sure you want to delete user?',
    user_deleted_confirm_text: 'Delete User',
    user_deleted_cancel_text: 'Cancel',
    payment_instructions: 'Payment instructions',
    payment_instructions_detailed: 'Please pay the Total amount to the account shown below. When the money has been received in the bank account, your transaction will be approved, and tokens will be added to your account. Bank transfers can take 1-3 business days to be approved',
    i_have_paid: 'I have paid',
    account_information: 'Account information',
    validation_error: 'Ошибка валидации',
    studio_orders: 'Заказы студии',
    model_orders: 'Заказы моделей',
    nothing_found: 'Ничего не найдено',
    page_not_found: 'Страница не найдена',

    // Platforms Page
    platformsPage_btn_join: 'Присоединиться',
    platformsPage_btn_add_profile: 'Добавить профиль',


    // Sorting
    sorting: 'Сортировка',
    name_az: 'Название А-Я',
    name_za: 'Название Я-А',
    viewers_per_token_09: 'Просмотров за токен 0-9',
    viewers_per_token_90: 'Просмотров за токен 9-0',
    newest_first: 'В начале новые',
    oldest_first: 'В начале старые',
    online_offline: 'В сети/Не в сети',
    online_only: 'Только в сети',
    include_deactivated: 'Включая деактивированные',

    // Content
    load_more: 'Загрузить ещё',
    loading: 'Загрузка',
    choose_file: 'Выберите файл',
}
