import BaseService from "../BaseService";

export default class OrderService extends BaseService {
  constructor() {
    super();
    this.baseUrl = 'order';
  }

  async getById(id, conditions) {
    return super.makeRequest(
      'GET',
      {url: `${this.baseUrl}/${id}`, params: conditions}
    ).then(response => response.data.data);
  }

  async getOrders(conditions) {
    return super.makeRequest(
        'GET',
        {url: this.baseUrl, params: conditions}
      )
      .then(response => response.data);
  }

  async getOrdersCount() {
    return super.makeRequest(
        'GET',
        `${this.baseUrl}/count`
      )
      .then(response => response.data);
  }

  async changeStatus(id, status, conditions) {
    return super.makeRequest(
        'PATCH',
        {url: `${this.baseUrl}/${id}/status/${status}`, params: conditions},
      ).then(response => response.data.data);
  }

  async renew(id, conditions) {
    return super.makeRequest(
        'PATCH',
        {url: `order/${id}/renew`, params: conditions},
      ).then(response => response.data.data);
  }

  async create(data, conditions) {
    return super
      .makeRequest(
        'POST',
        {url: `order`, params: conditions},
        data
      ).then(response => response.data.data);
  }

  async update(id, data, conditions) {
    return super
      .makeRequest(
        'PATCH',
        {url: `order/${id}`, params: conditions},
        data
      ).then(response => response.data.data);
  }
}
