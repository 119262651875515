import SubscriptionPlanService from '../../service/subscriptionPlan/subscriptionPlan.service'
import AbstractSingleStore from "@/store/AbstractSingleStore";

const subscriptionPlanService = new SubscriptionPlanService();


const state = {
  subscriptionPlans: {},
  baseStateName: 'subscriptionPlans',
}

const mutations = {
}

const actions = {
  get: ({ commit }) => {
    if (!state.subscriptionPlans.data) {
      commit('setLoading');
      return subscriptionPlanService.getSubscriptionPlans().then((response) => {
        commit('setData', {data:response.data.data});
        return response;
      }).finally(() => {
        commit('setLoading', false);
      })
    }
    return Promise.resolve();
  },
  buyPlan({commit}, plan) {
    commit('setLoading');
    return subscriptionPlanService.buyPlan(plan).then(response => {
      return response;
    }).finally(()=>{
      commit('setLoading', false);
    });
  },
  freePlan({commit}, plan) {
    commit('setLoading');
    return subscriptionPlanService.freePlan(plan).then(response => {
      return response;
    }).finally(()=>{
      commit('setLoading', false);
    });
  },
  cancelPlan({commit}, planId) {
    commit('setLoading');
    return subscriptionPlanService.cancelPlan(planId).then(response => {
      return response;
    }).finally(()=>{
      commit('setLoading', false);
    });
  },
  confirmPayment({commit}, chargeId) {
    commit('setLoading');
    return subscriptionPlanService.confirmPayment(chargeId).then(response => {
      return response;
    }).finally(()=>{
      commit('setLoading', false);
    });
  }
}

const getters = {
  getPlan: (state) => (id) => {
    return state?.subscriptionPlans?.data ? state.subscriptionPlans.data.find(p => p.id == id) : {};
  },
}

export default AbstractSingleStore.mergeInto({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
});