import AbstractSingleStore from "@/store/AbstractSingleStore";
import ModelService from "../../service/model/model.service";

const modelService = new ModelService();

const state = {
    baseStateListName: 'models',
    baseStateName: 'model',
    model: {
        data:null,
        loading:false,
    },
    models: {
        data: [],
        loading:false,
    },
};
const mutations = {};
const actions = {
    getList: ({commit, state}, payload) => {
        commit('setListLoading');
        return modelService.getModelPage(payload).then(response => {
            commit('setData', {name: state.baseStateListName, data: response.data.data, pagination: response.data.meta.pagination, loading: false});
        }).finally(()=>{
            commit('setListLoading', false);
        });
    },
    get: ({commit, state}, id) => {
        commit('setLoading');
        return modelService.getOneModel(id).then(response => {
            commit('setData', {name: state.baseStateName, data: response.data.data, loading: false});
        }).finally(()=>{
            commit('setLoading', false);
        });
    },

    save: ({commit}, payload) => {
        commit('setLoading');
        return modelService.saveModel(payload).then((response) => {
            commit('setData', {data: response.data});
        }).catch(error => {
            commit('setValidationErrors', error);
        }).finally(() => {
            commit('setLoading', false);
        });
    },
    create: ({commit}, payload) => {
        commit('setLoading');
        return modelService.createModel(payload).then(response => {
            commit('setData', {name: state.baseStateName, data: response.data});
            commit('setValidationErrors', {});
        }).catch(error => {
            commit('setValidationErrors', error);
            return Promise.reject(error);
        }).finally(() => {
            commit('setLoading', false);
        });
    },
    addTokens: (context, payload) => {
        context.commit('setLoading');
        return modelService.addTokens(payload).then((response) => {
          context.dispatch('User/refresh', null, {root: true});
          return Promise.resolve(response);
        });
    },
};

const getters = {
    getStatus:() => (model) => {
        return statusCodes[model.status]
    },

    getCamsiteProfilesFromModel:(state, getters, rootState, rootGetters) => (model) => {
        //filter by type
        return rootGetters['Profile/getCamsiteProfilesFromList'](model.profiles && Array.isArray(model.profiles) ? model.profiles : model.profiles && Array.isArray(model.profiles.data) ? model.profiles.data : []);
    }
};

export default AbstractSingleStore.mergeInto({
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  });

const statusCodes = {
    0: 'paused',
    1: 'active',
    2: 'cancelled',
    3: 'pending',
    4: 'completed',
}
