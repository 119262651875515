import translations from "../../lang";
import store from "..";

const state = {
  __promises: {},
  translations: {},
  currentLanguage: "en",
};

const mutations = {
  loadLang: (state) => {
    state.translations = translations;
  },
  setLang: (state, lang) => {
    state.currentLanguage = lang;
  }
};

const actions = {
  load(context) {
    context.commit('loadLang');
  },
  set(context, lang) {
    context.commit('setLang', lang);
  }
};

const getters = {
  getTranslations() {
    return state.translations;
  },

  getCurrentLang(state, getters, rootState, rootGetters) {
    const user = rootGetters['User/getUser'];
    return user?.locale ? user.locale : state.currentLanguage;
  },

  getTrans: (state) => (key, params = {}) => {
    return trans(state, key, params, state.currentLanguage);
  },
  getTransChoise: (state) => (key, count, params = {}) => {
    let _params = Object.assign({ count }, params);
    let str = trans(state, key, _params);
    if (str == key) return str;

    let choices = str.split("|");
    for (let c_id in choices) {
      let choice = __tr_validate_range(choices[c_id], count);
      if (choice) return choice;
    }

    return str;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

var _choice_set_re = /^\s*\{(-?\d+(,\s*-?\d+)*|Inf|\*)\}\s*(.*)$/;
// eslint-disable-next-line no-useless-escape
var _choice_interval_re = /^\s*([\[\]\(])(\*|-Inf|-?\d+),\s*(\*|Inf|-?\d+)([\[\]\)])\s*(.*)$/; // match is null if not matched. match

function getTranslationFromKey(key, translations) {
  let result = "";
  if (key.indexOf(".") >= 0) {
    let path = key.split(".")[0];
    result = getTranslationFromKey(
      key.substr(key.indexOf(".") + 1),
      translations[path]
    );
  } else {
    return translations[key];
  }
  return result;
}

function _replace(str, phs) {
  if (!str) throw "";
  return Object.getOwnPropertyNames(phs).reduce(
    (a, n) => a.replace(new RegExp(":" + n, "g"), phs[n]),
    str
  );
}

function __tr_validate_range(str, count) {
  let match = str.match(_choice_set_re);
  if (match) {
    // match[1] is a comma separated list of accepted counts, OR Inf, OR *.
    if (match[1] == "Inf" || match[1] == "*") return match[3];
    let numbers = match[1].split(/,\s*/).map((n) => parseInt(n, 10)); // parseInt can take multiple arguments
    if (numbers.reduce((a, n) => a || n == count, false)) {
      return match[3];
    }
    return false;
  } else {
    match = str.match(_choice_interval_re);
    if (!match) return str;

    if (match[2] != "-Inf" && match[2] != "*") {
      // Check that the count is within the lower bound
      let lower = parseInt(match[2]);
      if (count < lower) return false;
      if (match[1] != "[" && count == lower) return false;
    }

    if (match[3] != "Inf" && match[3] != "*") {
      // Check that the count is within the upper bound
      let upper = parseInt(match[3]);
      if (count > upper) return false;
      if (match[4] != "]" && count == upper) return false;
    }

    return match[5];
  }
}

function trans(state, key, params = {}, defaultLang = 'en') {
  var result = key;
  try {
    result = getTranslationFromKey(
      key,
      state.translations[store.getters['User/getUser'] ? store.getters['User/getUser'].locale : defaultLang]
    );
    if (typeof result == "undefined") {
      throw "";
    }
    result = _replace(result, params);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn("No translation found for " + key);
    try{
      //fallback
      let val = getTranslationFromKey(key,  state.translations['en']);
      return val ? val : key;
    } catch {
      return key;
    }
  }
  return result;
}
