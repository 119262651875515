const state = {
  deviceTypes: []
};

const mutations = {
  SET_DEVICE_TYPES: (state, payload) => {
    state.deviceTypes = payload;
  }
};

const actions = {
  get: ({ dispatch }) => {
    return dispatch("Dictionary/get", null, {root: true})
  },
};

const getters = {
  getDeviceTypes(state) {
    return state.deviceTypes;
  },
  getDeviceTypeById: state => id => {
    let deviceT = state.deviceTypes.find(t => {
      return id == t.id;
    }); return deviceT ? deviceT : { name: 'undefined' }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
