/* eslint-disable no-unused-vars */
import DashboardLayout from '@/views/Layout/DivaLayout.vue';
import AuthLayout from '@/views/Layout/AuthLayout.vue';
import store from '../store'
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

const AlternativeDashboard = () => import('@/views/Dashboard/Dashboard');
const SubPageContainer = () => import('@/views/SubPageContainer');
const AddProfilePage = () => import('@/views/Profiles/AddProfilePage.vue');
const BoostPage = () => import('@/views/Boost/BoostPage.vue');
const CamHead = () => import('@/views/Tools/CamHead.vue');
const InviteAFriend = () => import('@/views/Tools/InviteAFriend.vue');
const Login = () => import('@/views/Auth/Login.vue');
const OrdersPage = () => import('@/views/Orders/OrdersPage.vue');
const OrderStatsPage = () => import('@/views/Orders/OrderStatsPage');
const Plans = () => import('@/views/Plan/PlansPage.vue');
const CreatePlan = () => import('@/views/Plan/CreatePlanPage.vue');
const PlanDetails = () => import('@/views/Plan/PlanDetails.vue');

const ProfilesPage = () => import('@/views/Profiles/ProfilesPage');
const EditProfile = () => import('@/views/Profiles/EditProfile.vue');
const Links = () => import('@/views/Links/LinksPage');
const AddLink = () => import('@/views/Links/AddLinkPage');

const StudioPage = () => import('@/views/Studio/StudioPage');
const CreateModel = () => import('@/views/Studio/SubPages/CreateModel');
const ModelSettings = () => import('@/views/Studio/SubPages/ModelSettings');
const TokensSettings = () => import('@/views/Settings/SubPages/Tokens');
const Register = () => import('@/views/Register.vue');
const ResetPassword = () => import('@/views/ResetPassword.vue');
const Resources = () => import('@/views/Resources.vue');
const SmartLink = () => import('@/views/Tools/SmartLink.vue');
const Statistics = () => import('@/views/Statistics/StatsPage.vue');
const Tokens = () => import('@/views/Tokens');
const Wiretransfer = () => import('@/views/Wiretransfer');
const TransactionsPage = () => import('@/views/Transactions/TransactionsPage')
const Tools = () => import('@/views/Tools.vue');
const Widget = () => import('@/views/Tools/Widget.vue');

const Settings = () => import('@/views/Settings/SettingsPage');
const Account = () => import('@/views/Settings/SubPages/Account.vue');
const ModelAccount = () => import('@/views/Settings/SubPages/Account/StudioAccount.vue');
const ProfilesSettings = () => import('@/views/Settings/SubPages/ProfilesSettings');
const Billing = () => import('@/views/Settings/SubPages/Billing.vue');
const Payouts = () => import('@/views/Settings/SubPages/Payouts.vue');
const Postbacks = () => import('@/views/Settings/SubPages/Postbacks.vue');
const Camsite = () => import('@/views/Settings/SubPages/Camsite.vue');
const Password = () => import('@/views/Settings/SubPages/Password.vue');
const Subscription = () => import('@/views/Settings/SubPages/Subscription.vue');
const Privacy = () => import('@/views/Settings/SubPages/Privacy.vue');
const Social = () => import('@/views/Settings/SubPages/SocialSettings.vue');
const Notifications = () => import('@/views/Settings/SubPages/Notifications.vue');

import Static from '@/views/Static.vue';

const ifAuthenticated = (to, from, next) => {
  if (store.getters["User/isAuthenticated"]) {
    check(to, from, next);
  } else {
    next({name: "Authorize"})
  }
}

const notAuthenticated = (to, from, next) => {
  if (store.getters["User/isAuthenticated"]) {
    next({name: 'dashboard'});
  } else {
    next();
  }
}

function check(to, from, next) {
  if (
    !to.meta.permission ||
    store.getters["User/getUser"].permissions[to.meta.permission]
  ) {
    next();
  } else {
    next(from);
  }
}

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: {name: 'dashboard'},
    name: 'main_dashboard',
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: AlternativeDashboard,
        meta: {
          buttonIcon: 'fas fa-angle-double-up',
          buttonText: 'messages.boost',
          buttonFunction(that) {
            that.$router.push({name: 'boost'});
          },
          hasCustomHeader: true,
        }
      },
      {
        path: 'orders',
        name: 'orders',
        component: OrdersPage,
        meta: {
          permission: 'orders',
          buttonIcon: 'fas fa-plus',
          buttonText: 'messages.create_order',
          buttonFunction(that) {
            that.$router.push({name: 'boost'});
          },
        },
      },
      {
        path: 'order/:orderId',
        name: 'order_stats',
        component: OrderStatsPage,
        props: true,
        meta: {permission: 'orders', breadCrumbs: ['orders']},
      },
      {
        path: 'boost/:orderId?',
        name: 'boost',
        component: BoostPage,
        props: true,
        meta: {permission: 'orders-create'},
      },
      {
        path: 'boost/link/:orderId?',
        name: 'boost_link',
        component: BoostPage,
        props: (route) => ({ link: true, orderId: route.params.orderId }),
        meta: {permission: 'orders-create'},
      },
      //Profile Pages
      {
        path: 'profiles',
        name: 'profiles',
        component: ProfilesPage,
        meta: {
          permission: 'profiles',
          buttonIcon: 'fas fa-plus',
          buttonText: 'messages.add_profile',
          buttonFunction(that) {
            that.$router.push({name: 'add_profile'});
          },
        },
      },
      {
        path: 'profiles/addprofile',
        name: 'add_profile',
        component: AddProfilePage,
        meta: {permission: 'profiles-create', breadCrumbs: ['profiles']},
      },
      {
        path: 'profiles/:profileId?/edit',
        name: 'edit_profile',
        component: EditProfile,
        meta: {permission: 'profiles-create', breadCrumbs: ['profiles']},
        props: true,
      },
      //Link Pages
      {
        path: 'links',
        name: 'links',
        component: Links,
        meta: {
          permission: 'profiles',
          buttonIcon: 'fas fa-plus',
          buttonText: 'messages.add_link',
          buttonFunction(that) {
            that.$router.push({name: 'add_link'});
          },
        },
      },
      {
        path: 'links/add',
        name: 'add_link',
        component: AddLink,
        meta: {permission: 'profiles-create', breadCrumbs: ['links']},
        props: true,
      },
      {
        path: 'links/:profileId?/edit',
        name: 'edit_link',
        component: AddLink,
        meta: {permission: 'profiles-create', breadCrumbs: ['links']},
        props: true,
      },
      //Studio/Model pages
      {
        path: 'studio',
        redirect: 'studio/',
        name: 'studio',
        component: SubPageContainer,
        meta: {
          permission: 'models',
        },
        children: [
          {
            path: '',
            name: 'models',
            component: StudioPage,
            meta: {
              buttonIcon: 'fas fa-plus',
              buttonText: 'messages.add_model',
              buttonFunction(that) {
                that.$router.push({name: 'add_model'});
              },
            },
          },
          {
            path: 'add',
            name: 'add_model',
            component: CreateModel,
          },
          {
            path: 'edit/:modelId',
            name: 'model_settings',
            component: ModelSettings,
            props: true,
            children: [
              {
                path: 'account',
                name: 'model_account',
                component: ModelAccount,
              },
              {
                path: 'profile',
                name: 'model_profiles',
                component: ProfilesSettings,
              },
              {
                path: 'tokens',
                name: 'model_tokens',
                component: TokensSettings,
              },
              {
                path: 'security',
                name: 'model_password',
                component: Password,
              },
              {
                path: 'privacy',
                name: 'model_privacy',
                component: Privacy,
              },
              {
                path: 'social',
                name: 'model_social',
                component: Social,
              },
            ]
          }
        ]
      },
      //Tokens Pages
      {
        path: 'tokens',
        name: 'tokens',
        component: Tokens,
        meta: {permission: 'payments-make'},
      },
      {
        path: 'tokens/wiretransfer',
        name: 'wiretransfer',
        component: Wiretransfer,
        props: true,
        meta: {permission: 'payments-make'},
      },
      {
        path: 'transactions',
        name: 'transactions',
        component: TransactionsPage,
        meta: {
          permission: 'balance',
        },
      },
      {
        path: 'resources',
        name: 'resources',
        component: Resources,
        meta: {}
      },
      {
        path: 'statistics',
        name: 'statistics',
        component: Statistics,
        meta: {
          permission: 'statistic',
          hasCustomHeader: true,
        },
        props: true,
      },
      {
        path: 'packages/:packId?',
        name: 'packages',
        component: Plans,
        props: true,
        meta: {
          permission: 'plans',
          buttonIcon: 'fas fa-plus',
          buttonText: 'messages.add_package',
          buttonFunction(that) {
            that.$router.push({name: 'create_package'});
          },
        },
      },
      {
        path: 'details/:packId?',
        name: 'PackageDetails',
        component: PlanDetails,
        props: true,
        meta: {
          permission: 'plans',
          buttonIcon: 'fas fa-plus',
          buttonText: 'messages.add_package',
          buttonFunction(that) {
            that.$router.push({name: 'create_package'});
          },
        },
      },
      {
        path: 'package/create',
        name: 'create_package',
        component: CreatePlan,
        props: true,
        meta: {permission: 'plans-create'},
      },
      {
        path: 'package/edit',
        name: 'edit_package',
        component: CreatePlan,
        props: true,
        meta: {permission: 'plans-create'},
      },
      {
        path: 'settings',
        name: 'settings',
        redirect: '/settings/account',
        component: Settings,
        meta: {},
        children: [
          {
            path: 'account',
            name: 'account',
            component: Account,
          },
          {
            path: 'password',
            name: 'password',
            component: Password,
          },
          {
            path: 'privacy',
            name: 'privacy',
            component: Privacy,
          },
          {
            path: 'notifications',
            name: 'notifications',
            component: Notifications,
          },
          {
            path: 'subscription',
            name: 'subscription',
            component: Subscription,
            meta: {permission: 'subscription-plans-have'}
          },
          {
            path: 'camsite',
            name: 'camsite',
            component: Camsite,
            meta: {permission: 'white-label'}
          },
          {
            path: 'billing',
            name: 'billing',
            component: Billing,
          },
          {
            path: 'payouts',
            name: 'payouts',
            component: Payouts,
            meta: {permission: 'users-payout'}
          },
          {
            path: 'postbacks',
            name: 'postbacks',
            component: Postbacks,
            meta: {permission: 'post-backs'}
          }
        ]
      },
      {
        path: 'tools',
        name: 'tools',
        component: Tools,
        meta: {permission: 'tools'},
        children: [
          {
            path: 'camhead',
            name: 'CamHead',
            component: CamHead,
          },
          {
            path: 'widget',
            name: 'Widget',
            component: Widget,
          },
          {
            path: 'smartlink',
            name: 'SmartLink',
            component: SmartLink,
          },
        ],
      },
      {
        path: 'static',
        name: 'static',
        component: Static
      },
      {
        path: 'inviteafriend',
        name: 'invite_friend',
        component: InviteAFriend,
        meta: {}
      },
      {
        path: '/:pathMatch(.*)',
        name: 'not_found',
        component: NotFound,
      }
    ]
  },
  {
    path: '/',
    component: AuthLayout,
    name: 'Authorize',
    redirect: '/login',
    beforeEnter: notAuthenticated,
    children: [
      {
        path: 'Login',
        name: 'Login',
        component: Login,
      },
      {
        path: 'Register',
        name: 'Register',
        component: Register,
      },
      {
        path: 'ResetPassword',
        name: 'ResetPassword',
        component: ResetPassword,
      },
    ]
  },
];

export default routes;
