/**
 * Automatically imports all the modules and exports as a single module object
 */

const requireModule = require.context('.', true,  /.*\.js$/);
const modules = {};

requireModule.keys().forEach(filename => {
    if (filename!='./index.js'){
       // create the module name from fileName
        // remove the store.js extension
        var folder = filename.substr(2).split('/')[0];
        var mod = filename.split('/');
        mod = mod[mod.length-1];
        
        const moduleName = mod
                    .replace(/(\.\/|\.js)/g, '')
        if (folder) {
            if (!modules[folder]) {
                modules[folder]={};
            }
            modules[folder][moduleName] = requireModule(filename).default || requireModule(filename); 
        } else {
            modules[moduleName] = requireModule(filename).default || requireModule(filename); 
        }
    }
});

export default modules;