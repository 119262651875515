import BaseService from '../BaseService'

export default class PlatformService extends BaseService {
    constructor() {
        super();
        this.baseUrl = 'platform';
    }

    async getPlatforms() {
        return super.makeRequest('GET',
          `${this.baseUrl}?include[]=programs`
        )
          .then(response => response.data);
    }
}
