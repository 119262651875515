import AbstractStore from "@/store/AbstractStore";

/**
 * Extend of AbstractStore.
 * Abstract store functionality that allows to have state data as object with
 * data, loading flag and validation errors.
 *
 * In component use import getters and use as follow:
 * this.getData('data object name')
 * Same logic for other getters.
 */

const store = {
  /**
   * Methods can be used without argument if state.baseStateName was set.
   */
  getters: {
    loadingByName: state => (name) => {
      return state[name]?.['loading'];
    },
    getDataByName: state => (name = state.baseStateName) => {
      return state[name]?.['data'];
    },
    getErrorsByName: state => (name = state.baseStateName) => {
      return state[name]?.['errors'];
    },
    pagination: state => (name = state.baseStateName) => {
      return state[name]?.['pagination'];
    },
  },
  mutations: {
    setLoadingByName(state, payload) {
      const name = payload?.name || state.baseStateName;
      state[name]['loading'] = payload?.val === undefined ? true : payload.val;
    },
    setErrorsByName(state, payload) {
      const name = payload?.name || state.baseStateName;
      state[name]['errors'] = payload?.errors;
    },
    setValidationErrorsByName(state, payload) {
      const name = payload.name;
      const error = payload.error;
      if (error.http_code === 400) {
        state[name ? name : state.baseStateName]['errors'] = error.message;
      } else {
        throw error;
      }
    },
  }
};

export default AbstractStore.mergeInto(store);
