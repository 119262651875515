const state = {
  browsers: null
};

const mutations = {
  SET_BROWSERS: (state, payload) => {
    state.browsers = payload;
  }
};

const actions = {
  get: ({ dispatch }) => {
    return dispatch("Dictionary/get", null, {root: true})
  },
};

const getters = {
  getBrowsers(state) {
    return state.browsers;
  },
  getBrowserById: state => id => {
    return state.browsers.find(c => {
      return id == c.id;
    })
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
