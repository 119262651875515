import BaseService from "../BaseService";
import moment from "moment";
import dimensions from "./dimensions";

export default class StatsService extends BaseService {
  static targeting = ['country', 'source', 'device_type', 'device_brand', 'browser', 'os', 'network'];
  static nonArray = ['order', 'platform', 'plan'];
  
  async getStats(payload) {
    return super
      .makeRequest(
        "GET",
        {url: "statistic", params: payload}
      )
  }
  async getTotals(payload) {
    //get correct columns:
    let params = Object.assign({}, payload);
    params.columns = ['impressions','sales','registrations','rebills','redirects','earnings','opens','clicks'];    

    return super
      .makeRequest(
        "GET",
        {url: 'statistic/totals', params: params},
        {}
      )
  }

  static getParamsFromFilters(filters) {
    var params = {
      filter: {
        date_from: filters.date_from ? filters.date_from : moment().subtract(14, "days").format("YYYY-MM-DD"),
        date_to: filters.date_to ? filters.date_to : moment().format("YYYY-MM-DD"),
      },
      page: filters.page ? filters.page : 1,
      traffic_type: "",
      "dimension": filters.groupBy ? filters.groupBy : "date",
      sorting: {
      }
    };
    params.sorting[filters.sortColumn ? filters.sortColumn : "date"] = filters.sortDirection ? filters.sortDirection : "asc";
    if (filters.custom) {
      params.filter.targeting = {};
      filters.custom.forEach(f => {
        var dim = dimensions[f.dimIndex];
        let val;
        if (!dim["paramValue"]) {
          val = f.value;
        } else {
          val = dim.paramValue(f.value)
        }
        if (this.targeting.indexOf(dim.param_name)>=0) {
          if (!params.filter.targeting) {
            params.filter.targeting = {};
          }
          if (params.filter.targeting[dim.targeting_param_name]) {
            params.filter.targeting[dim.targeting_param_name].push(val);
          } else {
            params.filter.targeting[dim.targeting_param_name] = [val];
          }
        } else if (this.nonArray.indexOf(dim.param_name)>=0){
            params.filter[dim.param_name] = val;
        } else {
          if (params.filter[dim.param_name]) {
            params.filter[dim.param_name].push(val)
          } else {
            params.filter[dim.param_name] = [val]
          }
        }
        
      });
    }
    return params;
  }
  static getParamsFromFiltersForGraph(filters) {
    let filt = Object.assign({},filters);
    filt.groupBy = 'date';
    filt.sortColumn = 'date';
    filt.sortDirection = 'asc';
    return this.getParamsFromFilters(filt);
  }

  static addParam(params, paramName, value) {
    if (typeof value === "object" && value !== null) {
      Object.keys(value).forEach(p => {
        StatsService.addParam(params, p, value[p]);
      });
    } else {
      if (!params["filter["+paramName+"]"]) {
        params["filter["+paramName+"]"] = [];
      }
      params["filter["+paramName+"]"].push(value);
    }
  }
  static getAsRequestParamString(params) {
    var result = "";
    Object.keys(params).forEach(p => {
      result += "&" + p + "=" + params[p].join(",");
    });
    return result;
  }
}
