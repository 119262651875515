import {mapGetters} from 'vuex'


export default {
    data() {
        return {
        };
    },

    computed: {
        //locale() { return (this.$store.state.user.locale === undefined) ? 'en' : this.$store.state.user.locale ; },
        locale() {
            if (this.$store.state.user && this.$store.state.user.locale) return this.$store.state.user.locale;
            if (this.$store.state.local && this.$store.state.local.locale) return this.$store.state.local.locale;
            return 'en';
        },
        locale_flag() { return '../../img/flags/code/' + this.locale + '.png'; },
        ...mapGetters('Lang', ['getTrans', 'getTransChoise'])
    },

    methods: {
    }
}
