<template>
  <div class="auth__wrapper">
    <base-nav
      v-model:show="showMenu"
      type="white"
      menu-classes="justify-content-end"
      class="navbar-horizontal auth__header-navbar"
      expand="lg"
    >
      <template v-slot:brand>
        <router-link class="navbar-brand" to="/">
          <img src="/img/divatraffic_logo.png"/>
        </router-link>
      </template>

      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <router-link to="/">
              <img src="/img/divatraffic_logo.png"/>
            </router-link>
          </div>
          <div class="col-6 collapse-close">
            <button
              type="button"
              class="navbar-toggler"
              @click="showMenu = false"
            >
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>

      <ul class="navbar-nav auth__header-navbar-nav">
        <li class="nav-item">
          <router-link :to="{ path: 'services' }" class="nav-link">
            <span class="nav-link-inner--text">{{ getTrans('messages.services') }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ path: 'about' }" class="nav-link">
            <span class="nav-link-inner--text">{{ getTrans('messages.about') }}</span>
          </router-link>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="howItWorks" role="button" data-toggle="dropdown" aria-haspopup="true"
             aria-expanded="false">
            <span class="nav-link-inner--text">{{ getTrans('messages.how_it_works') }}</span>
          </a>
          <div class="dropdown-menu auth__header-dropdown-menu" aria-labelledby="howItWorks">
            <a href="https://divatraffic.com/onsubscription" target="_blank" class="nav-link">
              <span class="nav-link-inner--text">{{ getTrans('messages.traffic_on_subscription') }}</span>
            </a>
            <a href="https://divatraffic.com/ondemand" target="_blank" class="nav-link">
              <span class="nav-link-inner--text">{{ getTrans('messages.traffic_on_demand') }}</span>
            </a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="pricingDropdown" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            <span class="nav-link-inner--text">{{ getTrans('messages.pricing') }}</span>
          </a>
          <div class="dropdown-menu auth__header-dropdown-menu" aria-labelledby="pricingDropdown">
            <a href="https://divatraffic.com/pricing/onsubscription" target="_blank" class="nav-link">
              <span class="nav-link-inner--text">{{ getTrans('messages.traffic_on_subscription') }}</span>
            </a>
            <a href="https://divatraffic.com/pricing/ondemand" target="_blank" class="nav-link">
              <span class="nav-link-inner--text">{{ getTrans('messages.traffic_on_demand') }}</span>
            </a>
          </div>
        </li>
        <li class="nav-item">
          <router-link :to="{ path: 'support' }" class="nav-link">
            <span class="nav-link-inner--text">{{ getTrans('messages.support') }}</span>
          </router-link>
        </li>
        <li class="nav-item dropdown">
          <language-dropdown></language-dropdown>
        </li>
        <li class="nav-item auth__header-nav-item-controls">
          <router-link :to="{ path: 'Login' }" class="nav-link auth__header-link-login">
            <span class="nav-link-inner--text">{{ getTrans('messages.sign_in') }}</span>
          </router-link>
          <router-link :to="{ path: 'Register' }" class="nav-link auth__header-link-sign-in">
            <span class="nav-link-inner--text">{{ getTrans('messages.sign_up') }}</span>
          </router-link>
        </li>
      </ul>
    </base-nav>

    <div class="main-content" @click="showMenu = false">
      <router-view></router-view>
    </div>

    <footer class="py-5" id="footer-main">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <!-- eslint-disable -->
            <div class="footer__copyright">
              <router-link to="/" class="footer__copyright--link">DivaTraffic</router-link>
              © {{ year }}
              Diva Technologies AS. Norway
            </div>
            <!-- eslint-enable -->
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

import LanguageDropdown from "@/components/LanguageDropdown";

export default {
  components: {
    LanguageDropdown
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: "login-page",
    };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    },
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    }
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
};
</script>
<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}
</style>
