import axios from 'axios';
import store from '../store';

export default class BaseService {
  async makeRequest(method, url, data = {}, formData = false) {
    let params = null;
    if (url instanceof Object) {
      params = url.params;
      params = params ? this.convertToQuery(params) : null;
      url = url.url;
    }

    if (method != 'POST' && method != 'GET') {
      data._method = method;
      method = 'POST';
    }

    return axios({
      url,
      method,
      data: formData ? this.convertToFormData(data) : data,
      params: params,
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      withCredentials: true,
    }).catch((error) => {
      if (error.response?.data?.error?.code === 'GEN-WRONG-ARGS') {
        store.commit('Error/SET_ERRORS', error.response.data.error.message, {
          root: true,
        });
        return Promise.reject(error.response.data.error);
      } else {
        return Promise.reject(error);
      }
    });
  }

  convertToQuery(conditions, key) {
    const params = {};
    Object.keys(conditions).map((item) => {
      const k = key ? `${key}[${item}]` : item;
      if (conditions[item] instanceof Array) {
        Object.assign(params, { [k]: conditions[item] });
      } else if (conditions[item] instanceof Object) {
        Object.assign(params, this.convertToQuery(conditions[item], k));
      } else {
        Object.assign(params, { [k]: conditions[item] });
      }
    });
    return params;
  }

  convertToFormData(data) {
    let formData = new FormData();
    this._addToFormData(formData, data);
    return formData;
  }

  _addToFormData(formData, data, previousKey = null) {
    for (let key in data) {
      if (data[key] instanceof Array) {
        this._addToFormData(formData, data[key], key);
      } else {
        let k = previousKey ? previousKey + '[]' : key;
        formData.append(k, data[key]);
      }
    }
  }
}
