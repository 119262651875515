<template>
  <router-view></router-view>
</template>

<script>
import axios from 'axios'
export default {
  mounted() {
    axios.interceptors.response.use(undefined, (error) => {
      const statusCode = error && error.response ? error.response.status : null;
      if (statusCode === 500) {
        this.$swal.fire(
          this.getTrans('messages.something_went_wrong'),
          this.getTrans("messages.we_are_working_on_the_problem"),
          'error'
        );
      }
      return Promise.reject(error);
    });

    /**
     * Global errors (rejected promises) handling.
     * Handles http errors that were not handled in services.
     */
    window.onunhandledrejection = unhandledrejection => {
      const response = unhandledrejection.reason.response;
      const code = response?.data?.error?.code;
      if (code) {
        if (code === 'GEN-NOT-FOUND') {
          this.$swal.fire(
            this.getTrans('API endpoint not found'),
            this.getTrans("messages.we_are_working_on_the_problem"),
            'error'
          );
        } else if (code === 'GEN-FORBIDDEN') {
          this.$swal.fire(
            this.getTrans('Forbidden'),
            '',
            'error'
          );
        }
      }
      /**
       * Handle validation errors.
       * Format was transformed in BaseService.
       */
      if (unhandledrejection.reason?.code === 'GEN-WRONG-ARGS' && unhandledrejection.reason.message) {
        let errors = [];
        for (let key in unhandledrejection.reason.message) {
          errors.push(unhandledrejection.reason.message[key][0]);
        }
        this.$swal.fire(
          this.getTrans('messages.validation_error'),
          errors.join('</br>'),
          'warning'
        );
      }
    }

    // Load store that are in use in many places.
    this.$store.dispatch('Platform/get').catch(() => {});


    //loading external scripts
    [

      //required for securion
      "/js/securionpay_checkout.js",
      "/js/jquery-3.3.1.min.js"
    ].forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },
};
</script>
