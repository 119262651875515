import BaseService from '../BaseService'

export default class NetworkService extends BaseService {
    async getNetworks(commit) {
        return super.makeRequest('GET', `network`).then((response) => {
            commit('SET_NETWORKS', response.data.data);
            return response
        }).catch((response) => {
            throw response
        });
    }
}
