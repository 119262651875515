import UserService from '../../service/user/user.service';
import AbstractSingleStore from '@/store/AbstractSingleStore';

const userService = new UserService();

const state = {
  baseStateName: 'user',
  user: {},
  isAuthenticated: false,
  triedLogin: false,
  randomKey: Math.random().toString(),
};
const mutations = {
  setUser: (state, payload) => {
    if (payload) {
      let permissions = {};
      if (payload?.permissions && Array.isArray(payload.permissions)) {
        payload.permissions.forEach((permission) => {
          permissions[permission] = true;
        });
      }
      payload.permissions = permissions;
    }
    state.user = { data: payload, loading: false };
  },
  setAuthenticated: (state, payload) => {
    state.isAuthenticated = payload;
    state.triedLogin = true;
  },
  triedLogin: (state) => {
    state.triedLogin = true;
  },
  newRandom: (state) => {
    state.randomKey = Math.random().toString();
  },
};
const actions = {
  get: ({ commit, getters }) => {
    commit('setLoading');
    if (getters.getUser) {
      return Promise.resolve();
    }
    return userService
      .getUserInfo()
      .then((data) => {
        commit('setUser', data);
        commit('setAuthenticated', true);
        commit('triedLogin');
      })
      .catch((response) => {
        commit('triedLogin');
        commit('setAuthenticated', false);
        throw response;
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },
  refresh: ({ commit }) => {
    commit('setLoading');
    return userService
      .getUserInfo()
      .then((data) => {
        commit('setUser', data);
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },
  login: ({ commit, dispatch }, payload) => {
    commit('setLoading');
    return userService
      .login(payload)
      .then((res) => {
        if (res) {
          commit('setAuthenticated', true);
          dispatch('get');
        }
      })
      .catch((error) => {
        commit('setLoading', false);
        commit('setValidationErrors', error);
      });
  },
  logout: ({ commit }) => {
    commit('setLoading');
    return userService.logout().then((res) => {
      if (res) {
        commit('setAuthenticated', false);
        commit('setUser', null);
      }
    });
  },
  register: ({ commit }, payload) => {
    return userService.registerUser(payload).then((res) => {
      if (res) {
        commit('setAuthenticated', true);
      }
    });
  },
  saveUser: ({ commit }, payload) => {
    commit('setLoading');
    return userService
      .saveUser(payload)
      .then((data) => {
        commit('setUser', data);
      })
      .catch((error) => {
        commit('setValidationErrors', error);
        return Promise.reject(error);
      })
      .finally(() => {
        commit('setLoading', false);
        commit('newRandom');
      });
  },
  deleteCamsiteSettings: (context, payload) => {
    return userService.deleteCamsiteSettings(payload);
  },
};
const getters = {
  getUser(state) {
    return state.user.data;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  triedLogin(state) {
    return state.triedLogin;
  },
  hasRoles: (state) => (roles) => {
    var allowed = false;
    if (state.user && state.user.data.roles) {
      roles.forEach((role) => {
        if (state.user.data.roles.indexOf(role) >= 0) {
          allowed = true;
        }
      });
    }
    return allowed;
  },
  can: (state) => (permission) => {
    return state.user?.data?.permissions[permission] ? true : false;
  },
  userAvatarUrl: (state) => {
    return process.env.VUE_APP_API_URL + 'user/avatar?&rand=' + state.randomKey;
  },
  userAvatarUrlByid: (state) => (id) => {
    if (id == state.user.data.id) {
      return (
        process.env.VUE_APP_API_URL +
        `user/avatar/${id}?&rand=` +
        state.randomKey
      );
    }
    return (
      process.env.VUE_APP_API_URL +
      `model/${id}/avatar?&rand=` +
      state.randomKey
    );
  },
  currentSubscriptionPlan(state) {
    if (state.user?.data?.subscriptionPlans?.data?.length) {
      let last =
        state.user.data.subscriptionPlans.data[
          state.user.data.subscriptionPlans.data.length - 1
        ];
      if (!last.deleted_at) {
        return last;
      }
      return null;
    }
  },
  isStudio(state, getters) {
    return getters.can('studio-owner');
  },
};

export default AbstractSingleStore.mergeInto({
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
});
