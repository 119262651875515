import BaseService from "../BaseService";

export default class PostbackService extends BaseService {
    async getPostbacks() {
        return super.makeRequest(
            "GET",
            urls.getAll
        )
    }
    async createPostback(payload) {
        return super.makeRequest(
            "POST",
            urls.create,
            payload
        )
    }
    async save(payload) {
        return super.makeRequest(
            "PATCH",
            urls.save,
            payload
        )
    }
    async delete(payload) {
        return super.makeRequest(
            "DELETE",
            urls.delete.replace(/\{id\}/g, payload.id ? payload.id : payload)
        )
    }
}

const urls = {
    getAll: 'api/v1/post_back',
    create: 'api/v1/post_back',
    save: 'api/v1/post_back/{id}',
    delete: 'api/v1/post_back/{id}'
}